<template>
  <v-scale-transition class="align-center" group hide-on-leave origin="left" tag="v-row">
    <v-col key="icon" cols="1">
      <v-icon>mdi-calendar</v-icon>
    </v-col>
    <v-col v-if="!editMode" key="date" cols="10" style="text-align: justify">
      <span v-if="dueDate">{{ moment(dueDate).format('DD. MMMM YYYY') }}</span>
      <span v-else class="grey--text">Keine Fälligkeitsdatum</span>
    </v-col>
    <v-col v-else key="date-edit" cols="10">
      <date-picker v-model="updatedDueDate" :disabled="loading" autofocus clearable color="warning" hide-details
                   label="Fälligkeitsdatum"/>
    </v-col>
    <v-col v-if="!editMode" key="actions" v-can:update-stacks="{ id: this.stackId, keys: ['items'] }" cols="1">
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon @click="initializeEditMode" v-on="on">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        Fälligkeitsdatum bearbeiten
      </v-tooltip>
    </v-col>
    <v-col v-else key="edit-actions" cols="1">
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn :loading="loading" icon @click="saveChanges" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        Änderungen speichern
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn :disabled="loading" icon @click="stopEditMode" v-on="on">
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
        </template>
        Änderungen verwerfen
      </v-tooltip>
    </v-col>
  </v-scale-transition>
</template>
<script lang="ts">
import Vue from 'vue';
import {Types} from 'mongoose';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import moment from 'moment';
import DatePicker from '@/components/common/datePicker.vue';

moment.locale('de');

export default Vue.extend({
  components: {DatePicker},
  props: {
    dueDate: {type: Object as () => Date, required: true},
    stackId: {type: Object as () => Types.ObjectId, required: true},
    itemId: {type: Object as () => Types.ObjectId, required: true},
  },
  data: () => ({
    editMode: false,
    loading: false,
    updatedDueDate: undefined as unknown as Date,
  }),
  computed: {
    moment: () => moment,
  },
  methods: {
    initializeEditMode() {
      this.updatedDueDate = this.dueDate;
      this.editMode = true;
    },
    stopEditMode() {
      this.updatedDueDate = undefined as unknown as Date;
      this.editMode = false;
    },
    async saveChanges() {
      this.loading = true;
      try {
        await StackManagerApi.updateItem(this.stackId, this.itemId, {dueDate: this.updatedDueDate || null});
        this.$$showSnackbar('Die Änderungen wurden erfolgreich gespeichert', 'success');
        this.$emit('update:dueDate', this.updatedDueDate);
        this.stopEditMode();
      } catch (e) {
        this.$$showSnackbar('Beim Speichern der Änderungen ist ein Fehler aufgetreten', 'error');
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
