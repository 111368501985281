import Vue from 'vue';
import {MessengerMessageDocument} from "@/documentTypes/messenger/MessengerMessage.document";
import {MessengerMailDocument} from "@/documentTypes/messenger/messages";

interface MessengerBusInterface {
    $emit(event: 'update:replyToMessage', message?: MessengerMessageDocument | MessengerMailDocument): void;

    $on(event: 'update:replyToMessage', callback: (message?: MessengerMessageDocument | MessengerMailDocument) => void): void;
}

export const MessengerBus: MessengerBusInterface = new Vue();
