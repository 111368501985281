import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {ConfigFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/ConfigFields';

export const configFieldsDictionary: FieldDictionary<ConfigFields> = [
    {
        text: 'Eigenes Firmenprofil',
        value: ConfigFields.CUSTOMER,
    },
    {
        text: 'E-Mail-Zugangsdaten',
        value: ConfigFields.MAIL,
    },
    {
        text: 'Erzeugungsdatum',
        value: ConfigFields.CREATED_AT,
    },
    {
        text: 'Änderungsdatum',
        value: ConfigFields.UPDATED_AT,
    },
];
