<template>
  <v-alert v-if="notification.showInApp" :key="notification.id" class="elevation-3 mt-4 mx-2 notification" dark
           transition="slide-x-transition">
    <template v-slot:prepend>
      <div class="mr-4">
        <v-icon :color="formattedTemplateStatus.color">{{ formattedTemplateStatus.icon }}</v-icon>
      </div>
    </template>
    <template v-slot:default>
      <div class="body-2">
        {{ notification.messagePayload.name }} {{ formattedTemplateStatus.text }}
      </div>
      <v-btn v-if="link" :href="link" @click="hide" class="mt-2" small target="_blank" text>
        <v-icon class="mr-2" small>mdi-page-next-outline</v-icon>
        Zur Vorlagenübersicht
      </v-btn>
    </template>
    <template v-slot:close>
      <v-btn class="ml-2" icon small @click="hide">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import {NotifierApi} from '@/classes/api/notifier.api';
import {
  WhatsAppMessageTemplateStatusUpdateNotificationDocument,
} from "@/documentTypes/notifier/discriminators/supervisor/WhatsAppMessageTemplateStatusUpdateNotification.document";

export default Vue.extend({
  props: {
    notification: {
      type: Object as () => NotificationLike<WhatsAppMessageTemplateStatusUpdateNotificationDocument>,
      required: true,
    },
  },
  computed: {
    link(): string | undefined {
      const configId = this.notification.messagePayload.configurationId;
      if (configId) {
        return this.$router.resolve(`/templates/whatsapp/${configId}`).href;
      }
      return undefined;
    },
    formattedTemplateStatus() {
      switch (this.notification.messagePayload.status) {
        case 'REJECTED':
          return {color: 'error', text: 'abgelehnt', icon: 'mdi-close'};
        case 'APPROVED':
          return {color: 'success', text: 'genehmigt', icon: 'mdi-check'};
        case 'PENDING':
          return {color: 'warning', text: 'ausstehend', icon: 'mdi-clock'};
        default:
          return {color: 'grey', text: 'unbekannt', icon: 'mdi-help'};
      }
    },
  },
  methods: {
    async hide() {
      const id = this.notification._id;
      await NotifierApi.markRead({
        notificationIds: [id],
      });
      this.$store.commit('hideNotification', id);
    },
  },
});
</script>

<style scoped>
.notification {
  background-color: rgba(0, 0, 10, 0.5) !important;
  backdrop-filter: blur(15px);
}
</style>
