<template>
  <v-dialog
      :value="value"
      max-width="500px"
      @click:outside="$emit('input', false)"
      @keydown.esc="$emit('input', false)"
  >
    <v-card v-if="loading || !employee || !employeeId">
      <v-skeleton-loader type="article"/>
    </v-card>
    <v-card v-else>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <profile-avatar :user="employee"/>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <span v-can:read-employees="{ id: employeeId, keys: ['gender'] }"
                    class="mr-1">{{ toSalutation(employee.gender) }}</span>
              <span v-can:read-parents="{ id: employeeId, keys: ['firstName'] }"
                    class="mr-1">{{ employee.firstName }}</span>
              <span v-can:read-parents="{ id: employeeId, keys: ['lastName'] }"
                    class="mr-1">{{ employee.lastName }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{
                employee.isEmployee && employee.isTeacher ? 'Mitarbeiter:in und Lehrkraft' : employee.isEmployee && !employee.isTeacher ? 'Mitarbeitender' : 'Lehrkraft'
              }}
              <span v-if="employee.customId">- {{employee.customId}}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
                icon
                @click="$emit('input', false)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <employee-availabilities-list-item :employee="employee"/>
      <contact-data-list-items v-can:read-employees="{ id: employeeId, keys: ['contactInformation'] }"
                               :entity="employee"/>
      <address-list-item v-can:read-employees="{ id: employeeId, keys: ['address'] }" :entity="employee"/>
      <birthday-list-item v-can:read-employees="{ id: employee._id, keys: ['birthday'] }" :entity="employee"/>
      <subjects-list-item v-if="employee.isTeacher" v-can:read-employees="{ id: employee._id, keys: ['subjects'] }"
                          :entity="employee"/>
      <travel-list-item v-if="employee.isTeacher" :entity="employee"/>
      <abilities-list-item v-if="employee.isTeacher" :entity="employee" v-can:read-employees="{ id: employee._id, keys: ['abilities'] }"/>
      <contract-list-item v-can:read-employees="{ id: employee._id, keys: ['contract'] }" :entity="employee"/>
      <assigned-institutions-list-item v-if="employee.isTeacher" v-can:read-employees="{ id: employee._id, keys: ['assignedInstitutions'] }"
                                       :entity="employee"/>
      <planned-institutions-list-item v-if="employee.isTeacher" v-can:read-employees="{ id: employee._id, keys: ['plannedInstitutions'] }"
                                      :entity="employee"/>
      <v-card-actions>
        <v-tooltip v-can:custom-shareAccess right>
          <template v-slot:activator="{on}">
            <v-btn color="info" outlined rounded small @click="shareAccess" v-on="on">
              <v-icon small>mdi-shield-lock-open</v-icon>
              Freigeben
            </v-btn>
          </template>
          {{ employee.isTeacher ? 'Lehrkraft' : 'Mitarbeiter:in' }} freigeben
        </v-tooltip>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script lang="ts">
import Vue from 'vue';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import ContactDataListItems from '@/components/common/profile/contactDataListItems.vue';
import { toSalutation } from '@/helpers/toSalutation.helper';
import moment from 'moment';
import { CrudEntityTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import { GenderTypes } from '@/enums/GenderTypes.enum';
import AddressListItem from '@/components/common/profile/addressListItem.vue';
import { EmployeeHandlerAPI } from '@/classes/api/employee-handler.api.class';
import { Employee } from '@/interfaces/employee.interface';
import BirthdayListItem from '@/components/common/profile/birthdayListItem.vue';
import SubjectsListItem from '@/components/common/profile/subjectsListItem.vue';
import TravelListItem from '@/components/employees/employeeView/profileView/travelListItem.vue';
import ContractListItem from '@/components/employees/employeeView/profileView/contractListItem.vue';
import AssignedInstitutionsListItem
  from '@/components/employees/employeeView/profileView/assignedInstitutionsListItem.vue';
import PlannedInstitutionsListItem
  from '@/components/employees/employeeView/profileView/plannedInstitutionsListItem.vue';
import EmployeeAvailabilitiesListItem
  from '@/components/employees/employeeView/profileView/employeeAvailabilitiesListItem.vue';
import AbilitiesListItem from '@/components/employees/employeeView/profileView/abilitiesListItem.vue';
import mongoose from "mongoose";

export default Vue.extend({
  components: {
    AbilitiesListItem,
    EmployeeAvailabilitiesListItem,
    ContactDataListItems,
    AddressListItem,
    ProfileAvatar,
    BirthdayListItem,
    SubjectsListItem,
    TravelListItem,
    ContractListItem,
    AssignedInstitutionsListItem,
    PlannedInstitutionsListItem,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    employeeId: {
      type: Object as () => mongoose.Types.ObjectId,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    employee: null as null | Employee,
  }),
  computed: {
    toSalutation: () => toSalutation,
    moment: () => moment,
    GenderTypes: () => GenderTypes,
  },
  methods: {
    async getEmployee() {
      try {
        this.loading = true;
        this.employee = await EmployeeHandlerAPI.findById(this.employeeId, {
          populate: {
            assignedInstitutions: {
              fields: ['name'],
            },
            plannedInstitutions: {
              fields: ['name'],
            },
            contract: {
              fields:['name', 'salaryBasis', 'salaryPerUnit', 'conditions', 'travelExpenseCompensationPerKilometer'],
            },
            subjects: {
              fields: ['name'],
            },
          },
        });
      } catch (e) {
        this.$emit('input', false);
        this.$$showSnackbar('Es ist ein Fehler aufgetreten.');
      } finally {
        this.loading = false;
      }
    },
    shareAccess() {
      if (this.employee) {
        this.$$shareAccessDialog.show(CrudEntityTypes.EMPLOYEE, this.employee._id, 'Lehrkraft freigeben');
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.getEmployee();
        }
      },
    },
  },
});
</script>
