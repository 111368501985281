import { Types } from 'mongoose';

export enum RecorderEvents {
  NEW_LOG_ENTRY = 'recorder:new-log-entry',
  REMOVE_LOG_ENTRY = 'recorder:remove-log-entry',
  UPDATE_LOG_ENTRY = 'recorder:update-log-entry',
}

type RecorderEventDefaultPayload = {
  logEntryId: Types.ObjectId;
  objectId: Types.ObjectId;
}

export type RecorderEventPayloads = {
  [RecorderEvents.NEW_LOG_ENTRY]: RecorderEventDefaultPayload;
  [RecorderEvents.REMOVE_LOG_ENTRY]: RecorderEventDefaultPayload;
  [RecorderEvents.UPDATE_LOG_ENTRY]: RecorderEventDefaultPayload;
};
