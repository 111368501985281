<template>
  <v-tooltip :disabled="!showTooltip" bottom>
    <template v-slot:activator="{on}">
      <v-hover v-slot="{hover}" :disabled="!editable">
        <v-avatar v-if="user && user.profileImg" :color="bgColor" :size="size" v-on="on">
          <avataaars :avatarOptions="options"/>
          <v-fade-transition>
            <v-avatar v-show="hover" :size="size" class="overlay">
              <v-scale-transition origin="center, center">
                <v-icon v-show="hover" :size="size * 0.35" color="white">mdi-pencil</v-icon>
              </v-scale-transition>
            </v-avatar>
          </v-fade-transition>
        </v-avatar>
        <v-avatar v-else-if="!noFallback && !useIconFallback" :color="fallbackBgColor" :size="size" v-on="on">
          <span v-if="useInitialsAsFallback" :class="fallbackTextClass"
                :style="{'font-size': `${size * 0.5}px`, transform: hover ? 'scale(1.1)' : '', transition: '0.3s' }"
                class="no-user-select">
            {{ initials }}
          </span>
          <v-icon v-else :color="fallbackIconColor" :size="size * 0.75"
                  :style="{transform: hover ? 'scale(1.1)' : '', transition: '0.3s'}">
            {{ icon }}
          </v-icon>
          <v-fade-transition>
            <v-avatar v-show="hover" :size="size" class="overlay">
              <v-scale-transition origin="center, center">
                <v-icon v-show="hover" :size="size * 0.35" color="white">mdi-pencil</v-icon>
              </v-scale-transition>
            </v-avatar>
          </v-fade-transition>
        </v-avatar>
        <v-avatar v-else-if="icon && useIconFallback" :color="fallbackBgColor" :size="size" v-on="on">
          <v-icon :color="fallbackIconColor" :size="size * 0.75"
                  :style="{transform: hover ? 'scale(1.1)' : '', transition: '0.3s'}">
            {{ icon }}
          </v-icon>
        </v-avatar>
      </v-hover>
    </template>
    {{tooltipText}}
  </v-tooltip>
</template>

<script lang="ts">
import Vue from 'vue';
import Avataaars from 'vue-avataaars';
import {Avataaar} from '@/classes/clientOnly/Avataaar';
import {AvataaarOptions} from '@/interfaces/avataaarOptions.interface';

export default Vue.extend({
  components: {
    Avataaars,
  },
  props: {
    user: {
      type: Object as () => { profileImg?: string; firstName?: string; lastName?: string },
      required: false,
    },
    size: {
      type: Number,
      default: 32,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    noFallback: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: 'primary',
    },
    useInitialsAsFallback: {
      type: Boolean,
      default: false,
    },
    fallbackBgColor: {
      type: String,
      default: 'grey',
    },
    fallbackIconColor: {
      type: String,
      default: 'white',
    },
    fallbackTextClass: {
      type: String,
      default: 'white--text',
    },
    useIconFallback: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'mdi-account',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    tooltipFallbackText: {
      type: String,
      default: 'Unbekannt',
    },
  },
  computed: {
    tooltipText(): string {
      if (this.user && (this.user.firstName || this.user.lastName)) {
        return `${this.user.firstName || ''} ${this.user.lastName || ''}`.trim();
      }
      return this.tooltipFallbackText;
    },
    initials(): string | null {
      if (this.user && (this.user.firstName || this.user.lastName)) {
        let initials = '';
        if (this.user.firstName) {
          initials += this.user.firstName[0];
        }
        if (this.user.lastName) {
          initials += this.user.lastName[0];
        }
        return initials;
      }
      return null;
    },
    options(): AvataaarOptions {
      return new Avataaar(this.user?.profileImg).options;
    },
  },
});
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(20px);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
}
</style>
