import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {
    OnlineFormSubmissionFields,
} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/OnlineFormSubmissionFields';

export const onlineFormSubmissionFieldsDictionary: FieldDictionary<OnlineFormSubmissionFields> = [
    {
        text: 'Verwendetes Formular',
        value: OnlineFormSubmissionFields.FORM,
    },
    {
        text: 'Übermittelte Daten',
        value: OnlineFormSubmissionFields.DATA,
    },
    {
        text: 'Dauer in Sekunden',
        value: OnlineFormSubmissionFields.DURATION_IN_SEC,
    },
    {
        text: 'Standort',
        value: OnlineFormSubmissionFields.INSTITUTION,
    },
    {
        text: 'Werbeaktion',
        value: OnlineFormSubmissionFields.HANDOUT_EVENT,
    },
    {
        text: 'Status',
        value: OnlineFormSubmissionFields.STATE,
    },
];
