import Vue from 'vue';
import {Types} from 'mongoose';
import {StackItemStates} from '@/enums/StackItemStates.enum';
import {StackItemMetaTypes} from '@/enums/StackItemMetaTypes';

interface TimetableBusInterface {
    $emit(event: 'open:item', data: { stackId: Types.ObjectId; itemId: Types.ObjectId }): void;
    $emit(event: 'reload:stacks' | 'unselect:stack' | 'reload:selectedStack' | 'unselect:item'): void;
    $emit(event: 'reload:stack' | 'select:stack' | 'select:item' | 'deleted:stack', id: Types.ObjectId): void;
    $emit(event: 'update:itemState', data: { itemId: Types.ObjectId; state: StackItemStates }): void;
    $emit(event: 'add:item', data: { stackId: Types.ObjectId; meta?: Types.ObjectId; type?: StackItemMetaTypes }): void;
    $on(event: 'open:item', callback: (data: { stackId: Types.ObjectId; itemId: Types.ObjectId }) => void): void;
    $on(event: 'reload:stacks' | 'unselect:stack' | 'reload:selectedStack' | 'unselect:item', callback: () => void): any;
    $on(event: 'reload:stack' |'select:stack' | 'select:item' | 'deleted:stack', callback: (id: Types.ObjectId) => void): any;
    $on(event: 'update:itemState', callback: (data: { itemId: Types.ObjectId; state: StackItemStates }) => void): void;
    $on(event: 'add:item', callback: (data: { stackId: Types.ObjectId; meta?: Types.ObjectId; type?: StackItemMetaTypes }) => void): void;
}

export const StackBus: TimetableBusInterface = new Vue();
