export function extractEnumValues(data: any): Array<string | number> {
    const enumValues: Array<string | number> = [];
    for (const value of Object.values(data) as Array<string | number>) {
        const keys = Object.keys(data) as Array<string | number>;
        if (keys.includes(value)) {
            continue;
        }
        enumValues.push(value as string | number);
    }
    return enumValues;
}
