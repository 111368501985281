<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mdAndDown" :persistent="loading" :value="value" max-width="1400" scrollable
            @input="reset">
    <v-card style="min-height: 60vH">
      <v-row justify="space-between" no-gutters style="max-height: 100%; overflow: hidden">
        <stacks-list :active-stacks="activeStacks" v-if="!stackId || $vuetify.breakpoint.mdAndUp"
                     :show-closed-stacks.sync="showClosedStacks"
                     :stack-id.sync="stackId"/>
        <v-divider vertical/>
        <stack-items-list v-if="stackId" :apply-done-filter.sync="applyDoneFilter"
                          :apply-muted-filter.sync="applyMutedFilter"
                          :item-id.sync="itemId" :stack-id.sync="stackId"/>
        <v-divider v-if="stackId" vertical/>
        <stack-item-content :item-id="itemId" :stack-id="stackId" @close="reset"/>
      </v-row>
    </v-card>
    <add-element-to-stack-dialog v-model="showAddElementToStackDialog"/>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import StacksList from '@/components/stack/stackDialog/StacksList.vue';
import StackItemsList from '@/components/stack/stackDialog/StackItemsList.vue';
import StackItemContent from '@/components/stack/stackDialog/StackItemContent.vue';
import AddElementToStackDialog from '@/components/stack/stackDialog/AddElementToStackDialog.vue';
import {StackBus} from '@/busses/StackBus';
import {sleep} from '@/helpers/sleep.helper';
import mongoose from "mongoose";
import {Stack} from "@/interfaces/stack.interface";

export default Vue.extend({
  components: {AddElementToStackDialog, StackItemContent, StackItemsList, StacksList},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    activeStacks: {
      type: Array as () => Stack[],
      default: () => [],
    },
  },
  data: () => ({
    loading: false,
    stackId: null as mongoose.Types.ObjectId | null,
    itemId: null as mongoose.Types.ObjectId | null,
    showAddElementToStackDialog: false,

    showClosedStacks: false,
    applyDoneFilter: true,
    applyMutedFilter: false,
  }),
  methods: {
    reset() {
      this.$emit('input', false);
    },
  },
  mounted() {
    StackBus.$on('add:item', () => this.showAddElementToStackDialog = true);
    StackBus.$on('unselect:stack', () => {
      this.stackId = null;
      this.itemId = null;
    });
    StackBus.$on('deleted:stack', (id: mongoose.Types.ObjectId) => {
      if (this.stackId === id) {
        this.stackId = null;
        this.itemId = null;
      }
    });
    StackBus.$on('open:item', async (data) => {
      this.$emit('input', true);
      await sleep(100)
      this.stackId = data.stackId;
      this.itemId = data.itemId;
    });
  },
});
</script>
