<template>
  <div>
    <div v-if="entity && entity.plannedInstitutions">
      <v-divider />
      <v-list-group
          v-model="expandListGroup"
          prepend-icon="mdi-domain"
          v-if="entity.plannedInstitutions.length > 0"
          active-class="black--text"
      >
        <template v-slot:activator>
          <v-list-item-title>Vorgemerkte Standorte <span class="grey--text">({{entity.plannedInstitutions.length}})</span></v-list-item-title>
          <v-list-item-action v-can:readAndUpdate-employees="{ id: entity._id, keys: ['plannedInstitutions'] }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" color="warning" @click="showPlanInstitutionsDialog = true">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Vormerkungen bearbeiten</span>
            </v-tooltip>
          </v-list-item-action>
        </template>
        <v-list-item v-for="item in entity.plannedInstitutions" :key="item._id">
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <overflow-text v-can:read-institutions="{id: item._id, keys: ['name']}" :text="getInstitutionNameWithSateCode(item)" />
            </v-list-item-title>
            <v-list-item-subtitle>
              Vorgemerkter Standort
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-can:read-institutions="{ id: item._id }">
            <v-layout row wrap>
              <v-flex>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" :href="$router.resolve(`/institutions/${item._id}`).href" target="_blank">
                      <v-icon>mdi-page-next-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Standort in neuem Tab öffnen</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="activeInstitutionId = item._id; showInstitutionCardDialog = true;">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Standort ansehen</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-list-item-action>
        </v-list-item>

      </v-list-group>
      <v-list-item v-else>
        <v-list-item-icon>
          <v-icon>mdi-domain</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Kein Standort vorgemerkt
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" color="info" @click="showPlanInstitutionsDialog = true">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Standort vormerken</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </div>
    <!-- DIALOGS -->
    <institution-card-dialog v-if="activeInstitutionId" v-model="showInstitutionCardDialog" :institution-id="activeInstitutionId" />
    <plan-institutions-dialog v-model="showPlanInstitutionsDialog" :employee="entity" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import OverflowText from '@/components/common/overflow-text.vue';
import InstitutionCardDialog from '@/components/common/profile/institutionCardDialog.vue';
import { Institution } from '@/interfaces/institution.interface';
import PlanInstitutionsDialog from '@/components/employees/employeeView/profileView/planInstitutionsDialog.vue';
import {getInstitutionNameWithSateCode} from '@/helpers/getInstitutionNameWithSateCode';
import mongoose from 'mongoose';

interface CanHavePlannedInstitutions {
  _id: mongoose.Types.ObjectId;
  plannedInstitutions?: Array<Pick<Institution, 'name' | '_id'>>;
}

export default Vue.extend({
  components: {PlanInstitutionsDialog, OverflowText, InstitutionCardDialog},
  props: {
    entity: {
      type: Object as () => CanHavePlannedInstitutions,
      required: true,
    },
  },
  computed: {
    getInstitutionNameWithSateCode: () => getInstitutionNameWithSateCode,
  },
  data: () => ({
    activeInstitutionId: null as null | mongoose.Types.ObjectId,
    showInstitutionCardDialog: false,
    showPlanInstitutionsDialog: false,
    expandListGroup: false,
  }),
});
</script>
