import store from '@/store';
import Axios, {AxiosInstance, AxiosResponse} from 'axios';
import router from '@/router';
import CrudAccess from '@/classes/clientOnly/permissionTreeResources/interfaces/CrudAccess';
import {LoginResponseDto} from '@/classes/dto/authenticator/response/LoginResponseDto';
import {CheckLoginResponseDto} from '@/classes/dto/authenticator/response/CheckLogin.response.dto';
import {Types} from "mongoose";
import {NotifierSocketApi} from '@/classes/api/notifier.socket.api';
import {NavigationDrawerAccess} from '@/enums/_common/NavigationDrawerAccess.enum';

/**
 * This class is used for communicating with Institutioner microservice
 * @class InstitutionerApi
 * @author mklaczinski
 */
export class LoginApi {
    private static service = 'authenticator';
    private static accessToken = '';

    static async login(mail: string, password: string): Promise<LoginResponseDto> {
        const credentials = (await Axios.post(`${process.env.VUE_APP_API_PATH}/${this.service}/login`, {
            username: mail,
            password,
        }, {
            withCredentials: true,
        })).data;
        this.accessToken = credentials.token.access_token;
        store.commit('user', credentials.meta);
        store.commit('setLoginUserId', credentials.loginUserId);
        store.commit('login', this.accessToken);
        store.commit('setUserType', credentials.type);
        await NotifierSocketApi.connect();
        return credentials;
    }

    static async loginAs(employeeId: Types.ObjectId) {
        const credentials = (await Axios.post(`${process.env.VUE_APP_API_PATH}/${this.service}/loginAs`, {
            employeeId,
        }, {
            withCredentials: true,
        })).data;
        this.accessToken = credentials.token.access_token;
        store.commit('user', credentials.meta);
        store.commit('setLoginUserId', credentials.loginUserId);
        store.commit('login', this.accessToken);
        store.commit('setUserType', credentials.type);
        window.location.reload();
    }

    static async loginAsOrigin() {
        const credentials = (await Axios.post(`${process.env.VUE_APP_API_PATH}/${this.service}/loginAsOrigin`, {
        }, {
            withCredentials: true,
        })).data;
        this.accessToken = credentials.token.access_token;
        store.commit('user', credentials.meta);
        store.commit('setLoginUserId', credentials.loginUserId);
        store.commit('login', this.accessToken);
        store.commit('setUserType', credentials.type);
        window.location.reload();
    }

    static async checkLogin(): Promise<CheckLoginResponseDto> {
        let instance: AxiosInstance;
        if (this.accessToken) {
            instance = Axios.create({
                headers: {
                    Authorization: `Bearer ${this.accessToken}`,
                },
                baseURL: process.env.VUE_APP_API_PATH,
                withCredentials: true,
            });
        } else {
            instance = Axios.create({
                baseURL: process.env.VUE_APP_API_PATH,
                withCredentials: true,
            });
        }
        return (await instance.post(`${this.service}/checkLogin`)).data as CheckLoginResponseDto;
    }

    static async logout(): Promise<void> {
        await Axios.create({withCredentials: true}).post(`${process.env.VUE_APP_API_PATH}/${this.service}/logout`);
        store.commit('logout');
        await NotifierSocketApi.disconnect();
        router.push('/login').catch();
        window.location.reload();
    }

    /**
     * Retrieves the user's crud access from permissioner and uses it to create
     * a new instance of CrudAccessManager in store.
     * This method was implemented here because using any child classes of Api.class.ts
     * before authorization results in an error.
     * Handle with care.
     */
    public static async setCrud(): Promise<void> {
        const axiosInstance = store.state.axios;
        if (!axiosInstance) {
            throw new Error('Axios instance not defined');
        }
        let resp: AxiosResponse<CrudAccess>;
        try {
            resp = await axiosInstance.get(`${process.env.VUE_APP_API_PATH}/permissioner/crud`);
        } catch (e) {
            throw new Error('Error during retrieving crud access');
        }
        store.commit('setCrudAccess', resp.data);
        let guiResp: AxiosResponse<NavigationDrawerAccess>;
        try {
            guiResp = await axiosInstance.get(`${process.env.VUE_APP_API_PATH}/permissioner/gui`);
        } catch (e) {
            throw new Error('Error during retrieving crud access');
        }
        store.commit('setGuiAccess', guiResp.data);
    }

}
