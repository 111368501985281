<template>
  <v-footer absolute app color="transparent" inset style="min-height: 60px">
    <v-row class="text-center" justify="center" no-gutters>
      <v-col align-self="center" cols="12" md="3">
        <strong @contextmenu.alt.prevent="$$debug.toggle()"> &copy; Vanilla&nbsp;</strong> {{ new Date().getFullYear() }}
      </v-col>
      <v-col align-self="center" cols="3" sm="4" md="3">
        <v-btn :href="($router.resolve('/imprint')).href" small target="_blank" text> Impressum</v-btn>
      </v-col>
      <v-col align-self="center" cols="3" sm="4" md="3">
        <v-btn :href="($router.resolve('/compliance')).href" small target="_blank" text>Datenschutz</v-btn>
      </v-col>
      <v-col cols="6" sm="4" md="3" v-if="$store.state.axios">
        <v-btn @click="showCoachingZoneDialog = true" small target="_blank" text color="#e30888">
          <v-icon small class="mr-1">mdi-music</v-icon>
          Zur Coachingzone
        </v-btn>
      </v-col>
<!--      href="https://coachingzone.mymusicschool.de"-->
    </v-row>
    <v-dialog v-model="showCoachingZoneDialog" max-width="400">
      <v-card>
        <v-card-title>
          Zur Coachingzone
        </v-card-title>
        <v-list>
          <v-list-item href="https://coachingzone.mymusicschool.de" target="_blank">
            <v-list-item-content>
              <v-list-item-title>
                coachingzone.mymusicschool.de
              </v-list-item-title>
              <v-list-item-subtitle>
                Klicken, um zur Coachingzone zu gelangen
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="addToClipboard">
            <v-list-item-content>
              <v-list-item-title>
                mym#2024
              </v-list-item-title>
              <v-list-item-subtitle>
                Kennwort
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="addToClipboard">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-footer>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    showCoachingZoneDialog: false,
  }),
  methods: {
    addToClipboard() {
      navigator.clipboard.writeText('mym#2024');
      this.$$showSnackbar('Kennwort kopiert', 'success');
    },
  },
})
</script>
