export enum InstitutionFields {
    ACCOUNTING_REF = 'accountingRef',
    ACQUIRED_BY = 'acquiredBy',
    ACQUISITION_STATUS = 'acquisitionStatus',
    ACQUISITION_STATUS_ITEMS = 'acquisitionStatusItems',
    ACTIVATE_AT = 'activateAt',
    ADDRESS = 'address',
    ASSIGNED_EMPLOYEES = 'assignedEmployees',
    AVAILABILITIES = 'availabilities',
    BLOCKED_SLOTS_CALENDAR = 'blockedSlotsCalendar',
    CONTACT_INFORMATION = 'contactInformation',
    COURSE_INFO_TEXT = 'courseInfoText',
    COVID_RECORDS = 'covidRecords',
    CREATED_AT = 'createdAt',
    CUSTOM_ID = 'customId',
    DEACTIVATED_AT = 'deactivatedAt',
    DEACTIVATE_AT = 'deactivateAt',
    EXCEPTIONAL_BLOCKED_SLOTS_CALENDAR = 'exceptionalBlockedSlotsCalendar',
    IS_ACTIVE = 'isActive',
    IS_RENTED = 'isRented',
    LAST_UPDATED_ACQUISITION_STATUS = 'lastUpdatedAcquisitionStatus',
    LAST_UPDATED_ACQUISITION_STATUS_BY = 'lastUpdatedAcquisitionStatusBy',
    NAME = 'name',
    PLANNED_EMPLOYEES = 'plannedEmployees',
    RENT_AMOUNT = 'rentAmount',
    RENT_INTERVAL = 'rentInterval',
    RENT_NOTE = 'rentNote',
    RESPONSIBLES = 'responsibles',
    STATUS = 'status',
    SUPERVISOR = 'supervisor',
    TAGS = 'tags',
    TOTAL_AMOUNT_PUPILS = 'totalAmountPupils',
    TYPE = 'type',
    UPDATED_AT = 'updatedAt',
}
