import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {SubjectFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/SubjectFields';

export const subjectFieldsDictionary: FieldDictionary<SubjectFields> = [
    {
        text: 'Name',
        value: SubjectFields.NAME,
    },
    {
        text: 'Farbe',
        value: SubjectFields.COLOR,
    },
    {
        text: 'Themen',
        value: SubjectFields.TOPICS,
    },
    {
        text: 'Ist verborgen?',
        value: SubjectFields.HIDDEN_FROM_SELECTION,
    },
];
