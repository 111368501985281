<template>
  <v-col :cols="11" :offset="isOwn ? 1 : 0">
    <v-hover v-slot="{ hover }">
      <div>
        <v-subheader class="mx-n3 my-n2">{{ message.fromMail }}
          <v-icon small>mdi-arrow-right</v-icon>
          {{ message.toMail }} {{ sentAt }}
          <v-icon class="ml-3 mr-1" small>mdi-archive</v-icon>
          {{ box }}
        </v-subheader>
        <v-lazy :min-height="minHeight" transition="slide-y-transition">
          <v-card :color="isOwn ? ownColor : strangerColor" tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="font-weight-bold">
                  Betreff
                </v-list-item-subtitle>
                <v-list-item-title class="body-1">
                  <overflow-text :text="message.subject"/>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-slide-x-reverse-transition>
                  <div v-if="hover">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon @click="replyTo" v-on="on">
                          <v-icon>mdi-reply</v-icon>
                        </v-btn>
                      </template>
                      <span>Auf diese Nachricht antworten</span>
                    </v-tooltip>
                  </div>
                </v-slide-x-reverse-transition>
              </v-list-item-action>
            </v-list-item>
            <v-divider/>
            <v-card-text>
              <div v-if="message.message" v-html="message.message"/>
              <div v-else class="font-italic">
                Diese Nachricht hat keinen Inhalt.
              </div>
            </v-card-text>
            <div v-if="attachments && attachments.length > 0">
              <v-divider/>
              <v-expansion-panels flat>
                <v-expansion-panel class="transparent">
                  <v-expansion-panel-header>
                    {{ attachments.length }} {{ attachments.length === 1 ? 'Anhang' : 'Anhänge' }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col v-for="attachment in attachments" :key="attachment.filename" cols="12" lg="4" md="6"
                             xl="3">
                        <message-card-download-item :attachment="attachment" :box="message.box" :uid="message.uid"
                                                    :username="message.username"/>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div v-if="media && media.length > 0">
              <v-divider/>
              <v-expansion-panels flat>
                <v-expansion-panel class="transparent">
                  <v-expansion-panel-header>
                    {{ media.length }} in dieser Mail enthaltene {{
                      media.length === 1 ? 'Mediendatei' : 'Mediendateien'
                    }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col v-for="attachment in media" :key="attachment.filename" cols="12" lg="6"
                             xl="4">
                        <message-card-download-item :attachment="attachment" :box="message.box" :uid="message.uid"
                                                    :username="message.username"/>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-card>
        </v-lazy>
      </div>
    </v-hover>
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import {MessengerMail} from '@/documentTypes/messenger/messages';
import moment from 'moment';
import {MessengerBus} from '@/busses/MessengerBus';
import {MessengerMessageDocument} from '@/documentTypes/messenger/MessengerMessage.document';
import OverflowText from '@/components/common/overflow-text.vue';
import MessageCardDownloadItem from '@/components/app/messagingComponents/messageCards/sub/messageCardDownloadItem.vue';

moment.locale('de');

export default Vue.extend({
  components: {MessageCardDownloadItem, OverflowText},
  props: {
    message: {
      type: Object as () => MessengerMail,
      required: true,
    },
    isOwn: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    ownColor: 'outlook lighten-3',
    strangerColor: 'outlook lighten-4',
  }),
  computed: {
    box(): string {
      switch (this.message.box?.toLowerCase()) {
        case 'inbox':
          return 'Posteingang';
        case 'outbox':
          return 'Postausgang';
        case 'sent':
        case 'sent items':
          return 'Gesendet';
        case 'draft':
          return 'Entwürfe';
        case 'trash':
          return 'Papierkorb';
        default:
          return this.message.box;
      }
    },
    minHeight(): number {
      const div = document.createElement('div');
      div.classList.add('v-application');
      div.style.display = 'block';
      const messagingContainer = document.getElementById('vanilla-messaging-dialog-message-container');
      if (messagingContainer) {
        const width = messagingContainer.clientWidth;
        div.style.maxWidth = `${width * 11 / 12}px`;
      } else {
        div.style.maxWidth = '600px';
      }
      if (this.message.subject) {
        div.innerHTML += `<div>${this.message.subject}</div>`;
      }
      div.innerHTML += this.message.message;
      document.body.appendChild(div);
      const height = div.clientHeight;
      document.body.removeChild(div);
      div.remove();
      return Math.max(height, 132);
    },
    sentAt(): string {
      return moment(this.message.sentAt).format('DD.MM.YYYY HH:mm [Uhr]');
    },
    attachments(): MessengerMail['attachments'] {
      return this.message.attachments.filter(attachment => !attachment.isPartOfContent);
    },
    media(): MessengerMail['attachments'] {
      return this.message.attachments.filter(attachment => attachment.isPartOfContent);
    },
  },
  methods: {
    replyTo() {
      const rawContents = this.message.message;
      const span = document.createElement('span');
      span.innerHTML = rawContents;
      MessengerBus.$emit('update:replyToMessage', {
        ...this.message,
        message: span.textContent || '',
      } as unknown as MessengerMessageDocument);
    },
  },
});
</script>
