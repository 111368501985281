<template>
  <div>
    <v-alert v-if="notification.showInApp" :key="notification.id" class="elevation-3 mt-4 mx-2 notification" dark
             transition="slide-x-transition">
      <template v-slot:prepend>
        <div class="mr-4">
          <profile-avatar v-if="notification.messagePayload.fromProfileImg"
                          :user="{profileImg: notification.messagePayload.fromProfileImg}"/>
          <v-icon v-else>mdi-information</v-icon>
        </div>
      </template>
      <template v-slot:default>
        <div class="body-2">
          Neue Aufgabe
          {{ notification.messagePayload.fromName ? `von ${notification.messagePayload.fromName}` : 'vom System' }}
        </div>
        <v-card class="mt-2" light>
          <v-list-item>
            <v-list-item-avatar>
              <v-simple-checkbox :value="toDoValue" color="error" disabled/>
            </v-list-item-avatar>
            <v-list-item-content class="body-2">
              <overflow-text max-width="200" :text="notification.messagePayload.name"/>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-btn :href="link" class="mt-2" small target="_blank" text>
          <v-icon class="mr-2" small>mdi-page-next-outline</v-icon>
          Profil öffnen
        </v-btn>
        <to-do-notification-acquisition-lists-link-button :notification="notification" v-if="notification"/>
      </template>
      <template v-slot:close>
        <v-btn class="ml-2" icon small @click="hide">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-alert>
  </div>

</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import {NotifierApi} from '@/classes/api/notifier.api';
import {
  ToDoAssignedNotificationDocument,
} from '@/documentTypes/notifier/discriminators/ToDoAssignedNotification.document';
import OverflowText from '@/components/common/overflow-text.vue';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import ToDoNotificationAcquisitionListsLinkButton
  from "@/components/app/appBar/notifications/appNotifications/components/todoHelpers/toDoNotificationAcquisitionListsLinkButton.vue";

export default Vue.extend({
  components: {ToDoNotificationAcquisitionListsLinkButton, ProfileAvatar, OverflowText},
  props: {
    notification: {
      type: Object as () => NotificationLike<ToDoAssignedNotificationDocument>,
      required: true,
    },
  },
  computed: {
    link(): string {
      return this.$router.resolve(`/${this.notification.messagePayload.entityType}/${this.notification.messagePayload.entityId}`).href;
    },
  },
  data: () => ({
    toDoValue: false,

  }),
  methods: {
    async hide() {
      const id = this.notification._id;
      await NotifierApi.markRead({
        notificationIds: [id],
      });
      this.$store.commit('hideNotification', id);
    },
  },
});
</script>

<style scoped>
.notification {
  background-color: rgba(0, 0, 10, 0.5) !important;
  backdrop-filter: blur(15px);
}
</style>
