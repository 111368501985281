export enum GenderTypes {
    MALE, FEMALE, TRANS, FAMILY,
}

export const SalutationTypesVue = [
    {
        text: 'Herr',
        value: GenderTypes.MALE,
    },
    {
        text: 'Frau',
        value: GenderTypes.FEMALE,
    },
    {
        text: 'Divers',
        value: GenderTypes.TRANS,
    },
    {
        text: 'Familie',
        value: GenderTypes.FAMILY,
    },
];
