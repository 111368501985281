<template>
  <div v-if="entity && entity.abilities">
    <v-divider/>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-head-lightbulb</v-icon>
      </v-list-item-icon>
      <v-list-item-icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon :color="!!entity.abilities.online ? 'success' : 'grey'" v-on="on">mdi-cast-education</v-icon>
            </template>
            <span>{{typeof entity.abilities.online === 'boolean' ? `Die Lehrkraft gibt ${entity.abilities.online ? '' : 'keinen'} Onlineunterricht.` : 'Es existieren keine Informationen über den Onlineunterricht.'}}</span>
          </v-tooltip>
      </v-list-item-icon>
      <v-list-item-icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                  :color="typeof entity.abilities.atHome === 'boolean' && entity.abilities.atHome ? 'success' : 'grey'"
                  v-on="on">mdi-home-account
              </v-icon>
            </template>
            <span>{{ typeof entity.abilities.atHome === 'boolean' ? `Die Lehrkraft gibt ${entity.abilities.atHome ? '' : 'keinen'} Einzelunterricht zuhause.` : 'Es existieren keine Informationen über den Einzelunterricht.' }}</span>
          </v-tooltip>
      </v-list-item-icon>
    </v-list-item>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Abilities} from '@/interfaces/abilities.interface';


interface CanHaveAbilities {
  abilities: Abilities;
}

export default Vue.extend({
  props: {
    entity: {
      type: Object as () => CanHaveAbilities,
      required: true,
    },
  },
})
</script>
