import {API} from '@/classes/api/api.class';
import {FindAddressRequestDto} from '@/classes/dto/geocoder/request/FindAddress.request.dto';
import {FindAddressResponseDto} from '@/classes/dto/geocoder/response/FindAddress.response.dto';

export class GeocoderAPI extends API {
    static service = 'geocoder';

    static findAddress(data: FindAddressRequestDto): Promise<FindAddressResponseDto> {
        return this.get(`address`, data);
    }
}
