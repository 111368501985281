<template>
  <v-dialog
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :overlay="false"
      :value="value"
      max-width="500px"
      persistent
      scrollable
      transition="dialog-transition"
      @input="reset"
  >
    <v-card>
      <v-card-title>
        <div class="info--text">Jobposition anlegen</div>
        <v-spacer/>
        <v-btn icon @click="reset">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-2">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field v-model="jobType.neutral" :rules="[RuleFactory.required()]" color="info"
                            dense hint="beispielsweise Rektor:in, Hausmeister:in"
                            label="Geschlechtsneutrale Form" outlined
                            prepend-icon="mdi-human-non-binary"
                            rounded/>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="jobType.male" :rules="[RuleFactory.required()]" color="info" dense
                            hint="beispielsweise Rektor, Hausmeister" label="Männliche Form"
                            outlined prepend-icon="mdi-human-female" rounded/>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="jobType.female" :rules="[RuleFactory.required()]" color="info"
                            dense hint="beispielsweise Rektorin, Hausmeisterin" label="Weibliche Form"
                            outlined prepend-icon="mdi-human-male" rounded/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="reset">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="info" small @click="createJobType">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { RuleFactory } from '@/helpers/ruleFactory.helper';
import { Form } from '@/interfaces/Form.interface';
import { JobType } from '@/interfaces/jobType.interface';
import { ResponsibleHandlerAPI } from '@/classes/api/responsible-handler.api.class';

export default Vue.extend({
  data: () => ({
    loading: false,
    valid: true,
    jobType: {} as JobType,
  }),
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    RuleFactory: () => RuleFactory,
  },
  methods: {
    async createJobType() {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        try {
          this.loading = true;
          const job = await ResponsibleHandlerAPI.jobTypes.create(this.jobType)
          this.valid = true;
          this.$emit('update:jobType', job);
          this.reset();
          this.$$showSnackbar('Jobposition erfolgreich angelegt.', 'success');
        } finally {
          this.loading = false;
        }
      }
    },
    reset() {
      this.jobType = {} as JobType;
      this.loading = false;
      this.$emit('input', false);
    },
  },
});
</script>
