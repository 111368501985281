import {Types, UpdateQuery} from 'mongoose';
import {API} from './api.class';
import {Termination} from '@/interfaces/termination.interface';
import {FindByIdOptions} from '@/interfaces/findByIdOptions';
import {CreateTerminationRequestDto} from '@/classes/dto/terminator/request/CreateTermination.request.dto';
import {FindTerminationRequestDto} from '@/classes/dto/terminator/request/FindTermination.request.dto';

/**
 * This class is used for communicating with Terminator microservice
 * @class Terminator
 * @author mklaczinski
 */
export class TerminatorApi extends API {
    static service = 'terminator';

    static async create(termination: CreateTerminationRequestDto): Promise<Termination> {
        return this.post(``, termination);
    }

    static async update(updateQuery: UpdateQuery<Termination>): Promise<Termination> {
        return this.put(`${updateQuery._id}`, updateQuery);
    }

    static async find(findQuery: FindTerminationRequestDto): Promise<{ terminations: Termination[]; maxPage: number }> {
        return this.get(``, findQuery);
    }

    static async findById(id: Types.ObjectId, options?: FindByIdOptions): Promise<Termination> {
        return this.get(`${id}`, options);
    }

    static async remove(id: Types.ObjectId): Promise<void> {
        return this.delete(`${id}`);
    }

}
