import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {HolidayFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/HolidayFields';

export const holidayFieldsDictionary: FieldDictionary<HolidayFields> = [
    {
        text: 'Art der Ferien / des Feiertags',
        value: HolidayFields.TYPE,
    },
    {
        text: 'Beginn',
        value: HolidayFields.START,
    },
    {
        text: 'Ende',
        value: HolidayFields.END,
    },
    {
        text: 'Bezeichnung',
        value: HolidayFields.NAME,
    },
    {
        text: 'Bundesland',
        value: HolidayFields.STATE_CODE,
    },
    {
        text: 'Land',
        value: HolidayFields.COUNTRY_CODE,
    },
    {
        text: 'Erläuterungen',
        value: HolidayFields.ADDITIONAL_TEXT,
    },
    {
        text: 'Standort (bei standortspezifischen Ferientagen)',
        value: HolidayFields.INSTITUTION,
    },
    {
        text: 'Person, die Ferien / Feiertage hinterlegt hat',
        value: HolidayFields.CREATOR,
    },
];
