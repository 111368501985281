import { Types } from 'mongoose';
import { API } from './api.class';
import { Contract } from '@/interfaces/contract.interface';
import {FindContractRequestDto} from '@/classes/dto/contractor/request/FindContract.request.dto';
import {CreateContractRequestDto} from '@/classes/dto/contractor/request/CreateContract.request.dto';
import {UpdateContractRequestDto} from '@/classes/dto/contractor/request/UpdateContract.request.dto';
import { FindByIdOptionsRequestDto } from '@/classes/dto/_common/request/FindByIdOptions.request.dto';
/**
 * This class is used for communicating with the contractor microservice.
 * @class ContractorAPI
 * @author Joshua Seipel
 */
export class ContractorAPI extends API {
  static service = 'contractor';

  static async create(document: CreateContractRequestDto): Promise<Contract> {
    return this.post('', document);
  }
  static async find(c: FindContractRequestDto): Promise<{ contracts: Contract[]; maxPage: number }> {
    return this.get(``, c);
  }
  static async findById(id: Types.ObjectId, options?: FindByIdOptionsRequestDto): Promise<Contract> {
    return this.get(`${id}`, options);
  }

  static async update(id: Types.ObjectId, update: UpdateContractRequestDto): Promise<void> {
    return this.put(`${id}`, update);
  }
}
