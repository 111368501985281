<template>
  <div v-if="entity && (entity.hasDriverLicense || entity.hasCar || entity.maxDistance || entity.maxTravelTime)">
    <v-divider/>
    <v-list-item three-line>
      <v-list-item-icon>
        <v-icon>{{entity.hasDriverLicense ? 'mdi-car' : 'mdi-car-off'}}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{entity.hasDriverLicense && entity.hasCar ? 'Führerschein und Fahrzeug vorhanden' : entity.hasDriverLicense && !entity.hasCar ? 'Kein Fahrzeug vorhanden' : 'Kein Führerschein vorhanden'}}
        </v-list-item-title>
        <v-list-item-subtitle>
          Maximaler Fahrtweg: {{entity.maxDistance ? `${entity.maxDistance} km` : 'Unbekannt'}}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Maximaler Fahrtdauer: {{entity.maxTravelTime ? `${entity.maxTravelTime} min` : 'Unbekannt'}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';


interface CanHaveTravelInfos {
  hasDriverLicense?: boolean;
  hasCar?: boolean;
  maxDistance?: number;
  maxTravelTime?: number;
}

export default Vue.extend({
  props: {
    entity: {
      type: Object as () => CanHaveTravelInfos,
      required: true,
    },
  },
})
</script>
