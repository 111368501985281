<template>
    <v-list key="list" dense
            style="margin-left: 1px; max-height: 100%; overflow: hidden; width: 300px">
      <v-skeleton-loader class="mx-4 my-4 mb-6" type="heading" v-if="initializing"/>
      <v-slide-x-transition hide-on-leave>
        <v-card-title v-if="!initializing && stack" :style="{color: stack.color}" class="title px-5 pb-5 pt-3">
          <v-btn v-if="$vuetify.breakpoint.smAndDown" icon @click="$emit('update:stackId', null)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <overflow-text :max-width="$vuetify.breakpoint.smAndDown ? 190 : 220" :text="stack.name"/>
          <v-spacer/>
          <stack-item-list-menu :apply-done-filter="applyDoneFilter" :apply-muted-filter="applyMutedFilter"
                                :stack="stack" :stack-id="stackId"
                                @update:applyDoneFilter="(v) => $emit('update:applyDoneFilter', v)"
                                @update:applyMutedFilter="(v) => $emit('update:applyMutedFilter', v)"/>
        </v-card-title>
      </v-slide-x-transition>
      <v-divider/>
      <v-alert :value="showFilterAlert" class="caption" color="grey darken-1" dark dense tile type="info">
        Filter verhindern eventuell, die Anzeige von Elementen
      </v-alert>
      <div v-if="initializing">
        <v-skeleton-loader type="list-item-avatar-two-line"/>
        <v-skeleton-loader type="list-item-avatar-two-line"/>
        <v-skeleton-loader type="list-item-avatar-two-line"/>
      </div>
      <v-list-item-group v-else-if="stack" v-model="selectedStackItemIndex"
                         style="height: calc(100% - 56px); overflow: auto">
        <stack-items-list-item v-for="(item, i) in filteredItems" :key="item._id.toString()+i" :item="item"
                               :stack="stack"/>
      </v-list-item-group>
    </v-list>
<!--  </v-slide-x-transition>-->
</template>
<script lang="ts">
import Vue from 'vue';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {Types} from 'mongoose';
import {Stack} from '@/interfaces/stack.interface';
import StackItemsListItem from '@/components/stack/stackDialog/stackItemsList/StackItemsListItem.vue';
import {StackBus} from '@/busses/StackBus';
import {StackItemStates} from '@/enums/StackItemStates.enum';
import StackItemListMenu from '@/components/stack/stackDialog/stackItemsList/StackItemListMenu.vue';
import OverflowText from '@/components/common/overflow-text.vue';

export default Vue.extend({
  components: {OverflowText, StackItemListMenu, StackItemsListItem},
  props: {
    stackId: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
    itemId: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
    applyDoneFilter: {
      type: Boolean,
      required: true,
    },
    applyMutedFilter: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    stack: undefined as unknown as Stack,
    initializing: false,
  }),
  computed: {
    filteredItems() {
      let items = this.stack?.items || [];
      if (this.applyDoneFilter) {
        items = items.filter((item) => item.state !== StackItemStates.DONE);
      }
      if (this.applyMutedFilter) {
        items = items.filter((item) => item.state !== StackItemStates.MUTED);
      }
      return items;
    },
    showFilterAlert(): boolean {
      if (!this.initializing && this.filteredItems.length === 0) {
        return this.applyDoneFilter || this.applyMutedFilter;
      }
      return false;
    },
    selectedStackItemIndex: {
      get(): number {
        return this.filteredItems.findIndex((s) => s._id === this.itemId);
      },
      set(v: number) {
        if (v >= 0) {
          this.$emit('update:itemId', this.filteredItems[v]._id || null);
        } else {
          this.$emit('update:itemId', null);
        }
      },
    },
  },
  methods: {
    async getStack() {
      if (!this.stackId) {
        return;
      }
      this.initializing = true;
      try {
        this.stack = await StackManagerApi.findById(this.stackId, {
          fields: ['items', 'color', 'name', 'closedAt'],
          populate: {
            'items.meta': {
              fields: ['firstName', 'lastName', 'name'],
            },
          },
        });
      } catch (e) {
        this.$$showSnackbar(`Fehler beim Laden der Stapelelemente`);
      } finally {
        this.initializing = false;
      }
    },
  },
  watch: {
    stackId: {
      immediate: true,
      handler(id: Types.ObjectId) {
        if (id) {
          this.getStack();
        }
      },
    },
  },
  mounted() {
    StackBus.$on('reload:selectedStack', this.getStack);
    StackBus.$on('update:itemState', (data) => {
      const item = this.stack.items.find((item) => item._id === data.itemId);
      if (item) {
        item.state = data.state;
      }
    });
  },
});
</script>
