<template>
  <v-card :style="{ 'max-width': maxWidthCss }" color="#fafdff" flat
          style="flex-grow: 1; flex-shrink: 0; margin-left: 1px"
          tile>
    <v-card-title>
      <div v-if="stackId">
        <v-slide-x-transition>
          <div v-if="isReady && name && stack && item" :style="{color: stack.color}">
            <v-tooltip nudge-right="15" right transition="slide-x-reverse-transition">
              <template v-slot:activator="{on}">
                <div v-on="on">
                  <v-badge :color="item.meta.isActive ? 'success' : 'error'"
                           :value="item.meta && typeof item.meta.isActive !== 'undefined'" dot offset-x="-5" overlap>
                    {{ name }}
                  </v-badge>
                </div>
              </template>
              <span>
              Dieses Element ist {{ item.meta.isActive ? 'aktiv' : 'inaktiv' }}.
            </span>
            </v-tooltip>
          </div>
        </v-slide-x-transition>
      </div>
      <v-spacer/>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="!isReady && !initializing" class="align-center flex" style="height: calc(100% - 90px)">
      <v-row align="center" class="fill-height">
        <v-col cols="12">
          <div class="text-center mb-4 mt-n6">
            <v-icon size="64">mdi-tray-remove</v-icon>
          </div>
          <div class="text-h5 text-center mb-2">
            Nichts zu sehen
          </div>
          <div class="text-subtitle-1 text-center">
            Bitte wähle erst einen Stapel und ein Element aus
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else-if="initializing">
      <v-card class="mb-4" flat>
        <v-card-text>
          <v-skeleton-loader class="mb-4" type="text"/>
          <v-skeleton-loader type="paragraph"/>
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-text>
          <v-skeleton-loader type="list-item-avatar-two-line@4"/>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-text v-else-if="isReady && item">
      <stack-item-meta-info-component v-if="meta" :meta="meta" :type="item.type"/>
      <v-card>
        <v-card-text>
          <stack-item-state-component v-can:read-stacks="{ id: stackId, keys: ['items'] }" :item-id="itemId"
                                      :stack-id="stackId" :state.sync="item.state"/>
          <stack-item-note-component v-can:read-stacks="{ id: stackId, keys: ['items'] }" :item-id="itemId"
                                     :note.sync="item.note" :stack-id="stackId"/>
          <stack-item-due-date-component v-can:read-stacks="{ id: stackId, keys: ['items'] }"
                                         :due-date.sync="item.dueDate"
                                         :item-id="itemId" :stack-id="stackId"/>
          <stack-item-assigned-to-component v-can:read-stacks="{ id: stackId, keys: ['items'] }"
                                            :assigned-to.sync="item.assignedTo"
                                            :item-id="itemId" :stack-id="stackId"/>
        </v-card-text>
      </v-card>
      <div v-if="item.createdAt || item.updatedAt" class="caption mt-2">
        <div v-if="item.createdAt">
          Hinzugefügt am {{ moment(item.createdAt).format('DD.MM.YYYY [um] HH:mm [Uhr]') }}
        </div>
        <div v-if="item.updatedAt && !moment(item.createdAt).isSame(item.updatedAt)">
          Zuletzt aktualisiert am {{ moment(item.updatedAt).format('DD.MM.YYYY [um] HH:mm [Uhr]') }}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue';
import {Types} from 'mongoose';
import {Stack, StackItem} from '@/interfaces/stack.interface';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {sleep} from '@/helpers/sleep.helper';
import {User} from '@/interfaces/user.interface';
import {Institution} from '@/interfaces/institution.interface';
import {isPopulated} from '@/helpers/isObjectId.helper';
import {StackItemMetaTypes} from '@/enums/StackItemMetaTypes';
import StackItemNoteComponent from '@/components/stack/stackDialog/stackItemContent/stackItemNoteComponent.vue';
import StackItemStateComponent from '@/components/stack/stackDialog/stackItemContent/stackItemStateComponent.vue';
import StackItemMetaInfoComponent from '@/components/stack/stackDialog/stackItemContent/stackItemMetaInfoComponent.vue';
import StackItemDueDateComponent from '@/components/stack/stackDialog/stackItemContent/stackItemDueDateComponent.vue';
import StackItemAssignedToComponent
  from '@/components/stack/stackDialog/stackItemContent/stackItemAssignedToComponent.vue';
import {StackBus} from '@/busses/StackBus';
import moment from 'moment';

moment.locale('de');

export default Vue.extend({
  components: {
    StackItemAssignedToComponent,
    StackItemDueDateComponent, StackItemMetaInfoComponent, StackItemStateComponent, StackItemNoteComponent,
  },
  props: {
    stackId: {type: Types.ObjectId},
    itemId: {type: Types.ObjectId},
  },
  data: () => ({
    initializing: false,
    stack: null as Stack | null,
    item: null as StackItem<any> | null,
  }),
  computed: {
    moment: () => moment,
    maxWidthCss(): string {
      if (!this.stackId || this.$vuetify.breakpoint.smAndDown) {
        return 'calc(100% - 300px)';
      } else {
        return 'calc(100% - 600px)';
      }
    },
    isReady(): boolean {
      return !!this.stack && !!this.item && !this.initializing;
    },
    meta(): User | Institution | null {
      if (isPopulated(this.item?.meta)) {
        return this.item?.meta;
      }
      return null;
    },
    name(): string {
      if (!this.meta) {
        return '';
      }
      if (this.item?.type === StackItemMetaTypes.INSTITUTION) {
        return (this.meta as Institution).name;
      } else {
        const user = this.meta as User;
        return `${user.firstName} ${user.lastName}`;
      }
    },
  },
  methods: {
    async getItem() {
      if (!this.stackId || !this.itemId) {
        this.stack = null;
        this.item = null;
        return;
      }
      this.initializing = true;
      try {
        if (this.stack?._id?.toString() !== this.stackId.toString()) {
          this.stack = await StackManagerApi.findById(this.stackId, {
            populate: {
              'items.meta': {
                fields: ['firstName', 'lastName', 'name', 'contactInformation', 'address', 'institution', 'isActive', 'customId'],
                populate: {
                  institution: {
                    fields: ['name', 'address'],
                  },
                },
              },
            },
          });
        } else {
          await sleep(200);
        }
        this.item = this.stack.items.find((item) => item._id === this.itemId) || null;
      } catch (e) {
        alert(e);
        this.$$showSnackbar(`Fehler beim Laden des Stapelelements vom Server`, 'error');
      } finally {
        this.initializing = false;
      }
    },
  },
  watch: {
    stackId() {
      this.getItem();
    },
    itemId() {
      this.getItem();
    },
  },
  mounted() {
    StackBus.$on('update:itemState', (data) => {
      if (this.itemId === data.itemId && this.item) {
        this.item.state = data.state;
      }
    });
  },
});
</script>
