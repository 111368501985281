export enum HandoutFields {
    CREATOR = 'creator',
    INSTITUTION = 'institution',
    NAME = 'name',
    TEMPLATE = 'template',
    SUBJECTS = 'subjects',
    PLACEHOLDERS = 'placeholders',
    FILE = 'file',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
    VISIBLE = 'visible',
}
