import { Types } from 'mongoose';
import { DeleteResponseDTO } from '@/classes/dto/delete.response.dto';
import { Subject } from '@/interfaces/subject.interface';
import { API } from './api.class';
import {CreateSubjectRequestDTO} from '@/classes/dto/subject-manager/request/CreateSubject.request.dto';
import {FindSubjectRequestDto} from '@/classes/dto/subject-manager/request/FindSubject.request.dto';
import {FindByIdOptionsRequestDto} from '@/classes/dto/_common/request/FindByIdOptions.request.dto';
import { UpdateSubjectRequestDTO } from '@/classes/dto/subject-manager/request/UpdateSubject.request.dto';
/**
 * This class is used for communicating with SubjectManager microservice
 * @class SubjectManagerApi
 * @author mklaczinski
 */
export class SubjectManagerApi extends API {
    static service = 'subject-manager';

    static async create(newDocument: CreateSubjectRequestDTO): Promise<Subject> {
        return this.post(``, newDocument);
    }
    static async update(subjectId: Types.ObjectId, update: UpdateSubjectRequestDTO): Promise<Subject> {
        return this.put(`${subjectId}`, update);
    }

    static async find(findQuery: FindSubjectRequestDto): Promise<{subjects: Subject[]; maxPage: number}> {
        return this.get(``, findQuery);
    }
    static async findById(id: Types.ObjectId, options?: FindByIdOptionsRequestDto): Promise<Subject> {
        return this.get(`${id}`, options);
    }
    static async remove(id: Types.ObjectId): Promise<DeleteResponseDTO> {
        return this.delete(`${id}`);
    }

}
