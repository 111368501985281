import {API} from '@/classes/api/api.class';
import {BookingFormTemplate} from '@/interfaces/booking-manager/BookingFormTemplate.interface';
import {
    CreateBookingFormTemplateRequestDto,
} from '@/classes/dto/booking-manager/request/CreateBookingFormTemplate.request.dto';
import {
    FindBookingFormTemplateRequestDto,
} from '@/classes/dto/booking-manager/request/FindBookingFormTemplate.request.dto';
import {Types} from 'mongoose';
import {FindByIdOptionsRequestDto} from '@/classes/dto/_common/request/FindByIdOptions.request.dto';
import {
    CreatePrintedFormRequestDto,
} from '@/classes/dto/booking-manager/request/printedForm/CreatePrintedForm.request.dto';
import {PrintedForm} from '@/components/bookingForm/printedFormBuilder/interfaces/PrintedForm.interface';
import {
    UpdatePrintedFormRequestDto,
} from '@/classes/dto/booking-manager/request/printedForm/UpdatePrintedForm.request.dto';
import {
    UpdateBookingFormTemplateRequestDto,
} from '@/classes/dto/booking-manager/request/UpdateBookingFormTemplate.request.dto';
import {
    CreateBookingFormRequestDto,
} from '@/classes/dto/booking-manager/form-builder/request/CreateBookingForm.request.dto';
import {
    CreateOnlineFormRequestDto,
} from '@/classes/dto/booking-manager/request/onlineForm/CreateOnlineForm.request.dto';
import {
    FindBookingFormRequestDto,
} from '@/classes/dto/booking-manager/form-builder/request/FindBookingForm.request.dto';
import {BookingForm} from '@/interfaces/booking-manager/BookingForm.interface';
import {
    UpdateOnlineFormRequestDto,
} from '@/classes/dto/booking-manager/request/onlineForm/UpdateOnlineForm.request.dto';
import {FormBuilderOnlineForm} from '@/interfaces/booking-manager/formBuilder/formBuilderOnlineForm';
import {
    GetOnlineFormRequestDto,
} from '@/classes/dto/booking-manager/form-builder/form-server/request/GetOnlineForm.request.dto';
import {
    FindOnlineFormSubmissionRequestDto,
} from '@/classes/dto/booking-manager/form-builder/submissionCollector/FindOnlineFormSubmission.request.dto';
import {OnlineFormSubmission} from '@/interfaces/booking-manager/OnlineFormSubmission.interface';
import {
    UpdateBookingFormRequestDto,
} from '@/classes/dto/booking-manager/form-builder/request/UpdateBookingForm.request.dto';
import {BookingStates} from '@/enums/bookingManager/BookingStates.enum';
import {
    CopyBookingFormTemplateRequestDto,
} from '@/classes/dto/booking-manager/request/printedForm/CopyBookingFormTemplate.request.dto';
import {
    UpdateOnlineFormSubmissionStateRequestDto,
} from '@/classes/dto/booking-manager/form-builder/submissionCollector/UpdateOnlineFormSubmissionState.request.dto';
import {
    UpdateSubmissionStateRequestDto,
} from "@/classes/dto/booking-manager/form-builder/submissionCollector/UpdateSubmissionState.request.dto";

export class BookingManagerApi extends API {
    static service = 'booking-manager';
    static formBuilder = {
        formPrinter: {
            async getFileId(id: Types.ObjectId, additionalDocumentId?: Types.ObjectId): Promise<Types.ObjectId> {
                return BookingManagerApi.get(`form-builder/form-printer/${id}`, {
                    additionalDocumentId,
                });
            },
        },
        formServer: {
            /**
             * validates form based on given shortCode
             * @param data
             */
            async validateForm(data: GetOnlineFormRequestDto): Promise<boolean> {
                return BookingManagerApi.get(`form-builder/form-server/validate/${data.id}`);
            },
            /**
             * returns form based on given shortCode
             * @param data
             */
            async getForm(data: GetOnlineFormRequestDto): Promise<FormBuilderOnlineForm> {
                return BookingManagerApi.get(`form-builder/form-server/${data.id}`);
            },
        },
        async create(data: CreateBookingFormRequestDto): Promise<Types.ObjectId> {
            return BookingManagerApi.post('form-builder', data);
        },
        async find(data: FindBookingFormRequestDto): Promise<{
            bookingForms: BookingForm[];
            maxPage: number;
        }> {
            return BookingManagerApi.get('form-builder', data);
        },
        async findByShortId(id: string, options?: FindByIdOptionsRequestDto): Promise<BookingForm> {
            return BookingManagerApi.get(`form-builder/short-id/${id}`, options);
        },
        async findById(id: Types.ObjectId | string, options?: FindByIdOptionsRequestDto): Promise<BookingForm> {
            return BookingManagerApi.get(`form-builder/${id.toString()}`, options);
        },
        async update(id: Types.ObjectId | string, update: UpdateBookingFormRequestDto): Promise<BookingForm> {
            return BookingManagerApi.get(`form-builder/${id.toString()}`, update);
        },
        async remove(id: Types.ObjectId | string): Promise<void> {
            return BookingManagerApi.delete(`form-builder/${id.toString()}`);
        },
    }

    static templateBuilder = {
        onlineForm: {
            async create(data: CreateOnlineFormRequestDto): Promise<Types.ObjectId> {
                return BookingManagerApi.post('template-builder/online-form', data);
            },
            async findById(id: Types.ObjectId): Promise<FormBuilderOnlineForm> {
                return BookingManagerApi.get(`template-builder/online-form/${id}`);
            },
            async update(id: Types.ObjectId, data: UpdateOnlineFormRequestDto): Promise<void> {
                return BookingManagerApi.put(`template-builder/online-form/${id}`, data);
            },
            async remove(id: Types.ObjectId): Promise<void> {
                return BookingManagerApi.delete(`template-builder/online-form/${id}`);
            },
        },
        printedForm: {
            async create(data: CreatePrintedFormRequestDto): Promise<Types.ObjectId> {
                return BookingManagerApi.post('template-builder/printed-form', data);
            },
            async findById(id: Types.ObjectId): Promise<PrintedForm> {
                return BookingManagerApi.get(`template-builder/printed-form/${id}`);
            },
            async update(id: Types.ObjectId, data: UpdatePrintedFormRequestDto): Promise<void> {
                return BookingManagerApi.put(`template-builder/printed-form/${id}`, data);
            },
            async remove(id: Types.ObjectId): Promise<void> {
                return BookingManagerApi.delete(`template-builder/printed-form/${id}`);
            },
        },

        async create(data: CreateBookingFormTemplateRequestDto): Promise<BookingFormTemplate> {
            return BookingManagerApi.post('template-builder', data)
        },
        async copy(from: Types.ObjectId, data: CopyBookingFormTemplateRequestDto): Promise<Types.ObjectId> {
            return BookingManagerApi.post(`template-builder/copy/${from}`, data);
        },
        async find(data: FindBookingFormTemplateRequestDto): Promise<{
            bookingFormTemplates: BookingFormTemplate[];
            maxPage: number;
        }> {
            return BookingManagerApi.get('template-builder', data);
        },
        async update(id: Types.ObjectId, data: UpdateBookingFormTemplateRequestDto): Promise<void> {
            return BookingManagerApi.put(`template-builder/${id.toString()}`, data)
        },
        async findById(id: Types.ObjectId | string, options?: FindByIdOptionsRequestDto): Promise<BookingFormTemplate> {
            return BookingManagerApi.get(`template-builder/${id.toString()}`, options);
        },
    };

    static submissionCollector = {
        async find(data: FindOnlineFormSubmissionRequestDto): Promise<{
            onlineFormSubmissions: OnlineFormSubmission[];
            maxPage: number
        }> {
            return BookingManagerApi.get('submission-collector', data);
        },
        async findById(id: Types.ObjectId | string, options?: FindByIdOptionsRequestDto): Promise<OnlineFormSubmission> {
            return BookingManagerApi.get(`submission-collector/${id.toString()}`, options);
        },
        async getState(id: Types.ObjectId): Promise<{ state: BookingStates }> {
            return BookingManagerApi.get(`submission-collector/state/${id.toString()}`);
        },
        /**
         * Request the state of a submission to be updated by the server
         * @param id
         * @param data data to be transmitted to the server
         */
        async updateState(id: Types.ObjectId, data?: UpdateOnlineFormSubmissionStateRequestDto): Promise<void> {
            return BookingManagerApi.put(`submission-collector/state/${id.toString()}`, data || {});
        },
        async updateSubmissionState(id: Types.ObjectId, data: UpdateSubmissionStateRequestDto): Promise<void> {
            return BookingManagerApi.put(`submission-collector/submissionState/${id.toString()}`, data);
        },
        async remove(id: Types.ObjectId) {
            return BookingManagerApi.delete(`submission-collector/${id.toString()}`);
        },
    }

    static createPdf(pages: string[]) {
        return this.post('createPdf', {pages});
    }
}
