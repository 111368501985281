export enum EnvTypesEnum {
    PRODUCTION = 'production',
    LOCAL = 'local',
    TEST = 'test',
}

export const EnvTypesItems = [{
    text: 'Produktivsystem',
    value: EnvTypesEnum.PRODUCTION,
}, {
    text: 'Testserver',
    value: EnvTypesEnum.TEST,
}, {
    text: 'Lokale Entwicklung',
    value: EnvTypesEnum.LOCAL,
}];
