<template>
    <div>
        <v-row v-if="includeSubheader">
            <v-subheader>{{subheaderText}}</v-subheader>
        </v-row>
        <v-row class="mt-0">
            <v-col cols="12" md="4">
                <v-select :color="color" :item-color="color" :items="SalutationTypesVue"
                          :rules="[RuleFactory.required()]"
                          :value="gender" label="Anrede" prepend-icon="mdi-human-greeting-variant"
                          @input="(v) => $emit('update:gender', v)"
                          :dense="dense"
                          :rounded="rounded"
                          :outlined="outlined"
                          :filled="filled"
                          :solo="solo"
                          :readonly="readonly"
                          :disabled="disabled"
                />
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field :color="color" :label="gender === GenderTypes.FAMILY ? 'Vornamen' : 'Vorname'"
                              :rules="requireFirstName ? [RuleFactory.required('Dieses Feld ist erforderlich.')] : []"
                              :value="firstName"
                              prepend-icon="mdi-card-account-details"
                              @input="(v) => $emit('update:firstName', v)"
                              :dense="dense"
                              :rounded="rounded"
                              :outlined="outlined"
                              :filled="filled"
                              :solo="solo"
                              :readonly="readonly"
                              :disabled="disabled"
                />
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field :color="color" :rules="[RuleFactory.required()]" :value="lastName" label="Nachname"
                              prepend-icon="mdi-card-account-details"
                              @input="(v) => $emit('update:lastName', v)"
                              :dense="dense"
                              :rounded="rounded"
                              :outlined="outlined"
                              :filled="filled"
                              :solo="solo"
                              :readonly="readonly"
                              :disabled="disabled"
                />
            </v-col>
        </v-row>
    </div>

</template>

<script lang="ts">

import Vue from 'vue';
import {GenderTypes, SalutationTypesVue} from '@/enums/GenderTypes.enum';
import {RuleFactory} from '@/helpers/ruleFactory.helper';

export default Vue.extend({
    props: {
        gender: {
            type: Object as () => GenderTypes,
            required: true,
        },
        firstName: {
            type: String,
            required: true,
        },
        lastName: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: 'info',
        },
        includeSubheader: {
            type: Boolean,
            default: true,
        },
        subheaderText: {
            type: String,
            default: 'Persönliche Informationen',
        },
        requireFirstName: {
            type: Boolean,
            default: true,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        filled: {
            type: Boolean,
            default: false,
        },
        solo: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        SalutationTypesVue: () => SalutationTypesVue,
        RuleFactory: () => RuleFactory,
        GenderTypes: () => GenderTypes,
    },
});

</script>
