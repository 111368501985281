import {API} from './api.class';
import {GetTimeTableRequestDto} from '@/classes/dto/scheduler/request/GetTimeTable.request.dto';
import {TimetableItem} from '@/interfaces/timetable/TimetableItem';
import {GetAvailabilitiesRequestDto} from '@/classes/dto/scheduler/request/GetAvailabilities.request.dto';
import {LabeledAvailabilityInterface} from '@/interfaces/availability.interface';
import {DataReliability} from '@/enums/DataReliability.enum';
import {ApplyAbsenceRequestDto} from '@/classes/dto/scheduler/request/ApplyAbsence.request.dto';

/**
 * This class is used for communicating with Scheduler microservice
 * @class SchedulerApi
 * @author mklaczinski
 */
export class SchedulerApi extends API {
    static service = 'scheduler';

    static async applyAbsence(data: ApplyAbsenceRequestDto): Promise<{ events: Array<TimetableItem<any>>; start: Date; end: Date }> {
        return this.post(`absence`, data);
    }

    static async getTimeTable(data: GetTimeTableRequestDto): Promise<{ events: Array<TimetableItem<any>>; start: Date; end: Date }> {
        return this.get(`timetable`, data);
    }

    static async getAvailabilities(data: GetAvailabilitiesRequestDto): Promise<{ availabilities: LabeledAvailabilityInterface[]; reliability: DataReliability}> {
        return this.get(`availabilities/${data.type}/${data.id}`, {});
    }
}
