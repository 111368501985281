export enum RoomFields {
    NAME = 'name',
    INSTITUTION = 'institution',
    ROOM_NUMBER = 'roomNumber',
    FLOOR = 'floor',
    CAPACITY = 'capacity',
    AVAILABILITY = 'availability',
    DETAILS = 'details',
    IS_ONLINE = 'isOnline',
    ONLINE_CREDENTIALS = 'onlineCredentials',
}
