<template>
  <v-dialog
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :value="value"
      max-width="750px"
      persistent
      transition="dialog-transition"
      @input="reset"
  >
    <v-card>
      <v-card-title>
        <div class="warning--text">Standorte vormerken</div>
        <v-spacer/>
        <v-btn icon @click="reset" :disabled="loading || removeLoading">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-2">
        <v-list v-can:read-employees="{ id: employee._id, keys: ['plannedInstitutions'] }">
          <div v-for="(item, i) in employee.plannedInstitutions" :key="i">
            <v-list-item >
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.name ? item.name : 'Unbekannt'}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Bereits vorgemerkter Standort
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-can:read-employees="{ id: employee._id, keys: ['plannedInstitutions'] }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="removePlanning(item._id)" :loading="removeLoading">
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Vormerkung aufheben</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </div>
          <div v-if="!employee.plannedInstitutions || employee.plannedInstitutions.length === 0">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-domain-off</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Es wurden noch keine Standorte vorgemerkt
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
        <v-row>
          <v-col>
            <institution-autocomplete :prefetch="institutionPrefetch" :prefetch-filter="institutionPrefetchFilter" label="Standort auswählen" v-model="addInstitution" prepend-icon="mdi-domain" color="warning" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="reset">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="warning" @click="addPlanning" :disabled="!addInstitution || loading || removeLoading">Standort zuteilen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { Employee } from '@/interfaces/employee.interface';
import InstitutionAutocomplete from '@/components/common/search-fields/institutionAutocomplete.vue';
import { EmployeeHandlerAPI } from '@/classes/api/employee-handler.api.class';
import { EventBus } from '@/busses/EventBus';
import { CrudEntityTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import { CrudActionTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import { FindInstitutionFilter } from '@/classes/dto/institutioner/request/filters/FindInstitution.filter';
import mongoose from 'mongoose';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    employee: {
      type: Object as () => Employee,
      required: true,
    },
  },
  components: {
    InstitutionAutocomplete,
  },
  data: () => ({
    loading: false,
    addInstitution: null as null | mongoose.Types.ObjectId,
    removeLoading: false,
    institutionPrefetch: false,
    institutionPrefetchFilter: {} as FindInstitutionFilter,
  }),
  methods: {
    async addPlanning() {
      try {
        if (this.addInstitution) {
          this.loading = true;
          await EmployeeHandlerAPI.addAssignment(this.employee._id, {
            type: 'plan',
            institutionId: this.addInstitution,
          });
          this.$$showSnackbar(`Die Vormerkung des Standorts war erfolgreich.`, 'success');
          this.$emit('update:employee');
          EventBus.$emit('update:employee');
        }
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler bei der Vormerkung des Standorts aufgetreten. Bitte erneut versuchen.', 'error');
      } finally {
        this.loading = false;
        this.reset();
      }
    },
    async removePlanning(id: mongoose.Types.ObjectId) {
      try {
        this.removeLoading = false;
        await EmployeeHandlerAPI.removeAssignment(this.employee._id, {
          type: 'plan',
          institutionId: id,
        });
        this.$$showSnackbar('Die Vormerkung wurde erfolgreich aufgehoben.', 'success');
        this.$emit('update:employee');
        EventBus.$emit('update:employee');
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler beim Aufheben der Vormerkung ausgetreten. Bitte erneut versuchen.', 'error');
      } finally {
        this.removeLoading = false;
      }
    },
    reset() {
      this.addInstitution = null;
      this.$emit('input', false);
    },
    async getInstitutionPrefetchFilter() {
      try {
        if (!this.employee.address || !this.employee.address.city) {
          this.employee.address = (await EmployeeHandlerAPI.findById(this.employee._id, {
            fields: ['address'],
          })).address;
        }
        if (this.employee.address.location.coordinates.length === 2 && this.employee.address.location.coordinates[0] !== 0 && this.employee.address.location.coordinates[1] !== 0) {
          this.institutionPrefetchFilter = {
            isActive: true,
            near: {
              lat: this.employee.address.location.coordinates[1],
              lon: this.employee.address.location.coordinates[0],
              maxDistance: 20000,
            },
          } as FindInstitutionFilter;
          this.institutionPrefetch = true;
        }
      } catch (e) {
        this.$$showSnackbar('Beim Laden der Daten ist ein Fehler aufgetreten', 'error');
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        const canReadEmployeeAddress = this.$$crudAccessManager.canAccessEntity(CrudEntityTypes.EMPLOYEE, CrudActionTypes.READ, this.employee._id, 'address');
        if (canReadEmployeeAddress && this.employee?.address?.location) {
          this.getInstitutionPrefetchFilter();
        }
      },
    },
  },
});
</script>
