<template>
  <v-alert v-if="notification.showInApp" :key="notification.id" class="elevation-3 mt-4 mx-2 notification" dark
           transition="slide-x-transition">
    <template v-slot:prepend>
      <div class="mr-4">
        <v-icon color="error">mdi-alert</v-icon>
      </div>
    </template>
    <template v-slot:default>
      <div class="body-2">
        Fehler bei Kursabsage
      </div>
      <div class="caption">
        <ul>
          <li v-if="notification.messagePayload.institutionErrorCount">
            Probleme bei Zustellung der Benachrichtigung an den Standort
          </li>
          <li v-if="notification.messagePayload.studentErrorCount">
            Probleme bei Zustellung der Benachrichtigung an {{ notification.messagePayload.studentErrorCount }} {{ notification.messagePayload.studentErrorCount === 1 ? 'Schüler:in' : 'Schüler:innen' }}
          </li>
          <li v-if="notification.messagePayload.teacherErrorCount">
            Probleme bei Zustellung der Benachrichtigung an die Lehrkraft
          </li>
        </ul>
      </div>
      <v-btn v-if="directLink" :href="directLink" class="mt-2" small target="_blank" text @click="hide">
        <v-icon class="mr-2" small>mdi-page-next-outline</v-icon>
        Zur Vertretungsanfrage
      </v-btn>
    </template>
    <template v-slot:close>
      <v-btn class="ml-2" icon small @click="hide">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import {NotifierApi} from '@/classes/api/notifier.api';
import {
  CourseCancellationFailedNotificationDocument,
} from '@/documentTypes/notifier/discriminators/substitutionManager/CourseCancellationFailedNotification.document';

export default Vue.extend({
  props: {
    notification: {
      type: Object as () => NotificationLike<CourseCancellationFailedNotificationDocument>,
      required: true,
    },
  },
  computed: {
    directLink(): string {
      return this.$router.resolve(`/substitution-inquiries-table?q=1&filter._id=["${this.notification.messagePayload.substitutionInquiryId}"]`).href;
    },
  },
  methods: {
    async hide() {
      const id = this.notification._id;
      await NotifierApi.markRead({
        notificationIds: [id],
      });
      this.$store.commit('hideNotification', id);
    },
  },
});
</script>

<style scoped>
.notification {
  background-color: rgba(0, 0, 10, 0.5) !important;
  backdrop-filter: blur(15px);
}
</style>
