import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import de from 'vuetify/src/locale/de';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
        // dark: true,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#f2e791',
                // primary: '#e5da92',
                tabs: '#616161',
                nav: '#f2e791',
                navContent: '#332f20',
                search: '#455A64',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                warning: '#FFC107',
                anchor: '#2196F3',
                whatsApp: {
                    base: '#25d366',
                    darken1: '#24b35d',
                    darken2: '#198042',
                    darken3: '#134d26',
                    darken4: '#0d260d',
                    lighten1: '#47ed87',
                    lighten2: '#80ffb9',
                    lighten3: '#b3ffd5',
                    lighten4: '#d9ffe9',
                },
                outlook: {
                    base: '#127CD6',
                    darken1: '#0e66b3',
                    darken2: '#0d5491',
                    darken3: '#0b4170',
                    darken4: '#082e4f',
                    lighten1: '#4aa7f7',
                    lighten2: '#80c4ff',
                    lighten3: '#b3dbff',
                    lighten4: '#d9f2ff',
                },
            },
            dark: {
                primary: '#f2e791',
                anchor: '#ffffff',
            },
        },
    },
    lang: {
        locales: {de},
        current: 'de',
    },
});
