export enum JobAdFields {
    JOB_TITLE = 'jobTitle',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    COMPANY_DESCRIPTION = 'companyDescription',
    TASK_DESCRIPTION = 'taskDescription',
    EMPLOYEE_REQUIREMENTS = 'employeeRequirements',
    COMPANY_BENEFITS = 'companyBenefits',
    JOB_LOCATIONS = 'jobLocations',
    SALARY = 'salary',
    COMPANY_CONTACT = 'companyContact',
    PUBLICATION_DATA = 'publicationData',
    PUBLISHED_ON = 'publishedOn',
    PUBLICATION_REFS = 'publicationRefs',
    LOCALIZATION = 'localization',
    METADATA = 'metadata',
}
