<template>
  <v-dialog
    :value="showUnauthorizedDialog"
    scrollable
    :fullscreen="$vuetify.breakpoint.xs" 
    :overlay="false"
    max-width="600px"
    transition="dialog-transition"
    @keydown.enter="$store.commit('showUnauthorizedDialog', false)"
    @input="$store.commit('showUnauthorizedDialog', false)"
    style="z-index: 999999;"
  >
    <v-card>
       <v-card-title primary-title class="error white--text">
          <v-icon color="white" class="mr-2">mdi-shield-half-full</v-icon>
          Zugriff verweigert
        </v-card-title>
        <v-img :src="`${publicPath}resources/graphics/iceesoft_wizard.svg`" height="256" contain class="my-3" />
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <p>
            Sie besitzen nicht die nötigen Berechtigungen, um den gewünschten Vorgang durchzuführen.
            Wir verweisen darauf, dass wiederholte Versuche nicht autorisierter Aktionen gemeldet werden.
          </p>
          <p>
            Sollten Sie der Auffassung sein, dass Ihre Tätigkeit die entsprechende Zugriffsberechtigung benötigt,
            kontaktieren Sie diesbezüglich bitte Ihren Vorgesetzten.
          </p>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="$store.commit('showUnauthorizedDialog', false)">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import store from '@/store';
import Vue from 'vue'
export default Vue.extend({
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  computed: {
    showUnauthorizedDialog() {
      return store.state.showUnauthorizedDialog;
    },
  },
});
</script>
