import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {StackFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/StackFields';

export const stackFieldsDictionary: FieldDictionary<StackFields> = [
    {
        text: 'Name des Stapels',
        value: StackFields.NAME,
    },
    {
        text: 'Farbe des Stapels',
        value: StackFields.COLOR,
    },
    {
        text: 'Eigentümer des Stapels',
        value: StackFields.OWNER,
    },
    {
        text: 'Mitglieder des Stapels',
        value: StackFields.MEMBERS,
    },
    {
        text: 'Elemente des Stapels',
        value: StackFields.ITEMS,
    },
    {
        text: 'Datum der Archivierung des Stapels',
        value: StackFields.CLOSED_AT,
    },
];
