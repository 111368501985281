<template>
  <v-scale-transition class="align-center" group hide-on-leave origin="left" tag="v-row">
    <v-col key="icon" cols="1">
      <v-avatar :color="stateColor" size="32">
        <v-icon color="white" size="20">{{ stateIcon }}</v-icon>
      </v-avatar>
    </v-col>
    <v-col v-if="!editMode" key="state" cols="10" style="text-align: justify">
      <span v-if="typeof state !== 'undefined'"> {{ stateText }} </span>
      <span v-else class="grey--text">Status unbekannt</span>
    </v-col>
    <v-col v-else key="state-edit" cols="10">
      <v-select v-model="updatedState" :color="stateColor" :item-color="stateColor" :disabled="loading" :items="StackItemStatesVue"
                autofocus dense hide-details label="Status" outlined rounded>
        <template v-slot:item="{item}">
          <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{item.text}}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-select>
    </v-col>
    <v-col v-if="!editMode" key="actions" v-can:update-stacks="{ id: this.stackId, keys: ['items'] }" cols="1">
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon @click="initializeEditMode" v-on="on">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        Status bearbeiten
      </v-tooltip>
    </v-col>
    <v-col v-else key="edit-actions" cols="1">
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn :loading="loading" icon @click="saveChanges" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        Änderungen speichern
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn :disabled="loading" icon @click="stopEditMode" v-on="on">
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
        </template>
        Änderungen verwerfen
      </v-tooltip>
    </v-col>
  </v-scale-transition>
</template>
<script lang="ts">
import Vue from 'vue';
import {Types} from 'mongoose';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {StackItemStates, StackItemStatesVue} from '@/enums/StackItemStates.enum';
import {StackBus} from '@/busses/StackBus';

export default Vue.extend({
  props: {
    state: {type: Number as () => StackItemStates, required: true},
    stackId: {type: Object as () => Types.ObjectId, required: true},
    itemId: {type: Object as () => Types.ObjectId, required: true},
  },
  data: () => ({
    editMode: false,
    loading: false,
    updatedState: undefined as unknown as StackItemStates,
  }),
  computed: {
    StackItemStatesVue: () => StackItemStatesVue,
    stateText(): string {
      return StackItemStatesVue.find((el) => el.value === this.state)?.text || '';
    },
    stateIcon(): string {
      if (this.updatedState) {
        return StackItemStatesVue.find((el) => el.value === this.updatedState)?.icon || '';
      }
      return StackItemStatesVue.find((el) => el.value === this.state)?.icon || '';
    },
    stateColor(): string {
      if (this.updatedState) {
        return StackItemStatesVue.find((el) => el.value === this.updatedState)?.color || '';
      }
      return StackItemStatesVue.find((el) => el.value === this.state)?.color || '';
    },
  },
  methods: {
    initializeEditMode() {
      this.updatedState = this.state;
      this.editMode = true;
    },
    stopEditMode() {
      this.updatedState = undefined as unknown as StackItemStates;
      this.editMode = false;
    },
    async saveChanges() {
      this.loading = true;
      try {
        await StackManagerApi.updateItem(this.stackId, this.itemId, {state: this.updatedState || undefined});
        this.$$showSnackbar('Die Änderungen wurden erfolgreich gespeichert', 'success');
        StackBus.$emit('update:itemState', { itemId: this.itemId, state: this.updatedState });
        this.$emit('update:state', this.updatedState);
        this.stopEditMode();
      } catch (e) {
        this.$$showSnackbar('Beim Speichern der Änderungen ist ein Fehler aufgetreten', 'error');
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
