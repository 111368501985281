import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {HandoutEventFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/HandoutEventFields';

export const handoutEventFieldsDictionary: FieldDictionary<HandoutEventFields> = [
    {
        text: 'Standort',
        value: HandoutEventFields.INSTITUTION,
    },
    {
        text: 'Standort hat Termin bestätigt',
        value: HandoutEventFields.INSTITUTION_HAS_CONFIRMED,
    },
    {
        text: 'Standort teilt selbst Anmeldungen aus',
        value: HandoutEventFields.INSTITUTION_IS_ADVERTISER,
    },
    {
        text: 'Koordinator:in',
        value: HandoutEventFields.COORDINATOR,
    },
    {
        text: 'Koordinator:in hat Organisation bestätigt',
        value: HandoutEventFields.COORDINATOR_HAS_CONFIRMED,
    },
    {
        text: 'Werbende:r',
        value: HandoutEventFields.ADVERTISER,
    },
    {
        text: 'Werbende:r hat Termin bestätigt',
        value: HandoutEventFields.ADVERTISER_HAS_CONFIRMED,
    },
    {
        text: 'Lehrkraft',
        value: HandoutEventFields.TEACHER,
    },
    {
        text: 'Lehrkraft hat Termin bestätigt',
        value: HandoutEventFields.TEACHER_HAS_CONFIRMED,
    },
    {
        text: 'Datum der Austeilaktion',
        value: HandoutEventFields.EVENT_DATE,
    },
    {
        text: 'Startzeit der Austeilaktion',
        value: HandoutEventFields.EVENT_START_TIME,
    },
    {
        text: 'Endzeit der Austeilaktion',
        value: HandoutEventFields.EVENT_END_TIME,
    },
    {
        text: 'Anmeldung',
        value: HandoutEventFields.HANDOUT,
    },
    {
        text: 'Anmeldung gedruckt',
        value: HandoutEventFields.HANDOUT_PRINTED,
    },
    {
        text: 'Anmeldung versandt',
        value: HandoutEventFields.HANDOUT_SENT,
    },
    {
        text: 'Beginn der Kurse',
        value: HandoutEventFields.COURSE_START_DATE,
    },
    {
        text: 'Rücklaufdatum',
        value: HandoutEventFields.RETURNING_DATE,
    },
    {
        text: 'Rücklauf erfolgt',
        value: HandoutEventFields.HAS_RETURNED,
    },
    {
        text: 'Rücklauf verspätet',
        value: HandoutEventFields.IS_DELAYED,
    },
    {
        text: 'Rücklauf',
        value: HandoutEventFields.RETURNING_AMOUNT,
    },
    {
        text: 'Aktivitätsstatus',
        value: HandoutEventFields.IS_ACTIVE,
    },
    {
        text: 'Protokoll',
        value: HandoutEventFields.PROTOCOL,
    },
    {
        text: 'Fernbestätigung der Koordinator:in',
        value: HandoutEventFields.COORDINATOR_REMOTE_CONFIRMATION,
    },
    {
        text: 'Fernbestätigung des Werbende:n',
        value: HandoutEventFields.ADVERTISER_REMOTE_CONFIRMATION,
    },
    {
        text: 'Fernbestätigung der Lehrkraft',
        value: HandoutEventFields.TEACHER_REMOTE_CONFIRMATION,
    },
    {
        text: 'Fernbestätigung des Standorts',
        value: HandoutEventFields.INSTITUTION_REMOTE_CONFIRMATION,
    },
    {
        text: 'Austeilaktion erfolgreich ausgeführt?',
        value: HandoutEventFields.EVENT_TOOK_PLACE,
    },
    {
        text: 'Feedback der Lehrkraft',
        value: HandoutEventFields.ADVERTISER_FEEDBACK,
    },
    {
        text: 'Feedback des Standorts',
        value: HandoutEventFields.INSTITUTION,
    },
    {
        text: 'Automatische Erinnerung',
        value: HandoutEventFields.REMINDER_CONFIG,
    },
    {
        text: 'Datum der Erzeugung',
        value: HandoutEventFields.CREATED_AT,
        readOnly: true,
    },
    {
        text: 'Datum der letzten Aktualisierung',
        value: HandoutEventFields.CREATED_AT,
        readOnly: true,
    },
    {
        text: 'Online-Formular Anmeldungen',
        value: HandoutEventFields.ONLINE_FORM_SUBMISSIONS,
        readOnly: true,
    },
];
