import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {WarehouseOrderFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/WarehouseOrderFields';

export const warehouseOrderFieldsDictionary: FieldDictionary<WarehouseOrderFields> = [
    {
        text: 'Artikel',
        value: WarehouseOrderFields.ARTICLE,
    },
    {
        text: 'Bestellmenge / Liefermenge',
        value: WarehouseOrderFields.QTY,
    },
    {
        text: 'Interne Empfänger:in / Absender:in',
        value: WarehouseOrderFields.REF,
    },
    {
        text: 'Art der internen Empfänger:in / Absender:in',
        value: WarehouseOrderFields.REF,
    },
    {
        text: 'Externe Empfänger:in / Absender:in',
        value: WarehouseOrderFields.ALTERNATE_REF,
    },
    {
        text: 'Status der Bestellung',
        value: WarehouseOrderFields.STATE,
    },
    {
        text: 'Zeitpunkt der Auftragsabwicklung',
        value: WarehouseOrderFields.FULFILLED_AT,
    },
    {
        text: 'Abwickelnde Mitarbeiter:in',
        value: WarehouseOrderFields.FULFILLED_BY,
    },
    {
        text: 'Erzeugungsdatum',
        value: WarehouseOrderFields.FULFILLED_AT,
        readOnly: true,
    },
    {
        text: 'Datum der letzten Aktualisierung',
        value: WarehouseOrderFields.UPDATED_AT,
        readOnly: true,
    },
    {
        text: 'Adressversandaufkleber',
        value: WarehouseOrderFields.SHIPMENT_LABEL,
        readOnly: true,
    },
];
