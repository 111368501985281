<template>
  <v-dialog :value="value" content-class="elevation-0 globalSearch" @input="reset" class="fill-height">
    <v-row style="height: 100%" align="center" @click="reset">
      <v-col cols="12">
        <v-slide-y-reverse-transition group leave-absolute>
          <v-row key="search" no-gutters justify="center">
            <v-col cols="12" sm="8" md="6" lg="4" xl="2">
              <div key="search" :class="$vuetify.theme.dark ? 'grey darken-4' : 'white'" class="rounded-pill mb-4">
                <v-text-field v-model="searchInput" autofocus clearable color="info" dense filled autocomplete="off"
                              full-width hide-details label="Suchbegriff eingeben" @click.stop
                              placeholder="Suchbegriff eingeben" prepend-inner-icon="mdi-magnify" rounded single-line @input="search"/>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="loading" key="loader" no-gutters justify="center">
            <v-col cols="12" sm="8" md="6" lg="4" xl="2">
              <v-card class="rounded-pill translucent no-user-select">
                <v-card-text class="text-center grey--text">
                  <v-row dense align="center">
                    <v-col cols="1">
                      <v-progress-circular size="24" width="2" indeterminate color="info"/>
                    </v-col>
                    <v-col cols="10">
                      Suchergebnisse werden geladen...
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else-if="searchInput && !result" justify="center" key="no-results">
            <v-col cols="12" sm="8" md="6" lg="4" xl="2">
              <v-card class="rounded-pill translucent no-user-select">
                <v-card-text class="text-center grey--text">
                  Keine Ergebnisse für diese Suche
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <div v-else-if="result" key="results">
            <app-bar-search-dialog-results-grid :result="result" :highlight-best-match="highlightBestMatch"/>
          </div>
        </v-slide-y-reverse-transition>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {sleep} from '@/helpers/sleep.helper';
import AppBarSearchDialogResultsGrid from '@/components/app/appBar/searchDialog/appBarSearchDialogResultsGrid.vue';
import {FindGlobalResponseDto} from '@/classes/dto/finder/response/FindGlobal.response.dto';
import {FinderAPI} from '@/classes/api/finder.api';

export default Vue.extend({
  components: {AppBarSearchDialogResultsGrid},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    searchInput: '',
    result: undefined as FindGlobalResponseDto | undefined,
    loading: false,

    throttleCounter: 0,
    throttleDelay: 800,

    clearSearchTimeout: undefined as number | undefined,
    bestMatchLimit: 5,
  }),
  computed: {
    highlightBestMatch(): boolean {
      if (this.result) {
        const maxScore = this.result.maxScore;
        let numberOfBestMatches = 0;
        const items = this.result.items as Record<string, FindGlobalResponseDto['items']['students']>
        for (const key in items) {
          if (items.hasOwnProperty(key)) {
            const typeResponse = items[key];
            for (const item of typeResponse.items) {
              if (item.score === maxScore) {
                numberOfBestMatches++;
                if (numberOfBestMatches > this.bestMatchLimit) {
                  return false;
                }
              }
            }
          }
        }
      }
      return true;
    },
  },
  methods: {
    async search() {
      if (!this.searchInput) {
        this.result = undefined;
        return;
      }
      this.loading = true;
      this.throttleCounter++;
      await sleep(this.throttleDelay);
      this.throttleCounter--;
      if (this.throttleCounter === 0) {
        if (!this.searchInput) {
          this.loading = false;
          this.result = undefined;
          return;
        }
        try {
          this.result = await FinderAPI.findGlobal(this.searchInput);
        } catch (e) {
          this.$$showSnackbar('Beim Laden der Suchergebnisse ist ein Fehler aufgetreten', 'error');
        } finally {
          this.loading = false;
        }
      }
    },
    reset() {
      this.$emit('input', false);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          if (this.clearSearchTimeout) {
            clearTimeout(this.clearSearchTimeout);
          }
        } else {
          this.clearSearchTimeout = setTimeout(() => {
            this.searchInput = '';
            this.result = undefined;
          }, 10_000) as unknown as number;
        }
      },
    },
  },
});
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.v-dialog__content--active {
  backdrop-filter: blur(5px);
}

.translucent {
  opacity: 0.8;
}
</style>

<!--suppress CssUnusedSymbol -->
<style>
.globalSearch {
  height: 100% !important;
}
</style>
