<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="loading" :value="value" max-width="600"
            @input="reset">
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="createConversation">
        <v-card-title>
          <div class="info--text">
            Neue Konversation
          </div>
          <v-spacer/>
          <v-btn :disabled="loading" icon @click="reset">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-slide-y-transition>
          <v-list v-if="conversationItem && conversationItem.name"
                  :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'">
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar>
                  <v-icon v-if="conversationItem.profileId">mdi-account</v-icon>
                  <v-icon v-else-if="conversationItem.phoneNumber">mdi-whatsapp</v-icon>
                  <v-icon v-else-if="conversationItem.mail">mdi-email</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ conversationItem.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="conversationItem.mail">
                  {{ conversationItem.mail }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="conversationItem.phoneNumber">
                  {{ conversationItem.phoneNumber }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-slide-y-transition>
        <v-tabs v-model="tab" color="info">
          <v-tab href="#profile">
            Profil
          </v-tab>
          <v-tab href="#unlinked">
            Extern
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="profile">
            <v-card-text>
              <v-autocomplete v-if="tab === 'profile'" v-model="itemSelectionIndex" :items="displayItems"
                              :loading="searching" :rules="[RuleFactory.required(), isValid]"
                              :search-input.sync="searchInput" autofocus color="info" dense item-color="info"
                              label="Kontakt" outlined rounded/>
              <v-autocomplete v-else :items="displayItems" :value="itemSelectionIndex" color="info" dense
                              label="Kontakt" outlined readonly rounded/>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="unlinked">
            <v-card-text>
              <v-text-field v-if="tab === 'unlinked'" v-model="unlinkedConversationAddress"
                            :rules="[RuleFactory.required(), isValid]" color="info" dense
                            label="E-Mail-Adresse oder Telefonnummer" outlined rounded/>
              <v-text-field v-else :value="unlinkedConversationAddress" color="info" dense
                            label="E-Mail-Adresse oder Telefonnummer" outlined readonly rounded/>
              <v-text-field v-model="unlinkedConversationName" color="info" dense label="Name (optional)" outlined
                            rounded/>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>

        <v-card-actions>
          <v-btn :disabled="loading" text @click="reset">
            Abbrechen
          </v-btn>
          <v-spacer/>
          <v-btn :disabled="!valid" :loading="loading" color="info" type="submit">
            Erstellen
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {Form} from '@/interfaces/Form.interface';
import {GetConversationsResponseDto} from '@/classes/dto/messenger/response/messages/GetConversations.response.dto';
import {CountryCodes} from '@/enums/CountryCodes.enum';
import {sleep} from '@/helpers/sleep.helper';
import {EmployeeHandlerAPI} from '@/classes/api/employee-handler.api.class';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {User} from '@/interfaces/user.interface';
import {MessengerTypesEnum} from '@/enums/messenger/MessengerTypes.enum';
import {parsePhoneNumberFromString} from 'libphonenumber-js';

type ConversationItem = GetConversationsResponseDto['conversations'][0];

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    tab: 'profile' as 'profile' | 'unlinked',
    loading: false,
    valid: false,
    items: [] as ConversationItem[],
    searchInput: '',
    throttleCounter: 0,
    searching: false,
    unlinkedConversationAddress: '',
    unlinkedConversationName: '',
    itemSelectionIndex: null as number | null,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    displayItems(): Array<{ text: string; value: number }> {
      return this.items.map((item, index) => ({
        text: item.name,
        value: index,
      }))
    },
    conversationItem(): ConversationItem | undefined {
      switch (this.tab) {
        case 'profile':
          return this.items.find((_, index) => index === this.itemSelectionIndex);
        case 'unlinked': {
          const isPhoneNumber = !!parsePhoneNumberFromString(this.unlinkedConversationAddress || '', CountryCodes.Germany)?.isValid();
          const isMail = RuleFactory.isValidMail()(this.unlinkedConversationAddress || '');
          return {
            name: this.unlinkedConversationName || this.unlinkedConversationAddress,
            mail: isMail && !isPhoneNumber ? this.unlinkedConversationAddress : undefined,
            phoneNumber: isPhoneNumber ? this.unlinkedConversationAddress : undefined,
            lastMessagePreview: '',
            profileId: undefined,
            profileType: undefined,
            lastMessageType: isPhoneNumber ? MessengerTypesEnum.WHATS_APP : MessengerTypesEnum.MAIL,
            messages: [],
            receivedLastMessageAt: new Date(),
            seen: false,
          }
        }
        default:
          return undefined;
      }
    },
  },
  methods: {
    isValid(item: any) {
      if (item && typeof item === 'object') {
        return true;
      }
      const isPhoneNumber = RuleFactory.isValidPhoneNumber(CountryCodes.Germany)(item || '');
      if (typeof isPhoneNumber !== 'string') {
        return true;
      }
      const isMail = RuleFactory.isValidMail()(item);
      if (typeof isMail !== 'string') {
        return true;
      }
      return 'Der Wert für dieses Feld muss ein Profil, eine E-Mail-Adresse oder eine Telefonnummer sein';
    },
    createConversation() {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        this.$emit('create', this.conversationItem);
        this.reset();
      }
    },
    profileToConversationItem(profile: User, type: CrudEntityTypes.EMPLOYEE | CrudEntityTypes.INSTITUTION | CrudEntityTypes.PARENT | CrudEntityTypes.STUDENT): ConversationItem {
      const hasMail = !!profile.contactInformation?.mail1 || !!profile.contactInformation?.mail2;
      const hasPhone = !!profile.contactInformation?.phone1 || !!profile.contactInformation?.phone2;

      return {
        name: profile.firstName + ' ' + profile.lastName,
        profileId: profile._id,
        profileType: type,
        lastMessagePreview: '',
        mail: profile.contactInformation?.mail1 || profile.contactInformation?.mail2,
        phoneNumber: profile.contactInformation?.mobile1 || profile.contactInformation?.mobile2,
        lastMessageType: hasMail ? MessengerTypesEnum.MAIL : hasPhone ? MessengerTypesEnum.WHATS_APP : undefined as any,
        messages: [],
        receivedLastMessageAt: new Date(),
        seen: false,
      }
    },
    reset() {
      const form = this.$refs.form as Form;
      form.reset();
      this.$emit('input', false);
    },
    async searchProfiles() {
      this.searching = true;
      this.throttleCounter++;
      await sleep(500);
      this.throttleCounter--;
      if (this.throttleCounter > 0) {
        return;
      }
      if (!this.searchInput) {
        this.items = [];
        this.searching = false;
        return;
      }

      const query = {
        filter: {
          $search: this.searchInput,
        },
        fields: ['contactInformation', 'firstName', 'lastName', 'name'],
        limit: 3,
      };

      try {
        const employees = await EmployeeHandlerAPI.find(query as any);
        this.items.push(...employees.employees.map((e) => this.profileToConversationItem(e, CrudEntityTypes.EMPLOYEE)));

      } catch (e) {
        this.$$showSnackbar('Fehler beim Suchen nach Profilen', 'error', e);
      } finally {
        this.searching = false;
      }
    },
  },
  watch: {
    searchInput() {
      this.searchProfiles();
    },
    tab() {
      const form = this.$refs.form as Form;
      form?.resetValidation;
    },
    unlinkedConversationAddress(val: string) {
      const phoneNumber = parsePhoneNumberFromString(val || '', CountryCodes.Germany);
      if (phoneNumber?.isValid()) {
        this.unlinkedConversationAddress = phoneNumber.formatInternational();
      }
    },
  },
})
</script>
