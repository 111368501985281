<template>
  <v-card flat>
    <v-card-text>
      <v-form v-model="valid" lazy-validation ref="form">
        <v-row class="my-1" v-if="messengerAccount">
          <v-col cols="12">
            <v-alert type="success" dense>
              Es wurde bereits eine Kommunikationsverbindung für diesen Kommunikationstyp angelegt
              <template v-slot:append v-can:delete-messengerAccounts>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="removeMessengerAccount">
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>Konfiguration löschen</span>
                </v-tooltip>
              </template>
            </v-alert>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <api-configuration-autocomplete :disabled="messengerAccount" :rules="[RuleFactory.required()]" clearable
                                            multiple prefetch only-accessible
                                            :filter="apiConfigurationFilter"
                                            label="API-Konfigurationen auswählen" outlined rounded color="info"
                                            v-model="apiConfigurations"/>
          </v-col>
          <v-col cols="12">
            <login-user-autocomplete :disabled="messengerAccount" v-model="loginUser" :rules="[RuleFactory.required()]"
                                     label="Nutzer:in auswählen"
                                     outlined rounded color="info"/>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="type === MessengerTypesEnum.MAIL">

          <v-col cols="12" v-if="boxLoading">
            <v-skeleton-loader v-if="boxLoading" type="list-item-two-line"/>
          </v-col>
          <v-col cols="12" v-else>
            <v-expansion-panels>
              <v-expansion-panel v-for="(boxConfiguration, i) in boxConfigurations" :key="i">
                <v-expansion-panel-header>
                  {{ boxConfiguration.username }} -
                  {{ boxConfiguration.isPrimary ? 'Postfach' : 'Freigegebenes Postfach' }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-treeview selection-type="independent" item-key="fullPath" dense selectable
                              v-model="boxConfiguration.boxes"
                              :items="boxConfiguration.treeItems"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn v-can:create-messengerAccounts v-can:update-messengerAccounts :loading="loading"
             :disabled="!valid || boxLoading || loading" type="submit"
             @click="synchronizeMessengerAccount">
        Synchronisieren
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import {MessengerTypesEnum} from "@/enums/messenger/MessengerTypes.enum";
import ApiConfigurationAutocomplete from "@/components/common/search-fields/apiConfigurationAutocomplete.vue";
import {ApiTypesEnum} from "@/enums/api-handler/ApiTypes.enum";
import {Form} from "@/interfaces/Form.interface";
import mongoose, {LeanDocument} from "mongoose";
import {ApiHandlerApi} from "@/classes/api/api-handler.api";
import {GetBoxesMailResponseDto} from "@/classes/dto/api-handler/response/mail/api/GetBoxes.mail.response.dto";
import {MessengerApi} from "@/classes/api/messenger.api";
import {FindApiConfigurationFilter} from "@/classes/dto/api-handler/request/filters/FindApiConfiguration.filter";
import {RuleFactory} from "@/helpers/ruleFactory.helper";
import LoginUserAutocomplete from "@/components/common/search-fields/loginUserAutocomplete.vue";
import {MessengerAccount, MessengerAccountDocument} from "@/documentTypes/messenger/MessengerAccount.document";
import {MessengerMailAccount} from "@/documentTypes/messenger/MessengerMailAccount.document";
import {retrieveObjectId} from "@/helpers/isObjectId.helper";

interface BoxConfiguration {
  username: string;
  isPrimary: boolean;
  /** Value to model */
  boxes: string[];
  /** Available items */
  treeItems: GetBoxesMailResponseDto['root'];
}

export default Vue.extend({
  components: {
    LoginUserAutocomplete,
    ApiConfigurationAutocomplete,
  },
  props: {
    type: {
      type: Object as () => MessengerTypesEnum,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    messengerAccount: {
      type: Object as () => LeanDocument<MessengerAccountDocument>,
      required: false,
      default: undefined,
    },
  },
  data: () => ({
    valid: false,
    apiConfigurations: [] as mongoose.Types.ObjectId[],
    boxLoading: false,
    boxConfigurations: [] as BoxConfiguration[],
    loginUser: null as null | mongoose.Types.ObjectId,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    ApiTypesEnum: () => ApiTypesEnum,
    MessengerTypesEnum: () => MessengerTypesEnum,
    apiConfigurationFilter(): FindApiConfigurationFilter {
      return {
        type: this.type === MessengerTypesEnum.MAIL ? [ApiTypesEnum.MAIL] : [ApiTypesEnum.WHATS_APP],
      }
    },
  },
  methods: {
    async removeMessengerAccount() {
      try {
        if (this.messengerAccount && this.messengerAccount._id) {
          this.$emit('update:loading', true);
          await MessengerApi.accounts.delete(this.messengerAccount._id);
          this.$emit('update:messengerAccount');
          this.$$showSnackbar('Kommunikationsaccount wurde erfolgreich gelöscht.', 'success');
        }
      } catch (e) {
        this.$$showSnackbar('Fehler beim Löschen der Kommunikationsverbindung', 'error', e);
      } finally {
        this.$emit('update:loading', false);
      }
    },
    async getMailBoxes() {
      try {
        this.boxLoading = true;
        const details = await ApiHandlerApi.mail.api.getImapAccessDetails({
          _id: this.apiConfigurations.map((el) => retrieveObjectId(el)),
        });
        for (const item of details) {
          for (const user of item.users) {
            const found = this.boxConfigurations.find((el) => el.username === user.username);
            if (!found) {
              this.boxConfigurations.push({
                username: user.username,
                isPrimary: user.isPrimary,
                boxes: [],
                treeItems: user.boxHierarchy.root,
              });
            }
          }
        }
      } catch (e) {
        this.$$showSnackbar('Fehler beim Abrufen der Postfachordner', 'error', e);
      } finally {
        this.boxLoading = false;
      }
    },
    async synchronizeMessengerAccount() {
      try {
        const form = this.$refs.form as Form;
        if (form.validate()) {
          this.$emit('update:loading', true);
          if (this.messengerAccount && this.messengerAccount._id) {
            // handle update if messenger account exists
            await MessengerApi.accounts.update(this.messengerAccount._id, {
              boxConfiguration: this.type === MessengerTypesEnum.MAIL ? this.boxConfigurations.map((el) => ({
                username: el.username,
                boxes: el.boxes,
              })) : undefined,
            });
            this.$$showSnackbar('Kommunikationsaccount wurde erfolgreich aktualisiert.', 'success')
          } else {
            await MessengerApi.accounts.create({
              apiConfigs: this.apiConfigurations,
              type: this.type,
              loginUser: this.loginUser as mongoose.Types.ObjectId,
              boxConfiguration: this.type === MessengerTypesEnum.MAIL ? this.boxConfigurations.map((el) => ({
                username: el.username,
                boxes: el.boxes,
              })) : undefined,
            });
            this.$$showSnackbar('Kommunikationsaccount wurde erfolgreich angelegt.', 'success')
          }
        }
        this.$emit('update:messengerAccount');
      } catch (e) {
        this.$$showSnackbar('Fehler beim Anlegen des Kommunikationsaccounts', 'error', e);
      } finally {
        this.$emit('update:loading', false);
      }
    },
  },
  watch: {
    apiConfigurations: {
      handler(v: mongoose.Types.ObjectId[]) {
        if (v.length > 0 && this.type === MessengerTypesEnum.MAIL && !this.messengerAccount) {
          this.getMailBoxes();
        }
      },
    },
    messengerAccount: {
      immediate: true,
      handler(account: MessengerAccount) {
        if (account) {
          this.apiConfigurations = account.apiConfigs;
          this.loginUser = account.loginUser;
          if (this.type === MessengerTypesEnum.MAIL) {
            this.getMailBoxes().then(() => {
              this.boxConfigurations = this.boxConfigurations.map((config) => ({
                ...config,
                boxes: (account as MessengerMailAccount).boxConfiguration.find((el) => el.username === config.username)?.boxes || [],
              }));
            });
          }
        }
      },
    },
  },
});
</script>
