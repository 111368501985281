<template>
  <v-badge :value="!notification.readAt" color="error" dot offset-x="25" offset-y="20" style="width: 100%">
    <v-list-item :class="{ 'red lighten-5': !notification.readAt }">
      <v-list-item-avatar>
        <profile-avatar v-if="notification.messagePayload.entityType === 'employees'"
                        :user="{profileImg: notification.messagePayload.profileImg}"/>
        <v-avatar v-else color="grey" size="32">
          <v-icon small color="white">mdi-domain</v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle v-if="isDeactivated">
          Deaktivierung am {{ moment(notification.messagePayload.dateOfDeactivation).format('DD.MM.YYYY') }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          Deaktivierung zum {{ moment(notification.messagePayload.dateOfDeactivation).format('DD.MM.YYYY') }}
        </v-list-item-subtitle>
        <v-list-item-title v-if="isDeactivated">
          <overflow-text :text="`${notification.messagePayload.entityName} wurde deaktiviert`"/>
        </v-list-item-title>
        <v-list-item-title v-else>
          <overflow-text :text="`${notification.messagePayload.entityName} wird deaktiviert`"/>
        </v-list-item-title>
        <v-list-item-subtitle v-if="!notification.dismissedAt">
          {{ moment(notification.createdAt).fromNow() }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          <v-icon small>mdi-delete</v-icon>
          {{ moment(notification.dismissedAt).fromNow() }} gelöscht
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn :href="link" icon target="_blank" v-on="on">
              <v-icon>mdi-page-next-outline</v-icon>
            </v-btn>
          </template>
          <span>Ansehen</span>
        </v-tooltip>

      </v-list-item-action>
      <v-list-item-action v-if="!notification.dismissedAt" class="ml-0">
        <v-btn icon @click="$emit('dismiss', notification._id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action v-else class="ml-0">
        <v-btn icon @click="$emit('revoke', notification._id)">
          <v-icon>mdi-delete-off</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-badge>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import moment from 'moment';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import {
  SupervisedEntityDeactivatedNotificationDocument,
} from '@/documentTypes/notifier/discriminators/supervisor/SupervisedEntityDeactivatedNotification.document';
import OverflowText from '@/components/common/overflow-text.vue';

moment.locale('de');

export default Vue.extend({
  components: {OverflowText, ProfileAvatar},
  props: {
    notification: {
      type: Object as () => NotificationLike<SupervisedEntityDeactivatedNotificationDocument>,
      required: true,
    },
  },
  computed: {
    moment: () => moment,
    isDeactivated(): boolean {
      return moment(this.notification.messagePayload.dateOfDeactivation).isBefore(moment().add(1, 'day').startOf('day'));
    },
    link(): string {
      return this.$router.resolve(`/${this.notification.messagePayload.entityType}/${this.notification.messagePayload.entityId}`).href;
    },
  },
});
</script>
