<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="loading" :value="value" max-width="600"
            @input="reset">
    <v-card>
      <v-card-title class="my-2">
        <div class="info--text">
          Passwort zurücksetzen
        </div>
        <v-spacer/>
        <v-btn :disabled="loading" icon @click="reset">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field v-model="mail" :rules="[RuleFactory.required(), RuleFactory.isValidMail()]" color="info"
                        dense hint="Bitte gib deine E-Mail-Adresse ein." label="E-Mail-Adresse" outlined
                        rounded/>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="loading" text @click="reset">
          Abbrechen
        </v-btn>
        <v-spacer/>
        <v-btn :disabled="!valid" :loading="loading" color="info" @click="resetPassword">
          Passwort zurücksetzen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {Form} from '@/interfaces/Form.interface';
import Axios from 'axios';
import {ForgotPasswordRequestDto} from '@/classes/dto/user-login-handler/request/ForgotPassword.request.dto';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    valid: false,
    mail: '',
  }),
  computed: {
    RuleFactory: () => RuleFactory,
  },
  methods: {
    async resetPassword() {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        this.loading = true;
        try {
          await Axios.post(`${process.env.VUE_APP_API_PATH}/user-login-handler/forgotPassword`, {
            mail: this.mail,
          } as ForgotPasswordRequestDto);
          this.$$showSnackbar('Sollte ein Account zur angegebenen E-Mail Adresse existieren, wird dir ein Link zum Zurücksetzen des Passworts zugeschickt.', 'success');
          this.reset();
        } catch (e) {
          this.$$showSnackbar('Es ist ein Fehler beim Zurücksetzen des Passworts aufgetreten. Bitte erneut versuchen.', 'error');
        } finally {
          this.loading = false;
        }
      }
    },
    reset() {
      const form = this.$refs.form as Form;
      form.reset();
      this.$emit('input', false);
    },
  },
});
</script>
