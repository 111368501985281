export function toCurrencyString(val: number, fractionDigits?: number, currency?: string) {
  if (!val) {
    val = 0;
  }
  return val.toLocaleString('de-DE', {
    currency: currency?.toUpperCase() || 'EUR',
    style: 'currency',
    minimumFractionDigits: fractionDigits || 2,
    maximumFractionDigits: fractionDigits || 2,
  });
}
