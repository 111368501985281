import {io, Socket} from 'socket.io-client';
import store from '@/store';
import {NotificationTypes} from '@/enums/notifier/NotificationTypes';
import {LeanDocument, Types} from 'mongoose';
import {MentionNotificationDocument} from '@/documentTypes/notifier/discriminators/MentionNotification.document';
import {CallNotificationDocument} from '@/documentTypes/notifier/discriminators/CallNotification.document';
import {
    OverdueToDoNotificationDocument,
} from '@/documentTypes/notifier/discriminators/OverdueToDoNotification.document';
import {
    ToDoAssignedNotificationDocument,
} from '@/documentTypes/notifier/discriminators/ToDoAssignedNotification.document';
import {
    ToDoCompletedNotificationDocument,
} from '@/documentTypes/notifier/discriminators/ToDoCompletedNotification.document';
import {NotifierEvents} from '@/enums/notifier/NotifierEvents';
import {NotificationBus} from '@/busses/NotificationBus';
import {
    EntityAssignedToSupervisorNotificationDocument,
} from '@/documentTypes/notifier/discriminators/supervisor/EntityAssignedToSupervisorNotification.document';
import {
    SupervisedEntityDeactivatedNotification,
} from '@/documentTypes/notifier/discriminators/supervisor/SupervisedEntityDeactivatedNotification.document';
import {SoundManager} from '@/classes/clientOnly/SoundManager.class';
import {RecorderEvents} from '@/enums/notifier/serviceEvents/RecorderEvents';
import {RecorderEventBus} from '@/busses/RecorderEventBus';
import {extractEnumValues} from '@/helpers/extractEnumValues';
import {
    WhatsAppMessageTemplateStatusUpdateNotification,
} from '@/documentTypes/notifier/discriminators/supervisor/WhatsAppMessageTemplateStatusUpdateNotification.document';
import {MessengerEvents} from '@/enums/notifier/serviceEvents/MessengerEvents';
import {MessengerEventBus} from '@/busses/MessengerEventBus';
import {
    CourseCancellationFailedNotificationDocument,
} from '@/documentTypes/notifier/discriminators/substitutionManager/CourseCancellationFailedNotification.document';
import {
    HandoutPlannerEventFeedbackNotificationDocument,
} from '@/documentTypes/notifier/discriminators/handoutPlanner/HandoutPlannerEventFeedbackNotification.document';

/**
 * This class is used for communicating with the notifier microservice.
 * @class NotifierSocketApi
 * @author Joshua Seipel
 */
export class NotifierSocketApi {
    static service = 'notifier';
    private static socket: Socket;

    static async connect() {
        this.socket = io(`${process.env.VUE_APP_API_PATH}/${this.service}`, {
            path: '/notifier/socket.io',
            withCredentials: true,
        });
        this.socket.on('connect', () => {
            store.commit('setSocketConnected', true);
        });
        this.socket.on('disconnect', function () {
            store.commit('setSocketConnected', false);
        });
        this.socket.on(NotificationTypes.MENTION, (data: LeanDocument<MentionNotificationDocument>) => {
            store.commit('addRtcNotification', data);
            SoundManager.sounds.silent();
        });
        this.socket.on(NotificationTypes.CALL, (data: LeanDocument<CallNotificationDocument>) => {
            store.commit('addRtcNotification', data);
            SoundManager.sounds.call();
        });
        this.socket.on(NotificationTypes.OVERDUE_TO_DO, (data: LeanDocument<OverdueToDoNotificationDocument>) => {
            store.commit('addRtcNotification', data);
            SoundManager.sounds.reminder();
        });
        this.socket.on(NotificationTypes.TO_DO_ASSIGNED, (data: LeanDocument<ToDoAssignedNotificationDocument>) => {
            store.commit('addRtcNotification', data);
            SoundManager.sounds.common();
        });
        this.socket.on(NotificationTypes.HANDOUT_EVENT_FEEDBACK, (data: LeanDocument<HandoutPlannerEventFeedbackNotificationDocument>) => {
            store.commit('addRtcNotification', data);
            SoundManager.sounds.common();
        });
        this.socket.on(NotificationTypes.TO_DO_COMPLETED, (data: LeanDocument<ToDoCompletedNotificationDocument>) => {
            store.commit('addRtcNotification', data);
            SoundManager.sounds.completed();
        });
        this.socket.on(NotificationTypes.ENTITY_ASSIGNED_TO_SUPERVISOR, (data: LeanDocument<EntityAssignedToSupervisorNotificationDocument>) => {
            store.commit('addRtcNotification', data);
            SoundManager.sounds.silent();
        });
        this.socket.on(NotificationTypes.SUPERVISED_ENTITY_DEACTIVATED, (data: LeanDocument<SupervisedEntityDeactivatedNotification>) => {
            store.commit('addRtcNotification', data);
            SoundManager.sounds.silent();
        });

        this.socket.on(NotificationTypes.WHATSAPP_MESSAGE_TEMPLATE_STATUS_UPDATE, (data: LeanDocument<WhatsAppMessageTemplateStatusUpdateNotification>) => {
            store.commit('addRtcNotification', data);
            SoundManager.sounds.silent();
        });
        this.socket.on(NotificationTypes.COURSE_CANCELLATION_FAILED, (data: LeanDocument<CourseCancellationFailedNotificationDocument>) => {
            store.commit('addRtcNotification', data);
            SoundManager.sounds.silent();
        });

        this.socket.on(NotifierEvents.MARK_READ, (ids: Types.ObjectId[]) => {
            NotificationBus.$emit(NotifierEvents.MARK_READ, ids);
        });
        this.socket.on(NotifierEvents.DISMISS, (ids: Types.ObjectId[]) => {
            NotificationBus.$emit(NotifierEvents.DISMISS, ids);
        });
        this.socket.on(NotifierEvents.REVOKE_DISMISSION, (ids: Types.ObjectId[]) => {
            NotificationBus.$emit(NotifierEvents.REVOKE_DISMISSION, ids);
        });

        // Handle Recorder events
        for (const v of extractEnumValues(RecorderEvents) as any[]) {
            this.socket.on(v, (data: any) => RecorderEventBus.$emit(v, data));
        }

        // Handle Messenger events
        for (const v of extractEnumValues(MessengerEvents) as any[]) {
            this.socket.on(v, (data: any) => MessengerEventBus.$emit(v, data));
        }
    }

    static async disconnect(): Promise<void> {
        return new Promise((res) => {
            this.socket.disconnect();
            res();
        });
    }
}
