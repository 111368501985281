export enum ToDoItemFields {
    ENTITY = 'entity',
    ENTITY_TYPE = 'entityType',
    NAME = 'name',
    DESCRIPTION = 'description',
    DUE_DATE = 'dueDate',
    RESPONSIBLE = 'responsible',
    COMPLETED = 'completed',
    COMPLETED_AT = 'completedAt',
    COMPLETION_COMMENT = 'completionComment',
    DATA = 'data',
    DATA_TYPE = 'dataType',
    FROM_TEMPLATE = 'fromTemplate',
    CREATED_BY = 'createdBy',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
}
