import { render, staticRenderFns } from "./overflow-text.vue?vue&type=template&id=8e8d29d0&scoped=true"
import script from "./overflow-text.vue?vue&type=script&lang=ts"
export * from "./overflow-text.vue?vue&type=script&lang=ts"
import style0 from "./overflow-text.vue?vue&type=style&index=0&id=8e8d29d0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e8d29d0",
  null
  
)

export default component.exports