<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" :value="value" max-width="600">
    <v-card>
      <v-card-title>
        <div class="warning--text">
          Ablagestapel bearbeiten
        </div>
        <v-spacer/>
        <v-btn :disabled="this.loading" icon @click="reset">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="loading">
        <v-skeleton-loader type="list-item-avatar-two-line"/>
        <v-skeleton-loader type="list-item-avatar-two-line"/>
      </v-card-text>
      <v-card-text v-else>
        <v-form v-model="valid" lazy-validation ref="form">
          <v-row dense>
            <v-col cols="12" lg="6">
              <v-text-field v-model="stackName"
                            :rules="[
                            RuleFactory.required('Der Name des Stapels ist erforderlich'),
                            RuleFactory.maxLength(32),
                        ]" rounded outlined color="warning" dense
                            counter="32" label="Name des Ablagestapels"/>
            </v-col>
            <v-col cols="12">
              <v-subheader>Farbe auswählen</v-subheader>
              <v-row align="center">
                <v-col cols="1">
                  <v-avatar size="32" :color="stackColor">
                    <v-icon color="white" small>mdi-tray-full</v-icon>
                  </v-avatar>
                </v-col>
                <v-col cols="11">
                  <v-color-picker v-model="stackColor" hide-sliders show-swatches :swatches="swatches" hide-canvas
                                  hide-inputs/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="reset" :disabled="loading">
          Abbrechen
        </v-btn>
        <v-spacer/>
        <v-btn :disabled="!valid" :loading="loading" color="warning" @click="save">
          Stapel anlegen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {Form} from '@/interfaces/Form.interface';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {StackBus} from '@/busses/StackBus';
import colors from 'vuetify/lib/util/colors'
import mongoose from "mongoose";
import {Stack} from "@/interfaces/stack.interface";
import {compareForUpdateNonNullable} from "@/helpers/compareForUpdate";


export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    stackId: {
      type: Object as () => mongoose.Types.ObjectId,
    },
  },
  data: () => ({
    stackName: undefined as undefined | string,
    stackColor: undefined as undefined | string,
    loading: false,
    valid: false,
    swatches: [[colors.red.base], [colors.pink.base], [colors.purple.base], [colors.indigo.base],
      [colors.blue.base], [colors.lightBlue.base], [colors.cyan.base], [colors.teal.base],
      [colors.green.base], [colors.lightGreen.base], [colors.amber.base], [colors.orange.base],
      [colors.deepOrange.base], [colors.blueGrey.base], ['#9E9E9E'],
    ],
    stack: undefined as undefined | Stack,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
  },
  methods: {
    async save() {
      const form = this.$refs.form as Form;
      if (form && form.validate()) {
        this.loading = true;
        try {
          await StackManagerApi.update(this.stackId, {
            name: compareForUpdateNonNullable(this.stackName, this.stack?.name),
            color: compareForUpdateNonNullable(this.stackColor, this.stack?.color),
          });
          StackBus.$emit('reload:stacks');
          StackBus.$emit('reload:selectedStack');
          this.reset();
        } catch (err) {
          this.$$showSnackbar('Fehler beim Aktualisieren des Stapels', 'error', err);
        }
        this.loading = false;
      }
    },
    async initialize() {
      try {
        this.loading = true;
        this.stack = await StackManagerApi.findById(this.stackId, {
          fields: ['name', 'color'],
        });
        this.stackName = this.stack.name;
        this.stackColor = this.stack.color;
      } catch (e) {
        this.$$showSnackbar('Fehler beim Laden des Stapels', 'error', e)
      } finally {
        this.loading = false;
      }
    },
    reset() {
      const form = this.$refs.form as Form;
      if (form) {
        form.reset();
      }
      this.$emit('input', false);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.initialize();
        }
      },
    },
  },
});
</script>
