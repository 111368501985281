<template>
  <v-dialog :value="value" :persistent="loading" @input="reset" max-width="800"
            :fullscreen="$vuetify.breakpoint.smAndDown">
    <v-card>
      <v-card-title>
        <div class="info--text">
          Kommunikationsaccounts
        </div>
        <v-spacer/>
        <v-btn icon :disabled="loading" @click="reset">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs vertical color="info" v-model="tab">
          <v-tab :disabled="loading">
            <v-icon left>
              mdi-whatsapp
            </v-icon>
            WhatsApp
          </v-tab>
          <v-tab :disabled="loading">
            <v-icon left>
              mdi-email-sync
            </v-icon>
            E-Mail
          </v-tab>

          <v-tab-item>
            <synchronize-messenger-accounts-card @update:messengerAccount="getMessengerAccounts"
                                                 :messenger-account="whatsAppMessengerAccount" :loading.sync="loading"
                                                 :type="MessengerTypesEnum.WHATS_APP"/>
          </v-tab-item>
          <v-tab-item>
            <synchronize-messenger-accounts-card @update:messengerAccount="getMessengerAccounts"
                                                 :messenger-account="mailMessengerAccount" :loading.sync="loading"
                                                 :type="MessengerTypesEnum.MAIL"/>
          </v-tab-item>
        </v-tabs>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {MessengerTypesEnum} from "@/enums/messenger/MessengerTypes.enum";
import {MessengerAccount, MessengerAccountDocument} from "@/documentTypes/messenger/MessengerAccount.document";
import {MessengerApi} from "@/classes/api/messenger.api";
import mongoose, {LeanDocument} from "mongoose";
import SynchronizeMessengerAccountsCard from "@/components/messenger/synchronizeMessengerAccountsCard.vue";


export default Vue.extend({
  components: {SynchronizeMessengerAccountsCard},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    tab: 0,
    messengerAccounts: [] as LeanDocument<MessengerAccountDocument[]>,
  }),
  computed: {
    MessengerTypesEnum: () => MessengerTypesEnum,
    mailMessengerAccount(): MessengerAccount | undefined {
      return this.messengerAccounts.find((el) => el.type === MessengerTypesEnum.MAIL);
    },
    whatsAppMessengerAccount(): MessengerAccount | undefined {
      return this.messengerAccounts.find((el) => el.type === MessengerTypesEnum.WHATS_APP);
    },
  },
  methods: {
    reset() {
      this.$emit('input', false);
    },
    async getMessengerAccounts() {
      try {
        this.loading = true;
        const resp = await MessengerApi.accounts.find({
          filter: {
            loginUser: [this.$store.state.loginUserId as mongoose.Types.ObjectId],
          },
          skipPagination: true,
        });
        this.messengerAccounts = resp.messengerAccounts;
      } catch (e) {
        this.$$showSnackbar('Fehler beim Laden der Kommunikationsaccounts', 'error', e);
        this.reset();
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getMessengerAccounts();
        }
      },
    },
  },
});
</script>
