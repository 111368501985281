import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum MessengerTypesEnum {
    WHATS_APP = 'whatsApp',
    MAIL = 'mail',
}

export namespace MessengerTypesEnum {
    type Data = {
        text: string;
        icon: string;
    }

    export const Record: Record<MessengerTypesEnum, Data> = {
        [MessengerTypesEnum.WHATS_APP]: {
            text: 'WhatsApp',
            icon: 'mdi-whatsapp',
        },
        [MessengerTypesEnum.MAIL]: {
            text: 'Mail',
            icon: 'mdi-email',
        },
    };

    export const Items = mapRecordToItems(Record);
}
