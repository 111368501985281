<template>
  <autocomplete-preset :api="api" :fields="['firstName', 'isApplicant', 'lastName', 'customId', 'status']" :resp-mapper="respMapper"
                       :value="value"
                       v-bind="$props" @input="$emit('input', $event)"/>
</template>
<script lang="ts">
import AutocompleteMixin from '@/mixins/autocompletes/Autocomplete.mixin';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {EmployeeHandlerAPI} from '@/classes/api/employee-handler.api.class';
import {Employee} from '@/interfaces/employee.interface';
import {EmployeeFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/EmployeeFields';

export default AutocompleteMixin.extend({
  props: {
    disablePotential: {type: Boolean, default: false},
    disabledRules: {
      type: Array as () => Array<(v: any) => string | false>,
      default: () => ([]),
    },
    disableApplicants: {
      type: Boolean,
      default: false,
    },
    appendOuterIcon: {
      type: String,
      default: '',
    },
  },
  computed: {
    api: () => EmployeeHandlerAPI,
    computedDisabledRules(): Array<(v: any) => string | false> {
      const disabledRules = [...this.disabledRules];
      if (this.disableApplicants) {
        disabledRules.push((e) => e.isApplicant && 'Noch im Bewerbungsstatus');
        disabledRules.push((e) => !this.$$crudAccessManager.entity(CrudEntityTypes.EMPLOYEE, e._id).canRead(EmployeeFields.IS_APPLICANT) && 'Kein Lesezugriff auf Bewerbungsstatus');
      }
      return disabledRules;
    },
  },
  methods: {
    respMapper(resp: { employees: Employee[] }) {
      return resp.employees.map((employee) => {

        let text = (employee.firstName || employee.lastName) ? `${employee.firstName || ''} ${employee.lastName || ''}`.trim() : 'Unbekannte Person';

        return {
          text,
          value: employee._id,
          description: employee.customId,
          disabled: this.computedDisabledRules.map((fn) => fn(employee)).find((v) => v) ?? false,
        };
      });
    },
  },
});
</script>
