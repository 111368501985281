<template>
  <div>
    Konversation mit {{ conversation.name }}
    <v-menu nudge-right="8" :nudge-top="nudgeTop" offset-x transition="slide-x-transition" v-if="hasInfoContent">
      <template v-slot:activator="{on}">
        <v-btn icon small v-on="on">
          <v-icon small>mdi-help-circle</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-if="conversation.mail">
          <v-list-item-icon>
            <v-icon dense>mdi-email</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ conversation.mail }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="conversation.phoneNumber">
          <v-list-item-icon>
            <v-icon dense>mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ conversation.phoneNumber }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-tooltip bottom v-if="profileLink">
      <template v-slot:activator="{ on }">
        <v-btn icon small v-on="on" :to="profileLink" target="_blank">
          <v-icon color="info" small>mdi-page-next-outline</v-icon>
        </v-btn>
      </template>
      <span>Zum Profil</span>
    </v-tooltip>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import {GetConversationsResponseDto} from '@/classes/dto/messenger/response/messages/GetConversations.response.dto';
import {CrudEntityTypes} from "@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes";

export default Vue.extend({
  props: {
    conversation: {
      type: Object as () => GetConversationsResponseDto['conversations'][0],
      required: true,
    },
  },
  computed: {
    hasInfoContent(): boolean {
      return !!this.conversation.phoneNumber || !!this.conversation.mail;
    },
    infoContentLength(): number {
      return (this.conversation.phoneNumber ? 1 : 0) + (this.conversation.mail ? 1 : 0);
    },
    nudgeTop(): number {
      return this.infoContentLength * 15;
    },
    profileLink(): string | undefined {
      if (this.conversation.profileId && this.conversation.profileType) {
        switch (this.conversation.profileType) {
          case CrudEntityTypes.EMPLOYEE:
            return `/employees/${this.conversation.profileId}`;
          case CrudEntityTypes.INSTITUTION:
            return `/institutions/${this.conversation.profileId}`;
          case CrudEntityTypes.PARENT:
            return `/parents/${this.conversation.profileId}`;
          case CrudEntityTypes.STUDENT:
            return `/students/${this.conversation.profileId}`;
        }
      }
      return undefined;
    },
  },
});
</script>
