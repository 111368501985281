import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {ContractFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/ContractFields';

export const contractFieldsDictionary: FieldDictionary<ContractFields> = [
    {
        text: 'Vertragsbezeichnung',
        value: ContractFields.NAME,
    },
    {
        text: 'Vertragsinhalt',
        value: ContractFields.CONDITIONS,
    },
    {
        text: 'Gehaltsgrundlage',
        value: ContractFields.SALARY_BASIS,
    },
    {
        text: 'Lohn',
        value: ContractFields.SALARY_PER_UNIT,
    },
    {
        text: 'Fahrtkostenkompensation',
        value: ContractFields.TRAVEL_EXPENSE_COMPENSATION_PER_KILOMETER,
    },
    {
        text: 'Erzeugungsdatum',
        value: ContractFields.CREATED_AT,
    },
    {
        text: 'Bearbeitungsdatum',
        value: ContractFields.UPDATED_AT,
    },
];
