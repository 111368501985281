import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {EmployeeFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/EmployeeFields';

export const employeeFieldsDictionary: FieldDictionary<EmployeeFields> = [
    {
        text: 'Geschlecht',
        value: EmployeeFields.GENDER,
    },
    {
        text: 'Vorname',
        value: EmployeeFields.FIRST_NAME,
    },
    {
        text: 'Nachname',
        value: EmployeeFields.LAST_NAME,
    },
    {
        text: 'Geburtsdatum',
        value: EmployeeFields.BIRTHDAY,
    },
    {
        text: 'Anschrift',
        value: EmployeeFields.ADDRESS,
    },
    {
        text: 'Zahlungsinformationen',
        value: EmployeeFields.PAYMENT,
    },
    {
        text: 'Zugang zu Vanilla',
        value: EmployeeFields.HAS_LOGIN,
    },
    {
        text: 'Aktivitätsstatus',
        value: EmployeeFields.IS_ACTIVE,
    },
    {
        text: 'Datum der Deaktivierung',
        value: EmployeeFields.DEACTIVATED_AT,
        readOnly: true,
    },
    {
        text: 'Kontaktdaten',
        value: EmployeeFields.CONTACT_INFORMATION,
    },
    {
        text: 'Mitarbeitendennummer',
        value: EmployeeFields.CUSTOM_ID,
        readOnly: true,
    },
    {
        text: 'Art der Mitarbeitendennummer',
        value: EmployeeFields.CUSTOM_ID_SCHEME,
        readOnly: true,
    },
    {
        text: 'Referenz zur Buchhaltungsschnittstelle',
        value: EmployeeFields.ACCOUNTING_REF,
        readOnly: true,
    },
    {
        text: 'Fächer',
        value: EmployeeFields.SUBJECTS,
    },
    {
        text: 'Vertragsinformationen',
        value: EmployeeFields.CONTRACT,
    },
    {
        text: 'Person ist Lehrkraft?',
        value: EmployeeFields.IS_TEACHER,
    },
    {
        text: 'Person ist Mitarbeitende:r?',
        value: EmployeeFields.IS_EMPLOYEE,
    },
    {
        text: 'Person ist Bewerber:in?',
        value: EmployeeFields.IS_APPLICANT,
    },
    {
        text: 'Informationen über Führerschein',
        value: EmployeeFields.HAS_DRIVER_LICENSE,
    },
    {
        text: 'Informationen über Fahrzeug',
        value: EmployeeFields.HAS_CAR,
    },
    {
        text: 'Maximale Fahrbereitschaft',
        value: EmployeeFields.MAX_DISTANCE,
    },
    {
        text: 'Maximale Fahrtzeit',
        value: EmployeeFields.MAX_TRAVEL_TIME,
    },
    {
        text: 'Profilbild',
        value: EmployeeFields.PROFILE_IMG,
    },
    {
        text: 'Zugeteilte Standorte',
        value: EmployeeFields.ASSIGNED_INSTITUTIONS,
    },
    {
        text: 'Geplante Standorte',
        value: EmployeeFields.PLANNED_INSTITUTIONS,
    },
    {
        text: 'Unterrichtsverfügbarkeiten',
        value: EmployeeFields.BLOCKED_SLOTS_CALENDAR,
    },
    {
        text: 'Austeilverfügbarkeiten',
        value: EmployeeFields.EXCEPTIONAL_BLOCKED_SLOTS_CALENDAR,
    },
    {
        text: 'Verfügbarkeiten',
        value: EmployeeFields.AVAILABILITIES,
    },
    {
        text: 'Datenschutz',
        value: EmployeeFields.COMPLIANCE,
    },
    {
        text: 'Akquiriert von',
        value: EmployeeFields.ACQUIRED_BY,
    },
    {
        text: 'Fähigkeiten',
        value: EmployeeFields.ABILITIES,
    },
    {
        text: 'Tags',
        value: EmployeeFields.TAGS,
    },
    {
        text: 'Recruiting-Plattform',
        value: EmployeeFields.RECRUITING_PLATFORM,
    },
    {
        text: 'Bewerbungen',
        value: EmployeeFields.APPLICATIONS,
    },
    {
        text: 'Vorgesetzte:r',
        value: EmployeeFields.SUPERVISOR,
    },
    {
        text: 'Person ist Vorgesetzte:r?',
        value: EmployeeFields.IS_SUPERVISOR,
    },
    {
        text: 'Person ist Standardvorgesetzte:r für Bundesland',
        value: EmployeeFields.IS_DEFAULT_SUPERVISOR_FOR_STATES,
    },
    {
        text: 'Datum der Erzeugung',
        value: EmployeeFields.CREATED_AT,
        readOnly: true,
    },
    {
        text: 'Datum der letzten Aktualisierung',
        value: EmployeeFields.UPDATED_AT,
        readOnly: true,
    },
    {
        text: 'Datum einer geplanten Deaktivierung',
        value: EmployeeFields.DEACTIVATE_AT,
        readOnly: true,
    },
    {
        text: 'Datum einer geplanten Aktivierung',
        value: EmployeeFields.ACTIVATE_AT,
        readOnly: true,
    },
];
