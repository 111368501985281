import {Types} from 'mongoose';
import {MessengerTypesEnum} from '@/enums/messenger/MessengerTypes.enum';

export enum MessengerEvents {
    NEW_MESSAGE = 'messenger:new-message',
    UPDATE_MESSAGE = 'messenger:update-message',
}

type MessengerNewMessagePayload = {
    messageId: Types.ObjectId;
    messageType: MessengerTypesEnum;
    apiPhoneNumber?: string;
    apiMailAddress?: string;
}

type UpdateMessagePayload = {
    messageId: Types.ObjectId;
    messageType: MessengerTypesEnum;
}

export type MessengerEventPayloads = {
    [MessengerEvents.NEW_MESSAGE]: MessengerNewMessagePayload;
    [MessengerEvents.UPDATE_MESSAGE]: UpdateMessagePayload;
};
