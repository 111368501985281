import AutocompletePreset from '@/mixins/autocompletes/AutocompletePreset.vue';
import {InputStyleOptions} from '@/constants/InputStyleOptions.constant';
import mongoose from 'mongoose';
import Vue from 'vue';
import {Rule} from '@/helpers/ruleFactory.helper';

export default Vue.extend({
    components: { AutocompletePreset },
    props: {
        ...InputStyleOptions.Autocomplete,
        dense: {type: Boolean, default: true},
        rounded: {type: Boolean, default: true},
        outlined: {type: Boolean, default: true},
        prefetch: {type: Boolean, default: false},
        prefetchCount: {type: Number, default: 10},
        rules: { type: Array as () => Rule[] },
        filter: { type: Object, default: () => ({}) },
        prefetchFilter: { type: Object, required: false },
        value: {
            type: Object as () => mongoose.Types.ObjectId | mongoose.Types.ObjectId[] | undefined,
            required: true,
        },
    },
})
