import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import {Types} from 'mongoose';
import {NotifierEvents} from '@/enums/notifier/NotifierEvents';

interface NotificationBusInterface {
    $emit(event: 'pushRtc', notification: NotificationLike): any;
    $emit(event: 'update'): any;
    $emit(event: NotifierEvents, notificationIds?: Types.ObjectId[]): any;
    $on(event: 'pushRtc', callback: (notification: NotificationLike) => void): any;
    $on(event: 'update', callback: () => void): any;
    $on(event: NotifierEvents, callback: (notificationIds: Types.ObjectId[]) => void): any;
}

export const NotificationBus: NotificationBusInterface = new Vue();
