import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {StoredFileFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/StoredFileFields';

export const storedFileFieldsDictionary: FieldDictionary<StoredFileFields> = [
    {
        text: 'Name der Vorlage',
        value: StoredFileFields.NAME,
    },
    {
        text: 'Name der Datei auf dem Server',
        value: StoredFileFields.FILENAME,
    },
    {
        text: 'Dateityp',
        value: StoredFileFields.FILETYPE,
    },
    {
        text: 'Datei-Hash',
        value: StoredFileFields.FILE_HASH,
    },
    {
        text: 'Dateipfad / Inhalt der Datei',
        value: StoredFileFields.PATH,
    },
    {
        text: 'Platzhalter, Standordverknüpfung, etc.',
        value: StoredFileFields.TEMPLATE,
    },
    {
        text: 'Informationen zum Vorlagenstatus',
        value: StoredFileFields.IS_TEMPLATE,
    },
    {
        text: 'Erzeuger der Datei',
        value: StoredFileFields.CREATOR,
    },
    {
        text: 'Erzeugungsdatum',
        value: StoredFileFields.CREATED_AT,
    },
    {
        text: 'Änderungsdatum',
        value: StoredFileFields.UPDATED_AT,
    },
    {
        text: 'Sichtbarkeit',
        value: StoredFileFields.VISIBLE,
    },
    {
        text: 'Veröffentlichungsstatus',
        value: StoredFileFields.IS_PUBLIC,
    },
];
