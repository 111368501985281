import FieldDictionary from "@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary";
import {
    MessengerAccountFields,
} from "@/classes/clientOnly/permissionTreeResources/enums/entityFields/MessengerAccountFields";

export const messengerAccountFieldsDictionary: FieldDictionary<MessengerAccountFields> = [
    {
        text: 'Type',
        value: MessengerAccountFields.TYPE,
    },
    {
        text: 'API Configs',
        value: MessengerAccountFields.API_CONFIGS,
        readOnly: true,
    },
    {
        text: 'Login User',
        value: MessengerAccountFields.LOGIN_USER,
    },
    {
        text: 'Box Configuration',
        value: MessengerAccountFields.BOX_CONFIGURATION,
    },
];
