export enum AcquisitionListFields {
    NAME = 'name',
    IS_ACTIVE = 'isActive',
    FOR_EMPLOYEE = 'forEmployee',
    INSTITUTIONS = 'institutions',
    CREATOR = 'creator',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
    ASSIGNEE = 'assignee',
}
