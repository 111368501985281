export enum WarehouseOrderStates {
    UNTOUCHED,
    LABELS_PREPARED,
    LABELS_PRINTED,
    IN_SHIPPING,
    FULFILLED,
}

export const WarehouseOrderStatesVue: Array<{text: string; icon: string; color: string; value: WarehouseOrderStates}> = [
    {text: 'Unberührt', icon: 'mdi-checkbox-blank-circle-outline', color: 'warning', value: WarehouseOrderStates.UNTOUCHED},
    {text: 'Labels vorbereitet', icon: 'mdi-postage-stamp', color: 'info', value: WarehouseOrderStates.LABELS_PREPARED},
    {text: 'Labels gedruckt', icon: 'mdi-printer-pos-check', color: 'teal', value: WarehouseOrderStates.LABELS_PRINTED},
    {text: 'In Versand', icon: 'mdi-truck-delivery', color: 'yellow darken-3', value: WarehouseOrderStates.IN_SHIPPING},
    {text: 'Abgeschlossen', icon: 'mdi-check', color: 'success', value: WarehouseOrderStates.FULFILLED},
];
