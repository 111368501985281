import FieldDictionary from "@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary";
import {
    WhatsAppMessageTemplateFields,
} from "@/classes/clientOnly/permissionTreeResources/enums/entityFields/WhatsAppMessageTemplateFields";

export const whatsAppMessageTemplateFieldsDictionary: FieldDictionary<WhatsAppMessageTemplateFields> = [
    {
        text: 'Zuletzt aktualisiert durch',
        value: WhatsAppMessageTemplateFields.LAST_EDITED_BY,
    },
    {
        text: 'API-Konfiguration',
        value: WhatsAppMessageTemplateFields.CONFIGURATION_ID,
        readOnly: true,
    },
    {
        text: 'Kategoriewechsel durch WhatsApp erlauben',
        value: WhatsAppMessageTemplateFields.ALLOW_CATEGORY_CHANGE,
        readOnly: true,
    },
    {
        text: 'Art der Vorlage',
        value: WhatsAppMessageTemplateFields.TEMPLATE_TYPE,
    },
    {
        text: 'WhatsApp-Vorlagen-ID',
        value: WhatsAppMessageTemplateFields.WHATS_APP_TEMPLATE_ID,
        readOnly: true,
    },
    {
        text: 'Kategorie (WhatsApp)',
        value: WhatsAppMessageTemplateFields.CATEGORY,
    },
    {
        text: 'Komponenten (WhatsApp)',
        value: WhatsAppMessageTemplateFields.COMPONENTS,
    },
    {
        text: 'Sprache',
        value: WhatsAppMessageTemplateFields.LANGUAGE,
    },
    {
        text: 'Name',
        value: WhatsAppMessageTemplateFields.NAME,
    },
    {
        text: 'Ablehnungsgrund',
        value: WhatsAppMessageTemplateFields.REASON,
        readOnly: true,

    },
    {
        text: 'Status',
        value: WhatsAppMessageTemplateFields.STATUS,
    },

];
