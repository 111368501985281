<template>
  <div v-if="link">
    <v-tooltip :disabled="!showTooltip" right>
      <template v-slot:activator="{on}">
        <v-list-item :class="{ 'pl-6': inset && !showTooltip }" :dense="dense" :to="link" v-on="on">
          <v-list-item-icon>
            <v-tooltip v-if="badgeValue && showTooltip" :disabled="!badgeDescription">
              <template v-slot:activator="badgeDescriptionActivator">
                <v-badge :color="item.badge?.color" :content="badgeValue" dot v-on="badgeDescriptionActivator.on">
                  <v-icon v-text="item.icon"/>
                </v-badge>
              </template>
            </v-tooltip>
            <v-icon v-else v-text="item.icon"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"/>
          </v-list-item-content>
          <v-tooltip v-if="badgeValue" :disabled="!badgeDescription" right>
            <template v-slot:activator="badgeDescriptionActivator">
              <v-chip :color="item.badge?.color" class="white--text justify-center" small style="min-width: 40px"
                      v-on="badgeDescriptionActivator.on">
                {{ badgeValue }}
              </v-chip>
            </template>
            {{ badgeDescription }}
          </v-tooltip>
        </v-list-item>
      </template>
      <span v-if="badgeDescription">
        {{ item.text }} - {{ badgeDescription }}
      </span>
      <span v-else>
        {{ item.text }}
      </span>
    </v-tooltip>
    <v-divider v-if="!noDivider"/>
  </div>
  <div v-else/>
</template>

<script lang="ts">
import Vue from 'vue';
import {NavigationDrawerAccess} from '@/enums/_common/NavigationDrawerAccess.enum';

export default Vue.extend({
  props: {
    item: {
      type: Object as () => NavigationDrawerAccess.DrawerDataItem,
      required: true,
    },
    // title: String,
    // icon: String,
    // to: String,
    // infoText: [String, Number],
    // infoColor: String,
    // infoTooltip: [String, Number],
    showTooltip: Boolean,
    noDivider: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    inset: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    link(): string | null {
      if (typeof this.item.to === 'function') {
        return this.item.to();
      } else {
        return this.item.to;
      }
    },
    badgeValue(): string | number | null | undefined {
      if (this.item.badge) {
        if (typeof this.item.badge.value === 'function') {
          return this.item.badge.value();
        } else {
          return this.item.badge.value;
        }
      }
      return null;
    },
    badgeDescription(): string | number | null | undefined {
      if (this.item.badge) {
        if (typeof this.item.badge.description === 'function') {
          return this.item.badge.description();
        } else {
          return this.item.badge.description;
        }
      }
      return null;
    },
  },
});
</script>
