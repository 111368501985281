import { render, staticRenderFns } from "./profile-avatar.vue?vue&type=template&id=17bee8da&scoped=true"
import script from "./profile-avatar.vue?vue&type=script&lang=ts"
export * from "./profile-avatar.vue?vue&type=script&lang=ts"
import style0 from "./profile-avatar.vue?vue&type=style&index=0&id=17bee8da&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17bee8da",
  null
  
)

export default component.exports