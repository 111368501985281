import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {DocumentTypeFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/DocumentTypeFields';

export const documentTypeFieldsDictionary: FieldDictionary<DocumentTypeFields> = [
    {
        text: 'Name',
        value: DocumentTypeFields.NAME,
    },
    {
        text: 'Beschreibung',
        value: DocumentTypeFields.DESCRIPTION,
    },
    {
        text: 'Art der Bereitstellenden',
        value: DocumentTypeFields.PROVIDERS,
    },
    {
        text: 'Ist erforderlich?',
        value: DocumentTypeFields.IS_REQUIRED,
    },
];
