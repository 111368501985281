import { Types } from 'mongoose';
import {isMongoId} from "class-validator";

/**
 * Returns if given data is a valid ObjectId of mongoose.
 * Allows strings and numbers as valid input.
 * @param data The data to validate
 * @returns Is data valid ObjectId?
 * @author Joshua Seipel
 */
export function isObjectId(data: any): data is Types.ObjectId {
  try {
    return isMongoId(data);
  } catch (error) {
    return false;
  }
}

type Populated<T> = T extends Types.ObjectId | null | undefined ? never : T;

export function isPopulated<T>(data: T): data is Populated<T> {
  return !!data && !isObjectId(data);
}

export function retrieveObjectId(data: any): Types.ObjectId {
  if (isPopulated(data)) {
    return data._id;
  }
  return data;
}
