import { Types } from 'mongoose';
import { API } from './api.class';
import { Stack } from '@/interfaces/stack.interface';
import {CreateStackRequestDto} from '@/classes/dto/stack-manager/request/CreateStack.request.dto';
import {UpdateStackRequestDto} from '@/classes/dto/stack-manager/request/UpdateStack.request.dto';
import {FindByIdOptions} from '@/interfaces/findByIdOptions';
import {FindStackRequestDto} from '@/classes/dto/stack-manager/request/FindStack.request.dto';
import {UpdateStackItemRequestDto} from '@/classes/dto/stack-manager/request/UpdateStackItem.request.dto';
import {PushStackItemRequestDto} from '@/classes/dto/stack-manager/request/PushStackItem.request.dto';

export class StackManagerApi extends API {
  static service = 'stack-manager';

  static async create(doc: CreateStackRequestDto): Promise<Stack> {
    return this.post(``, doc);
  }
  static async find(data: FindStackRequestDto): Promise<{ stacks: Stack[]; maxPage: number }> {
    return this.get(``, data);
  }
  static async findById(id: Types.ObjectId, options?: FindByIdOptions): Promise<Stack> {
    return this.get(`${id}`, options);
  }
  static async update(id: Types.ObjectId, update: UpdateStackRequestDto): Promise<void> {
    return this.put(`${id}`, update);
  }
  static async pushItem(id: Types.ObjectId, item: PushStackItemRequestDto): Promise<void> {
    return this.put(`push/${id}`, item);
  }
  static async pullItem(id: Types.ObjectId, itemId: Types.ObjectId): Promise<void> {
    return this.put(`pull/${id}/${itemId}`, {});
  }
  static async updateItem(id: Types.ObjectId, itemId: Types.ObjectId, update: UpdateStackItemRequestDto): Promise<void> {
    return this.put(`${id}/${itemId}`, update);
  }
  static async remove(id: Types.ObjectId): Promise<Stack> {
    return this.delete(`${id}`);
  }
}
