<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="loading" :value="value" max-width="800"
              @input="reset">
        <v-card>
            <v-card-title>
                <div class="warning--text">
                    Verfügbarkeiten bearbeiten
                </div>
                <v-spacer/>
                <v-btn :disabled="loading" icon @click="reset">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text v-if="initializing">
                <v-skeleton-loader type="image"/>
            </v-card-text>
            <v-card-text v-else-if="employee">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-slide-y-transition group hide-on-leave>
                        <v-card v-if="availabilities" key="availabilities" class="mb-8"
                                color="grey lighten-3 rounded-lg" flat>
                            <v-list color="transparent">
                                <v-scroll-y-reverse-transition group hide-on-leave>
                                    <v-list-item v-if="availabilities.length === 0" key="no-data">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Nicht verfügbar
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                Die Lehrkraft wird als nicht verfügbar angezeigt
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-for="(item, i) in availabilities" :key="i">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ getWeekdaysStringFromWeekday(item.dayOfWeek) }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                Von {{ getTimeStringFromMinutes(item.timeRange.startTime) }}
                                                bis {{ getTimeStringFromMinutes(item.timeRange.endTime) }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content v-if="item.labels && item.labels.length > 0">
                                            <v-list-item-title>
                                                <v-chip v-for="label in item.labels" :key="label" :color="getVueLabel(label).color"
                                                        class="ma-1" small>
                                                    {{ getVueLabel(label).text }}
                                                </v-chip>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{on}">
                                                    <v-btn icon @click="availabilities.splice(i, 1)" v-on="on">
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                </template>
                                                Eintrag entfernen
                                            </v-tooltip>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-scroll-y-reverse-transition>
                            </v-list>
                        </v-card>
                        <div v-else key="fallback" class="grey--text text-center my-8 font-weight-bold">
                            Aktuell sind keine Informationen über die Verfügbarkeiten dieser Lehrkraft hinterlegt
                        </div>
                    </v-slide-y-transition>
                    <v-subheader>
                        Neuer Eintrag
                    </v-subheader>
                    <v-row dense>
                        <v-col cols="12" sm="6">
                            <v-select v-model="availabilityDay" :items="WeekdaysVue" :rules="[RuleFactory.required()]"
                                      color="warning"
                                      dense item-color="warning" label="Wochentag" outlined rounded/>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-select v-model="availabilityLabels" :items="AvailabilityLabelsVue"
                                      :rules="[RuleFactory.minLength(1, 'Mindestens ein Element erwartet')]"
                                      color="warning"
                                      counter="32" dense item-color="warning" label="Markierung" multiple
                                      outlined rounded>
                                <template v-slot:selection="{item}">
                                    <v-chip :color="item.color" class="mt-1" small>
                                        {{ item.text }}
                                    </v-chip>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <time-picker v-model="availabilityFrom" color="warning" dense label="Von" required/>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <time-picker v-model="availabilityTo" color="warning" dense label="Bis" required/>
                        </v-col>
                        <v-col class="text-center">
                            <v-btn :disabled="!valid" color="warning" rounded @click="addAvailability">
                                <v-icon dense>
                                    mdi-plus
                                </v-icon>
                                Eintrag hinzufügen
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn :disabled="loading" text @click="reset">
                    Abbrechen
                </v-btn>
                <v-spacer/>
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-btn :disabled="loadingAvailabilityUpdate" :loading="loadingAvailabilityUnset" color="warning"
                               text
                               @click="saveUnset" v-on="on">
                            Verfügbarkeiten verwerfen
                        </v-btn>
                    </template>
                    <div>
                        Alle Informationen über die Verfügbarkeiten dieser Lehrkraft werden verworfen.
                    </div>
                </v-tooltip>
                <v-spacer/>
                <v-btn :disabled="loadingAvailabilityUnset" :loading="loadingAvailabilityUpdate" color="warning"
                       @click="saveUpdate">
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {Form} from '@/interfaces/Form.interface';
import {EmployeeHandlerAPI} from '@/classes/api/employee-handler.api.class';
import {EmployeeFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/EmployeeFields';
import {UpdateType} from '@/helpers/compareForUpdate';
import {LabeledAvailabilityInterface} from '@/interfaces/availability.interface';
import {Employee} from '@/interfaces/employee.interface';
import {Weekdays, WeekdaysVue} from '@/helpers/dateHelpers/Weekdays.enum';
import TimePicker from '@/components/common/timePicker.vue';
import moment from 'moment';
import {TimeRange} from '@/helpers/dateHelpers/TimeRange';
import {AvailabilityLabels, AvailabilityLabelsVue} from '@/enums/AvailabilityLabels';
import mongoose from 'mongoose';

export default Vue.extend({
    components: {TimePicker},
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        employeeId: {
            type: mongoose.Types.ObjectId,
            required: true,
        },
    },
    data: () => ({
        initializing: false,
        loadingAvailabilityUpdate: false,
        loadingAvailabilityUnset: false,
        valid: false,

        availabilities: undefined as UpdateType<LabeledAvailabilityInterface[]>,
        employee: undefined as unknown as Employee,

        availabilityDay: undefined as unknown as Weekdays,
        availabilityFrom: undefined as unknown as string,
        availabilityTo: undefined as unknown as string,
        availabilityLabels: [] as AvailabilityLabels[],
    }),
    computed: {
        AvailabilityLabelsVue: () => AvailabilityLabelsVue,
        WeekdaysVue: () => WeekdaysVue,
        RuleFactory: () => RuleFactory,
        loading(): boolean {
            return this.loadingAvailabilityUpdate || this.loadingAvailabilityUnset;
        },
    },
    methods: {
        async initialize() {
            this.initializing = true;
            this.employee = await EmployeeHandlerAPI
                .findById(this.employeeId, {fields: [EmployeeFields.AVAILABILITIES]});
            this.availabilities = this.employee.availabilities || [];
            this.initializing = false;
        },
        async saveUpdate() {
            this.loadingAvailabilityUpdate = true;
            try {
                await EmployeeHandlerAPI.update(this.employeeId, {
                    availabilities: this.availabilities,
                });
                this.$$showSnackbar('Die Verfügbarkeiten wurden erfolgreich gespeichert', 'success');
                this.$emit('update:availabilities', this.availabilities);
                this.reset();
            } catch (e) {
                this.$$showSnackbar('Fehler beim Speichern der Verfügbarkeiten', 'error');
            } finally {
                this.loadingAvailabilityUpdate = false;
            }
        },
        async saveUnset() {
            this.loadingAvailabilityUnset = true;
            const isInterrupted = await this.$$waitForInterrupt('Löschen der Verfügbarkeiten wird vorbereitet...', 2000);
            if (isInterrupted) {
                this.loadingAvailabilityUnset = false;
                return;
            }
            try {
                await EmployeeHandlerAPI.update(this.employeeId, {availabilities: null});
                this.$$showSnackbar('Verfügbarkeiten wurden verworfen', 'success');
                this.$emit('update:availabilities', undefined);
                this.reset();
            } catch (e) {
                this.$$showSnackbar('Fehler beim Löschen der Verfügbarkeiten', 'error');
            } finally {
                this.loadingAvailabilityUnset = false;
            }
        },
        addAvailability() {
            const form = this.$refs.form as Form;
            if (form.validate()) {
                if (!this.availabilities) {
                    this.availabilities = [];
                }
                const timeRange = new TimeRange(
                    moment(this.availabilityFrom, 'HH:mm').diff(moment().startOf('day'), 'minutes'),
                    moment(this.availabilityTo, 'HH:mm').diff(moment().startOf('day'), 'minutes'),
                );
                this.availabilities.push({
                    dayOfWeek: this.availabilityDay,
                    timeRange,
                    labels: this.availabilityLabels,
                });
                this.availabilities.sort((a, b) => {
                    const delta = a.dayOfWeek - b.dayOfWeek;
                    if (delta) {
                        return delta;
                    }
                    return a.timeRange.startTime - b.timeRange.startTime;
                });
            }
        },
        getWeekdaysStringFromWeekday(weekday: Weekdays) {
            const found = this.WeekdaysVue.find(item => item.value === weekday);
            return found ? found.text : '';
        },
        getTimeStringFromMinutes(minutes: number) {
            return moment().startOf('day').add(minutes, 'minutes').format('HH:mm');
        },
        getVueLabel(label: string) {
            return this.AvailabilityLabelsVue.find(item => item.value === label) || {
                text: 'Unbekanntes label',
                color: 'grey',
            };
        },
        reset() {
            this.availabilityDay = undefined as unknown as Weekdays;
            this.availabilityFrom = undefined as unknown as string;
            this.availabilityTo = undefined as unknown as string;
            this.availabilityLabels = [];
            const form = this.$refs.form as Form;
            if (form) {
                form.resetValidation();
            }
            this.$emit('input', false);
            setTimeout(() => {
                this.employee = undefined as unknown as Employee;
            }, 300);
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(v: boolean) {
                if (v) {
                    this.initialize();
                }
            },
        },
    },
});
</script>
