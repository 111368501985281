<template>
  <v-card class="mb-4" elevation="1">
    <v-card-title class="pb-0">
      <div class="text-uppercase caption">
        Informationen zum Kontakt
      </div>
      <v-spacer/>
      <v-tooltip left transition="slide-x-reverse-transition">
        <template v-slot:activator="{on}">
          <v-btn small icon v-on="on" color="info" :href="metaLink" target="_blank">
            <v-icon small>mdi-page-next-outline</v-icon>
          </v-btn>
        </template>
        <div class="caption">Kontakt in neuem Tab öffnen</div>
      </v-tooltip>
    </v-card-title>
    <v-row dense>
      <v-col cols="12" md="6" v-if="meta.customId">
        <v-list-item dense>
          <v-list-item-icon>
            <v-icon small>mdi-card-account-details</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{meta.customId}}</v-list-item-title>
            <v-list-item-subtitle>Referenznummer</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <stack-item-meta-info-contact-information v-if="meta.contactInformation" :contact-information="meta.contactInformation"/>
    <v-row dense>
      <v-col cols="12" md="6" v-if="institution && institution.name">
        <v-list-item dense>
          <v-list-item-icon>
            <v-icon small>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{institution.name}}
            </v-list-item-title>
            <v-list-item-subtitle v-if="institution.address">
              {{meta.address.street}} {{meta.address.houseNumber}} |
              {{meta.address.postal}} {{meta.address.city}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="6" v-if="meta.address">
        <v-list-item dense>
          <v-list-item-icon>
            <v-icon small>mdi-map-marker</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{meta.address.street}} {{meta.address.houseNumber}}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{meta.address.postal}} {{meta.address.city}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue';
import {User} from '@/interfaces/user.interface';
import {Institution} from '@/interfaces/institution.interface';
import StackItemMetaInfoContactInformation
  from '@/components/stack/stackDialog/stackItemContent/stackItemMetaInfoComponent/stackItemMetaInfoContactInformation.vue';
import {isPopulated} from '@/helpers/isObjectId.helper';
import {Student} from '@/interfaces/student.interface';
import {StackItemMetaTypes} from '@/enums/StackItemMetaTypes';

export default Vue.extend({
  components: {StackItemMetaInfoContactInformation},
  props: {
    meta: {
      type: Object as () => User | Institution,
      required: true,
    },
    type: {
      type: String as () => StackItemMetaTypes,
      required: true,
    },
  },
  computed: {
    institution(): Institution | null {
      const student = this.meta as Student;
      return isPopulated(student.institution) ? student.institution : null;
    },
    metaLink(): string {
      const metaIdString = this.meta._id.toString();
      switch (this.type) {
        case StackItemMetaTypes.PARENT:
          return this.$router.resolve(`/parents/${metaIdString}`).href;
        case StackItemMetaTypes.INSTITUTION:
          return this.$router.resolve(`/institutions/${metaIdString}`).href;
        case StackItemMetaTypes.EMPLOYEE:
          return this.$router.resolve(`/employees/${metaIdString}`).href;
        case StackItemMetaTypes.STUDENT:
          return this.$router.resolve(`/students/${metaIdString}`).href;
        default:
          return '';
      }
    },
  },
});
</script>
