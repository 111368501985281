<template>
  <v-menu v-if="canPerformActions" offset-y>
    <template v-slot:activator="{on}">
      <v-btn :loading="loading" icon small v-on="on">
        <v-icon small>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0" dense>
      <v-list-item v-can:read-stacks="{ id: stackId, keys: ['items'] }"
                   @click="$emit('update:applyDoneFilter', !applyDoneFilter)">
        <v-list-item-icon>
          <v-icon>
            {{ applyDoneFilter ? 'mdi-eye' : 'mdi-eye-off' }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-if="applyDoneFilter">
            Erledigte Elemente anzeigen
          </v-list-item-title>
          <v-list-item-title v-else>
            Erledigte Elemente ausblenden
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-can:read-stacks="{ id: stackId, keys: ['items'] }"
                   @click="$emit('update:applyMutedFilter', !applyMutedFilter)">
        <v-list-item-icon>
          <v-icon>
            {{ applyMutedFilter ? 'mdi-eye' : 'mdi-eye-off' }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-if="applyMutedFilter">
            Elemente zur Ablage anzeigen
          </v-list-item-title>
          <v-list-item-title v-else>
            Elemente zur Ablage ausblenden
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-can:readAndUpdate-stacks="{ id: stackId, keys: ['name', 'color'] }" @click="editStack">
        <v-list-item-icon>
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Stapel bearbeiten
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="shareStack">
        <v-list-item-icon>
          <v-icon color="info">
            mdi-shield-lock-open
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Stapel freigeben
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-can:update-stacks="{ id: stackId, keys: ['closedAt'] }" @click="toggleStackState">
        <v-list-item-icon>
          <v-icon>
            {{ stack && stack.closedAt ? 'mdi-archive-off' : 'mdi-archive' }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-if="stack && stack.closedAt">
            Archivierung des Stapels aufheben
          </v-list-item-title>
          <v-list-item-title v-else>
            Stapel archivieren
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-can:delete-stacks @click="handleShowDeleteStackDialog">
        <v-list-item-icon>
          <v-icon color="error">
            mdi-delete
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Stapel löschen
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <edit-stack-dialog v-model="showEditStackDialog" :stack-id="stackId"/>
    <delete-stack-dialog v-model="showDeleteStackDialog" v-can:delete-stacks :stack-id="stackId"/>
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';
import {Types} from 'mongoose';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import DeleteStackDialog from '@/components/stack/deleteStackDialog.vue';
import {Stack} from '@/interfaces/stack.interface';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {StackBus} from '@/busses/StackBus';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import {StackFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/StackFields';
import {CustomAccessKeys} from '@/classes/clientOnly/permissionTreeResources/enums/CustomAccessKeys';
import EditStackDialog from "@/components/stack/editStackDialog.vue";

export default Vue.extend({
  components: {EditStackDialog, DeleteStackDialog},
  props: {
    stackId: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
    stack: {
      type: Object as () => Stack,
      required: true,
    },
    applyDoneFilter: {
      type: Boolean,
      required: true,
    },
    applyMutedFilter: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    showDeleteStackDialog: false,
    showEditStackDialog: false,
    loading: false,
  }),
  computed: {
    canPerformActions(): boolean {
      const stackEntity = this.$$getCrudEntity(CrudEntityTypes.STACK, this.stackId);
      const canDelete = this.$$crudAccessManager.getAccess(CrudEntityTypes.STACK, CrudActionTypes.DELETE);
      return stackEntity.canRead(StackFields.ITEMS) || stackEntity.canUpdate(StackFields.CLOSED_AT)
          || canDelete || !!this.$$crudAccessManager.getCustom(CustomAccessKeys.SHARE_ACCESS);
    },
  },
  methods: {
    editStack() {
      setTimeout(() => {
        this.showEditStackDialog = true;
      }, 50);
    },
    shareStack() {
      this.$$shareAccessDialog.show(CrudEntityTypes.STACK, this.stackId, 'Stapel freigeben');
    },
    /** Delays execution of show stack dialog to prevent collision with menu behaviour */
    handleShowDeleteStackDialog() {
      setTimeout(() => {
        this.showDeleteStackDialog = true;
      }, 50);
    },
    async toggleStackState() {
      if (!this.stack) {
        this.$$showSnackbar('Keine Referenz zum Stapel gefunden', 'error');
      }
      this.loading = true;
      try {
        await StackManagerApi.update(this.stackId, {closedAt: this.stack.closedAt ? null : new Date()});
        this.$$showSnackbar('Der Stapel wurde erfolgreich aktualisiert', 'success');
        StackBus.$emit('reload:selectedStack');
        StackBus.$emit('reload:stacks');
      } catch (e) {
        this.$$showSnackbar('Beim Aktualisieren des Stapels ist ein Fehler aufgetreten', 'error');
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
