export enum InstitutionStatus {
    UNTOUCHED, PENDING, IN_PROGRESS, INACTIVE, ACTIVE, DECLINED, NO_TEACHER, POTENTIAL,
}

export const InstitutionStatusVue = [
    {
        text: 'Unberührt',
        value: InstitutionStatus.UNTOUCHED,
        icon: 'mdi-vibrate-off',
        color: 'grey',
    },
    {
        text: 'Ausstehend',
        value: InstitutionStatus.PENDING,
        icon: 'mdi-account-clock',
        color: 'warning',
    },
    {
        text: 'In Arbeit',
        value: InstitutionStatus.IN_PROGRESS,
        icon: 'mdi-briefcase',
        color: 'info',
    },
    {
        text: 'Inaktiv',
        value: InstitutionStatus.INACTIVE,
        icon: 'mdi-account-off',
        color: 'grey',
    },
    {
        text: 'Aktiv',
        value: InstitutionStatus.ACTIVE,
        icon: 'mdi-check',
        color: 'success',
    },
    {
        text: 'Abgesagt',
        value: InstitutionStatus.DECLINED,
        icon: 'mdi-cancel',
        color: 'error',
    },
    {
        text: 'Keine Lehrkraft',
        value: InstitutionStatus.NO_TEACHER,
        icon: 'mdi-human-male-board',
        color: 'error',
    },
    {
        text: 'Potentieller Standort',
        value: InstitutionStatus.POTENTIAL,
        icon: 'mdi-room-service',
        color: 'indigo',
    },
];
