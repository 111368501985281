import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {CustomIdSchemeFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/CustomIdSchemeFields';

export const customIdSchemeFieldsDictionary: FieldDictionary<CustomIdSchemeFields> = [
    {
        text: 'Referenzmaske',
        value: CustomIdSchemeFields.PATTERN,
    },
    {
        text: 'Beschreibung',
        value: CustomIdSchemeFields.DESCRIPTION,
    },
    {
        text: 'Referenzierungsart',
        value: CustomIdSchemeFields.TARGET,
    },
    {
        text: 'Zählerwert',
        value: CustomIdSchemeFields.COUNTER,
    },
    {
        text: 'Ist Standardreferenz?',
        value: CustomIdSchemeFields.IS_DEFAULT,
    },
    {
        text: 'Erzeugungsdatum',
        value: CustomIdSchemeFields.CREATED_AT,
    },
    {
        text: 'Änderungsdatum',
        value: CustomIdSchemeFields.UPDATED_AT,
    },
];
