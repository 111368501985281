import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {InstitutionFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/InstitutionFields';

export const institutionFieldsDictionary: FieldDictionary<InstitutionFields> = [{
    text: 'Name',
    value: InstitutionFields.NAME,
}, {
    text: 'Standortnummer',
    value: InstitutionFields.CUSTOM_ID,
    readOnly: true,
}, {
    text: 'Anschrift',
    value: InstitutionFields.ADDRESS,
}, {
    text: 'Inzidenzwerte',
    value: InstitutionFields.COVID_RECORDS,
    readOnly: true,
}, {
    text: 'Verantwortliche',
    value: InstitutionFields.RESPONSIBLES,
}, {
    text: 'Art des Standorts',
    value: InstitutionFields.TYPE,
}, {
    text: 'Kontaktdaten',
    value: InstitutionFields.CONTACT_INFORMATION,
}, {
    text: 'Aktivitätsstatus',
    value: InstitutionFields.IS_ACTIVE,
}, {
    text: 'Datum der Deaktivierung',
    value: InstitutionFields.DEACTIVATED_AT,
}, {
    text: 'Status der Akquise',
    value: InstitutionFields.ACQUISITION_STATUS,
    readOnly: true,
}, {
    text: 'Historie der Akquise-Status',
    value: InstitutionFields.ACQUISITION_STATUS_ITEMS,
}, {
    text: 'Datum der letzten Aktualisierung des Akquise-Status',
    value: InstitutionFields.LAST_UPDATED_ACQUISITION_STATUS,
    readOnly: true,
}, {
    text: 'Letzte:r Aktualisierende:r des Akquise-Status',
    value: InstitutionFields.LAST_UPDATED_ACQUISITION_STATUS_BY,
    readOnly: true,
}, {
    text: 'Referenz zur Buchhaltungsschnittstelle',
    value: InstitutionFields.ACCOUNTING_REF,
    readOnly: true,
}, {
    text: 'Gesamte Schülerzahl am Standort',
    value: InstitutionFields.TOTAL_AMOUNT_PUPILS,
}, {
    text: 'Miete - Information ob Standort angemietet ist',
    value: InstitutionFields.IS_RENTED,
}, {
    text: 'Miete - Höhe der Raummiete',
    value: InstitutionFields.RENT_AMOUNT,
}, {
    text: 'Miete - Zahlungsintervall für Raummiete',
    value: InstitutionFields.RENT_INTERVAL,
}, {
    text: 'Miete - Bemerkung zur Miete',
    value: InstitutionFields.RENT_NOTE,
}, {
    text: 'Lehrkräfte - Zugeteilte Lehrkräfte',
    value: InstitutionFields.ASSIGNED_EMPLOYEES,
}, {
    text: 'Lehrkräfte - Vorgemerkte Lehrkräfte',
    value: InstitutionFields.PLANNED_EMPLOYEES,
}, {
    text: 'Unterrichtsverfügbarkeiten',
    value: InstitutionFields.BLOCKED_SLOTS_CALENDAR,
}, {
    text: 'Austeilverfügbarkeiten',
    value: InstitutionFields.EXCEPTIONAL_BLOCKED_SLOTS_CALENDAR,
}, {
    text: 'Verfügbarkeiten',
    value: InstitutionFields.AVAILABILITIES,
}, {
    text: 'Akquiriert von',
    value: InstitutionFields.ACQUIRED_BY,
}, {
    text: 'Tags',
    value: InstitutionFields.TAGS,
}, {
    text: 'Verantwortliche:r',
    value: InstitutionFields.SUPERVISOR,
}, {
    text: 'Datum einer geplanten Deaktivierung',
    value: InstitutionFields.DEACTIVATE_AT,
    readOnly: true,
}, {
    text: 'Datum einer geplanten Aktivierung',
    value: InstitutionFields.ACTIVATE_AT,
    readOnly: true,
}, {
    text: 'Kursinformationen des Standorts',
    value: InstitutionFields.COURSE_INFO_TEXT,
}, {
    text: 'Erstellungsdatum',
    value: InstitutionFields.CREATED_AT,
    readOnly: true,
}, {
    text: 'Aktualisierungsdatum',
    value: InstitutionFields.UPDATED_AT,
    readOnly: true,
}];
