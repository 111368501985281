<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row v-if="canSendMessage" align="center">
        <v-col cols="11">
          <v-card v-if="repliesTo && repliesTo.message" class="mb-4">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Antwort auf:</v-list-item-title>
                <v-list-item-subtitle v-text="repliesTo.message">
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon small @click="resetRepliesTo">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card>

          <div class="caption pb-2 pl-1 grey--text">
            Freitextnachrichten via WhatsApp sind noch für {{ remainingString }} möglich.
          </div>
          <div v-if="lastUsedNumberOption" class="caption">
            Nachricht wird von {{ outPhoneNumber }} gesendet.
          </div>
          <v-select v-else v-model="outPhoneNumber" :items="numberOptions" :rules="[RuleFactory.required()]"
                    color="whatsApp" dense filled label="Telefonnummer"/>
          <html-content-input v-model="message" :loading="loading" :rules="[RuleFactory.required()]" color="whatsApp"
                              context="whatsApp" dense filled/>
        </v-col>
        <v-col cols="1">
          <v-btn :disabled="!valid" :loading="loading" color="whatsApp" icon @click="sendMessage">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="caption" cols="12" style="min-height: 128px">
          <v-card>
            <v-card-text>
              <p v-if="lastResponseAt">
                Der Kontakt hat zuletzt {{ moment(lastResponseAt).fromNow() }} auf eine Nachricht reagiert.
              </p>
              <p v-else>
                Der Kontakt hat bislang auf keine Nachrichten reagiert.
              </p>
              <p>
                Freitextnachrichten können nur innerhalb von 24 Stunden nach der letzten Antwort gesendet werden.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {MessengerMessageDocument} from '@/documentTypes/messenger/MessengerMessage.document';
import {MessengerTypesEnum} from '@/enums/messenger/MessengerTypes.enum';
import {MessengerBus} from '@/busses/MessengerBus';
import {MessengerMailDocument, MessengerWhatsAppMessageDocument} from '@/documentTypes/messenger/messages';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {Form} from '@/interfaces/Form.interface';
import {MessengerApi} from '@/classes/api/messenger.api';
import {GetConversationsResponseDto} from '@/classes/dto/messenger/response/messages/GetConversations.response.dto';
import HtmlContentInput from '@/components/common/inputs/htmlContentInput.vue';
import {LeanDocument} from 'mongoose';
import {isPopulated} from '@/helpers/isObjectId.helper';
import moment from 'moment';
import {MessengerAccountDocument} from '@/documentTypes/messenger/MessengerAccount.document';
import {MessengerWhatsAppAccountDocument} from '@/documentTypes/messenger/MessengerWhatsAppAccount.document';
import {WhatsAppApiConfigurationDocument} from '@/documentTypes/api-handler/WhatsAppApiConfiguration';

moment.locale('de');

type LocalStorageDraft = {
  message: string;
  repliesTo?: MessengerMailDocument;
}

export default Vue.extend({
  components: {HtmlContentInput},
  props: {
    conversation: {
      type: Object as () => GetConversationsResponseDto['conversations'][0],
      required: true,
    },
    accounts: {
      type: Array as () => Array<LeanDocument<MessengerAccountDocument>>,
      required: true,
    },
  },
  data: () => ({
    repliesTo: undefined as undefined | MessengerMailDocument,
    valid: false,
    message: '',
    loading: false,
    mailRowCount: 1,
    whatsAppRowCount: 1,
    customOutPhoneNumber: '',
  }),
  computed: {
    moment: () => moment,
    RuleFactory: () => RuleFactory,
    MessengerTypesEnum: () => MessengerTypesEnum,
    outPhoneNumber: {
      get(): string {
        return this.lastUsedNumberOption || this.customOutPhoneNumber;
      },
      set(value: string) {
        this.customOutPhoneNumber = value;
      },
    },
    remainingString(): string | undefined {
      if (!this.lastResponseAt) {
        return undefined;
      }
      const remainingHours = moment(this.lastResponseAt).add(24, 'hours').diff(moment(), 'hours');
      if (remainingHours <= 1) {
        const remainingMinutes = moment(this.lastResponseAt).add(24, 'hours').diff(moment(), 'minutes');
        return remainingMinutes === 1 ? '1 Minute' : `${remainingMinutes} Minuten`;
      }
      return `${remainingHours} Stunden`;
    },
    canSendMessage(): boolean {
      return !!this.lastResponseAt && moment().diff(this.lastResponseAt, 'hours', true) <= 24;
    },
    lastResponseAt(): Date | undefined {
      const messages = this.conversation.messages as LeanDocument<MessengerWhatsAppMessageDocument>[];
      const populatedReceivedMessages = messages
          .filter((message) => isPopulated(message) && message.type === MessengerTypesEnum.WHATS_APP && message.phoneNumber === message.toNumber);
      const deliveryDates = populatedReceivedMessages
          .filter((message) => (!message.whatsAppErrors || message.whatsAppErrors.length === 0))
          .map((message) => new Date(message.deliveredAt || message.sentAt));
      if (deliveryDates.length === 0) {
        return undefined;
      }
      return new Date(Math.max(...deliveryDates.map((date) => date.getTime())));
    },
    numberOptions(): string[] {
      const whatsAppAccounts = this.accounts.filter(account => account.type === MessengerTypesEnum.WHATS_APP) as LeanDocument<MessengerWhatsAppAccountDocument>[];
      return whatsAppAccounts
          .flatMap(account => account.apiConfigs as LeanDocument<WhatsAppApiConfigurationDocument>[])
          .filter(isPopulated)
          .map(apiConfig => apiConfig.phoneNumber);
    },
    lastUsedNumberOption(): string | undefined {
      if (!this.conversation.phoneNumber) return undefined;
      const messages = (this.conversation.messages as LeanDocument<MessengerWhatsAppMessageDocument>[]).filter((m) => isPopulated(m) && m.type === MessengerTypesEnum.WHATS_APP);
      messages.reverse();
      for (const message of messages) {
        if (message.fromNumber === this.conversation.phoneNumber && this.numberOptions.includes(message.toNumber)) {
          return message.toNumber;
        } else if (message.toNumber === this.conversation.phoneNumber && this.numberOptions.includes(message.fromNumber)) {
          return message.fromNumber;
        }
      }
      return undefined;
    },
  },
  methods:
      {
        handleResize() {
          const windowHeight = window.innerHeight;
          if (windowHeight > 1200) {
            this.mailRowCount = 7;
          } else if (windowHeight > 1000) {
            this.mailRowCount = 6;
          } else if (windowHeight > 800) {
            this.mailRowCount = 5;
          } else {
            this.mailRowCount = 4;
          }
        }
        ,
        async sendMessage() {
          const form = this.$refs.form as Form;
          if (form && form.validate()) {
            this.loading = true;
            try {
              await MessengerApi.messages.whatsApp.sendMessage({
                message: this.message,
                to: this.conversation.profileId,
                toType: this.conversation.profileType || undefined,
                fromNumber: this.outPhoneNumber,
                toNumber: this.conversation.phoneNumber as string,
                repliesTo: this.repliesTo ? (this.repliesTo as MessengerMailDocument)._id : undefined,
              });
              this.$$showSnackbar('Nachricht wurde gesendet', 'success');
              this.$emit('sent');
              form.reset();
              this.$nextTick(() => {
                this.deleteDraft();
              });
            } catch (e) {
              this.$$showSnackbar('Nachricht konnte nicht gesendet werden', 'error', e);
            } finally {
              this.loading = false;
            }
          }
        }
        ,
        resetRepliesTo() {
          MessengerBus.$emit('update:replyToMessage', undefined);
        }
        ,
        storeDraft() {
          const draft: LocalStorageDraft = {
            message: this.message,
            repliesTo: this.repliesTo,
          };
          localStorage.setItem(`draft.${MessengerTypesEnum.WHATS_APP}.${this.conversation.phoneNumber}`, JSON.stringify(draft));
        }
        ,
        deleteDraft() {
          localStorage.removeItem(`draft.${MessengerTypesEnum.WHATS_APP}.${this.conversation.phoneNumber}`);
        },
        restoreDraft() {
          const draft = localStorage.getItem(`draft.${MessengerTypesEnum.WHATS_APP}.${this.conversation.phoneNumber}`);
          if (draft) {
            const parsedDraft = JSON.parse(draft) as LocalStorageDraft;
            this.message = parsedDraft.message;
            MessengerBus.$emit('update:replyToMessage', parsedDraft.repliesTo);
          } else {
            this.message = '';
            this.repliesTo = undefined;
          }
          const form = this.$refs.form as Form;
          if (form) form.resetValidation();
        },
      },
  watch: {
    message() {
      this.storeDraft();
    },
    repliesTo() {
      this.storeDraft();
    },
    conversation() {
      const form = this.$refs.form as Form;
      if (form) form.reset();
      this.resetRepliesTo();
      this.restoreDraft();
    },
  },
  mounted() {
    MessengerBus.$on('update:replyToMessage', (message?: MessengerMessageDocument | MessengerMailDocument) => {
      if (message && message.type !== MessengerTypesEnum.WHATS_APP) {
        this.repliesTo = undefined;
        return;
      }
      this.repliesTo = message as any;
    });
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.restoreDraft();
  }
  ,
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
})
;
</script>
