<template>
  <v-card flat color="transparent">
    <v-card-text>
      <v-select :items="eyebrowTypeItems" :value="eyebrowType" color="info" dense item-color="info"
                label="Augenbrauen" outlined rounded @input="(v) => $emit('update:eyebrowType', v)"/>
      <v-select :items="eyesTypeItems" :value="eyesType" color="info" dense item-color="info"
                label="Augen" outlined rounded @input="(v) => $emit('update:eyesType', v)"/>
      <v-select :items="accessoriesTypeItems" :value="accessoriesType" color="info" dense item-color="info"
                label="Brille, o.ä." outlined rounded @input="(v) => $emit('update:accessoriesType', v)"/>
      <v-select :items="mouthTypeItems" :value="mouthType" color="info" dense item-color="info"
                label="Mund" outlined rounded @input="(v) => $emit('update:mouthType', v)"/>
      <v-select :items="facialHairTypeItems" :value="facialHairType" color="info" dense item-color="info"
                label="Bart" outlined rounded @input="(v) => $emit('update:facialHairType', v)"/>
      <v-slide-y-transition>
        <div v-if="facialHairType !== 'none'">
          <v-subheader>Bartfarbe</v-subheader>
          <color-button :value="facialHairColor" color="#ECDCBF" icon-color="black" output-value="platinum"
                        @input="(v) => $emit('update:facialHairColor', v)"/>
          <color-button :value="facialHairColor" color="#D6B370" output-value="blondeGolden"
                        @input="(v) => $emit('update:facialHairColor', v)"/>
          <color-button :value="facialHairColor" color="#B58143" output-value="blonde"
                        @input="(v) => $emit('update:facialHairColor', v)"/>
          <color-button :value="facialHairColor" color="#A55729" output-value="auburn"
                        @input="(v) => $emit('update:facialHairColor', v)"/>
          <color-button :value="facialHairColor" color="#724133" output-value="brown"
                        @input="(v) => $emit('update:facialHairColor', v)"/>
          <color-button :value="facialHairColor" color="#4A312C" output-value="brownDark"
                        @input="(v) => $emit('update:facialHairColor', v)"/>
          <color-button :value="facialHairColor" color="#2B1B18" output-value="black"
                        @input="(v) => $emit('update:facialHairColor', v)"/>
          <v-divider/>
          <color-button :value="facialHairColor" color="#C93305" output-value="red"
                        @input="(v) => $emit('update:facialHairColor', v)"/>
        </div>
      </v-slide-y-transition>
    </v-card-text>
    <v-card-actions>
      <v-btn color="info" text @click="$emit('prev')">
        <v-icon>mdi-chevron-left</v-icon>
        Zurück
      </v-btn>
      <v-spacer/>
      <v-btn color="info" text @click="$emit('next')">
        Weiter
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue';
import {
  avataaarAccessoriesType,
  avataaarEyebrowType,
  avataaarEyesType,
  avataaarFacialHairType,
  avataaarMouthType,
} from '@/constants/avataaar.constant';
import ColorButton from '@/components/common/profile/createAvataaarDialog/colorButton.vue';

export default Vue.extend({
  components: {ColorButton},
  props: {
    facialHairType: {
      type: String,
      default: '',
    },
    facialHairColor: {
      type: String,
      default: '',
    },
    eyebrowType: {
      type: String,
      default: '',
    },
    eyesType: {
      type: String,
      default: '',
    },
    mouthType: {
      type: String,
      default: '',
    },
    accessoriesType: {
      type: String,
      default: '',
    },
  },
  computed: {
    facialHairTypeItems: () => avataaarFacialHairType,
    eyebrowTypeItems: () => avataaarEyebrowType,
    eyesTypeItems: () => avataaarEyesType,
    mouthTypeItems: () => avataaarMouthType,
    accessoriesTypeItems: () => avataaarAccessoriesType,
  },
});
</script>
