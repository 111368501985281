import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {
    OptimizationSuggestionFields,
} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/optimizationSuggestionFields';

export const optimizationSuggestionFieldsDictionary: FieldDictionary<OptimizationSuggestionFields> = [{
    text: 'Lehrkraft',
    value: OptimizationSuggestionFields.EMPLOYEE,
}, {
    text: 'Kurs',
    value: OptimizationSuggestionFields.COURSE,
}, {
    text: 'Standort',
    value: OptimizationSuggestionFields.INSTITUTION,
}, {
    text: 'Datum',
    value: OptimizationSuggestionFields.DATE,
}, {
    text: 'Score',
    value: OptimizationSuggestionFields.SCORE,
}, {
    text: 'Erwarteter Profit',
    value: OptimizationSuggestionFields.PROFIT,
}, {
    text: 'Erwarteter Profit zuwachs',
    value: OptimizationSuggestionFields.PROFIT_GAIN,
}, {
    text: 'Vorschlag Bestätigt von',
    value: OptimizationSuggestionFields.COMMITTED_BY,
}, {
    text: 'In Kursplan übernommen',
    value: OptimizationSuggestionFields.COMMITTED,
}, {
    text: 'In Kursplan übernommen von',
    value: OptimizationSuggestionFields.COMMITTED_BY,
}];
