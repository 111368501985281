import {CountryCodes} from '@/enums/CountryCodes.enum';

interface Mask {
    mask: string[] | string;
    tokens?: Record<string, {
        pattern: RegExp;
        transform?: (value: string) => string;
    }>;
}

const tokens = {
    'x': {
        pattern: /[a-zA-Z]/,
        transform: (v: string) => v.toUpperCase(),
    },
    '*': {
        pattern: /[a-zA-Z0-9]/,
        transform: (v: string) => v.toUpperCase(),
    },
    '#': {
        pattern: /[0-9]/,
    },
};

export namespace InputMasks {
    export function phoneNumber(val: string | undefined): string {
        if (!val) {
            return '##### ########';
        } else if (val.startsWith('00')) {
            return `#### #### ############`;
        } else if (val.startsWith('0')) {
            return `##### ############`;
        } else {
            return `+## #### ############`;
        }
    }

    export function IBAN(val: string | undefined | null): string | Mask {
        if (!val || val.length < 2) {
            return {
                mask: 'xx## **** **** **** **** **** **** ****',
                tokens,
            };
        }

        const countryCode: CountryCodes = val.substr(0, 2) as CountryCodes;
        switch (countryCode) {
            case CountryCodes.Germany:
                return 'DE## #### #### #### #### ##';
            case CountryCodes.France:
                return {
                    mask: 'FR## **** **** **** **** **** ***',
                    tokens,
                };
            case CountryCodes.Finland:
                return {
                    mask: 'FI## **** **** **** **',
                    tokens,
                };
            case CountryCodes.Denmark:
                return {
                    mask: 'DK## **** **** **** **',
                    tokens,
                };
            case CountryCodes.Liechtenstein:
                return {
                    mask: 'LI## **** **** **** **** *',
                    tokens,
                };
            case CountryCodes.Poland:
                return {
                    mask: 'PL## **** **** **** **** **** ****',
                    tokens,
                };
            case CountryCodes.Austria:
                return {
                    mask: 'AT## **** **** **** ****',
                    tokens,
                };
            case CountryCodes.Switzerland:
                return {
                    mask: 'CH## **** **** **** **** *',
                    tokens,
                };
            case CountryCodes.CzechRepublic:
                return {
                    mask: 'CZ## **** **** **** **** ****',
                    tokens,
                };
            case CountryCodes.Hungary:
                return {
                    mask: 'HU## **** **** **** **** **** ****',
                    tokens,
                };
            case CountryCodes.Ukraine:
                return {
                    mask: 'UA## **** **** **** **** **** **** +',
                    tokens,
                };
            default:
                return {
                    mask: 'xx## **** **** **** **** **** **** ****',
                    tokens,
                };
        }
    }
}
