<template>
  <v-card flat style="overflow: hidden">
    <v-fade-transition hide-on-leave>
      <v-skeleton-loader v-if="loading" key="loader" type="image"/>
      <GmapMap v-else key="map" ref="map" :center="center" :options="mapOptions" :zoom.sync="zoom" class="map">
        <GmapMarker v-if="centerElement" :clickable="true" :draggable="true"
                    :position="getPosition(centerElement)" @dragend="emitPositionUpdate"/>
        <GmapMarker v-for="employee in additionalMarkers.employees" :key="employee._id"
                    :clickable="true"
                    :icon="getEmployeeIcon(employee)" :position="getPosition(employee)" @click="renderEmployeeInfo(employee)"/>
        <GmapMarker v-for="institution in additionalMarkers.institutions" :key="institution._id"
                    :clickable="true"
                    :icon="getInstitutionIcon(institution)" :position="getPosition(institution)"
                    @click="renderInstitutionInfo(institution)"/>
        <GmapCircle
            :center="getPosition(centerElement)"
            :options="{fillColor:'#2196f3',fillOpacity: 0.2, strokeColor: '#2196f3', strokeWeight: 1}"
            :radius="radius"
            :visible="true">
        </GmapCircle>
        <GmapInfoWindow
            v-if="infoContent"
            :opened="showInfo"
            :options="infoOptions"
            :position="infoPosition"
            @closeclick="showInfo = false"
        />
      </GmapMap>
    </v-fade-transition>
  </v-card>
</template>

<script lang="ts">
import {Employee} from '@/interfaces/employee.interface';
import {Institution} from '@/interfaces/institution.interface';
import * as mdi from '@mdi/js';
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import {Place} from '@/interfaces/place.interface';
import {GetDirectionResponseDto} from '@/classes/dto/geocoder/response/GetDirections.response.dto';
import {LeanDocument} from 'mongoose';

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_CLOUD_PLATFORM_API_KEY,
    libraries: 'places',
    region: 'De',
    language: 'de',
  },
});

export default Vue.extend({
  props: {
    centerElement: {type: Object as () => { address?: Place }, required: true},
    additionalMarkers: {
      type: Object as () => { institutions: Array<LeanDocument<Institution> & GetDirectionResponseDto>; employees: Array<LeanDocument<Employee> & GetDirectionResponseDto> },
      default: () => ({institutions: [], employees: []} as { institutions: Array<LeanDocument<Institution> & GetDirectionResponseDto>; employees: Array<LeanDocument<Employee> & GetDirectionResponseDto> }),
    },
    radius: {type: Number, required: true},
    loading: {type: Boolean, default: false},
    activeElement: {type: Object as () => Employee | Institution, default: null},
    activeElementType: {type: String as () => 'employee' | 'institution', default: null},
  },
  data: () => ({
    num: 0,
    zoom: 10,
    mapOptions: {
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,

    },
    infoPosition: {lat: 0, lng: 0},
    infoContent: '',
    showInfo: false,
  }),
  computed: {
    google: () => VueGoogleMaps.gmapApi(),
    center(): { lat: number; lng: number } {
      if (this.activeElement) {
        return this.getPosition(this.activeElement);
      }
      return this.getPosition(this.centerElement);
    },
    infoOptions(): { pixelOffset: any; content: string } {
      return {pixelOffset: {width: 0, height: -35}, content: this.infoContent};
    },
  },
  methods: {
    emitPositionUpdate(dragEvent: { latLng: { lat: number; lng: number } }) {
      this.$emit('update:position', JSON.parse(JSON.stringify(dragEvent.latLng)));
    },
    getPosition(addressable: { address?: Place }) {
      if (addressable.address) {
        const coords = addressable.address.location.coordinates;
        return {lat: coords[1], lng: coords[0]};
      }
      return {lat: 0, lng: 0};
    },
    renderEmployeeInfo(employee: LeanDocument<Employee> & GetDirectionResponseDto) {
      this.infoPosition = this.getPosition(employee as unknown as Employee);
      const employeeName = employee.firstName || employee.lastName
          ? `${employee.firstName || ''} ${employee.lastName || ''}`
          : 'Unbekannte Lehrkraft';
      this.infoContent = `
            <strong class="body-1">${employeeName}</strong>
            <div class="body-2">${(employee.address as Place)?.street} ${(employee.address as Place)?.houseNumber || ''}</div>
            <div>${(employee.address as Place)?.postal} ${(employee.address as Place)?.city}</div>
            <div class="text-center pb-1 pt-2">`;
      this.showInfo = true;
    },
    renderInstitutionInfo(institution: LeanDocument<Institution> & GetDirectionResponseDto) {
      this.infoPosition = this.getPosition(institution);
      this.infoContent = `
            <strong class="body-1">${institution.name || 'Unbekannter Standort'}</strong>
            <div class="body-2">${institution.address?.street} ${institution.address?.houseNumber}</div>
            <div>${institution.address?.postal} ${institution.address?.city}</div>`;
      this.showInfo = true;
    },
    getInstitutionIcon(institution: Institution) {
      const hasAssignedTeachers = institution.assignedTeachers?.length || 0 > 0;
      const hasPlannedTeachers = institution.plannedTeachers?.length || 0 > 0;

      return {
        path: mdi.mdiDomain,
        fillColor: hasAssignedTeachers ? '#2196F3' : hasPlannedTeachers ? '#FFC107' : '#FF5252',
        fillOpacity: 1,
        strokeWeight: .5,
        strokeColor: hasAssignedTeachers ? '#1B75BF' : hasPlannedTeachers ? '#FFB300' : '#FF1744',
        scale: 1.5,
        anchor: new this.google.maps.Point(12, 12),
      };
    },
    getEmployeeIcon() {
      return {
        path: mdi.mdiAccount,
        fillColor: '#5eb342',
        fillOpacity: 1,
        strokeWeight: .5,
        strokeColor: '#43802f',
        scale: 1.5,
        anchor: new this.google.maps.Point(12, 12),
      };
    },
  },
  watch: {
    activeElement: {
      deep: true,
      handler() {
        if (this.activeElement && this.activeElementType) {
          switch (this.activeElementType) {
            case 'institution':
              this.renderInstitutionInfo(this.activeElement as unknown as LeanDocument<Institution> & GetDirectionResponseDto);
              break;
            case 'employee':
              this.renderEmployeeInfo(this.activeElement as unknown as LeanDocument<Employee> & GetDirectionResponseDto);
              break;
          }
          this.$vuetify.goTo(0);
          this.zoom = 10;
        }
      },
    },
  },
});
</script>

<style scoped>
.map {
  min-height: calc(100vh - 62px);
}
</style>


