<template>
  <div v-if="!initializing" key="input">
    <v-autocomplete :disabled="initializing || disabled" :item-color="itemColor || color" :items="computedItems"
                    :loading="loading || initializing"
                    :rules="rules" :search-input.sync="searchInput" :value="value" no-filter
                    placeholder="Tippen um zu suchen"
                    v-bind="styleOptions" @input="$emit('input', $event)"
                    @update:search-input="handleSearch">
      <template v-slot:prepend-item>
        <v-subheader class="px-4">Vorgeschlagene Ergebnisse:</v-subheader>
        <v-divider/>
      </template>
      <template v-slot:item="{item}">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="item.disabled && typeof item.disabled !== 'boolean'">
            {{ item.disabled }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="item.description">
            {{ item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
  <div v-else>
    <v-autocomplete :item-color="itemColor || color" loading disabled
                    v-bind="styleOptions"/>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import mongoose from 'mongoose';
import {InputStyleOptions} from '@/constants/InputStyleOptions.constant';
import {PaginationRequest} from '@/classes/dto/_common/request/PaginationRequest';
import {Constructor} from 'vue/types/options';
import {sleep} from '@/helpers/sleep.helper';
import {Rule} from '@/helpers/ruleFactory.helper';
import {PopulationOptions} from '@/interfaces/PopulationOptions';
import {GeoNearRequest} from '@/classes/dto/_common/request/GeoNearRequest';

export default Vue.extend({
  props: {
    ...InputStyleOptions.Autocomplete,
    api: {
      type: Object as () => ({ find: (paginationRequest: PaginationRequest<any>) => Promise<any> }),
      required: true,
    },
    populate: {
      type: Object as () => PopulationOptions,
      required: false,
    },
    respMapper: {
      type: Object as () => ((data: any) => Array<{
        text: string,
        description?: string;
        value: mongoose.Types.ObjectId,
        disabled?: boolean,
      }>),
      required: true,
    },
    fields: {type: Array as () => string[], required: true},
    prefetch: {type: Boolean, default: false},
    prefetchCount: {type: Number, default: 10},
    rules: {type: Array as () => Rule[]},
    filter: {type: Object as () => any & { near?: GeoNearRequest }, default: () => ({})},
    prefetchFilter: {type: Object as () => any & { near?: GeoNearRequest }, required: false},
    value: {type: Object as () => Array<mongoose.Types.ObjectId> | mongoose.Types.ObjectId | undefined},
  },
  data: () => ({
    searchInput: 'Eulenbande',
    initializing: true,
    loading: false,
    items: [] as Array<{ text: string, value: mongoose.Types.ObjectId }>,
    recentValueItems: [] as Array<{ text: string, value: mongoose.Types.ObjectId }>,

    throttleCounter: 0,
    throttleTimeout: 500,
  }),
  computed: {
    styleOptions(): Record<string, { type: Constructor, required?: boolean }> {
      const props = this.$props as Record<string, any>;
      const styleOptions = {} as Record<string, { type: Constructor, required?: boolean }>;
      for (const key of Object.keys(props)) {
        if (InputStyleOptions.Autocomplete.hasOwnProperty(key)) {
          styleOptions[key] = props[key];
        }
      }
      return styleOptions;
    },
    style(): Record<string, any> {
      let value = 86;
      if (this.dense) {
        value -= 20;
      }
      if (this.hideDetails) {
        value -= 26;
      }
      return {
        minHeight: `${value}px`,
      };
    },
    computedFilter(): any {
      if (this.filter) {
        return {...this.filter, $search: this.searchInput};
      } else {
        return {$search: this.searchInput};
      }
    },
    computedItems(): Array<{ text: string, value: mongoose.Types.ObjectId }> {
      const stringItemValues = this.items.map((item) => item.value.toString());
      const filteredRecentValueItems = this.recentValueItems.filter((item) => !stringItemValues.includes(item.value.toString()));
      return [...this.items, ...filteredRecentValueItems];
    },
    searchInputValid(): boolean {
      return !!(this.searchInput && !this.computedItems.find((item) => item.text === this.searchInput));
    },
  },
  methods: {
    async handlePrefetch() {
      try {
        const resp = await this.api.find({
          filter: this.prefetchFilter || this.filter,
          skipPagination: this.prefetchFilter ? !!this.prefetchFilter.near : !!this.filter.near,
          limit: 20,
          fields: this.fields,
          populate: this.populate,
        });
        this.items = this.respMapper(resp);
      } catch (e) {
        this.$$showSnackbar('Fehler beim Laden der Daten', 'error', e);
      } finally {
        this.initializing = false;
      }
    },
    async handleSearch() {
      if (!this.searchInputValid) {
        if (!this.searchInput && this.prefetch) {
          await this.handlePrefetch();
        }
        return;
      }
      this.loading = true;
      this.throttleCounter++;
      await sleep(this.throttleTimeout);
      this.throttleCounter--;
      if (this.throttleCounter > 0) {
        return;
      } else if (!this.searchInputValid) {
        this.loading = false;
        return;
      }
      // Handle search
      try {
        const resp = await this.api.find({
          filter: this.computedFilter,
          limit: 20,
          fields: this.fields,
          populate: this.populate,
          skipPagination: !!this.computedFilter.near,
        });
        this.items = this.respMapper(resp);
      } catch (e) {
        this.$$showSnackbar('Fehler beim Laden der Daten', 'error', e);
      } finally {
        this.loading = false;
      }
    },
    async initialize() {
      this.initializing = true;
      this.items = [];
      this.recentValueItems = [];
      if (!this.value && this.multiple) {
        this.$emit('input', []);
      } else if (this.multiple && !Array.isArray(this.value)) {
        this.$emit('input', [this.value]);
      } else if (!this.multiple && Array.isArray(this.value)) {
        this.$emit('input', this.value[0]);
      }

      if (this.value) {
        const idArray = Array.isArray(this.value) ? this.value : [this.value];
        try {
          const resp = await this.api.find({
            filter: {_id: idArray},
            fields: this.fields,
            populate: this.populate,
          });
          this.recentValueItems = this.respMapper(resp);
        } catch (e) {
          this.$$showSnackbar('Fehler beim Initialisieren der existierenden Daten', 'error', e);
          for (const id of idArray) {
            this.recentValueItems.push({text: 'Unbekanntes Element', value: id});
          }
        }
      }

      if (this.prefetch) {
        await this.handlePrefetch();
      }
      this.initializing = false;
    },
  },
  watch: {
    searchInput(v) {
      if (!v && this.value && !Array.isArray(this.value)) {
        const stringValue = this.value.toString();
        const item = this.computedItems.find((item) => stringValue.includes(item.value.toString()));
        if (item) {
          this.searchInput = item.text;
        }
      }
    },
    value: {
      deep: true,
      handler() {
        if (this.value) {
          if (Array.isArray(this.value)) {
            if (this.value.length === 0) {
              this.recentValueItems = [];
            }
            const stringValues = this.value.map((value) => value.toString());
            this.recentValueItems = this.computedItems.filter((item: {
              value: any
            }) => stringValues.includes(item.value.toString()));
          } else {
            const stringValue = this.value.toString();
            const item = this.computedItems.find((item) => item.value.toString() === stringValue);
            if (item) {
              this.recentValueItems = [item];
            } else {
              // this.recentValueItems = [{ text: 'Unbekanntes Element', value: this.value }];
              this.initialize();
            }
          }
        } else {
          this.recentValueItems = [];
        }
      },
    },
  },
  mounted() {
    this.initialize();
  },
})
</script>
