import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum AcquisitionClosedReasonEnum {
    COMPETITOR = 'competitor',
    NON_COMMERCIAL = 'nonCommercial',
    NO_ROOM = 'noRoom',
    ROOM_USAGE_FORBIDDEN = 'roomUsageForbidden',
    COOPERATION_CANCELLED = 'cooperationCancelled',
    NOT_INTERESTED = 'notInterested',
    TOO_EXPENSIVE = 'tooExpensive',
    SOCIALLY_DEPRIVED_AREA = 'sociallyDeprivedArea',
    EQUALITY_OF_OPPORTUNITY_NOT_ENSURED = 'equalityOfOpportunityNotEnsured',
    NO_BUDGET = 'noBudget',

    OTHER = 'other',
}

export namespace AcquisitionClosedReasonEnum {
    type Data = {
        text: string;
    }

    export const Record: Record<AcquisitionClosedReasonEnum, Data> = {
        [AcquisitionClosedReasonEnum.COMPETITOR]: {
            text: 'Wettbewerber bereits am Standort',
        },
        [AcquisitionClosedReasonEnum.NON_COMMERCIAL]: {
            text: 'Keine kommerziellen Angebote',
        },
        [AcquisitionClosedReasonEnum.NO_ROOM]: {
            text: 'Keine Räumlichkeiten verfügbar',
        },
        [AcquisitionClosedReasonEnum.ROOM_USAGE_FORBIDDEN]: {
            text: 'Raumnutzung untersagt',
        },
        [AcquisitionClosedReasonEnum.NOT_INTERESTED]: {
            text: 'Kein Interesse',
        },
        [AcquisitionClosedReasonEnum.TOO_EXPENSIVE]: {
            text: 'Zu teuer',
        },
        [AcquisitionClosedReasonEnum.SOCIALLY_DEPRIVED_AREA]: {
            text: 'Sozial schwaches Umfeld',
        },
        [AcquisitionClosedReasonEnum.EQUALITY_OF_OPPORTUNITY_NOT_ENSURED]: {
            text: 'Chancengleichheit nicht gewährleistet',
        },
        [AcquisitionClosedReasonEnum.NO_BUDGET]: {
            text: 'Kein Budget vorhanden (Trägerfinanzierung)',
        },
        [AcquisitionClosedReasonEnum.COOPERATION_CANCELLED]: {
            text: 'Kooperation beendet',
        },
        [AcquisitionClosedReasonEnum.OTHER]: {
            text: 'Sonstiges',
        },
    };

    export const Items = mapRecordToItems(Record);
}
