import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {
    InvoiceDocumentFields,
} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/InvoiceDocumentFields';

export const invoiceDocumentFieldsDictionary: FieldDictionary<InvoiceDocumentFields> = [
    {
        text: 'Rechnung',
        value: InvoiceDocumentFields.INVOICE,
    },
    {
        text: 'Rechnungsvorlage',
        value: InvoiceDocumentFields.TEMPLATE,
    },
    {
        text: 'Interne Rechnungsidentifikationsnummer (erforderlich für FastBill)',
        value: InvoiceDocumentFields.INVOICE,
    },
    {
        text: 'Art der Rechnung',
        value: InvoiceDocumentFields.TYPE,
    },
    {
        text: 'Art der Kund:in',
        value: InvoiceDocumentFields.CUSTOMER_TYPE,
    },
    {
        text: 'Kund:in',
        value: InvoiceDocumentFields.CUSTOMER,
    },
    {
        text: 'Betroffene Schüler:in',
        value: InvoiceDocumentFields.STUDENT,
    },
    {
        text: 'Kundennummer',
        value: InvoiceDocumentFields.CUSTOM_ID,
    },
    {
        text: 'Status - Rechnung ist beendet?',
        value: InvoiceDocumentFields.TEMPLATE,
    },
    {
        text: 'Anmeldung',
        value: InvoiceDocumentFields.SUBMISSION,
    },
];
