import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import { HandoutFields } from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/HandoutFields';

export const handoutFieldsDictionary: FieldDictionary<HandoutFields> = [
    {
        text: 'Ersteller',
        value: HandoutFields.CREATOR,
        readOnly: true,
    },
    {
        text: 'Standort',
        value: HandoutFields.INSTITUTION,
        readOnly: true,
    },
    {
        text: 'Name der Anmeldung',
        value: HandoutFields.NAME,
    },
    {
        text: 'Verwendete Vorlage',
        value: HandoutFields.TEMPLATE,
        readOnly: true,
    },
    {
        text: 'Verwendete Platzhalter',
        value: HandoutFields.PLACEHOLDERS,
        readOnly: true,
    },
    {
        text: 'Datei',
        value: HandoutFields.FILE,
        readOnly: true,
    },
    {
        text: 'Erzeugungsdatum',
        value: HandoutFields.CREATED_AT,
        readOnly: true,
    },
    {
        text: 'Aktualisierungsdatum',
        value: HandoutFields.UPDATED_AT,
        readOnly: true,
    },
    {
        text: 'Sichtbarkeit',
        value: HandoutFields.VISIBLE,
    },
];
