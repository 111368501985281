<template>
  <v-card>
    <v-list-item dense>
      <v-list-item-avatar>
        <v-icon>{{fileIcon}}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{attachment.filename}}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{fileSizeString}}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-icon>
          <v-scale-transition origin="center" group hide-on-leave>
            <v-icon v-if="indicateDownloadComplete" color="success" key="indicator">mdi-check</v-icon>
            <v-btn v-else icon small @click="download" :loading="loading" key="action">
              <v-icon small>mdi-download</v-icon>
            </v-btn>
          </v-scale-transition>
        </v-list-item-icon>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue';
import {MessengerMail} from '@/documentTypes/messenger/messages';
import {ApiHandlerApi} from '@/classes/api/api-handler.api';
import {DownloadHelper} from '@/helpers/DownloadHelper';

export default Vue.extend({
  props: {
    attachment: {
      type: Object as () => MessengerMail['attachments'][0],
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
    box: {
      type: String,
      required: true,
    },
    uid: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    indicateDownloadComplete: false,
  }),
  computed: {
    fileSizeString(): string {
      if (this.attachment?.size < 1024) return this.attachment?.size + ' B';
      if (this.attachment?.size < 1024 * 1024) return (this.attachment?.size / 1024).toFixed(2) + ' KB';
      if (this.attachment?.size < 1024 * 1024 * 1024) return (this.attachment?.size / 1024 / 1024).toFixed(2) + ' MB';
      return (this.attachment?.size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
    },
    fileIcon(): string {
      switch (this.attachment.contentType.split('/')[0].toLowerCase()) {
        case 'image':
          return 'mdi-image';
        case 'video':
          return 'mdi-video';
        case 'audio':
          return 'mdi-music';
        case 'document':
          return 'mdi-file-document';
        case 'archive':
          return 'mdi-archive';
        case 'code':
          return 'mdi-code-tags';
        case 'pdf':
          return 'mdi-file-pdf';
        case 'spreadsheet':
          return 'mdi-file-excel';
        case 'presentation':
          return 'mdi-file-powerpoint';
        case 'text':
          return 'mdi-file-document';
        default:
          return 'mdi-file';
      }
    },
  },
  methods: {
    async download() {
      try {
        this.loading = true;
        this.$$showSnackbar({
          text: 'Datei wird heruntergeladen',
          btnColor: 'info',
          timeout: -1,
        });
        const resp = await ApiHandlerApi.mail.api.getAttachment({
          username: this.username,
          box: this.box,
          uid: this.uid,
          filename: this.attachment.filename,
        });
        DownloadHelper.triggerBlobDownload(resp, this.attachment.filename, false, true);
        this.$$showSnackbar(`${this.attachment.filename} wurde erfolgreich heruntergeladen`, 'success');
        this.indicateDownloadComplete = true;
        setTimeout(() => this.indicateDownloadComplete = false, 2000);
      } catch (e) {
        this.$$showSnackbar('Fehler beim Laden des Anhangs', 'error', e);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
