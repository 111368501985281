import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {
    AcquisitionListFields,
} from "@/classes/clientOnly/permissionTreeResources/enums/entityFields/AcquisitionListFields";

export const acquisitionListFieldsDictionary: FieldDictionary<AcquisitionListFields> = [{
    text: 'Name',
    value: AcquisitionListFields.NAME,
}, {
    text: 'Aktivitätsstatus',
    value: AcquisitionListFields.IS_ACTIVE,
}, {
    text: 'Datum der Erzeugung',
    value: AcquisitionListFields.CREATED_AT,
    readOnly: true,
}, {
    text: 'Datum der letzten Aktualisierung',
    value: AcquisitionListFields.UPDATED_AT,
    readOnly: true,
}, {
    text: 'Erstellt von',
    value: AcquisitionListFields.CREATOR,
}, {
    text: 'Liste für Lehrraft',
    value: AcquisitionListFields.FOR_EMPLOYEE,
}, {
    text: 'Listeneinträge (Standorte)',
    value: AcquisitionListFields.INSTITUTIONS,
}, {
    text: 'Verantwortliche(r)',
    value: AcquisitionListFields.ASSIGNEE,
}]
