export enum ArticleFields {
    ARTICLE_NUMBER = 'articleNumber',
    TITLE = 'title',
    DESCRIPTION = 'description',
    UNIT = 'unit',
    UNIT_PRICE = 'unitPrice',
    CURRENCY_CODE = 'currencyCode',
    VAT_PERCENT = 'vatPercent',
    IS_GROSS = 'isGross',
    ARTICLE_ID = 'articleId',
    TAGS = 'tags',
    IS_DEFAULT = 'isDefault',
    TRACK_STOCK = 'trackStock',
    PACKAGE_DEFAULTS = 'packageDefaults',
    STOCK = 'stock',
    DHL_DEFAULT_PRODUCT = 'dhlDefaultProduct',
}
