import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {RoleFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/RoleFields';

export const roleFieldsDictionary: FieldDictionary<RoleFields> = [
    {
        text: 'Name',
        value: RoleFields.NAME,
    },
    {
        text: 'Beschreibung',
        value: RoleFields.DESCRIPTION,
    },
    {
        text: 'Systemberechtigungen',
        value: RoleFields.CRUD_ACCESS,
    },
    {
        text: 'Anzeigeberechtigungen der grafischen Benutzeroberfläche',
        value: RoleFields.GUI_ACCESS,
    },
];
