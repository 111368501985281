export enum LoginUserFields {
    TYPE = 'type',
    PERMISSION_SUM = 'permissionSum',
    MAIL = 'mail',
    STATE = 'state',
    PASSWORD = 'password',
    ACTIVATION_LINK = 'activationLink',
    IS_DEACTIVATED = 'isDeactivated',
    PASSWORD_ATTEMPTS = 'passwordAttempts',
    META = 'meta',
    DASHBOARD = 'dashboard',
    CRUD_ACCESS = 'crudAccess',
    GUI_ACCESS = 'guiAccess',
    PERMISSION_HOOK_ENTRIES = 'permissionHookEntries',
    ROLES = 'roles',
    LAST_LOGIN_AT = 'lastLoginAt',
    SIP_IDS = 'sipIds',
    APP_TOKENS = 'appTokens',
}
