import { AxiosInstance, AxiosError, ResponseType } from 'axios';
import store from '@/store';
import { LoginApi } from './login.api.class';
import router from '@/router';

export abstract class API {
  protected static service: string;

  protected static async post(path: string, body: any,  responseType?: ResponseType) {
    if (!store.state.axios) {
      return;
    }
    try {
      return (await (store.state.axios as AxiosInstance).post(`${this.service}/${path}`, body, {
        responseType,
      })).data;
    } catch (error) {
      this.handleAxiosError(error as AxiosError);
      throw error;
    }
  }

  protected static async get(path: string, params?: any, responseType?: ResponseType) {
    if (!store.state.axios) {
      return;
    }
    try {
      if (params) {
        return (await (store.state.axios as AxiosInstance).get(`${this.service}/${path}`, {
          params: {
            json: JSON.stringify(params),
          },
          responseType,
        })).data;
      } else {
        return (await (store.state.axios as AxiosInstance).get(`${this.service}/${path}`)).data;
      }
    } catch (error) {
      this.handleAxiosError(error as AxiosError);
      throw error;
    }
  }

  protected static async put(path: string, body: any) {
    if (!store.state.axios) {
      return;
    }
    try {
      return (await (store.state.axios as AxiosInstance).put(`${this.service}/${path}`, body)).data;
    } catch (error) {
      this.handleAxiosError(error as AxiosError);
      throw error;
    }
  }

  protected static async delete(path: string, params?: any) {
    if (!store.state.axios) {
      return;
    }
    try {
      if (params) {
        let _pass: string | undefined;
        if (params._pass) {
            _pass = params._pass;
            delete params._pass;
        }
        return (await (store.state.axios as AxiosInstance).delete(`${this.service}/${path}`, {
          params: {
            json: JSON.stringify(params),
          },
          data: _pass ? { _pass } : undefined,
        })).data;
      } else {
        return (await (store.state.axios as AxiosInstance).delete(`${this.service}/${path}`)).data;
      }
    } catch (error) {
      this.handleAxiosError(error as AxiosError);
      throw error;
    }
  }
  private static async handleAxiosError(axiosError: AxiosError) {
    switch (axiosError.response?.status) {
      case 401:
        await LoginApi.logout();
        store.commit('logout');
        store.commit('showEmergencyLogoutDialog', true);
        router.push('/login').catch();
        break;
      case 403:
        store.commit('showUnauthorizedDialog', true);
        break;
      case 404:
        break;
      case 420:
        break;
      case 422:
        store.commit('showSnackbar', { text: 'Die übermittelten Daten waren fehlerhaft', btnColor: 'error' });
        break;
      case 429:
        store.commit('showSnackbar', { text: 'Das Anfragelimit wurde überschritten', btnColor: 'error' });
        break;
      case 500:
        if (!axiosError.config?.url?.includes('finder')) {
          store.commit('setError', axiosError);
        }
        break;
      case 502:
        store.commit('showSnackbar', { text: 'Die Verbindung zum externen Dienstleister konnte nicht hergestellt werden', btnColor: 'error' });
        break;
      default:
        store.commit('setError', axiosError);
        break;
    }
  }
}
