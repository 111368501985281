<template>
  <v-badge style="width: 100%" color="red" offset-y="20" offset-x="25" dot :value="!notification.readAt">
    <v-list-item :class="{ 'red lighten-5': !notification.readAt }">
      <v-list-item-avatar>
        <profile-avatar :user="{profileImg: notification.messagePayload.fromProfileImg}"/>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle v-if="notification.messagePayload.fromName">
          Neuer Kommentar von {{ notification.messagePayload.fromName }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          Neuer Kommentar
        </v-list-item-subtitle>
        <v-list-item-title>
          {{ message }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="!notification.dismissedAt">
          {{ moment(notification.createdAt).fromNow() }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          <v-icon small>mdi-delete</v-icon>
          {{ moment(notification.dismissedAt).fromNow() }} gelöscht
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn icon v-on="on" :href="link" target="_blank">
              <v-icon>mdi-page-next-outline</v-icon>
            </v-btn>
          </template>
          <span>Zum Profil</span>
        </v-tooltip>
      </v-list-item-action>
      <v-list-item-action v-if="!notification.dismissedAt">
        <v-btn icon @click="$emit('dismiss', notification._id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action v-else>
        <v-btn icon @click="$emit('revoke', notification._id)">
          <v-icon>mdi-delete-off</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-badge>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import {MentionNotificationDocument} from '@/documentTypes/notifier/discriminators/MentionNotification.document';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import moment from 'moment';
import {LogEntryObjectTypes} from '@/enums/LogEntryEnums';

moment.locale('de');

export default Vue.extend({
  props: {
    notification: {
      type: Object as () => NotificationLike<MentionNotificationDocument>,
      required: true,
    },
  },
  components: {ProfileAvatar},
  computed: {
    moment: () => moment,
    message(): string {
      const tag = document.createElement('div');
      tag.innerHTML = this.notification.messagePayload.message || '';
      return tag.innerText;
    },
    link(): string {
      switch (this.notification.messagePayload.objectType) {
        case LogEntryObjectTypes.EMPLOYEE:
          return this.$router.resolve(`/employees/${this.notification.messagePayload.objectId}`).href;
        case LogEntryObjectTypes.INSTITUTION:
          return this.$router.resolve(`/institutions/${this.notification.messagePayload.objectId}`).href;
        case LogEntryObjectTypes.PARENT:
          return this.$router.resolve(`/parents/${this.notification.messagePayload.objectId}`).href;
        case LogEntryObjectTypes.STUDENT:
          return this.$router.resolve(`/students/${this.notification.messagePayload.objectId}`).href;
        default:
          throw new Error('Unknown object type');
      }
    },
  },
});
</script>
