<template>
  <v-alert v-if="notification.showInApp" :key="notification.id" class="elevation-3 mt-4 mx-2 notification" dark
           transition="slide-x-transition">
    <template v-slot:prepend>
      <div class="mr-4">
        <v-icon>mdi-information</v-icon>
      </div>
    </template>
    <template v-slot:default>
      <div class="body-2">
        <span v-if="notification.messagePayload.authorType === 'institution'">
          Der <span class="font-weight-bold">Standort</span>
        </span>
        <span v-else>Die <span class="font-weight-bold">werbende Person</span></span>
        hat ein Feedback zur Austeilaktion <span class="font-weight-bold">{{
          notification.messagePayload.institutionName
        }}</span>
        <span v-if="notification.messagePayload.actionType === 'create'"> abgegeben</span>
        <span v-else> bearbeitet.</span>
      </div>
      <v-btn :href="link" class="mt-2" small target="_blank" text @click="hide">
        <v-icon class="mr-2" small>mdi-page-next-outline</v-icon>
        Austeilaktion öffnen
      </v-btn>
    </template>
    <template v-slot:close>
      <v-btn class="ml-2" icon small @click="hide">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import {NotifierApi} from '@/classes/api/notifier.api';
import {
  HandoutPlannerEventFeedbackNotificationDocument,
} from '@/documentTypes/notifier/discriminators/handoutPlanner/HandoutPlannerEventFeedbackNotification.document';

export default Vue.extend({
  components: {},
  props: {
    notification: {
      type: Object as () => NotificationLike<HandoutPlannerEventFeedbackNotificationDocument>,
      required: true,
    },
  },
  computed: {
    link(): string {
      return this.$router.resolve(`/planning/handout-events/${this.notification.messagePayload.handoutEventId}`).href;
    },
  },
  data: () => ({
    toDoValue: true,
  }),
  methods: {
    async hide() {
      const id = this.notification._id;
      await NotifierApi.markRead({
        notificationIds: [id],
      });
      this.$store.commit('hideNotification', id);
    },
  },
});
</script>

<style scoped>
.notification {
  background-color: rgba(0, 0, 10, 0.5) !important;
  backdrop-filter: blur(15px);
}
</style>
