import {API} from '@/classes/api/api.class';
import {FindNotificationRequestDto} from '@/classes/dto/notifier/request/FindNotification.request.dto';
import {LeanDocument, Types} from 'mongoose';
import {NotificationDocument} from '@/documentTypes/notifier/Notification.document';
import {FindNotificationFilter} from '@/classes/dto/notifier/request/filters/FindNotification.filter';
import {
    BulkUpdateNotificationStateRequestDto,
} from '@/classes/dto/notifier/request/BulkUpdateNotificationState.request.dto';

export class NotifierApi extends API {
    static service = 'notifier';

    static async getUnreadCount(): Promise<number> {
        return this.get('unread-count');
    }

    static async getOwnNotifications(data: FindNotificationRequestDto & {
        filter?: FindNotificationFilter & { to: never }
    }): Promise<{ notifications: LeanDocument<NotificationDocument>[], maxPage: number }> {
        return this.get('own', data);
    }

    static async markDelivered(data: BulkUpdateNotificationStateRequestDto): Promise<void> {
        return this.put('mark-delivered', data);
    }

    static async markRead(data: BulkUpdateNotificationStateRequestDto): Promise<void> {
        return this.put('mark-read', data);
    }

    static async dismiss(data: BulkUpdateNotificationStateRequestDto): Promise<void> {
        return this.put(`dismiss`, data);
    }

    static async revokeDismission(id: Types.ObjectId): Promise<void> {
        return this.put(`revoke-dismission/${id}`, {});
    }
}
