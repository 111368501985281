import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {
    RecurringDocumentFields,
} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/RecurringDocumentFields';

export const recurringDocumentFieldsDictionary: FieldDictionary<RecurringDocumentFields> = [
    {
        text: 'Rechnung',
        value: RecurringDocumentFields.INVOICE,
    },
    {
        text: 'Rechnungsvorlage',
        value: RecurringDocumentFields.TEMPLATE,
    },
    {
        text: 'Interne Rechnungsidentifikationsnummer (erforderlich für FastBill)',
        value: RecurringDocumentFields.INVOICE,
    },
    {
        text: 'Art der Rechnung',
        value: RecurringDocumentFields.TYPE,
    },
    {
        text: 'Art der Kund:in',
        value: RecurringDocumentFields.CUSTOMER_TYPE,
    },
    {
        text: 'Kund:in',
        value: RecurringDocumentFields.CUSTOMER,
    },
    {
        text: 'Betroffene Schüler:in',
        value: RecurringDocumentFields.STUDENT,
    },
    {
        text: 'Kundennummer',
        value: RecurringDocumentFields.CUSTOM_ID,
    },
    {
        text: 'Status - Rechnung ist beendet?',
        value: RecurringDocumentFields.TEMPLATE,
    },
    {
        text: 'Anmeldung',
        value: RecurringDocumentFields.SUBMISSION,
    },
];
