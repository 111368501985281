<template>
  <v-col :cols="11" :offset="isOwn ? 1 : 0">
    <v-hover v-slot="{ hover }">
      <div>
        <v-subheader class="mx-n3 my-n2">{{ message.fromNumber }}
          <v-icon small>mdi-arrow-right</v-icon>
          {{ message.toNumber }}
          <span v-if="hasErrors" class="ml-2 mr-1">
            <v-tooltip bottom max-width="300">
              <template v-slot:activator="{ on }">
                <v-icon color="error" small v-on="on">mdi-alert</v-icon>
              </template>
              <div>
                Fehler beim Senden der Nachricht:
                <div class="white--text" style="background-color: rgba(0,0,0,0.4)">
                  <code v-for="error in message.whatsAppErrors" :key="error">{{ error }}</code>
                </div>
              </div>
            </v-tooltip>
          </span>
          <span v-else-if="isOwn" class="ml-2 mr-1">
             <v-icon v-if="isRead" color="info" small>mdi-check-all</v-icon>
            <v-icon v-else-if="isDelivered" small>mdi-check-all</v-icon>
            <v-icon v-else-if="isSent" small>mdi-check</v-icon>
            <v-icon v-else small>mdi-clock</v-icon>
          </span>
          <span v-else class="ml-2 mr-1">
            <v-icon small>mdi-check-all</v-icon>
          </span>
          {{ lastStatusUpdate }}
        </v-subheader>
        <v-lazy v-model="isVisible" :min-height="minHeight" transition="slide-y-transition">
          <v-card :color="isOwn ? ownColor : strangerColor" tile ref="card">
            <v-card-text style="min-height: 80px">
              <div class="float-right" style="width: 36px; height: 36px">
                <v-slide-x-reverse-transition>
                  <div v-if="hover">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon @click="replyTo" v-on="on">
                          <v-icon>mdi-reply</v-icon>
                        </v-btn>
                      </template>
                      <span>Auf diese Nachricht antworten</span>
                    </v-tooltip>
                  </div>
                </v-slide-x-reverse-transition>
              </div>
              <v-row dense>
                <v-col v-for="(attachment, i) in attachmentData" :key="i" class="mb-2" cols="12" md="8" xl="6">
                  <v-card v-if="attachment.type.startsWith('image')" class="rounded-lg" style="overflow: hidden">
                    <v-img :src="attachment.mediaUrl" max-height="400">
                      <v-img :src="attachment.mediaUrl" contain max-height="400"
                             style="backdrop-filter: blur(10px) brightness(0.5)"/>
                    </v-img>
                  </v-card>
                  <audio v-else-if="attachment.type.startsWith('audio')" :src="attachment.mediaUrl" controls/>
                  <v-card v-else-if="attachment.type.startsWith('video')" class="d-flex align-center">
                    <v-icon>mdi-video</v-icon>
                    <span class="ml-2">Video</span>
                  </v-card>
                  <v-card v-else-if="attachment.type.startsWith('application')" class="d-flex align-center">
                    <v-icon>mdi-file</v-icon>
                    <span class="ml-2">Datei</span>
                  </v-card>
                </v-col>
              </v-row>

              <div v-if="message.header" class="font-weight-bold" v-html="message.header"/>
              <div v-if="message.message" v-html="message.message"/>
              <div v-else-if="message.messageType && message.messageType !== WhatsAppMessageTypes.TEXT" class="font-italic">
                Diese Nachricht hat keinen Inhalt.
              </div>
              <div v-if="message.footer" class="caption" v-html="message.footer"/>
            </v-card-text>
          </v-card>
        </v-lazy>
      </div>
    </v-hover>
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import {MessengerWhatsAppMessageDocument} from '@/documentTypes/messenger/messages';
import moment from 'moment';
import {MessengerBus} from '@/busses/MessengerBus';
import {MessengerMessageDocument} from '@/documentTypes/messenger/MessengerMessage.document';
import {LeanDocument} from 'mongoose';
import {ApiHandlerApi} from '@/classes/api/api-handler.api';
import {WhatsAppMessageTypes} from '@/enums/api-handler/whats-app/MessageTypes.whatsApp';

moment.locale('de');

export default Vue.extend({
  props: {
    message: {
      type: Object as () => LeanDocument<MessengerWhatsAppMessageDocument>,
      required: true,
    },
    isOwn: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    ownColor: 'whatsApp lighten-3',
    strangerColor: 'whatsApp lighten-4',
    attachmentData: [] as Array<{ type: string, mediaUrl: string }>,
    isVisible: false,
  }),
  computed: {
    WhatsAppMessageTypes: () => WhatsAppMessageTypes,
    minHeight(): number {
      const div = document.createElement('div');
      div.classList.add('v-application');
      div.style.display = 'block';
      const messagingContainer = document.getElementById('vanilla-messaging-dialog-message-container');
      if (messagingContainer) {
        const width = messagingContainer.clientWidth;
        div.style.maxWidth = `${width * 11 / 12}px`;
      } else {
        div.style.maxWidth = '600px';
      }
      if (this.message.header) {
        div.innerHTML += `<div>${this.message.header}</div>`;
      }
      div.innerHTML += this.message.message;
      if (this.message.footer) {
        div.innerHTML += `<div>${this.message.footer}</div>`;
      }
      document.body.appendChild(div);
      const height = div.clientHeight;
      document.body.removeChild(div);
      div.remove();
      return Math.max(height, 80);
    },
    hasErrors(): boolean {
      return !!this.message.whatsAppErrors && this.message.whatsAppErrors.length > 0;
    },
    isSent(): boolean {
      return !!this.message.sentAt;
    },
    isDelivered(): boolean {
      return !!this.message.deliveredAt;
    },
    isRead(): boolean {
      return !!this.message.readAt;
    },
    lastStatusUpdate(): string {
      let lastStatusUpdate = this.message.sentAt;
      if (this.message.deliveredAt) {
        lastStatusUpdate = this.message.deliveredAt;
      }
      if (this.message.readAt) {
        lastStatusUpdate = this.message.readAt;
      }

      return moment(lastStatusUpdate).format('DD.MM.YYYY HH:mm [Uhr]');
    },
  },
  methods: {
    replyTo() {
      const rawContents = this.message.message || '';
      const span = document.createElement('span');
      span.innerHTML = rawContents;
      MessengerBus.$emit('update:replyToMessage', {
        ...this.message,
        message: span.textContent || '',
      } as unknown as MessengerMessageDocument);
    },
    async loadAttachments() {
      for (const attachment of this.message.attachments) {
        let mediaUrl = '';

        if (attachment.contentType.startsWith('image') || attachment.contentType.startsWith('audio')) {
          const data = await ApiHandlerApi.whatsApp.api.getMedia({
            phoneNumberId: this.message.phoneNumberId,
            mediaId: attachment.id,
          });
          const base64 = new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(data);
          });
          mediaUrl = await base64;
        }

        this.attachmentData.push({
          type: attachment.contentType,
          mediaUrl,
        });
      }
    },
  },
  watch: {
    isVisible: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.loadAttachments();
        }
      },
    },
  },
});
</script>
