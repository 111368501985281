export class InputStyleOptions {

    public static get Input() {
        return {
            appendIcon: {type: String},
            backgroundColor: {type: String},
            color: {type: String},
            dark: {type: Boolean},
            dense: {type: Boolean},
            disabled: {type: Boolean},
            filled: {type: Boolean},
            hideDetails: {type: Boolean},
            hideSpinButtons: {type: Boolean},
            hint: {type: String},
            label: {type: String},
            light: {type: Boolean},
            loading: {type: Boolean},
            persistentHint: {type: Boolean},
            prependIcon: {type: String},
            readonly: {type: Boolean},
            singleLine: {type: Boolean},
        };
    }

    public static get Field() {
        return {
            ...this.Input,
            filled: {type: Boolean},
            outlined: {type: Boolean},
            rounded: {type: Boolean},
            solo: {type: Boolean},
            shaped: {type: Boolean},
        };
    }

    public static get CheckBox() {
        return {
            ...this.Input,
            indeterminate: { type: Boolean },
            trueValue: { type: [Boolean, String, Number] },
            falseValue: { type: [Boolean, String, Number] },
        };
    }

    public static get TextField() {
        return {
            ...this.Field,
            autofocus: {type: Boolean},
            autocomplete: {type: String},
            autoGrow: {type: Boolean},
            counter: {type: [Boolean, Number, String]},
            flat: {type: Boolean},
            fullWidth: {type: Boolean},
            outlined: {type: Boolean},
            prefix: {type: String},
            reverse: {type: Boolean},
            suffix: {type: String},
            type: {type: String},
            validateOnBlur: {type: Boolean},
            value: {type: [String, Number]},
        };
    }

    public static get TextArea() {
        return {
            ...this.TextField,
            rows: {type: [Number, String]},
            autoGrowMinRows: {type: [Number, String]},
            autoGrowMaxRows: {type: [Number, String]},
        };
    }

    public static get Select() {
        return {
            ...this.Field,
            chips: {type: Boolean},
            clearIcon: {type: String},
            clearable: {type: Boolean},
            multiple: {type: Boolean},
            smallChips: {type: Boolean},
            itemColor: {type: String},
        };
    }

    public static get Autocomplete() {
        return {
            ...this.Select,
            combobox: {type: Boolean},
            debounceTimeout: {type: Number},
            filter: {type: Function},
            itemText: {type: String},
            itemValue: {type: String},
            menuProps: {type: Object},
            noDataText: {type: String},
            openOnClear: {type: Boolean},
            searchInput: {type: String},
            searchInputId: {type: String},
            valueComparator: {type: Function},
        };
    }
}
