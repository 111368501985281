import {GenderTypes, SalutationTypesVue} from '@/enums/GenderTypes.enum';

export function toSalutation(gender?: GenderTypes | null) {
  if (typeof gender === 'undefined' || gender === null) {
    return '';
  }
  const target = SalutationTypesVue.find(v => v.value === gender);
  if (target) {
    return target.text;
  } else {
    return '';
  }
}
