import { API } from './api.class';
import {GetProductRequestDto} from '@/classes/dto/dhl-api/post/GetProductRequestDto';
import {CreateStampsRequestDto} from '@/classes/dto/dhl-api/post/CreateStampsRequestDto';
import {FormatListRequestFilter} from '@/classes/dto/dhl-api/post/FormatListRequestFilter';
import {HydratedDocument} from 'mongoose';
import {GetFormatRequestDto} from '@/classes/dto/dhl-api/post/GetFormatRequestDto';
import {ShortSalesProduct} from '@/classes/dto/dhl-api/post/ProductListResponseDto';
import {DhlPostFormat} from '@/classes/dto/dhl-api/post/FormatListResponseDto';
import {CreateCustomStampRequestDto} from '@/classes/dto/dhl-api/post/CreateCustomStamp.request.dto';

/**
 * This class is used for communicating with the dhl-shipment-api microservice.
 * @author Lukas Bretz
 */
export class DhlPostApi extends API {
    static service = 'dhl-api';

    static async getProductList(): Promise<Array<HydratedDocument<ShortSalesProduct, any, any>>> {
        return this.get('dhl-post-api/productList');
    }

    static async getProduct(data: GetProductRequestDto): Promise<HydratedDocument<unknown, any, ShortSalesProduct>> {
        return this.get('dhl-post-api/product', data);
    }

    static async downloadProductList() {
        return this.get('dhl-post-api/productList/download');
    }

    static async getFormatList(filter: FormatListRequestFilter): Promise<Array<HydratedDocument<DhlPostFormat, any, any>>> {
        return this.get('dhl-post-api/formatList', filter);
    }

    static async getFormat(data: GetFormatRequestDto): Promise<HydratedDocument<unknown, any, DhlPostFormat>> {
        return this.get('dhl-post-api/format', data);
    }

    static async getBalance(): Promise<number> {
        return this.get('dhl-post-api/balance');
    }

    static async getWalletChargeData(amount: number): Promise<any> {
        return this.get(`dhl-post-api/walletCharge/${amount}`);
    }

    static async downloadFormatList() {
        return this.get('dhl-post-api/formats/download');
    }

    static async createStamp(data: CreateStampsRequestDto) {
        return this.post('dhl-post-api/', data);
    }

    static async createCustomStamp(data: CreateCustomStampRequestDto) {
        return this.post('dhl-post-api/custom', data);
    }
}
