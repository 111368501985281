<template>
  <div>
    <v-divider/>
    <div style="display: flex; flex-direction: row; max-width: 100%">
      <div class="flex-shrink-1" style="overflow: hidden">
        <v-slide-x-transition hide-on-leave>
          <div v-if="hasMultipleConversationOptions" class="fill-height" style="display: flex; flex-direction: row; align-items: center">
            <div class="flex-shrink-1" style="box-sizing: border-box">
              <div v-for="option in selectableOptions" :key="option.value" class="ml-2 mb-2">
                <v-btn :color="option.btnColor" elevation="0" rounded @click="selectedType = option.value">
                  <v-icon :color="option.iconColor">{{ option.icon }}</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-slide-x-transition>
      </div>
      <div class="flex-grow-1 pa-3" style="max-width: 100%">
        <v-slide-x-transition group hide-on-leave>
          <mail-messaging-input v-if="selectedType === MessengerTypesEnum.MAIL" :key="MessengerTypesEnum.MAIL"
                                :accounts="accounts" :conversation="conversation"/>
          <whats-app-messaging-input v-else-if="selectedType === MessengerTypesEnum.WHATS_APP"
                                     :key="MessengerTypesEnum.WHATS_APP" :accounts="accounts"
                                     :conversation="conversation"/>
        </v-slide-x-transition>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import {GetConversationsResponseDto} from '@/classes/dto/messenger/response/messages/GetConversations.response.dto';
import {MessengerTypesEnum} from '@/enums/messenger/MessengerTypes.enum';
import MailMessagingInput from '@/components/app/messagingComponents/inputComponents/mailMessagingInput.vue';
import WhatsAppMessagingInput from '@/components/app/messagingComponents/inputComponents/whatsAppMessagingInput.vue';
import {MessengerBus} from '@/busses/MessengerBus';
import {MessengerMessageDocument} from '@/documentTypes/messenger/MessengerMessage.document';
import {MessengerMailDocument} from '@/documentTypes/messenger/messages';
import {MessengerAccountDocument} from '@/documentTypes/messenger/MessengerAccount.document';
import {LeanDocument} from 'mongoose';

type BtnConfig = { icon: string, value: MessengerTypesEnum, btnColor: string, iconColor: string };

export default Vue.extend({
  components: {WhatsAppMessagingInput, MailMessagingInput},
  props: {
    conversation: {
      type: Object as () => GetConversationsResponseDto['conversations'][0],
      required: true,
    },
    accounts: {
      type: Array as () => Array<LeanDocument<MessengerAccountDocument>>,
      required: true,
    },
  },
  data: () => ({
    selectedType: MessengerTypesEnum.MAIL,
  }),
  computed: {
    MessengerTypesEnum: () => MessengerTypesEnum,
    hasMultipleConversationOptions(): boolean {
      return !!this.conversation.mail && !!this.conversation.phoneNumber;
    },
    selectableOptions(): BtnConfig[] {
      const options: BtnConfig[] = [];
      if (this.conversation.mail) {
        options.push({
          icon: 'mdi-email',
          value: MessengerTypesEnum.MAIL,
          btnColor: this.isActive(MessengerTypesEnum.MAIL) ? 'outlook' : 'transparent',
          iconColor: this.isActive(MessengerTypesEnum.MAIL) ? 'white' : 'grey',
        });
      }
      if (this.conversation.phoneNumber) {
        options.push({
          icon: 'mdi-whatsapp',
          value: MessengerTypesEnum.WHATS_APP,
          btnColor: this.isActive(MessengerTypesEnum.WHATS_APP) ? 'whatsApp' : 'transparent',
          iconColor: this.isActive(MessengerTypesEnum.WHATS_APP) ? 'white' : 'grey',
        });
      }
      return options;
    },
  },
  methods: {
    isActive(type: MessengerTypesEnum): boolean {
      return this.selectedType === type;
    },
  },
  watch: {
    conversation: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.hasMultipleConversationOptions) {
          this.selectedType = this.conversation.mail ? MessengerTypesEnum.MAIL : MessengerTypesEnum.WHATS_APP;
        } else {
          this.selectedType = this.conversation.lastMessageType || MessengerTypesEnum.MAIL;
        }
      },
    },
  },
  mounted() {
    MessengerBus.$on('update:replyToMessage', (message?: MessengerMessageDocument | MessengerMailDocument) => {
      if (message && message.type !== this.selectedType) {
        this.selectedType = message.type;
        this.$nextTick(() => {
          MessengerBus.$emit('update:replyToMessage', message);
        });
      }
    });
  },
});
</script>
