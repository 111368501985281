export enum ResponsibleFields {
    GENDER = 'gender',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    BIRTHDAY = 'birthday',
    ADDRESS = 'address',
    PAYMENT = 'payment',
    CONTACT_INFORMATION = 'contactInformation',
    IS_ACTIVE = 'isActive',
    HAS_LOGIN = 'hasLogin',
    SCHOOL_TYPE = 'schoolType',
    JOB_TYPE = 'jobType',
}
