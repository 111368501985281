import { Document } from 'mongoose';

export interface JobType extends Document {
    male: string;
    female: string;
    neutral: string;
}

export function isJobType(doc: any): doc is JobType {
    return doc && doc.hasOwnProperty('male') && doc.hasOwnProperty('female') && doc.hasOwnProperty('neutral');
}
