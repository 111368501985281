import { Types } from 'mongoose';
import { Responsible } from '@/interfaces/responsible.interface';
import { DeprecatedCourse } from '@/interfaces/deprecatedCourse.interface';
import { API } from './api.class';
import { DeleteResponsibleRequestDto } from '@/classes/dto/responsible-handler/request/DeleteResponsible.request.dto';
import { UpdateResponsibleRequestDTO } from '@/classes/dto/responsible-handler/request/UpdateResponsible.request.dto';
import { FindResponsibleRequestDto } from '@/classes/dto/responsible-handler/request/FindResponsible.request.dto';
import { CreateResponsibleRequestDTO } from '@/classes/dto/responsible-handler/request/CreateResponsible.request.dto';
import { CreateJobTypeRequestDto } from '@/classes/dto/responsible-handler/CreateJobType.request.dto';
import { JobType } from '@/interfaces/jobType.interface';
import { FindJobTypeRequestDto } from '@/classes/dto/responsible-handler/request/FindJobType.request.dto';
import {FindByIdOptionsRequestDto} from '@/classes/dto/_common/request/FindByIdOptions.request.dto';

/**
 * This class is used for communicating with the employee handler microservice.
 * @class EmployeeHandlerAPI
 * @author Joshua Seipel
 */
export class ResponsibleHandlerAPI extends API {
    static service = 'responsible-handler';

    static async create(newDocument: CreateResponsibleRequestDTO): Promise<Responsible> {
        return this.post(``, newDocument);
    }

    static async find(findQuery: FindResponsibleRequestDto): Promise<{ responsibles: Responsible[]; maxPage: number }> {
        return this.get(``, findQuery);
    }

    static async findById(id: Types.ObjectId, options?: FindByIdOptionsRequestDto): Promise<Responsible> {
        return this.get(`${id}`, options);
    }

    static async update(id: Types.ObjectId, update: UpdateResponsibleRequestDTO): Promise<DeprecatedCourse> {
        return this.put(`${id}`, update);
    }

    static async remove(id: Types.ObjectId, query?: DeleteResponsibleRequestDto): Promise<void> {
        return this.delete(`${id}`, query);
    }

    static jobTypes = {
        create: async (newDocument: CreateJobTypeRequestDto): Promise<JobType> => {
            return ResponsibleHandlerAPI.post('jobType', newDocument);
        },
        find: async (data: FindJobTypeRequestDto): Promise<{ jobTypes: JobType[]; maxPage: number }> => {
            return ResponsibleHandlerAPI.get('jobType', data);
        },
    };
}
