<template>
  <v-img :src="`${publicPath}resources/backgrounds/loginBackground.svg`" contain
         height="100%" position="bottom"
         width="100%"
  >
    <v-container fill-height>
      <v-row align="center" dense justify="center" style="height: 100%;">
        <v-col cols="12" lg="5" md="7" sm="10">
          <div class="text-h4 font-weight-bold text-center mb-5">
            Willkommen bei Vanilla!
          </div>
          <v-card class="rounded-lg">
            <v-card-title class="title font-weight-black" primary-title>Login</v-card-title>
            <v-card-text>
              <v-text-field v-model="mail" :disabled="loading" color="info" dense label="E-Mail" outlined rounded
                            @keypress.enter="login"/>
              <v-text-field v-model="password" :disabled="loading" color="info" dense hide-details label="Passwort"
                            outlined rounded type="password" @keypress.enter="login"/>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="showForgotPasswordDialog = true;">Passwort vergessen</v-btn>
              <v-spacer></v-spacer>
              <v-btn :loading="loading" color="info" @click="login">Anmelden</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="error" max-width="500px" transition="dialog-transition" @keydown.enter="error = false">
        <v-card>
          <v-card-title primary-title>Login fehlgeschlagen</v-card-title>
          <v-card-text v-if="!locked">Die angegebenen Daten scheinen fehlerhaft.</v-card-text>
          <v-card-text v-else>
            Der Account wurde aufgrund zu vieler Loginversuche
            vorübergehend gesperrt.
            Bitte kontaktieren Sie den Systemadministrator für weitere
            Informationen.
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- DIALOGS -->
    <forgot-password-dialog v-model="showForgotPasswordDialog"/>
  </v-img>
</template>

<script lang="ts">
import Vue from 'vue';
import store from '../store';
import router from '../router';
import {LoginApi} from '@/classes/api/login.api.class';
import ForgotPasswordDialog from '@/components/app/forgotPasswordDialog.vue';

export default Vue.extend({
  components: {ForgotPasswordDialog},
  data: () => ({
    mail: '',
    password: '',
    loading: false,
    error: false,
    locked: false,
    publicPath: process.env.BASE_URL,
    showForgotPasswordDialog: false,
  }),
  methods: {
    async login() {
      this.loading = true;
      this.locked = false;
      this.error = false;
      try {
        await LoginApi.login(this.mail, this.password);
        if (store.state.axios) {
          const resp = await LoginApi.checkLogin();
          if (resp) {
            store.commit('setOriginUserId', resp.originUserId);
          }
          if (store.state.routeCache) {
            router.push(store.state.routeCache.path).catch();
            store.commit('setRouteCache', null);
          } else {
            router.push('/').catch();
          }
        }
      } catch (error) {
        this.error = true;
        if ((error as any).statusCode === 403) {
          this.locked = true;
        }
      }
      this.loading = false;
    },
  },
});
</script>
