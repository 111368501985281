<template>
  <v-dialog :value="value" max-width="800px" @click:outside="$emit('input', false)"
            @keydown.esc="$emit('input', false)">
    <v-card >
      <v-card-title>
        <div class="info--text">
          Arbeitsvertrag
        </div>
        <v-spacer/>
        <v-btn icon @click="reset">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="loading">
        <v-skeleton-loader type="list-item-avatar, divider, list-item-three-line, card-heading" />
      </v-card-text>
      <v-list class="py-2" v-else-if="contract">
        <v-list-item v-can:read-contracts="{ id: contractId, keys: ['name'] }">
          <v-list-item-avatar>
            <v-icon>mdi-clipboard-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{contract.name}}
            </v-list-item-title>
            <v-list-item-subtitle>
              Name
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-can:read-contracts="{ id: contractId, keys: ['name'] }" />
        <v-list-item v-can:read-contracts="{ id: contractId, keys: ['salaryBasis'] }">
          <v-list-item-avatar>
            <v-icon>mdi-human-male-board</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{SalaryBasisVue.find((el) => el.value === contract.salaryBasis).text}}
            </v-list-item-title>
            <v-list-item-subtitle>
              Gehaltsgrundlage
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-can:read-contracts="{ id: contractId, keys: ['salaryBasis'] }" />
        <v-list-item v-can:read-contracts="{ id: contractId, keys: ['salaryPerUnit'] }">
          <v-list-item-avatar>
            <v-icon>mdi-account-cash</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{toCurrency(contract.salaryPerUnit)}}
            </v-list-item-title>
            <v-list-item-subtitle>
              Lohn
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-can:read-handouts="{ id: contractId, keys: ['salaryPerUnit'] }"/>
        <v-list-item v-can:read-handouts="{ id: contractId, keys: ['travelExpenseCompensationPerKilometer'] }" v-if="contract.travelExpenseCompensationPerKilometer">
          <v-list-item-avatar>
            <v-icon>mdi-car</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{toCurrency(contract.travelExpenseCompensationPerKilometer)}}
            </v-list-item-title>
            <v-list-item-subtitle>
              Kilometerpauschale
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-can:read-handouts="{ id: contractId, keys: ['travelExpenseCompensationPerKilometer'] }" v-if="contract.travelExpenseCompensationPerKilometer" />
        <v-textarea :value="contract.conditions" prepend-icon="mdi-file-sign" readonly dense class="mx-7" v-can:read-contracts="{ id: contractId, keys: ['conditions'] }" color="info"/>
        <v-divider v-can:read-contracts="{ id: contractId, keys: ['conditions'] }" />
      </v-list>

      <v-card-actions>
        <v-tooltip right v-can:custom-shareAccess>
          <template v-slot:activator="{on}">
            <v-btn small outlined rounded @click="shareAccess" color="info" v-on="on"><v-icon small>mdi-shield-lock-open</v-icon>Freigeben</v-btn>
          </template>
          Arbeitsvertrag freigeben
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="info" outlined rounded @click="reset" small>
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script lang="ts">
import Vue from 'vue';
import { CrudEntityTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import { Types } from 'mongoose';
import { Contract } from '@/interfaces/contract.interface';
import { ContractorAPI } from '@/classes/api/contractor.api';
import { ContractFields } from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/ContractFields';
import { SalaryBasisVue } from '@/enums/SalaryBasis.enum';
import { toCurrencyString } from '@/helpers/toCurrencyString.helper';

export default Vue.extend({
  components: {
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    contractId: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    contract: null as null | Contract,
  }),
  computed: {
    SalaryBasisVue: () => SalaryBasisVue,
    toCurrency: () => toCurrencyString,
  },
  methods: {
    async getContract() {
      try {
        this.loading = true;
        this.contract = await ContractorAPI.findById(this.contractId, {
          fields: ['name', 'conditions', 'travelExpenseCompensationPerKilometer', 'salaryPerUnit', 'salaryBasis', 'createdAt'] as ContractFields[],
        });
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler beim Laden des Arbeitsvertrags aufgetreten. Bitte versuche es erneut.', 'error');
        this.$emit('input', false)
      } finally {
        this.loading = false;
      }
    },
    reset() {
      this.loading = false;
      this.contract = null;
      this.$emit('input', false);
    },
    shareAccess() {
      this.$$shareAccessDialog.show(CrudEntityTypes.CONTRACT, this.contractId, 'Arbeitsvertrag freigeben')
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.getContract();
        }
      },
    },
  },
});
</script>
