export enum WhatsAppMessageTemplateFields {
    LAST_EDITED_BY = 'lastEditedBy',
    CONFIGURATION_ID = 'configurationId',
    NAME = 'name',
    LANGUAGE = 'language',
    CATEGORY = 'category',
    ALLOW_CATEGORY_CHANGE = 'allow_category_change',
    TEMPLATE_TYPE = 'templateType',
    COMPONENTS = 'components',
    STATUS = 'status',
    WHATS_APP_TEMPLATE_ID = 'whatsAppTemplateId',
    REASON = 'reason'
}
