import { API } from './api.class';
import { SendErrorReportRequestDTO } from '../dto/mailer/request/sendErrorReport.request.dto';

export class MailerApi extends API {
    static service = 'mailer';

    static async sendErrorReport(data: SendErrorReportRequestDTO): Promise<boolean> {
        return this.post(`sendErrorReport`, data);
    }
}
