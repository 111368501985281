<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" persistent v-model="$store.state.showCookieDialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Cookie-Einstellungen</span>
      </v-card-title>
      <v-card-text>
        <p>Wir verwenden Cookies, um Ihnen die bestmögliche Erfahrung auf unserer Website zu bieten. Sie können Ihre Cookie-Einstellungen unten anpassen:</p>
        <p>Weitere Informationen können Sie der <a :href="($router.resolve('/compliance')).href" target="_blank">Datenschutzerklärung</a> entnehmen.</p>
        <v-switch color="info" v-model="allowFunctional" disabled label="Funktionale Cookie" />
        <v-switch color="info" v-model="allowAnalytics" label="Analytics" />
      </v-card-text>
      <v-card-actions>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-btn small block color="grey" text @click="$$cookieManager.resetTrackingCookie()">Nur erforderliche Cookies</v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn small block color="info" @click="$$cookieManager.setTrackingCookie()">Speichern</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
<!--<div>-->
<!--  <v-banner color="white darken-2" elevation="2" two-line style="position: absolute; z-index: 9; bottom: 0; left: 0; right: 0;">-->
<!--    <v-avatar-->
<!--        color="primary accent-4"-->
<!--        size="40"-->
<!--    >-->
<!--      <v-icon-->
<!--          color="white"-->
<!--      >-->
<!--        mdi-cookie-->
<!--      </v-icon>-->
<!--    </v-avatar>-->
<!--    Diese Website verwendet Cookies, um Ihnen die bestmögliche Funktionalität bieten zu können.-->
<!--    <a href="">Alle Informationen finden Sie in der Datenschutzerklärung</a>-->
<!--    <template v-slot:actions>-->
<!--      <v-btn-->
<!--          text-->
<!--          @click="showSettingsDialog = true"-->
<!--      >-->
<!--        Einstellungen-->
<!--      </v-btn>-->
<!--      <v-btn-->
<!--          color="info"-->
<!--          @click="setCookieSettings({analytics: true})"-->
<!--      >-->
<!--        Zustimmen-->
<!--      </v-btn>-->
<!--    </template>-->
<!--  </v-banner>-->
<!--  <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" :value="showSettingsDialog" max-width="700" persistent @input="reset">-->
<!--    <v-card>-->
<!--      <v-card-title>-->
<!--        Cookie-Einstellungen-->
<!--      </v-card-title>-->
<!--     <v-card-text>-->
<!--       <v-switch color="info" v-model="allowFunctional" disabled label="Funktionale Cookie" />-->
<!--       <v-switch color="info" v-model="allowAnalytics" label="Analytics" />-->
<!--     </v-card-text>-->
<!--      <v-card-actions>-->
<!--        <v-btn text @click="setCookieSettings({analytics: false})">-->
<!--          Ablehnen-->
<!--        </v-btn>-->
<!--        <v-spacer />-->
<!--        <v-btn color="info" @click="setCookieSettings({analytics: true})">-->
<!--          Speichern-->
<!--        </v-btn>-->
<!--      </v-card-actions>-->
<!--    </v-card>-->
<!--  </v-dialog>-->
<!--</div>-->
</template>


<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    currentVersion: 1,
    showSettingsDialog: false,
    allowFunctional: true,
    allowAnalytics: false,
  }),
  watch: {
    '$store.state.showCookieDialog': {
      immediate: true,
      handler(val: boolean) {
        if (val) {
          const cookies = this.$$cookieManager.getCookieSettings();
          if (cookies.analytics) {
            this.allowAnalytics = true;
          }
        }
      },
    },
  },
});

</script>
