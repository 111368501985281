<template>
  <autocomplete-preset :api="api" :fields="['name', 'customId', 'status']" :resp-mapper="respMapper" :value="value"
                       v-bind="$props" @input="$emit('input', $event)"/>
</template>
<script lang="ts">
import AutocompleteMixin from '@/mixins/autocompletes/Autocomplete.mixin';
import {Institution} from '@/interfaces/institution.interface';
import {InstitutionerApi} from '@/classes/api/institutioner.api.class';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {InstitutionFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/InstitutionFields';
import {
  AcquisitionListStatusEnum,
} from "@/enums/institutioner/acquisition/AcquisitionListStatus.enum";

export default AutocompleteMixin.extend({
  props: {
    disablePotential: {type: Boolean, default: false},
  },
  computed: {
    api: () => InstitutionerApi,
  },
  methods: {
    respMapper(resp: { institutions: Institution[] }) {
      return resp.institutions.map((institution) => {
        const canAccessStatus = this.$$crudAccessManager.entity(CrudEntityTypes.INSTITUTION, institution._id).canRead(InstitutionFields.ACQUISITION_STATUS);
        const isOpen = institution.acquisitionStatus === AcquisitionListStatusEnum.OPEN;
        const statusItem = AcquisitionListStatusEnum.Record[institution.acquisitionStatus];
        const description = !canAccessStatus ? 'Kein Lesezugriff auf Standortstatus'
            : isOpen && this.disablePotential ? 'Offene Standorte können in diesem Kontext nicht ausgewählt werden'
                : statusItem?.text;
        const disabled = !canAccessStatus || isOpen && this.disablePotential;

        return {
          text: (institution.name || 'Unbekannter Standort') + ' (' + (institution.customId || 'Unbekannte ID') + ')',
          value: institution._id,
          description,
          disabled,
        };
      });
    },
  },
});
</script>
