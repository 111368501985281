<template>
  <v-autocomplete :clearable="clearable" :color="color" :dense="dense" :disabled="disabled" :filled="filled"
                  :items="items" :label="label" :loading="loading" :multiple="multiple" :no-data-text="noDataText"
                  :outlined="outlined" :rounded="rounded" :rules="rules" :search-input.sync="searchInput" no-filter
                  :item-color="color" :single-line="singleLine" cache-items :value="value" @input="handleInput"
                  @update:search-input="handleSearchInput" :prepend-icon="prependIcon" :append-outer-icon="appendOuterIcon" placeholder="Tippen um zu suchen" >
    <template v-slot:prepend-item>
      <v-subheader class="px-4">Vorgeschlagene Ergebnisse:</v-subheader>
      <v-divider/>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import Vue from 'vue';
import {SubjectManagerApi} from '@/classes/api/subject-manager.api.class';
import {sleep} from '@/helpers/sleep.helper';
import mongoose from "mongoose";

export default Vue.extend({
  props: {
    value: {
      required: false,
    },
    rules: {
      type: Array as () => Array<(v: any) => boolean | string>,
      default: () => ([]),
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      required: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Suche',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    throttled: {
      type: Boolean,
      default: true,
    },
    throttleDelay: {
      type: Number,
      default: 500,
    },
    prefetch: {
      type: Boolean,
      default: false,
    },
    prefetchCount: {
      type: Number,
      default: 5,
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendOuterIcon: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    searchInput: '',
    items: [] as Array<{ text: string; value: mongoose.Types.ObjectId }>,
    loading: false,
    throttleCounter: 0,
  }),
  computed: {
    noDataText() {
      if (!this.searchInput) {
        return 'Nach Namen suchen';
      } else {
        return 'Keine Daten vorhanden';
      }
    },
  },
  methods: {
    async handleSearchInput() {
      this.loading = true;
      if (this.throttled) {
        this.throttleCounter++;
        await sleep(this.throttleDelay);
        this.throttleCounter--;
        if (this.throttleCounter !== 0) {
          return;
        }
      }
      if (this.searchInput) {
        await this.getItems();
      }
      this.loading = false;
    },
    async getItems() {
      const resp = await SubjectManagerApi.find({
        skipPagination: true,
        filter: { $search: this.searchInput },
        fields: ['name'],
      });
      this.items = resp.subjects.map((subject) => ({
        text: subject.name,
        value: subject._id,
      }));
    },
    handleInput(v: any) {
      this.$emit('input', v);
      this.searchInput = '';
    },
    async getInitialValues() {
      if (this.value) {
        const idFilter: mongoose.Types.ObjectId[] = [];
        if (Array.isArray(this.value)) {
          idFilter.push(...this.value);
        } else {
          idFilter.push(this.value as mongoose.Types.ObjectId);
        }
        const resp = await SubjectManagerApi.find({skipPagination: true, filter: {_id: idFilter}, fields: ['name']});
        this.items = resp.subjects.map((subject) => ({
          text: subject.name,
          value: subject._id,
        }));
      }
      if (this.prefetch) {
        const prefetchResp = await SubjectManagerApi.find({limit: this.prefetchCount, fields: ['name']});
        for (const subject of prefetchResp.subjects) {
          const found = this.items.find((item) => item.value.toString() === subject._id.toString());
          if (!found) {
            this.items.push({text: subject.name, value: subject._id});
          }
        }
      }
    },
  },
  mounted() {
    this.getInitialValues();
  },
});
</script>
