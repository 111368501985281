import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {JobAdFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/JobAdFields';

export const jobAdFieldsDictionary: FieldDictionary<JobAdFields> = [{
    text: 'Titel',
    value: JobAdFields.JOB_TITLE,
}, {
   text: 'Startdatum',
    value: JobAdFields.START_DATE,
}, {
    text: 'Enddatum',
    value: JobAdFields.END_DATE,
}, {
    text: 'Firmenbeschreibung',
    value: JobAdFields.COMPANY_DESCRIPTION,
}, {
    text: 'Aufgabenbeschreibung',
    value: JobAdFields.TASK_DESCRIPTION,
}, {
    text: 'Qualifikationen',
    value: JobAdFields.EMPLOYEE_REQUIREMENTS,
}, {
    text: 'Firmenleistungen',
    value: JobAdFields.COMPANY_BENEFITS,
}, {
    text: 'Standorte',
    value: JobAdFields.JOB_LOCATIONS,
}, {
    text: 'Gehalt',
    value: JobAdFields.SALARY,
}, {
    text: 'Firmenkontakt',
    value: JobAdFields.COMPANY_CONTACT,
}, {
    text: 'Veröffentlichungsdaten',
    value: JobAdFields.PUBLICATION_DATA,
}, {
    text: 'Veröffentlicht auf',
    value: JobAdFields.PUBLISHED_ON,
    readOnly: true,
}, {
    text: 'Veröffentlichungsreferenzen',
    value: JobAdFields.PUBLICATION_REFS,
    readOnly: true,
}, {
    text: 'Lokalisierung',
    value: JobAdFields.LOCALIZATION,
}, {
    text: 'Metadaten',
    value: JobAdFields.METADATA,
    readOnly: true,
}];
