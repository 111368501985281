export enum EventInfoFields {
    START_TIME = 'startTime',
    DURATION_IN_MINUTES = 'durationInMinutes',
    TOPIC = 'topic',
    ORGANIZER_LIST = 'organizerList',
    PARTICIPANT_LIST = 'participantList',
    VENUE = 'venue',
    IS_CANCELLED = 'isCancelled',
    IS_PUBLIC = 'isPublic',
}
