import FieldDictionary from "@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary";
import {TerminationFields} from "@/classes/clientOnly/permissionTreeResources/enums/entityFields/TerminationFields";

export const terminationFieldsDictionary: FieldDictionary<TerminationFields> = [
    {
        text: 'Grund',
        value: TerminationFields.TERMINATION_REASON,
    },
    {
        text: 'Beschreibung',
        value: TerminationFields.DESCRIPTION,
    },
    {
        text: 'Datum der Beendigung der Abrechnung',
        value: TerminationFields.DEACTIVATE_INVOICES_AT,
    },
    {
        text: 'Deaktivierungsdatum der Kund:in',
        value: TerminationFields.DEACTIVATE_CUSTOMER_AT,
    },
    {
        text: 'Deaktivierungsdatum der Schüler:in',
        value: TerminationFields.DEACTIVATE_STUDENT_AT,
    },
    {
        text: 'Deaktivierungsdatum der Kurse',
        value: TerminationFields.DEACTIVATE_COURSES_AT,
    },
    {
        text: 'Reaktivierungsdatum',
        value: TerminationFields.REACTIVATE_AT,
    },
    {
        text: 'Ist temporär?',
        value: TerminationFields.IS_TEMPORARY,
    },
    {
        text: 'Fälligkeitsdatum',
        value: TerminationFields.DUE_DATE,
    },
    {
        text: 'Mail',
        value: TerminationFields.MAIL,
    },
    {
        text: 'Kund:in',
        value: TerminationFields.CUSTOMER,
    },
    {
        text: 'Schüler:in',
        value: TerminationFields.STUDENT,
    },
    {
        text: 'Lehrkraft',
        value: TerminationFields.TEACHERS,
    },
    {
        text: 'Ersteller:in',
        value: TerminationFields.CREATOR,
    },
    {
        text: 'Erstellt am',
        value: TerminationFields.CREATED_AT,
        readOnly: true,
    },
    {
        text: 'Bearbeitet am',
        value: TerminationFields.UPDATED_AT,
        readOnly: true,
    },
    {
        text: 'Abrechnungsbelege',
        value: TerminationFields.INVOICE_DOCUMENTS,
    },
    {
        text: 'Wiederkehrende Belege',
        value: TerminationFields.RECURRING_DOCUMENTS,
    },
    {
        text: 'Erledigt?',
        value: TerminationFields.DONE,
        readOnly: true,
    },
];
