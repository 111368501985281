<template>
  <v-tooltip :disabled="!isEllipsisActive" :max-width="tooltipMaxWidth" bottom eager transition="slide-y-transition">
    <template v-slot:activator="{on}">
      <div ref="container" :style="{'max-width': maxWidth ? `${maxWidth}px` : '', display: inline ? 'inline-block' : 'block'}" class="text-container" v-on="on">
        {{ text }}
      </div>
    </template>
    {{ text }}
  </v-tooltip>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    text: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: Number,
      required: false,
    },
    tooltipMaxWidth: {
      type: Number,
      default: 300,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isEllipsisActive: false,
  }),
  methods: {
    checkEllipsis() {
      const container = this.$refs.container as HTMLElement;
      if (container) {
        this.isEllipsisActive = container.offsetWidth < container.scrollWidth;
      } else {
        this.isEllipsisActive = false;
      }
    },
  },
  watch: {
    text() {
      this.$nextTick(() => {
        this.checkEllipsis();
      });
    },
  },
  async mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.checkEllipsis();
        window.addEventListener('resize', this.checkEllipsis);
      });
    }, 500);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkEllipsis);
  },
});
</script>

<style scoped>
.text-container {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
