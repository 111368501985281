export enum OptimizationFields {
    EMPLOYEE = 'employee',
    COURSE = 'course',
    INSTITUTION = 'institution',
    DATE = 'date',
    SCORE = 'score',
    PROFIT = 'Profit',
    PROFIT_GAIN = 'ProfitGain',
    CONFIRMED_BY = 'confirmed_by',
    COMMITTED = 'committed',
    COMMITTED_BY = 'committed_by',
}
