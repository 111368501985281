<template>
  <v-badge :value="!notification.readAt" color="info" dot offset-x="25" offset-y="20" style="width: 100%">
    <v-list-item :class="{ 'info lighten-5': !notification.readAt }">
      <v-list-item-avatar>
        <v-icon color="info">mdi-information</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle>
          <span v-if="notification.messagePayload.authorType === 'institution'">
            Standort
          </span>
          <span v-else>
            Werbende Person
          </span>
          hat Feedback
          <span v-if="notification.messagePayload.actionType === 'create'">
            abgegeben
          </span>
          <span v-else>
            bearbeitet
          </span>
        </v-list-item-subtitle>
        <v-list-item-title>
          <overflow-text :text="`Austeilaktion ${notification.messagePayload.institutionName}`"/>
        </v-list-item-title>
        <v-list-item-subtitle v-if="!notification.dismissedAt">
          {{ moment(notification.createdAt).fromNow() }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          <v-icon small>mdi-delete</v-icon>
          {{ moment(notification.dismissedAt).fromNow() }} gelöscht
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn :href="link" icon target="_blank" v-on="on">
              <v-icon>mdi-page-next-outline</v-icon>
            </v-btn>
          </template>
          <span>Ansehen</span>
        </v-tooltip>
      </v-list-item-action>
      <v-list-item-action v-if="!notification.dismissedAt" class="ml-0">
        <v-btn icon @click="$emit('dismiss', notification._id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action v-else class="ml-0">
        <v-btn icon @click="$emit('revoke', notification._id)">
          <v-icon>mdi-delete-off</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-badge>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import moment from 'moment';
import OverflowText from '@/components/common/overflow-text.vue';
import {
  HandoutPlannerEventFeedbackNotificationDocument,
} from '@/documentTypes/notifier/discriminators/handoutPlanner/HandoutPlannerEventFeedbackNotification.document';

moment.locale('de');

export default Vue.extend({
  components: {OverflowText},
  props: {
    notification: {
      type: Object as () => NotificationLike<HandoutPlannerEventFeedbackNotificationDocument>,
      required: true,
    },
  },
  computed: {
    moment: () => moment,
    link(): string {
      return this.$router.resolve(`/planning/handout-events/${this.notification.messagePayload.handoutEventId}`).href;
    },
  },
});
</script>
