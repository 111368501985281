export enum ApiTypesEnum {
    FAST_BILL = 'fast_bill',
    DHL_RETOURE = 'dhl_retoure',
    DHL_SHIPMENT = 'dhl_shipment',
    DHL_TRACKING = 'dhl_tracking',
    DHL_DEUTSCHE_POST = 'dhl_deutsche_post',
    JOIN = 'join',
    WORD_PRESS = 'word_press',
    INTERNETMARKE = 'internetmarke',
    PLACETEL = 'placetel',
    WHATS_APP = 'whats_app',
    MAIL = 'mail',
}

export enum ApiTypesLabels {
    ACCOUNTING = 'Buchhaltung',
    INVOICES = 'Rechnungen',
    LOGISTICS = 'Logistik',
    RETURNS = 'Retoure',
    PACKAGES = 'Pakete',
    TRACKING = 'Sendungsverfolgung',
    LETTERS = 'Briefe',
    WEBSITE = 'Website',
    RECRUITING = 'Recruiting',
    APPLICANT_TRACKING = 'Bewerbermanagement',
    CMS = 'CMS',
    SHIPPING = 'Versand',
    COMMUNICATION = 'Kommunikation',
    PHONE = 'Telefonie',
}

export const ApiTypesItems = [
    {
        text: 'FastBill',
        value: ApiTypesEnum.FAST_BILL,
        description: 'Rechnungen mit FastBill erstellen',
        color: '#82b91e',
        dark: true,
        icon: 'mdi-cash',
        labels: [ApiTypesLabels.ACCOUNTING, ApiTypesLabels.INVOICES],
    },
    {
        text: 'DHL Retoure',
        value: ApiTypesEnum.DHL_RETOURE,
        description: 'Retouren mit DHL erstellen',
        color: '#ffcc00',
        dark: true,
        icon: 'mdi-package',
        labels: [ApiTypesLabels.LOGISTICS, ApiTypesLabels.RETURNS, ApiTypesLabels.SHIPPING, ApiTypesLabels.PACKAGES],
    },
    {
        text: 'DHL Sendung',
        value: ApiTypesEnum.DHL_SHIPMENT,
        description: 'Pakete mit DHL versenden',
        color: '#ffcc00',
        dark: true,
        icon: 'mdi-package',
        labels: [ApiTypesLabels.LOGISTICS, ApiTypesLabels.PACKAGES, ApiTypesLabels.SHIPPING],
    }, {
        text: 'DHL Tracking',
        value: ApiTypesEnum.DHL_TRACKING,
        description: 'Sendungsverfolgung mit DHL',
        color: '#ffcc00',
        dark: true,
        icon: 'mdi-package',
        labels: [ApiTypesLabels.LOGISTICS, ApiTypesLabels.TRACKING],
    },
    {
        text: 'DHL Deutsche Post',
        value: ApiTypesEnum.DHL_DEUTSCHE_POST,
        description: 'Briefe mit DHL Deutsche Post versenden',
        color: '#ffcc00',
        dark: true,
        icon: 'mdi-email-newsletter',
        labels: [ApiTypesLabels.LOGISTICS, ApiTypesLabels.LETTERS, ApiTypesLabels.SHIPPING],
    },
    {
        text: 'WordPress',
        value: ApiTypesEnum.WORD_PRESS,
        color: '#21759b',
        dark: true,
        icon: 'mdi-wordpress',
        description: 'Gib Vanilla Zugriff auf deine WordPress Website',
        labels: [ApiTypesLabels.WEBSITE, ApiTypesLabels.CMS, ApiTypesLabels.APPLICANT_TRACKING, ApiTypesLabels.RECRUITING],
    },
    {
        text: 'Join',
        value: ApiTypesEnum.JOIN,
        description: 'Stellenanzeigen- und Bewerbermanagement mit Join',
        color: '#2a73ff',
        dark: true,
        icon: 'mdi-briefcase',
        labels: [ApiTypesLabels.APPLICANT_TRACKING, ApiTypesLabels.RECRUITING],
    },
    {
        text: 'Internetmarke',
        value: ApiTypesEnum.INTERNETMARKE,
        description: 'Portokasse mit Internetmarke',
        color: '#ffcc00',
        dark: true,
        icon: 'mdi-stamp',
        labels: [ApiTypesLabels.LOGISTICS, ApiTypesLabels.SHIPPING, ApiTypesLabels.LETTERS, ApiTypesLabels.PACKAGES, ApiTypesLabels.COMMUNICATION],
    },
    {
        text: 'Placetel',
        value: ApiTypesEnum.PLACETEL,
        description: 'Telefonie mit Placetel',
        color: '#009ee2',
        dark: true,
        icon: 'mdi-phone',
        labels: [ApiTypesLabels.COMMUNICATION, ApiTypesLabels.PHONE],
    },
    {
        text: 'WhatsApp',
        value: ApiTypesEnum.WHATS_APP,
        description: 'WhatsApp-Nachrichten senden und empfangen',
        color: '#25d366',
        dark: true,
        icon: 'mdi-whatsapp',
        labels: [ApiTypesLabels.COMMUNICATION, ApiTypesLabels.PHONE],
    },
    {
        text: 'E-Mail',
        value: ApiTypesEnum.MAIL,
        description: 'E-Mail-Nachrichten senden und empfangen',
        color: '#127CD6',
        dark: true,
        icon: 'mdi-mail',
        labels: [ApiTypesLabels.COMMUNICATION],
    },
];
