import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {StatsFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/StatsFields';

export const statsFieldsDictionary: FieldDictionary<StatsFields> = [
    {
        text: 'Entwicklung der Einnahmen',
        readOnly: true,
        value: StatsFields.INCOME,
    },
    {
        text: 'Entwicklung der Schülerzahl',
        readOnly: true,
        value: StatsFields.NUMBER_OF_STUDENTS,
    },
    {
        text: 'Unrentable Kurse',
        readOnly: true,
        value: StatsFields.UNPROFITABLE_COURSES,
    },
    {
        text: 'Lehrkräfte ohne Kurse',
        readOnly: true,
        value: StatsFields.TEACHERS_WITHOUT_COURSES,
    },
    {
        text: 'Standorte ohne Kurse',
        readOnly: true,
        value: StatsFields.INSTITUTIONS_WITHOUT_COURSES,
    },
    {
        text: 'Kündigungsstatistik der Lehrkräfte',
        readOnly: true,
        value: StatsFields.TEACHER_TERMINATION_STATS,
    },
    {
        text: 'Akquiseleistung der Mitarbeitenden',
        readOnly: true,
        value: StatsFields.EMPLOYEE_ACQUISITION_STATS,
    },
];
