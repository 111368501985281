import Vue from 'vue';
import {RecorderEventPayloads, RecorderEvents} from '@/enums/notifier/serviceEvents/RecorderEvents';

interface RecorderEventBus {
    $emit(event: RecorderEvents.NEW_LOG_ENTRY, payload: RecorderEventPayloads[RecorderEvents.NEW_LOG_ENTRY]): void;

    $emit(event: RecorderEvents.UPDATE_LOG_ENTRY, payload: RecorderEventPayloads[RecorderEvents.UPDATE_LOG_ENTRY]): void;

    $emit(event: RecorderEvents.REMOVE_LOG_ENTRY, payload: RecorderEventPayloads[RecorderEvents.REMOVE_LOG_ENTRY]): void;

    $on(event: RecorderEvents.NEW_LOG_ENTRY, callback: (payload: RecorderEventPayloads[RecorderEvents.NEW_LOG_ENTRY]) => void): void;

    $on(event: RecorderEvents.UPDATE_LOG_ENTRY, callback: (payload: RecorderEventPayloads[RecorderEvents.UPDATE_LOG_ENTRY]) => void): void;

    $on(event: RecorderEvents.REMOVE_LOG_ENTRY, callback: (payload: RecorderEventPayloads[RecorderEvents.REMOVE_LOG_ENTRY]) => void): void;
}

export const RecorderEventBus: RecorderEventBus = new Vue();
