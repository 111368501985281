
export const avataaarBackgroundType = [
    {
        text: 'kreisförmig',
        value: 'circle',
    },
    {
        text: 'transparent',
        value: 'transparent',
    },
];

export const avataaarSkinColor = [
    {
        text: 'gebräunt',
        value: 'tanned',
    },
    {
        text: 'gelblich',
        value: 'yellow',
    },
    {
        text: 'pale',
        value: 'pale',
    },
    {
        text: 'hell',
        value: 'light',
    },
    {
        text: 'braun',
        value: 'brown',
    },
    {
        text: 'dunkelbraun',
        value: 'darkBrown',
    },
    {
        text: 'dunkel',
        value: 'dark',
    },
];

export const avataaarClothesType = [
    {
        text: 'Sakko und T-Shirt',
        value: 'blazerShirt',
    },
    {
        text: 'Sakko und Poloshirt',
        value: 'blazerSweater',
    },
    {
        text: 'Rollkragenpullover',
        value: 'collarSweater',
    },
    {
        text: 'T-Shirt mit Aufdruck',
        value: 'graphicShirt',
    },
    {
        text: 'Hoodie',
        value: 'hoodie',
    },
    {
        text: 'Tanktop',
        value: 'overall',
    },
    {
        text: 'T-Shirt mit rundem Ausschnitt',
        value: 'shirtCrewNeck',
    },
    {
        text: 'T-Shirt mit Ausschnitt',
        value: 'shirtScoopNeck',
    },
    {
        text: 'T-Shirt mit V-Ausschnitt',
        value: 'shirtVNeck',
    },
];

export const avataaarClothesColors = [
    {
        text: 'schwarz',
        value: 'black',
    },
    {
        text: 'hellblau',
        value: 'blue01',
    },
    {
        text: 'blau',
        value: 'blue02',
    },
    {
        text: 'dunkelblau',
        value: 'blue03',
    },
    {
        text: 'hellgrau',
        value: 'gray01',
    },
    {
        text: 'dunkelgrau',
        value: 'gray02',
    },
    {
        text: 'blaugrau',
        value: 'heather',
    },
    {
        text: 'pastellblau',
        value: 'pastelBlue',
    },
    {
        text: 'pastellgrün',
        value: 'pastelGreen',
    },
    {
        text: 'pastellorange',
        value: 'pastelOrange',
    },
    {
        text: 'pastellrot',
        value: 'pastelRed',
    },
    {
        text: 'pastellgelb',
        value: 'pastelYellow',
    },
    {
        text: 'pink',
        value: 'pink',
    },
    {
        text: 'rot',
        value: 'red',
    },
    {
        text: 'weiß',
        value: 'white',
    },
];

export const avataaarClothesGraphicsType = [
    {
        text: 'Kein Aufdruck',
        value: 'none',
    },
    {
        text: 'Fledermaus',
        value: 'bat',
    },
    {
        text: 'Cumbia',
        value: 'cumbia',
    },
    {
        text: 'Diamant',
        value: 'diamond',
    },
    {
        text: 'Pizza',
        value: 'pizza',
    },
    {
        text: 'Resist',
        value: 'resist',
    },
    {
        text: 'Selena',
        value: 'selena',
    },
    {
        text: 'Bär',
        value: 'bear',
    },
    {
        text: 'Totenkopfumriss',
        value: 'skullOutline',
    },
    {
        text: 'Totenkopf',
        value: 'skull',
    },
];

export const avataaarEyebrowType = [
    {
        text: 'künstlich verärgert',
        value: 'angry',
    },
    {
        text: 'verärgert',
        value: 'angryNatural',
    },
    {
        text: 'normal',
        value: 'default',
    },
    {
        text: 'natürlich',
        value: 'defaultNatural',
    },
    {
        text: 'flach natürlich',
        value: 'flatNatural',
    },
    {
        text: 'gerunzelt',
        value: 'frownNatural',
    },
    {
        text: 'künstlich hochgezogen',
        value: 'raisedExcited',
    },
    {
        text: 'hochgezogen',
        value: 'raisedExcitedNatural',
    },
    {
        text: 'künstlich traurig',
        value: 'sadConcerned',
    },
    {
        text: 'traurig',
        value: 'sadConcernedNatural',
    },
    {
        text: 'mono',
        value: 'unibrowNatural',
    },
    {
        text: 'künstlich oben/unten',
        value: 'updown',
    },
    {
        text: 'oben/unten',
        value: 'updownNatural',
    },
];

export const avataaarEyesType = [
    {
        text: 'geschlossen',
        value: 'close',
    },
    {
        text: 'weinend',
        value: 'cry',
    },
    {
        text: 'normal',
        value: 'default',
    },
    {
        text: 'verwirrt',
        value: 'dizzy',
    },
    {
        text: 'augenrollend',
        value: 'eyeroll',
    },
    {
        text: 'fröhlich',
        value: 'happy',
    },
    {
        text: 'Herzaugen',
        value: 'hearts',
    },
    {
        text: 'zur Seite schauend',
        value: 'side',
    },
    {
        text: 'schielend',
        value: 'squint',
    },
    {
        text: 'überrascht',
        value: 'surprised',
    },
    {
        text: 'zwinkernd',
        value: 'wink',
    },
    {
        text: 'stark zwinkernd',
        value: 'winkWacky',
    },
];

export const avataaarMouthType = [
    {
        text: 'besorgt',
        value: 'concerned',
    },
    {
        text: 'normal',
        value: 'default',
    },
    {
        text: 'misstrauisch',
        value: 'disbelief',
    },
    {
        text: 'kauend',
        value: 'eating',
    },
    {
        text: 'Grimasse',
        value: 'grimace',
    },
    {
        text: 'schreiend',
        value: 'screamOpen',
    },
    {
        text: 'traurig',
        value: 'sad',
    },
    {
        text: 'ernst',
        value: 'serious',
    },
    {
        text: 'lachend',
        value: 'smile',
    },
    {
        text: 'Zunge rausstreckend',
        value: 'tongue',
    },
    {
        text: 'lächelnd',
        value: 'twinkle',
    },
    {
        text: 'brechend',
        value: 'vomit',
    },
];

export const avataaarFacialHairType = [
    {
        text: 'Glatt',
        value: 'none',
    },
    {
        text: 'Voller Bart',
        value: 'beardMedium',
    },
    {
        text: 'Getrimmter Bart',
        value: 'beardLight',
    },
    {
        text: 'Vollbart',
        value: 'beardMajestic',
    },
    {
        text: 'Schnauzer dezent',
        value: 'moustacheFancy',
    },
    {
        text: 'Schnauzer',
        value: 'moustacheMagnum',
    },
];

export const avataaarFacialHairColor = [
    {
        text: 'rotbraun',
        value: 'auburn',
    },
    {
        text: 'schwarz',
        value: 'black',
    },
    {
        text: 'blond',
        value: 'blonde',
    },
    {
        text: 'hellblond',
        value: 'blondeGolden',
    },
    {
        text: 'braun',
        value: 'brown',
    },
    {
        text: 'dunkelbraun',
        value: 'brownDark',
    },
    {
        text: 'weiß',
        value: 'platinum',
    },
    {
        text: 'rot',
        value: 'red',
    },
];

export const avataaarAccessoriesType = [
    {
        text: 'Kein Accessoire',
        value: 'none',
    },
    {
        text: 'Augenklappe',
        value: 'eyepatch',
    },
    {
        text: 'Spaßbrille',
        value: 'kurt',
    },
    {
        text: 'Brille weiß',
        value: 'prescription01',
    },
    {
        text: 'Brille schwarz',
        value: 'prescription02',
    },
    {
        text: 'Brille mit unden Gläsern',
        value: 'round',
    },
    {
        text: 'Sonnenbrille',
        value: 'sunglasses',
    },
    {
        text: 'getönte Brille',
        value: 'wayfarers',
    },
];

export const avataaarTopType = [
    {
        text: 'Glatze',
        value: 'none',
    },
    {
        text: 'Lange Haare',
        value: 'longHairBigHair',
    },
    {
        text: 'Bob',
        value: 'longHairBob',
    },
    {
        text: 'Dutt',
        value: 'longHairBun',
    },
    {
        text: 'Lockige Haare',
        value: 'longHairCurly',
    },
    {
        text: 'Lange Haare gewellt',
        value: 'longHairCurvy',
    },
    {
        text: 'Lange Dreadlocks',
        value: 'longHairDreads',
    },
    {
        text: 'Afro',
        value: 'longHairFro',
    },
    {
        text: 'Afro mit Stirnband',
        value: 'longHairFroBand',
    },
    {
        text: 'Schulterlang mit Scheitel',
        value: 'longHairNotTooLong',
    },
    {
        text: 'Lange Haare mit Undercut',
        value: 'longHairShavedSides',
    },
    {
        text: 'Schulterlang',
        value: 'longHairMiaWallace',
    },
    {
        text: 'Lange Haare glatt (1)',
        value: 'longHairStraight',
    },
    {
        text: 'Lange Haare glatt (2)',
        value: 'longHairStraight2',
    },
    {
        text: 'Lange Haare glatt (3)',
        value: 'longHairStraightStrand',
    },
    {
        text: 'Kurze Haare mit Dreads (1)',
        value: 'shortHairDreads01',
    },
    {
        text: 'Kurze Haare mit Dreads (2)',
        value: 'shortHairDreads02',
    },
    {
        text: 'Kurze Haare mit Undercut',
        value: 'shortHairFrizzle',
    },
    {
        text: 'Schulterlang mit prominentem Scheitel',
        value: 'shortHairShaggyMullet',
    },
    {
        text: 'Kurze Haare gewellt',
        value: 'shortHairShortCurly',
    },
    {
        text: 'Kurzhaarschnitt',
        value: 'shortHairShortFlat',
    },
    {
        text: 'Kurzhaarschnitt (flach)',
        value: 'shortHairShortRound',
    },
    {
        text: 'Kurzhaarschnitt (voluminös)',
        value: 'shortHairShortWaved',
    },
    {
        text: ' Halbglatze',
        value: 'shortHairSides',
    },
    {
        text: 'Kurzhaarschnitt (minimal)',
        value: 'shortHairTheCaesar',
    },
    {
        text: 'Kurzhaarschnitt (minimal mit Scheitel)',
        value: 'shortHairTheCaesarSidePart',
    },
    {
        text: 'Hut',
        value: 'hat',
    },
    {
        text: 'Kopftuch',
        value: 'hijab',
    },
    {
        text: 'Turban',
        value: 'turban',
    },
    {
        text: 'Wintermütze 1',
        value: 'winterHat1',
    },
    {
        text: 'Wintermütze 2',
        value: 'winterHat2',
    },
    {
        text: 'Wintermütze 3',
        value: 'winterHat3',
    },
    {
        text: 'Wintermütze 4',
        value: 'winterHat4',
    },
];

export const avataaarTopColor  = [
    {
        text: 'rotbraun',
        value: 'auburn',
    },
    {
        text: 'schwarz',
        value: 'black',
    },
    {
        text: 'blond',
        value: 'blonde',
    },
    {
        text: 'hellblond',
        value: 'blondeGolden',
    },
    {
        text: 'braun',
        value: 'brown',
    },
    {
        text: 'dunkelbraun',
        value: 'brownDark',
    },
    {
        text: 'weiß',
        value: 'platinum',
    },
    {
        text: 'rot',
        value: 'red',
    },
    {
        text: 'hellblau',
        value: 'blue01',
    },
    {
        text: 'blau',
        value: 'blue02',
    },
    {
        text: 'dunkelblau',
        value: 'blue03',
    },
    {
        text: 'hellgrau',
        value: 'gray01',
    },
    {
        text: 'dunkelgrau',
        value: 'gray02',
    },
    {
        text: 'blaugrau',
        value: 'heather',
    },
    {
        text: 'pastellblau',
        value: 'pastelBlue',
    },
    {
        text: 'pastellgrün',
        value: 'pastelGreen',
    },
    {
        text: 'pastellorange',
        value: 'pastelOrange',
    },
    {
        text: 'pastellrot',
        value: 'pastelRed',
    },
    {
        text: 'pastellgelb',
        value: 'pastelYellow',
    },
    {
        text: 'pink',
        value: 'pink',
    },
    {
        text: 'rot',
        value: 'red',
    },
    {
        text: 'weiß',
        value: 'white',
    },
];
