import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum GoogleTravelModesEnum {
    DRIVING = 'driving',
    WALKING = 'walking',
    BICYCLING = 'bicycling',
    TRANSIT = 'transit',
}

export namespace GoogleTravelModesEnum {
    type Data = {
        text: string;
        icon: string;
        readable: string;
    };

    export const Record: Record<GoogleTravelModesEnum, Data> = {
        [GoogleTravelModesEnum.DRIVING]: {
            text: 'Auto',
            icon: 'mdi-car',
            readable: 'mit dem Auto',
        },
        [GoogleTravelModesEnum.WALKING]: {
            text: 'Fußweg',
            icon: 'mdi-walk',
            readable: 'zu Fuß',
        },
        [GoogleTravelModesEnum.BICYCLING]: {
            text: 'Fahrrad',
            icon: 'mdi-bicycle',
            readable: 'mit dem Fahrrad',
        },
        [GoogleTravelModesEnum.TRANSIT]: {
            text: 'ÖPNV',
            icon: 'mdi-bus',
            readable: 'mit öffentlichen Verkehrsmitteln',
        },
    };

    export const Items = mapRecordToItems(Record);
}
