<template>
  <v-lazy v-model="isVisible" :min-height="minHeight" transition="slide-y-transition" :key="retrieveObjectId(message)">
    <div>
      <v-skeleton-loader v-if="initializing" :height="minHeight" :style="{ height: minHeight + 'px' }" type="image"/>
      <v-card v-else-if="hasError && !populatedMessage">
        <v-card-title>
          Fehler beim Abrufen der Nachricht
        </v-card-title>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Die Nachricht konnte nicht vom Server abgerufen werden.
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon @click="initialize" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Erneut versuchen</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
      <div v-else-if="populatedMessage">
        <mail-message-card v-if="populatedMessage.type === MessengerTypesEnum.MAIL" :key="message._id"
                           :is-own="isOwn"
                           :message="message"/>
        <whats-app-message-card v-else-if="populatedMessage.type === MessengerTypesEnum.WHATS_APP" :key="message._id"
                                :is-own="isOwn"
                                :message="message"/>
        <v-card v-else>
          <v-card-text>
            Die Art dieser Nachricht ist unbekannt: {{ populatedMessage.type }}
          </v-card-text>
        </v-card>
      </div>
      <div v-else class="error--text">
        ERROR {{message}} : {{isVisible}}
      </div>
    </div>
  </v-lazy>
</template>
<script lang="ts">
import Vue from 'vue';
import {MessengerMailDocument, MessengerWhatsAppMessageDocument} from '@/documentTypes/messenger/messages';
import mongoose, {LeanDocument} from 'mongoose';
import {isObjectId, retrieveObjectId} from '@/helpers/isObjectId.helper';
import {MessengerApi} from '@/classes/api/messenger.api';
import {MessengerTypesEnum} from '@/enums/messenger/MessengerTypes.enum';
import {sleep} from '@/helpers/sleep.helper';
import MailMessageCard from '@/components/app/messagingComponents/messageCards/mailMessageCard.vue';
import WhatsAppMessageCard from '@/components/app/messagingComponents/messageCards/whatsAppMessageCard.vue';

export default Vue.extend({
  components: {WhatsAppMessageCard, MailMessageCard},
  props: {
    message: {
      type: Object as () => LeanDocument<MessengerMailDocument> | LeanDocument<MessengerWhatsAppMessageDocument> | mongoose.Types.ObjectId,
      required: true,
    },
  },
  data: () => ({
    initializing: false,
    hasError: false,
    isVisible: false,
  }),
  computed: {
    retrieveObjectId: () => retrieveObjectId,
    MessengerTypesEnum: () => MessengerTypesEnum,
    populatedMessage(): LeanDocument<MessengerMailDocument> | LeanDocument<MessengerWhatsAppMessageDocument> | undefined {
      if (!isObjectId(this.message)) {
        return this.message;
      }
      return undefined;
    },
    minHeight(): number {
      if (!this.populatedMessage) {
        return 250;
      }
      const div = document.createElement('div');
      div.classList.add('v-application');
      div.style.display = 'block';
      const messagingContainer = document.getElementById('vanilla-messaging-dialog-message-container');
      if (messagingContainer) {
        const width = messagingContainer.clientWidth;
        div.style.maxWidth = `${width * 11 / 12}px`;
      } else {
        div.style.maxWidth = '600px';
      }
      const sampleMessage = this.populatedMessage as Partial<LeanDocument<MessengerWhatsAppMessageDocument> & LeanDocument<MessengerMailDocument>>

      if (sampleMessage.subject) {
        div.innerHTML += `<div>${sampleMessage.subject}</div>`;
      }
      if (sampleMessage.header) {
        div.innerHTML += `<div>${sampleMessage.header}</div>`;
      }
      div.innerHTML += sampleMessage.message;
      if (sampleMessage.footer) {
        div.innerHTML += `<div>${sampleMessage.footer}</div>`;
      }
      document.body.appendChild(div);
      const height = div.clientHeight;
      document.body.removeChild(div);
      div.remove();
      return Math.max(height, 80);
    },
    isOwn(): boolean {
      const sampleMessage = this.populatedMessage as Partial<LeanDocument<MessengerWhatsAppMessageDocument> & LeanDocument<MessengerMailDocument>>
      switch (sampleMessage.type) {
        case MessengerTypesEnum.MAIL:
          return sampleMessage.username === sampleMessage.fromMail;
        case MessengerTypesEnum.WHATS_APP:
          return sampleMessage.phoneNumber === sampleMessage.fromNumber;
        default:
          return false;
      }
    },
  },
  methods: {
    async initialize() {
      if (this.populatedMessage) {
        return;
      }
      this.initializing = true;
      this.hasError = false;
      await sleep(200);
      try {
        const message = await MessengerApi.messages.findById(retrieveObjectId(this.message));
        this.$emit('update:message', message);
      } catch (e) {
        this.hasError = true;
        this.$$showSnackbar('Fehler beim Abrufen einer Nachricht', 'error');
      } finally {
        this.initializing = false;
      }
    },
  },
  watch: {
    isVisible: {
      handler() {
        if (isObjectId(this.message)) {
          this.initialize();
        }
      },
    },
    message: {
      deep: true,
      handler(v) {
        if (isObjectId(v)) {
          this.initialize();
        }
      },
    },
  },
});
</script>
