export enum WarehouseOrderFields {
    ARTICLE = 'article',
    QTY = 'qty',
    REF = 'ref',
    ALTERNATE_REF = 'alternateRef',
    REF_TYPE = 'refType',
    STATE = 'state',
    FULFILLED_AT = 'fulfilledAt',
    FULFILLED_BY = 'fulfilledBy',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
    SHIPMENT_LABEL = 'shipmentLabel',
}
