<template>
  <v-dialog
      :value="value"
      max-width="500px"
      @click:outside="$emit('input', false)"
      @keydown.esc="$emit('input', false)"
      :overlay="false"
  >
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <profile-avatar :user="responsible" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ toSalutation(responsible.gender) }}
              <span v-can:read-responsibles="{ id: responsible._id, keys: ['firstName'] }">{{responsible.firstName}}</span>
              {{responsible.lastName}}
            </v-list-item-title>
            <v-list-item-subtitle>
            <span v-can:read-responsibles="{ id: responsible._id, keys: ['jobType'] }">
              {{getJobTypeString(responsible)}}
            </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
                <v-btn
                    color="primary"
                    icon
                    @click="$emit('input', false)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-list>
        <contact-data-list-items :entity="responsible" v-can:read-responsibles="{ id: responsible._id, keys: ['contactInformation'] }"/>
        <v-divider />

        <!-- Birthday -->
        <v-list-item v-can:read-responsibles="{ id: responsible._id, keys: ['birthday'] }">
          <v-list-item-icon>
            <v-icon>mdi-cake-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ responsible.birthday ? moment(responsible.birthday).format('DD. MM YYYY') : 'Geburtsdatum unbekannt' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-can:read-responsibles="{ id: responsible._id, keys: ['birthday'] }"/>
      </v-list>
      <v-card-actions v-can:update-institutions="{keys: ['responsibles'], id: this.institutionId}">
        <v-tooltip right v-can:custom-shareAccess>
          <template v-slot:activator="{on}">
            <v-btn small outlined rounded @click="shareAccess" color="info" v-on="on"><v-icon small>mdi-shield-lock-open</v-icon>Freigeben</v-btn>
          </template>
          Ansprechpartner:in freigeben
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip left v-can:delete-responsibles="{ id: this.responsible._id }">
          <template v-slot:activator="{ on }">
            <v-btn
                color="error"
                @click="showDeleteAlert = true"
                v-on="on"
                outlined
                rounded
                small
            >
              <v-icon small>mdi-trash-can</v-icon>
               Löschen
            </v-btn>
          </template>
          Ansprechpartner:in löschen
        </v-tooltip>
      </v-card-actions>
    </v-card>

    <v-dialog
        v-model="showDeleteAlert"
        max-width="500px"
        persistent
        transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
          <div class="error--text">Ansprechpartner:in löschen</div>
          <v-spacer/>
          <v-btn icon @click="showDeleteAlert = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-xs>
            <v-layout row wrap>
              <v-alert :value="true" type="error">
                Achtung, dies kann nicht rückgängig gemacht werden!
              </v-alert>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" outlined @click="showDeleteAlert = false" small
          >Abbrechen
          </v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deleteResponsible" small v-can:delete-responsibles="{ id: responsible._id }"><v-icon small>mdi-trash-can</v-icon> Löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>


<script lang="ts">
import Vue from 'vue';
import { Responsible } from '@/interfaces/responsible.interface';
import { ResponsibleHandlerAPI } from '@/classes/api/responsible-handler.api.class';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import ContactDataListItems from '@/components/common/profile/contactDataListItems.vue';
import { toSalutation } from '@/helpers/toSalutation.helper';
import moment from 'moment';
import { CrudEntityTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import { isJobType } from '@/interfaces/jobType.interface';
import { GenderTypes } from '@/enums/GenderTypes.enum';
import { EventBus } from '@/busses/EventBus';
import mongoose from 'mongoose';

export default Vue.extend({
  components: { ProfileAvatar, ContactDataListItems },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    responsible: {
      type: Object as () => Responsible,
      required: true,
    },
    institutionId: {
      type: Object as () => mongoose.Types.ObjectId,
      required: true,
    },
  },
  data: () => ({
    showDeleteAlert: false,
    loading: false,
  }),
  computed: {
    toSalutation: () => toSalutation,
    moment: () => moment,
  },
  methods: {
    getJobTypeString(responsible: Responsible) {
      if (responsible.jobType && isJobType(responsible.jobType)) {
        switch (responsible.gender) {
          case GenderTypes.FEMALE:
            return responsible.jobType.female;
          case GenderTypes.MALE:
            return responsible.jobType.male;
          case GenderTypes.TRANS:
            return responsible.jobType.neutral;
          default:
            return responsible.jobType.neutral;
        }
      } else {
        return 'Jobposition unbekannt';
      }
    },
    shareAccess() {
      this.$$shareAccessDialog.show(CrudEntityTypes.RESPONSIBLE, this.responsible._id, 'Ansprechpartner:in freigeben')
    },
    async deleteResponsible() {
      try {
        this.loading = true;
        await ResponsibleHandlerAPI.remove(this.responsible._id, {
          pullFromInstitution: this.institutionId,
        });
        this.showDeleteAlert = false;
        this.$emit('delete:responsible');
        EventBus.$emit('delete:responsible');
        this.$emit('input', false);
        this.$$showSnackbar('Ansprechpartner:in wurde erfolgreich gelöscht.', 'success')
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
