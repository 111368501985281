<template>
  <v-container>
    <v-row v-can:read-institutions="{ keys: ['isActive'] }" dense>
      <v-col cols="2">
        <v-checkbox color="info" v-model="filterStates.isActive"/>
      </v-col>
      <v-col cols="10">
        <v-select item-color="info" color="info" single-line outlined dense class="mt-3" rounded label="Status"
                  :rules="[RuleFactory.required()]" v-if="filterStates.isActive" v-model="innerFilter.isActive"
                  :items="isActiveItems"/>
        <v-select label="Status" v-else single-line rounded outlined dense class="mt-3" disabled/>
      </v-col>
    </v-row>
    <v-row v-can:read-institutions="{ keys: ['type'] }" dense>
      <v-col cols="2">
        <v-checkbox color="info" v-model="filterStates.type"/>
      </v-col>
      <v-col cols="10">
        <v-select color="info" multiple single-line outlined dense class="mt-3" rounded
                  label="Art des Standorts" :rules="[RuleFactory.required()]" v-if="filterStates.type"
                  v-model="innerFilter.type" :items="institutionTypes" item-color="info"/>
        <v-select color="info" label="Art des Standorts" v-else single-line rounded outlined dense class="mt-3"
                  disabled/>
      </v-col>
    </v-row>
    <v-row v-can:read-institutions="{ keys: ['acquisitionStatus'] }" dense>
      <v-col cols="2">
        <v-checkbox color="info" v-model="filterStates.acquisitionStatus"/>
      </v-col>
      <v-col cols="10">
        <v-select color="info" multiple single-line outlined dense class="mt-3" rounded label="Akquisestatus"
                  :rules="[RuleFactory.required()]" v-if="filterStates.acquisitionStatus"
                  v-model="innerFilter.acquisitionStatus"
                  :items="AcquisitionListStatusVue" item-color="info"/>
        <v-select color="info" label="Akquisestatus" v-else single-line rounded outlined dense class="mt-3" disabled/>
      </v-col>
    </v-row>
    <v-row v-can:read-institutions="{ keys: ['status'] }" dense>
      <v-col cols="2">
        <v-checkbox color="info" v-model="filterStates.status"/>
      </v-col>
      <v-col cols="10">
        <v-select color="info" multiple single-line outlined dense class="mt-3" rounded
                  label="Status des Standorts (veraltet)"
                  :rules="[RuleFactory.required()]" v-if="filterStates.status" v-model="innerFilter.status"
                  :items="institutionStatus" item-color="info"/>
        <v-select color="info" label="Status des Standorts (veraltet)" v-else single-line rounded outlined dense
                  class="mt-3" disabled/>
      </v-col>
    </v-row>
  </v-container>
</template>


<script lang="ts">
import Vue from 'vue';
import {FindInstitutionFilter} from '@/classes/dto/institutioner/request/filters/FindInstitution.filter';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {InstitutionTypesVue} from '@/enums/InstitutionTypes.enum';
import {InstitutionStatusVue} from '@/enums/InstitutionStatus.enum';
import {Form} from "@/interfaces/Form.interface";
import {AcquisitionListStatusEnum} from '@/enums/institutioner/acquisition/AcquisitionListStatus.enum';

export default Vue.extend({
  props: {
    filter: {
      type: Object as () => FindInstitutionFilter,
      default: () => ({
        isActive: true,
      } as FindInstitutionFilter),
    },
  },
  data: () => ({
    filterStates: {} as Record<keyof FindInstitutionFilter, boolean>,
    innerFilter: {} as FindInstitutionFilter,
    isActiveItems: [{
      text: 'Nur aktive Standorte anzeigen',
      value: true,
    }, {
      text: 'Nur inaktive Standorte anzeigen',
      value: false,
    }],
    AcquisitionListStatusVue: AcquisitionListStatusEnum.Items,
    institutionTypes: InstitutionTypesVue,
    institutionStatus: InstitutionStatusVue,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
  },
  methods: {
    initializeFilterStates() {
      this.$set(this, 'filterStates', {});
      this.$nextTick(() => {
        this.$set(this, 'innerFilter', {...this.filter});
        for (const key in this.filter) {
          if (this.filter.hasOwnProperty(key)) {
            this.$set(this.filterStates, key, typeof (this.filter as Record<string, any>)[key] !== 'undefined');
          }
        }
      });
    },
    updateFilter() {
      const form = this.$refs.form as Form;
      if (form) {
        form.resetValidation();
      }
      for (const key in this.filterStates) {
        if (!this.filterStates.hasOwnProperty(key) || !(this.filterStates as Record<string, boolean>)[key]) {
          this.$delete(this.innerFilter, key);
        }
      }
    },
    applyFilter() {
      this.$emit('update:filter', {...this.innerFilter});
    },
  },
  watch: {
    filterStates: {
      deep: true,
      handler() {
        this.updateFilter();
      },
    },
    innerFilter: {
      deep: true,
      handler() {
        this.applyFilter();
      },
    },
  },
  mounted() {
    this.initializeFilterStates();
  },
});
</script>
