<template>
  <v-navigation-drawer
      :disable-resize-watcher="true"
      :expand-on-hover="false"
      :hide-overlay="hideOverlay"
      :mini-variant="miniVariant"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :touchless="true"
      :value="showDrawer"
      app
      clipped
      left
      @input="emitInput"
  >
    <v-list class="py-0">
      <template v-for="(item, i) in NavItems">
        <navigation-drawer-item v-if="item.type === 'item'" :key="`item-${i}`" :item="item"
                                :show-tooltip="miniVariant"/>
        <div v-else-if="item.type === 'group'" :key="`group-${i}`">
          <v-list-group active-class="blue-grey lighten-5 info--text" color="inherit">
            <template v-slot:activator>
              <v-tooltip :disabled="!miniVariant" right>
                <template v-slot:activator="{ on }">
                  <v-list-item-icon v-on="on">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <overflow-text :text="item.text"/>
              </v-tooltip>
            </template>
            <navigation-drawer-item v-for="(child, j) in item.items" :key="`group-${i}-child-${j}`" :item="child" inset
                                    :show-tooltip="miniVariant" :no-divider="j === item.items.length - 1"/>
          </v-list-group>
          <v-divider/>
        </div>
      </template>
      <div style="height: 40px"/>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';
import NavigationDrawerItem from '@/components/app/navigation-drawer-item.app.vue';
import {WarehouseManagerAPI} from '@/classes/api/warehouse-manager.api.class';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import {
  WarehouseOrderFields,
} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/WarehouseOrderFields';
import {BadgeBus} from '@/busses/BadgeBus';
import {WarehouseOrderStates} from '@/enums/WarehouseOrderStates.enum';
import {CustomAccessKeys} from '@/classes/clientOnly/permissionTreeResources/enums/CustomAccessKeys';
import {
  OnlineFormSubmissionFields,
} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/OnlineFormSubmissionFields';
import {BookingManagerApi} from '@/classes/api/booking-manager.api.class';
import {SubmissionStates} from '@/enums/bookingManager/onlineFormSubmission/SubmissionStates.enum';
import {TerminationFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/TerminationFields';
import {TerminatorApi} from '@/classes/api/terminator.api';
import moment from 'moment';
import OverflowText from '@/components/common/overflow-text.vue';
import {NavigationDrawerAccess} from '@/enums/_common/NavigationDrawerAccess.enum';

moment.locale('de');

export default Vue.extend({
  components: {OverflowText, NavigationDrawerItem},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    showDrawer: false,
    miniVariant: false,
    hideOverlay: true,
    updateInterval: 0,
    CrudEntityTypes: CrudEntityTypes,
  }),
  computed: {
    NavItems(): NavigationDrawerAccess.DrawerItem[] {
      return NavigationDrawerAccess.getDrawerItems(this.$store.state.guiAccess);
    },
    CustomAccessKeys: () => CustomAccessKeys,
  },
  methods: {
    toggleMenu() {
      if (this.value) {
        this.showDrawer = true;
        this.miniVariant = false;
      } else {
        if (this.$vuetify.breakpoint.mdAndUp) {
          this.showDrawer = true;
          this.miniVariant = true;
        } else {
          this.showDrawer = false;
          this.miniVariant = false;
        }
      }
    },
    async updateOrderNumbers() {
      const hasAccess = this.$$crudAccessManager.canReadAllProvided<WarehouseOrderFields>(CrudEntityTypes.WAREHOUSE_ORDER,
          WarehouseOrderFields.QTY,
          WarehouseOrderFields.FULFILLED_AT,
      );
      if (hasAccess) {
        const info = await WarehouseManagerAPI.getOrderNumbers({
          type: 'outgoing',
          states: [WarehouseOrderStates.UNTOUCHED],
        });
        this.$store.commit('updateOpenOrders', info.numberOfOrders);
      }
    },
    async updateSubmissionNumbers() {
      const hasAccess = this.$$crudAccessManager.canReadAllProvided<OnlineFormSubmissionFields>(CrudEntityTypes.ONLINE_FORM_SUBMISSION,
          OnlineFormSubmissionFields.STATE,
      );
      if (hasAccess) {
        const info = await BookingManagerApi.submissionCollector.find({
          filter: {
            studentCreated: false,
            submissionState: [SubmissionStates.ACTIVE],
          },
          skipPagination: true,
          fields: ['_id'],
        })
        this.$store.commit('updateOpenSubmissions', info.onlineFormSubmissions.length);
      }
    },
    async updateTerminationNumbers() {
      const hasAccess = this.$$crudAccessManager.canReadAllProvided<TerminationFields>(CrudEntityTypes.TERMINATION, TerminationFields.DUE_DATE);
      if (hasAccess) {
        const info = await TerminatorApi.find({
          filter: {
            dueDateBetween: {
              start: moment().startOf('month').toDate(),
              end: moment().endOf('month').toDate(),
            },
          },
          skipPagination: true,
          fields: ['_id'],
        })
        this.$store.commit('updateOpenTerminations', info.terminations.length);
      }
    },
    canAtLeastOneCustom(...keys: CustomAccessKeys[]) {
      for (const key of keys) {
        if (this.$$crudAccessManager.getCustom(key)) {
          return true;
        }
      }
    },
    canCreateOrRead(...types: CrudEntityTypes[]) {
      for (const type of types) {
        if (
            this.$$crudAccessManager.canAccessType(type, CrudActionTypes.CREATE)
            || this.$$crudAccessManager.canAccessType(type, CrudActionTypes.READ)
        ) {
          return true;
        }
      }
      return false;
    },
    canReadAndUpdate(...types: CrudEntityTypes[]) {
      for (const type of types) {
        if (
            this.$$crudAccessManager.canAccessType(type, CrudActionTypes.UPDATE)
            && this.$$crudAccessManager.canAccessType(type, CrudActionTypes.READ)
        ) {
          return true;
        }
      }
      return false;
    },
    async startInterval() {
      try {
        await this.updateOrderNumbers();
        await this.updateSubmissionNumbers();
        await this.updateTerminationNumbers()
        this.updateInterval = setInterval(() => {
          this.updateOrderNumbers();
          this.updateSubmissionNumbers();
          this.updateTerminationNumbers();
        }, 5 * 60 * 1_000) as unknown as number;
      } catch (e) {
        // Ignore
      }
    },
    emitInput(v: boolean) {
      this.$emit('input', v);
    },
  },
  watch: {
    value() {
      this.toggleMenu();
      if (this.$vuetify.breakpoint.smAndDown && this.value) {
        setTimeout(() => {
          this.hideOverlay = false;
        }, 1);
      } else {
        this.hideOverlay = true;
      }
    },
  },
  mounted() {
    this.toggleMenu();
    this.startInterval();
    BadgeBus.$on('update:orderNumbers', () => {
      this.updateOrderNumbers();
    });
    BadgeBus.$on('update:submissionNumbers', () => {
      this.updateSubmissionNumbers();
    });
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
});
</script>
