import {Holiday} from '@/interfaces/holiday.interface';
import {API} from './api.class';
import {Types} from 'mongoose';
import {CreateHolidayRequestDto} from '@/classes/dto/vacation-getter/request/CreateHoliday.request.dto';
import {FindByIdOptionsRequestDto} from '@/classes/dto/_common/request/FindByIdOptions.request.dto';
import {FindHolidayRequestDto} from '@/classes/dto/vacation-getter/request/FindHoliday.request.dto';
import {UpdateHolidayRequestDto} from '@/classes/dto/vacation-getter/request/UpdateHoliday.request.dto';

export class VacationGetterApi extends API {
    static service = 'vacation-getter';

    public static async createHoliday(data: CreateHolidayRequestDto): Promise<Holiday> {
        return this.post('', data);
    }

    public static async fetch(): Promise<void> {
        return this.post('fetch', {});
    }

    public static async findById(id: Types.ObjectId, options?: FindByIdOptionsRequestDto): Promise<Holiday> {
        return this.get(`${id}`, options);
    }

    public static async find(data: FindHolidayRequestDto): Promise<{ holidays: Holiday[]; maxPage: number }> {
        return this.get('', data);
    }

    public static async update(id: Types.ObjectId, update: UpdateHolidayRequestDto): Promise<void> {
        return this.put(`${id}`, update);
    }

    public static async remove(id: Types.ObjectId): Promise<void> {
        return this.delete(`${id}`);
    }
}
