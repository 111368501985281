<template>
  <v-dialog
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :value="value"
      max-width="1200px"
      persistent
      scrollable
      transition="dialog-transition"
      @input="reset"
  >
    <v-card>
      <v-card-title>
        <div class="info--text">Ansprechpartner:in anlegen</div>
        <v-spacer/>
        <v-btn icon @click="reset">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-2">
        <v-form ref="form" v-model="valid" lazy-validation>
          <create-personal-information :first-name.sync="responsible.firstName" :gender.sync="responsible.gender"
                                       :last-name.sync="responsible.lastName" :require-first-name="false"/>
          <v-row>
            <v-col>
              <date-picker v-model="responsible.birthday" birthday-mode color="info" dense
                           prepend-icon="mdi-cake-variant"/>
            </v-col>
            <v-col>
              <job-type-autocomplete v-model="responsible.jobType" color="info" dense outlined rounded label="Jobposition wählen"/>
            </v-col>
          </v-row>
          <create-contact-information v-model="responsible.contactInformation" is-business-contact/>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="reset">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="info" @click="createResponsible">Hinzufügen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { GenderTypes } from '@/enums/GenderTypes.enum';
import Vue from 'vue';
import { Form } from '@/interfaces/Form.interface';
import CreatePersonalInformation from '@/components/common/create/createPersonalInformation.vue';
import CreateContactInformation from '@/components/common/create/createContactInformation.vue';
import DatePicker from '@/components/common/datePicker.vue';
import JobTypeAutocomplete from '@/components/common/search-fields/jobTypeAutocomplete.vue';
import { Types } from 'mongoose';
import { CreateResponsibleRequestDTO } from '@/classes/dto/responsible-handler/request/CreateResponsible.request.dto';
import { ResponsibleHandlerAPI } from '@/classes/api/responsible-handler.api.class';
import {ContactInformationTemplateDto} from '@/classes/dto/_common/templates/ContactInformation.template.dto';
import {EventBus} from "@/busses/EventBus";

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    institutionId: {
      type: Types.ObjectId,
      required: true,
    },
  },
  components: {
    JobTypeAutocomplete,
    DatePicker,
    CreateContactInformation,
    CreatePersonalInformation,
  },
  data: () => ({
    valid: true,
    responsible: {
      // @ToDo login implementation
      hasLogin: false,
      // @ToDo remove schoolType from database
      schoolType: undefined,
      firstName: '',
      lastName: '',
      jobType: null as null | Types.ObjectId,
      birthday: null as null | Date,
      contactInformation: {} as ContactInformationTemplateDto,
      gender: null as null | GenderTypes,
    },
    loading: false,
  }),
  methods: {
    async createResponsible() {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        try {
          this.loading = true;
          const responsible: CreateResponsibleRequestDTO = {
            createLogin: false,
            birthday: this.responsible.birthday ? this.responsible.birthday : undefined,
            contactInformation: this.responsible.contactInformation,
            jobType: this.responsible.jobType ? this.responsible.jobType : undefined,
            firstName: this.responsible.firstName,
            lastName: this.responsible.lastName,
            gender: this.responsible.gender as GenderTypes,
            assignToInstitution: this.institutionId,
          }
          const response = await ResponsibleHandlerAPI.create(responsible)
          this.$emit('create:responsible', response);
          EventBus.$emit('create:responsible');
          this.$emit('input', false);
          this.$$showSnackbar('Ansprechpartner:in wurde erfolgreich angelegt.', 'success');
        } finally {
          this.loading = false;
        }
      }
    },
    reset() {
      this.responsible = {
        hasLogin: false,
        schoolType: undefined,
        firstName: '',
        lastName: '',
        jobType: null as null | Types.ObjectId,
        birthday: null as null | Date,
        contactInformation: {} as ContactInformationTemplateDto,
        gender: null as null | GenderTypes,
      };
      this.valid = true;
      this.$emit('input', false);
    },
  },
});
</script>
