export enum CustomAccessKeys {
    SEND_MAIL_AS_CUSTOMER = 'sendMailAsCustomer',
    RENDER_MAIL_TEMPLATES = 'renderMailTemplates',
    RENDER_WHATS_APP_MESSAGE_TEMPLATES = 'renderWhatsAppMessageTemplates',
    SHARE_ACCESS = 'shareAccess',
    WRITE_COURSE_LOGS_FOR_OTHERS = 'writeCourseLogsForOthers',
    ALLOCATE_ROLES_TO_USERS = 'allocateRolesToUsers',
    REMOVE_ROLES_FROM_USERS = 'removeRolesFromUsers',
    USE_GMAPS_DISTANCE_MATRIX = 'useGmapsDistanceMatrix',
    START_CRON_JOBS = 'startCronJobs',
    STOP_CRON_JOBS = 'stopCronJobs',
    UPDATE_COURSE_LINKS = 'updateCourseLinks',
    INITIALIZE_PERMISSION_HOOKS = 'initializePermissionHooks',
    USE_SEPA_TOOLS = 'useSepaTools',
    USE_BOOK_INVOICE_GENERATOR = 'useBookInvoiceGenerator',
    LOGIN_AS = 'loginAs',
    CALC_OPTIMIZATION_SUGGESTIONS = 'calcOptimizationSuggestions',
    MANAGE_JOB_AD_PUBLICATIONS = 'manageJobAdPublications',
    READ_STATS = 'readStats',
}
