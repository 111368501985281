<template>
  <div>
    <div v-if="loading || !institutionId">
      <v-skeleton-loader type="list-item-two-line" />
    </div>
    <div v-else>
      <v-divider />
      <v-list-group
          v-model="expandListGroup"
          prepend-icon="mdi-human-male-board"
          v-if="assignedEmployees.length > 0"
          active-class="black--text"
      >
        <template v-slot:activator>
          <v-list-item-title>Zugeteilte Lehrkräfte <span class="grey--text">({{assignedEmployees.length}})</span></v-list-item-title>
          <v-list-item-action v-can:readAndUpdate-employees="{ keys: ['assignedInstitutions'] }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" color="warning" @click="showAssignEmployeesDialog = true">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Zuteilungen bearbeiten</span>
            </v-tooltip>
          </v-list-item-action>
        </template>
        <v-list-item v-for="item in assignedEmployees" :key="item._id">
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span class="pr-1" v-can:read-employees="{ id: item._id, keys: ['firstName'] }">{{item.firstName}}</span>
              <span v-can:read-employees="{ id: item._id, keys: ['lastName'] }">{{item.lastName}}</span>
              <span v-if="!item.firstName && !item.lastName">Name unbekannt</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              Zugeteilte Lehrkraft
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-can:read-institutions="{ id: item._id }">
            <v-layout row wrap>
              <v-flex>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" :href="$router.resolve(`/employees/${item._id}`).href" target="_blank">
                      <v-icon>mdi-page-next-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Lehrkraft in neuem Tab öffnen</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="activeEmployeeId = item._id; showEmployeeCardDialog = true;">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Lehrkraft ansehen</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-list-item-action>
        </v-list-item>

      </v-list-group>
      <v-list-item v-else>
        <v-list-item-icon>
          <v-icon>mdi-human-male-board</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Keine Lehrkraft zugeteilt
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-can:read-employees="{ keys: ['assignedInstitutions'] }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" color="info" @click="showAssignEmployeesDialog = true">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Lehrkraft zuweisen</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </div>
    <!-- DIALOGS -->
    <assign-employees-dialog v-model="showAssignEmployeesDialog" :institution-id="institutionId" :assigned-employees="assignedEmployees" />
    <employee-card-dialog v-if="activeEmployeeId" v-model="showEmployeeCardDialog" :employee-id="activeEmployeeId" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {EmployeeHandlerAPI} from '@/classes/api/employee-handler.api.class';
import {Employee} from '@/interfaces/employee.interface';
import AssignEmployeesDialog from '@/components/institutions/institutionView/profileView/assignEmployeesDialog.vue';
import EmployeeCardDialog from '@/components/common/profile/employeeCardDialog.vue';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {EmployeeFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/EmployeeFields';
import mongoose from "mongoose";

export default Vue.extend({
  components: {EmployeeCardDialog, AssignEmployeesDialog},
  props: {
    institutionId: {
      type: Object as () => mongoose.Types.ObjectId,
      required: true,
    },
  },
  data: () => ({
    assignedEmployees: [] as Array<Pick<Employee, '_id' | 'firstName' | 'lastName'>>,
    activeEmployeeId: null as null | mongoose.Types.ObjectId,
    showEmployeeCardDialog: false,
    showAssignEmployeesDialog: false,
    expandListGroup: false,
    loading: false,
  }),
  methods: {
    async getAssignedEmployees() {
      if (!this.$$crudAccessManager.canReadAllProvided(CrudEntityTypes.EMPLOYEE, EmployeeFields.ASSIGNED_INSTITUTIONS)) {
        return;
      }
      try {
        this.loading = true;
        const employeeResp = await EmployeeHandlerAPI.find({
          skipPagination: true,
          filter: {
            isActive: true,
            assignedInstitutions: [this.institutionId],
          },
          fields: ['firstName', 'lastName'],
        });
        this.assignedEmployees = employeeResp.employees;
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler beim Laden der zugeordneten Lehrkräfte aufgetreten.', 'error');
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getAssignedEmployees();
  },
});
</script>
