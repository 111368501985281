<template>
  <v-dialog
      :fullscreen="$vuetify.breakpoint.xs"
      :value="showDialog"
      max-width="700px"
      overlay-color="#b2b4bf"
      overlay-opacity="0.6"
      :persistent="isConnectionError"
      scrollable
      style="z-index: 999999;"
      transition="dialog-transition"
      @input="$store.commit('setError')"
  >
    <v-card v-if="error && !isConnectionError">
      <v-card-title class="error--text" primary-title>
        Da ist etwas schiefgelaufen...
      </v-card-title>
      <v-card-text class="pt-3">
        <div>
          <p>
            Leider kam es bei der Kommunikation zwischen Vanilla und dem Server zu einem Fehler.
            Solltest du nicht selbst darauf kommen, was schief gelaufen ist, kannst du gerne einen
            Fehlerbericht schreiben.
          </p>
          <p class="pb-0 mb-0">
            <strong>Wichtig:</strong>
            Mit deinem Fehlerbericht übermittelst du automatisch auch eine Bildschirmaufnahme.
          </p>
        </div>
      </v-card-text>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-textarea
              v-model="msg"
              :rules="[RuleFactory.required(), RuleFactory.minLength(50)]"
              class="mt-2"
              color="error"
              counter="300"
              persistent-hint
              hint="Hinweis: Ein guter Fehlerbericht sollte mehr als 300 Zeichen umfassen"
              label="Dein Fehlerbericht"
              outlined
              rounded
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" small text @click="showDetails = !showDetails">
          <span v-if="showDetails">Details ausblenden</span>
          <span v-else>Details einblenden</span>
        </v-btn>
        <v-spacer />
      </v-card-actions>
      <code v-if="showDetails" class="caption mb-3 pa-5 elevation-0" style="max-height: 128px; overflow-y: scroll;">
        Statuscode: {{ statusCode }} {{ statusText }} <br />
        Nachricht: {{ responseMessage }} <br />
        Kontaktierte Adresse: {{ requestUrl }} <br />
        Art der Anfrage: {{ requestMethod }} <br />
        Übermittelte Daten: {{ requestPayload }}
      </code>
      <v-card-actions>
        <v-btn :disabled="loading" text @click="$store.commit('setError')">Fehler ignorieren</v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="error" @click="sendReport" :disabled="!valid">
          <v-icon>mdi-send</v-icon>
          Fehlerbericht senden
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else-if="error">
      <v-card-title class="warning--text" primary-title>
        Verbindungsprobleme
        <v-icon class="ml-2">mdi-wifi-off</v-icon>
        <v-spacer/>
        <v-btn icon @click="$store.commit('setError')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          Vanilla konnte keine Verbindung zum Server herstellen.
        </p>
        <p>
          Das kann verschiedene Ursachen haben. Bitte stelle sicher, dass dein Gerät eine stabile Internetverbindung
          hergestellt hat und versuche es dann erneut.
        </p>
        <p>
          Möglicherweise werden auch gerade Wartungsarbeiten am Server durchgeführt, was in der Regel nicht lange
          dauert.
        </p>
        <p>
          Wenn du sicher bist, dass deine Internetverbindung stabil ist und das Problem länger
          als 10 Minuten besteht, kontaktiere bitte den Support.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="warning" @click="reload">
          <v-icon>mdi-sync</v-icon>
          Diese Seite neu laden
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import store from '@/store';
import Vue from 'vue';
import html2canvas from 'html2canvas';
import { MailerApi } from '@/classes/api/mailer.api.class';
import { SendErrorReportRequestDTO } from '@/classes/dto/mailer/request/sendErrorReport.request.dto';
import { Employee } from '@/interfaces/employee.interface';
import Compressor from 'compressorjs';
import { RuleFactory } from '@/helpers/ruleFactory.helper';
import { Form } from '@/interfaces/Form.interface';
import * as mongoose from "mongoose";

export default Vue.extend({
  data: () => ({
    showDialog: false,
    loading: false,
    showDetails: false,
    screenshotData: null as Blob | null,
    msg: '',
    valid: false,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    error() {
      if (store.state.error) {
        return store.state.error;
      } else {
        return undefined;
      }
    },
    isConnectionError(): boolean {
      if (!this.error) {
        return false;
      }
      return !!store.state.error?.request && !store.state.error.response;
    },
    statusCode() {
      return store.state.error?.response?.status;
    },
    statusText() {
      return store.state.error?.response?.statusText;
    },
    responseMessage() {
      return (store.state.error?.response?.data as any)?.message as string;
    },
    requestUrl() {
      return `${store.state.error?.config?.baseURL}/${store.state.error?.config?.url}`;
    },
    requestMethod() {
      return store.state.error?.config?.method?.toUpperCase();
    },
    requestPayload() {
      return store.state.error?.config?.data || store.state.error?.config?.params || 'Keine Daten übermittelt';
    },
  },
  methods: {
    async sendReport() {
      const form = this.$refs.form as Form;
      if (!form.validate()) {
        return;
      }
      if (store.state.error) {
        this.loading = true;
        const user = store.state.user as Employee;
        const p: Promise<string> = new Promise((res, rej) => {
          new Compressor(this.screenshotData as Blob, {
            quality: 0.35,
            maxWidth: 1024,
            maxHeight: 1024,
            strict: true,
            success: (file: File) => {
              const reader = new FileReader();
              reader.onload = (event) => {
                if (event.target) {
                  res(event.target.result as string);
                }
              };
              reader.readAsDataURL(file);
            },
            error: (error: Error) => {
              rej(error);
            },
          });
        });
        const screenshot = await p;
        const data: SendErrorReportRequestDTO = {
          config: store.state.error.config,
          res: store.state.error.response,
          screenshot,
          msg: this.msg,
          userId: store.state.user?._id as unknown as mongoose.Types.ObjectId,
          userName: `${user.firstName} ${user.lastName}`,
        };
        try {
          await MailerApi.sendErrorReport(data);
          store.commit('setError');
        } catch (error) {
          // void
        }
        this.loading = false;
      }
    },
    reload() {
      window.location.reload();
    },
  },
  watch: {
    error(v) {
      if (v && !this.isConnectionError) {
        this.$nextTick(async () => {
          const appElement = document.getElementById('app');
          if (appElement) {
            const screenshotPromise = new Promise((resolve) => {
              html2canvas(appElement).then((canvas) => {
                canvas.toBlob((blob) => {
                  this.screenshotData = blob;
                  resolve(this.screenshotData);
                });
              });
            });
            await screenshotPromise;
          }
          this.showDialog = true;
        });
      } else this.showDialog = this.isConnectionError;
    },
  },
});
</script>
