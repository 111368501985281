import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {OptimizationFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/optimizationFields';

export const optimizationFieldsDictionary: FieldDictionary<OptimizationFields> = [{
    text: 'Lehrkraft',
    value: OptimizationFields.EMPLOYEE,
}, {
    text: 'Kurs',
    value: OptimizationFields.COURSE,
}, {
    text: 'Standort',
    value: OptimizationFields.INSTITUTION,
}, {
    text: 'Datum',
    value: OptimizationFields.DATE,
}, {
    text: 'Score',
    value: OptimizationFields.SCORE,
}, {
    text: 'Erwarteter Profit',
    value: OptimizationFields.PROFIT,
}, {
    text: 'Erwarteter Profit zuwachs',
    value: OptimizationFields.PROFIT_GAIN,
}, {
    text: 'Vorschlag Bestätigt von',
    value: OptimizationFields.COMMITTED_BY,
}, {
    text: 'In Kursplan übernommen',
    value: OptimizationFields.COMMITTED,
}, {
    text: 'In Kursplan übernommen von',
    value: OptimizationFields.COMMITTED_BY,
}];
