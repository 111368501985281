export enum TerminationFields {
    INVOICE_DOCUMENTS = 'invoiceDocuments',
    RECURRING_DOCUMENTS = 'recurringDocuments',
    TERMINATION_REASON = 'terminationReason',
    DESCRIPTION = 'description',
    DEACTIVATE_INVOICES_AT = 'deactivateInvoicesAt',
    DEACTIVATE_CUSTOMER_AT = 'deactivateCustomerAt',
    DEACTIVATE_STUDENT_AT = 'deactivateStudentAt',
    DEACTIVATE_COURSES_AT = 'deactivateCoursesAt',
    REACTIVATE_AT = 'reactivateAt',
    IS_TEMPORARY = 'isTemporary',
    DUE_DATE = 'dueDate',
    MAIL = 'mail',
    CUSTOMER_TYPE = 'customerType',
    CUSTOMER = 'customer',
    STUDENT = 'student',
    TEACHERS = 'teachers',
    CREATOR = 'creator',
    DONE = 'done',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
}
