export enum EmployeeFields {
    GENDER = 'gender',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    BIRTHDAY = 'birthday',
    ADDRESS = 'address',
    PAYMENT = 'payment',
    HAS_LOGIN = 'hasLogin',
    IS_ACTIVE = 'isActive',
    DEACTIVATED_AT = 'deactivatedAt',
    CONTACT_INFORMATION = 'contactInformation',
    CUSTOM_ID = 'customId',
    CUSTOM_ID_SCHEME = 'customIdScheme',
    ACCOUNTING_REF = 'accountingRef',

    SUBJECTS = 'subjects',
    CONTRACT = 'contract',
    IS_TEACHER = 'isTeacher',
    IS_EMPLOYEE = 'isEmployee',
    IS_APPLICANT = 'isApplicant',
    HAS_DRIVER_LICENSE = 'hasDriverLicense',
    HAS_CAR = 'hasCar',
    MAX_DISTANCE = 'maxDistance',
    MAX_TRAVEL_TIME = 'maxTravelTime',
    PROFILE_IMG = 'profileImg',

    ASSIGNED_INSTITUTIONS = 'assignedInstitutions',
    PLANNED_INSTITUTIONS = 'plannedInstitutions',

    BLOCKED_SLOTS_CALENDAR = 'blockedSlotsCalendar',
    EXCEPTIONAL_BLOCKED_SLOTS_CALENDAR = 'exceptionalBlockedSlotsCalendar',
    AVAILABILITIES = 'availabilities',

    ABILITIES = 'abilities',
    TAGS = 'tags',

    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',

    COMPLIANCE = 'compliance',

    ACQUIRED_BY = 'acquiredBy',
    RECRUITING_PLATFORM = 'recruitingPlatform',
    APPLICATIONS = 'applications',

    SUPERVISOR = 'supervisor',
    IS_SUPERVISOR = 'isSupervisor',
    IS_DEFAULT_SUPERVISOR_FOR_STATES = 'defaultSupervisorForStates',

    DEACTIVATE_AT = 'deactivateAt',
    ACTIVATE_AT = 'activateAt',
}
