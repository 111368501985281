<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" :value="value" max-width="600"
            @input="reset">
    <v-card>
      <v-card-title>
        <div class="info--text">
          Ablagestapel anlegen
        </div>
        <v-spacer />
        <v-btn :disabled="this.loading" icon @click="reset">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" lazy-validation ref="form">
          <v-row dense>
            <v-col cols="12" lg="6">
              <v-text-field v-model="stackName"
                            :rules="[
                            RuleFactory.required('Der Name des Stapels ist erforderlich'),
                            RuleFactory.maxLength(32),
                        ]" rounded outlined color="info" dense
                            counter="32" label="Name des Ablagestapels" />
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field v-model="stackOwner" disabled rounded outlined dense
                            label="Eigentümer" />
            </v-col>
            <v-col cols="12">
              <v-subheader>Farbe auswählen</v-subheader>
              <v-row align="center">
                <v-col cols="1">
                  <v-avatar size="32" :color="stackColor">
                    <v-icon color="white" small>mdi-tray-full</v-icon>
                  </v-avatar>
                </v-col>
                <v-col cols="11">
                  <v-color-picker v-model="stackColor" hide-sliders show-swatches :swatches="swatches" hide-canvas hide-inputs/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="reset" :disabled="loading">
          Abbrechen
        </v-btn>
        <v-spacer />
        <v-btn :disabled="!valid" :loading="loading" color="info" @click="save">
          Stapel anlegen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {Form} from '@/interfaces/Form.interface';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {StackBus} from '@/busses/StackBus';
import colors from 'vuetify/lib/util/colors'


export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
   stackOwner: '',
    stackName: '',
    stackColor: '',
    loading: false,
    valid: false,
    swatches: [[colors.red.base], [colors.pink.base], [colors.purple.base], [colors.indigo.base],
      [colors.blue.base], [colors.lightBlue.base], [colors.cyan.base], [colors.teal.base],
      [colors.green.base], [colors.lightGreen.base], [colors.amber.base], [colors.orange.base],
      [colors.deepOrange.base], [colors.blueGrey.base], ['#9E9E9E'],
    ],
  }),
  computed: {
    RuleFactory: () => RuleFactory,
  },
  methods: {
    async save() {
      const form = this.$refs.form as Form;
      if (form.validate()) {
        this.loading = true;
        try {
          await StackManagerApi.create({
            name: this.stackName,
            color: this.stackColor,
          });
          StackBus.$emit('reload:stacks');
          this.reset();
        } catch (err) {
          alert(err);
        }
        this.loading = false;
      }
    },
    initialize() {
      this.stackColor = '#9E9E9E';
      this.stackOwner = `${this.$store.state.user.firstName} ${this.$store.state.user.lastName}`;
    },
    reset() {
      const form = this.$refs.form as Form;
      form.reset();
      this.$emit('input', false);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.initialize();
        }
      },
    },
  },
});
</script>
