<template>
  <div>
    <v-layout row wrap v-can:read-employees="{ keys: ['isTeacher', 'isActive'] }">
      <v-flex xs1>
        <v-checkbox color="info" v-model="filterStates.isActive"/>
      </v-flex>
      <v-flex xs11>
        <v-select color="info" single-line outlined dense class="mt-3" rounded label="Status"
                  :rules="[RuleFactory.required()]" v-if="filterStates.isActive" v-model="innerFilter.isActive"
                  :items="isActiveItems"/>
        <v-select label="Status" v-else single-line rounded outlined dense class="mt-3" disabled/>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-can:read-employees="{ keys: ['subjects'] }" v-can:read-subjects="{ keys: ['name'] }">
      <v-flex xs1>
        <v-checkbox color="info" v-model="filterStates.subjects"/>
      </v-flex>
      <v-flex xs11>
        <subject-autocomplete v-model="innerFilter.subjects" color="info" multiple
                              v-if="filterStates.subjects" single-line outlined dense
                              rounded label="Unterrichtete Fächer" class="mt-3"
                              :rules="[RuleFactory.required()]"/>
        <v-autocomplete label="Unterrichtete Fächer" v-else single-line rounded outlined dense class="mt-3"
                        disabled/>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-can:read-employees="{ keys: ['isTeacher'] }">
      <v-flex xs1>
        <v-checkbox color="info" disabled v-model="filterStates.isTeacher"/>
      </v-flex>
      <v-flex xs11>
        <v-select color="info" single-line outlined dense class="mt-3" rounded label="Beschäftigungsverhältnis"
                  :rules="[RuleFactory.required()]" disabled v-model="innerFilter.isTeacher"
                  :items="[{ text: 'Nur Lehrkräfte anzeigen', value: true }]"/>
      </v-flex>
    </v-layout>
    <v-expansion-panels accordion flat v-model="panelValue">
      <v-expansion-panel v-can:read-employees="{ keys: ['isTeacher', 'hasDriverLicense', 'hasCar'] }">
        <filter-dialog-expansion-panel-header title="Mobilität" :filter-count="numberOfMobilityFilters" :value="0"
                                              :panel-value="panelValue"/>
        <v-expansion-panel-content>
          <v-layout row wrap>
            <v-flex xs1>
              <v-checkbox color="info" v-model="filterStates.hasDriverLicense"/>
            </v-flex>
            <v-flex xs11>
              <v-select color="info" single-line outlined dense class="mt-3" rounded label="Führerschein"
                        :rules="[RuleFactory.required()]" v-if="filterStates.hasDriverLicense"
                        v-model="innerFilter.hasDriverLicense"
                        :items="hasDriverLicenseItems"/>
              <v-select label="Führerschein" v-else single-line rounded outlined dense class="mt-3" disabled/>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs1>
              <v-checkbox color="info" v-model="filterStates.hasCar"/>
            </v-flex>
            <v-flex xs11>
              <v-select color="info" single-line outlined dense class="mt-3" rounded label="Fahrzeug"
                        :rules="[RuleFactory.required()]" v-if="filterStates.hasCar" v-model="innerFilter.hasCar"
                        :items="hasCarItems"/>
              <v-select label="Fahrzeug" v-else single-line rounded outlined dense class="mt-3" disabled/>
            </v-flex>
          </v-layout>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-can:read-employees="{ keys: ['isTeacher', 'assignedInstitutions'] }">
        <filter-dialog-expansion-panel-header title="Standortplanung" :panel-value="panelValue"
                                              :filter-count="numberOfInstitutionPlanningFilters" :value="1"/>
        <v-expansion-panel-content>
          <v-layout row wrap>
            <v-flex xs1>
              <v-checkbox color="info" v-model="filterStates.assignedInstitutions"/>
            </v-flex>
            <v-flex xs11>
              <institution-autocomplete v-model="innerFilter.assignedInstitutions" color="info" multiple
                                        v-if="filterStates.assignedInstitutions" single-line outlined dense
                                        rounded label="Zugeteilte Standorte" class="mt-3"
                                        :rules="[RuleFactory.required()]"/>
              <v-autocomplete label="Zugeteilte Standorte" v-else single-line rounded outlined dense class="mt-3"
                              disabled/>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs1>
              <v-checkbox color="success" v-model="filterStates.plannedInstitutions"/>
            </v-flex>
            <v-flex xs11>
              <institution-autocomplete v-model="innerFilter.plannedInstitutions" color="info" multiple
                                        v-if="filterStates.plannedInstitutions" single-line outlined dense
                                        rounded label="Vorgemerkte Standorte" class="mt-3"
                                        :rules="[RuleFactory.required()]"/>
              <v-autocomplete label="Vorgemerkte Standorte" v-else single-line rounded outlined dense class="mt-3"
                              disabled/>
            </v-flex>
          </v-layout>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-can:read-employees="{ keys: ['isTeacher', 'acquiredBy'] }">
        <filter-dialog-expansion-panel-header title="Sonstige" :panel-value="panelValue"
                                              :filter-count="numberOfOtherFilters" :value="2"/>
        <v-expansion-panel-content>
          <v-layout row wrap>
            <v-flex xs1>
              <v-checkbox color="info" v-model="filterStates.acquiredBy"/>
            </v-flex>
            <v-flex xs11>
              <employee-autocomplete v-model="innerFilter.acquiredBy" color="info" multiple
                                     v-if="filterStates.acquiredBy" single-line outlined dense
                                     rounded label="Akquiriert von" class="mt-3"
                                     :rules="[RuleFactory.required()]"/>
              <v-autocomplete label="Akquiriert von" v-else single-line rounded outlined dense class="mt-3"
                              disabled/>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs1>
              <v-checkbox color="info" v-model="filterStates.hasLogin"/>
            </v-flex>
            <v-flex xs11>
              <v-select color="info" single-line outlined dense class="mt-3" rounded label="Vanilla-Zugang"
                        :rules="[RuleFactory.required()]" v-if="filterStates.hasLogin"
                        v-model="innerFilter.hasLogin"
                        :items="hasLoginItems"/>
              <v-select label="Vanilla-Zugang" v-else single-line rounded outlined dense class="mt-3" disabled/>
            </v-flex>
          </v-layout>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {FindEmployeeFilter} from '@/classes/dto/employee-handler/request/filters/FindEmployee.filter';
import EmployeeAutocomplete from '@/components/common/search-fields/employeeAutocomplete.vue';
import SubjectAutocomplete from '@/components/common/search-fields/subjectAutocomplete.vue';
import InstitutionAutocomplete from '@/components/common/search-fields/institutionAutocomplete.vue';
import FilterDialogExpansionPanelHeader
  from '@/components/common/filterDialogComponents/filterDialogExpansionPanelHeader.vue';

export default Vue.extend({
  components: {FilterDialogExpansionPanelHeader, InstitutionAutocomplete, SubjectAutocomplete, EmployeeAutocomplete},
  props: {
    filter: {
      type: Object as () => FindEmployeeFilter,
      default: () => ({
        isTeacher: true,
        isActive: true,
      } as FindEmployeeFilter),
    },
  },
  data: () => ({
    filterStates: {
      isTeacher: true,
      isActive: true,
    } as Record<keyof FindEmployeeFilter, boolean>,
    innerFilter: {} as FindEmployeeFilter,
    panelValue: null as number | null,
    isActiveItems: [{
      text: 'Nur aktive Lehrkräfte anzeigen',
      value: true,
    }, {
      text: 'Nur inaktive Lehrkräfte anzeigen',
      value: false,
    }],
    hasDriverLicenseItems: [{
      text: 'Nur Lehrkräfte mit Führerschein anzeigen',
      value: true,
    }, {
      text: 'Nur Lehrkräfte ohne Führerschein anzeigen',
      value: false,
    }],
    hasCarItems: [{
      text: 'Nur Lehrkräfte mit Fahrzeug anzeigen',
      value: true,
    }, {
      text: 'Nur Lehrkräfte ohne Fahrzeug anzeigen',
      value: false,
    }],
    hasLoginItems: [{
      text: 'Nur Lehrkräfte mit Zugang zu Vanilla anzeigen',
      value: true,
    }, {
      text: 'Nur Lehrkräfte ohne Zugang zu Vanilla anzeigen',
      value: false,
    }],
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    numberOfMobilityFilters(): number {
      let n = 0;
      if (this.filterStates.hasDriverLicense) {
        n++;
      }
      if (this.filterStates.hasCar) {
        n++;
      }
      return n;
    },
    numberOfInstitutionPlanningFilters(): number {
      let n = 0;
      if (this.filterStates.assignedInstitutions) {
        n++;
      }
      if (this.filterStates.plannedInstitutions) {
        n++;
      }
      return n;
    },
    numberOfOtherFilters(): number {
      let n = 0;
      if (this.filterStates.acquiredBy) {
        n++;
      }
      if (this.filterStates.hasLogin) {
        n++;
      }
      return n;
    },
  },
  methods: {
    initializeFilterStates() {
      this.$set(this, 'filterStates', {});
      this.$nextTick(() => {
        this.$set(this, 'innerFilter', {...this.filter});
        for (const key in this.filter) {
          if (this.filter.hasOwnProperty(key)) {
            this.$set(this.filterStates, key, typeof (this.filter as Record<string, any>)[key] !== 'undefined');
          }
        }
      });
    },
    // updateFilter() {
    //   const form = this.$refs.form as Form;
    //   if (form) {
    //     form.resetValidation();
    //   }
    //   for (const key in this.filterStates) {
    //     if (!this.filterStates.hasOwnProperty(key) || !(this.filterStates as Record<string, boolean>)[key]) {
    //       this.$delete(this.innerFilter, key);
    //     }
    //   }
    // },
    applyFilter() {
        this.$emit('update:filter', {...this.innerFilter});
    },
  },
  watch: {
    // filterStates: {
    //   deep: true,
    //   handler() {
    //     this.updateFilter();
    //   },
    // },
    innerFilter: {
      deep: true,
      handler() {
        this.applyFilter();
      },
    },
  },
  mounted() {
    this.initializeFilterStates();
  },
});
</script>
