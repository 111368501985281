export enum BookingFormTemplateFields {
    NAME = 'name',
    DESCRIPTION = 'description',
    CREATES_STUDENT = 'createsStudent',
    CUSTOMER_TYPE = 'customerType',
    CREATES_COURSE = 'createsCourse',
    COURSE_TYPE = 'courseType',
    SELECTABLE_TIMESLOTS = 'selectableTimeslots',
    SELECTABLE_COURSES = 'selectableCourses',
    USE_OPEN_COURSES = 'useOpenCourses',
    USE_DRAFT_COURSES = 'useDraftCourses',
    PRINTED_FORM_CONFIG = 'printedFormConfig',
    ONLINE_FORM_CONFIG = 'onlineFormConfig',
    INVOICE_TEMPLATES = 'invoiceTemplates',
    SELECTABLE_SUBJECTS = 'selectableSubjects',
    CUSTOM_PLACEHOLDERS = 'customPlaceholders',
    ADDITIONAL_DOCUMENTS = 'additionalDocuments',
    IS_ACTIVE = 'isActive',
}
