<template>
  <v-btn class="ma-2" elevation="1" x-small depressed fab :color="color" @click="emitInput">
    <v-icon :color="iconColor" v-if="value === outputValue">mdi-check</v-icon>
  </v-btn>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    color: { type: String, required: true },
    outputValue: { type: String, required: true },
    value: { type: String, required: true },
    iconColor: { type: String, default: 'white' },
  },
  methods: {
    emitInput() {
      this.$emit('input', this.outputValue);
    },
  },
});
</script>
