<template>
  <span v-if="activeAcquisitionLists.length > 0 && activeInstitution">
      <v-tooltip bottom v-if="showTooltip">
        <template v-slot:activator="{ on }">
          <v-btn icon @click="showLinkDialog = true;" v-on="on">
            <v-icon>mdi-room-service</v-icon>
        </v-btn>
        </template>
        <span>{{ tooltipText }}</span>
      </v-tooltip>
    <template v-else>
      <v-btn class="mt-2" small text @click="showLinkDialog = true;">
        <v-icon class="mr-2" small>mdi-page-next-outline</v-icon>
        Liste öffnen
      </v-btn>
    </template>

    <!-- DIALOGS -->
    <to-dos-widget-institution-link-dialog v-if="activeInstitution && activeInstitution.name"
                                           :institution="activeInstitution" :acquisition-lists="activeAcquisitionLists"
                                           v-model="showLinkDialog"/>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from "@/types/NotificationLike.type";
import {
  ToDoAssignedNotificationDocument,
} from "@/documentTypes/notifier/discriminators/ToDoAssignedNotification.document";
import {AcquisitionList} from "@/documentTypes/institutioner/acquisition/AcquisitionList.document";
import {Institution} from "@/interfaces/institution.interface";
import mongoose from "mongoose";
import {InstitutionerApi} from "@/classes/api/institutioner.api.class";
import {
  AcquisitionListFields,
} from "@/classes/clientOnly/permissionTreeResources/enums/entityFields/AcquisitionListFields";
import {CrudEntityTypes} from "@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes";
import ToDosWidgetInstitutionLinkDialog
  from "@/components/dashboard/widgets/toDos/toDosWidgetInstitutionLinkDialog.vue";

export default Vue.extend({
  components: {ToDosWidgetInstitutionLinkDialog},
  props: {
    notification: {
      type: Object as () => NotificationLike<ToDoAssignedNotificationDocument>,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: 'Akquiseliste öffnen',
    },
  },
  data: () => ({
    showLinkDialog: false,
    activeAcquisitionLists: [] as AcquisitionList[],
    activeInstitution: null as null | Institution,
  }),
  methods: {
    async institutionInAcquisitionList(id: mongoose.Types.ObjectId) {
      try {
        const resp = await InstitutionerApi.acquisition.find({
          fields: [AcquisitionListFields.NAME, AcquisitionListFields.FOR_EMPLOYEE, AcquisitionListFields.ASSIGNEE, AcquisitionListFields.INSTITUTIONS],
          filter: {
            institution: [id],
            isActive: true,
          },
          populate: {
            institution: {
              fields: ['name'],
            },
          },
          skipPagination: true,
        });
        if (resp.acquisitionLists.length > 0) {
          this.activeAcquisitionLists = resp.acquisitionLists;
          const institution = await InstitutionerApi.findById(id);
          if (institution) {
            this.activeInstitution = institution;
          } else {
            this.$$showSnackbar('Fehler beim Laden des Standorts', 'error');
          }
        }
      } catch (e) {
        this.$$showSnackbar('Fehler beim Laden der möglichen Akquiselisten', 'error', e);
      }
    },
  },
  watch: {
    notification: {
      immediate: true,
      handler(notification: NotificationLike<ToDoAssignedNotificationDocument>) {
        if (notification.messagePayload.entityType === CrudEntityTypes.INSTITUTION && notification.messagePayload.entityId) {
          this.institutionInAcquisitionList(notification.messagePayload.entityId);
        }
      },
      deep: true,
    },
  },
});

</script>
