import {LeanDocument, Types} from 'mongoose';
import {API} from './api.class';
import {CreateMessengerAccountRequestDto} from '@/classes/dto/messenger/request/CreateMessengerAccount.request.dto';
import {FindByIdOptionsRequestDto} from '@/classes/dto/_common/request/FindByIdOptions.request.dto';
import {MessengerAccountDocument} from '@/documentTypes/messenger/MessengerAccount.document';
import {FindMessengerAccountRequestDto} from '@/classes/dto/messenger/request/FindMessengerAccount.request.dto';
import {UpdateMessengerAccountRequestDto} from '@/classes/dto/messenger/request/UpdateMessengerAccount.request.dto';
import {
    MessengerMailDocument,
    MessengerMessageDocument,
    MessengerWhatsAppMessageDocument,
} from '@/documentTypes/messenger/messages';
import {FindMessengerMailRequestDto} from '@/classes/dto/messenger/request/FindMessengerMail.request.dto';
import {GetConversationsRequestDto} from '@/classes/dto/messenger/request/messages/GetConversations.request.dto';
import {GetConversationsResponseDto} from '@/classes/dto/messenger/response/messages/GetConversations.response.dto';
import {SendMessengerMailRequestDto} from '@/classes/dto/messenger/request/SendMessengerMail.request.dto';
import {
    FindMessengerWhatsAppMessageRequestDto,
} from '@/classes/dto/messenger/request/FindMessengerWhatsAppMessage.request.dto';
import {FindMessengerMessagesRequestDto} from '@/classes/dto/messenger/request/FindMessengerMessages.request.dto';
import {
    SendMessengerWhatsAppMessageRequestDto,
} from '@/classes/dto/messenger/request/SendMessengerWhatsAppMessage.request.dto';
import {
    GetMessengerAccountOptionsResponseDto,
} from '@/classes/dto/messenger/response/accounts/GetMessengerAccountOptions.response.dto';
import {
    SendMessengerMailTemplateRequestDto,
} from '@/classes/dto/messenger/request/SendMessengerMailTemplate.request.dto';

export class MessengerApi extends API {
    static service = 'messenger';

    static accounts = {
        create: async (data: CreateMessengerAccountRequestDto): Promise<Types.ObjectId> => {
            return this.post('accounts', data);
        },
        getOptions: async (): Promise<GetMessengerAccountOptionsResponseDto> => {
            return this.get('accounts/options');
        },
        findById: async (id: Types.ObjectId, options?: FindByIdOptionsRequestDto): Promise<LeanDocument<MessengerAccountDocument>> => {
            return this.get(`accounts/${id}`, options);
        },
        find: async (data: FindMessengerAccountRequestDto): Promise<{
            messengerAccounts: LeanDocument<MessengerAccountDocument>[],
            maxPage: number
        }> => {
            return this.get('accounts', data);
        },
        update: async (id: Types.ObjectId, data: UpdateMessengerAccountRequestDto) => {
            return this.put(`accounts/${id}`, data);
        },
        delete: async (id: Types.ObjectId) => {
            return this.delete(`accounts/${id}`);
        },
    }

    static messages = {
        getConversations: async (data: GetConversationsRequestDto): Promise<GetConversationsResponseDto> => {
            return this.get('messages/conversations', data);
        },
        find: async (data: FindMessengerMessagesRequestDto): Promise<{
            messengerMessages: LeanDocument<MessengerMessageDocument>[],
            maxPage: number
        }> => {
            return this.get('messages', data);
        },
        findById: async (id: Types.ObjectId, options?: FindByIdOptionsRequestDto): Promise<LeanDocument<MessengerMessageDocument>> => {
            return this.get(`messages/${id}`, options);
        },
        mails: {
            findById: async (id: Types.ObjectId, options?: FindByIdOptionsRequestDto): Promise<LeanDocument<MessengerMailDocument>> => {
                return this.get(`mails/${id}`, options);
            },
            find: async (data: FindMessengerMailRequestDto): Promise<{
                messengerMails: LeanDocument<MessengerMailDocument>[],
                maxPage: number
            }> => {
                return this.get('mails', data);
            },
            sendMail: async (data: SendMessengerMailRequestDto): Promise<Types.ObjectId> => {
                return this.post('mails', data);
            },
            sendMailTemplate: async (data: SendMessengerMailTemplateRequestDto): Promise<Types.ObjectId> => {
                return this.post('mails/template', data);
            },
        },
        whatsApp: {
            sendMessage: async (data: SendMessengerWhatsAppMessageRequestDto): Promise<Types.ObjectId> => {
                return this.post('whats-app/send-message', data);
            },
            findById: async (id: Types.ObjectId, options?: FindByIdOptionsRequestDto): Promise<LeanDocument<MessengerWhatsAppMessageDocument>> => {
                return this.get(`whats-app/${id}`, options);
            },
            find: async (data: FindMessengerWhatsAppMessageRequestDto): Promise<{
                messengerWhatsAppMessages: LeanDocument<MessengerWhatsAppMessageDocument>[],
                maxPage: number
            }> => {
                return this.get('whats-app', data);
            },
        },
    }

}
