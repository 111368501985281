<template>
  <v-card flat color="transparent">
    <v-subheader>Hautfarbe</v-subheader>
    <v-card-text>
      <color-button color="#FD9842" :value="skinColor" @input="(v) => $emit('update:skinColor', v)" output-value="tanned"/>
      <color-button color="#EDB989" :value="skinColor" @input="(v) => $emit('update:skinColor', v)" output-value="light"/>
      <color-button color="#D08B5B" :value="skinColor" @input="(v) => $emit('update:skinColor', v)" output-value="brown"/>
      <color-button color="#AE5D29" :value="skinColor" @input="(v) => $emit('update:skinColor', v)" output-value="darkBrown"/>
      <color-button color="#624335" :value="skinColor" @input="(v) => $emit('update:skinColor', v)" output-value="dark"/>
      <color-button color="#F8D25C" :value="skinColor" @input="(v) => $emit('update:skinColor', v)" output-value="yellow"/>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn text color="info" @click="$emit('next')">
        Weiter
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue';
import ColorButton from '@/components/common/profile/createAvataaarDialog/colorButton.vue';

export default Vue.extend({
  components: {ColorButton},
  props: {
    skinColor: {
      type: String,
      default: '',
    },
  },
});
</script>
