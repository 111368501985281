<template>
  <div>
    <v-row dense>
      <v-col>
        <v-autocomplete :search-input.sync="addressSearchInput" :prepend-icon="prependIcon"
                        v-model="selectedAddressSearchItem" hide-no-data @input="handleAddressSearchInput"
                        :items="addressSearchItems" :clearable="clearable" :disabled="useManualInput" return-object
                        :loading="addressSearchLoading" :label="label" :color="color" no-filter :rounded="rounded"
                        :item-color="color" :dense="dense" :rules="rules" :outlined="outlined"
                        @update:search-input="handleAddressSearch">
          <template v-slot:item="{item}">
            <v-list-item-icon>
              <v-icon v-if="item.source === 'google-maps'">mdi-google-maps</v-icon>
              <v-icon v-else-if="item.source === 'osm'">mdi-map</v-icon>
              <v-icon v-else>mdi-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
              <v-list-item-subtitle style="opacity: 0.5" v-if="item.source === 'google-maps'">
                Bereitgestellt von Google Maps
              </v-list-item-subtitle>
              <v-list-item-subtitle style="opacity: 0.5" v-else-if="item.source === 'osm'">
                Bereitgestellt von Open Street Map
              </v-list-item-subtitle>
              <v-list-item-subtitle style="opacity: 0.5" v-else>
                Unbekannte Quelle
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="1" v-if="allowManualInput">
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-scale-transition group leave-absolute origin="center">
              <v-btn icon v-if="useManualInput" @click="useManualInput = false" v-on="on" key="disableManual">
                <v-icon>mdi-pencil-off</v-icon>
              </v-btn>
              <v-btn icon v-else @click="useManualInput = true" v-on="on" key="enableManual" :color="color">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-scale-transition>
          </template>
          <span v-if="useManualInput">Zur automatischen Eingabe wechseln</span><span v-else>Zur manuellen Eingabe wechseln</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-slide-y-transition>
      <v-row v-if="useManualInput" dense>
        <v-col cols="12" md="6">
          <v-text-field :rules="[RuleFactory.required()]" @input="handleManualInput" :color="color" :rounded="rounded"
                        label="Straße" :outlined="outlined" :dense="dense" v-model="street"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field :color="color" :rounded="rounded" @input="handleManualInput" label="Hausnummer"
                        :outlined="outlined"
                        :dense="dense" v-model="houseNumber"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field :rules="[RuleFactory.required()]" @input="handleManualInput" :color="color" :rounded="rounded"
                        label="Postleitzahl" :outlined="outlined" :dense="dense" v-model="postal"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field :rules="[RuleFactory.required()]" @input="handleManualInput" :color="color" :rounded="rounded"
                        label="Ort" :outlined="outlined" :dense="dense" v-model="city"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete :rules="[RuleFactory.required()]" @input="handleManualInput" :color="color" :rounded="rounded"
                          label="Bundesland" :items="StateCodesVue" :outlined="outlined" :dense="dense"
                          v-model="state"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete :rules="[RuleFactory.required()]" @input="handleManualInput" :color="color" :rounded="rounded"
                          label="Land" :items="CountryCodesVue" :outlined="outlined" :dense="dense" v-model="country"/>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {StateCodes, StateCodesVue} from '@/enums/StateCodes.enum';
import {CountryCodes, CountryCodesVue} from '@/enums/CountryCodes.enum';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {sleep} from '@/helpers/sleep.helper';
import {GeocoderAPI} from '@/classes/api/geocoder.api.class';
import {FindAddressResponseDto} from '@/classes/dto/geocoder/response/FindAddress.response.dto';
import {PlaceTemplateDto} from '@/classes/dto/_common/templates/Place.template.dto';
import {Place} from '@/interfaces/place.interface';
import {PartialPlaceTemplateDto} from '@/classes/dto/_common/templates/PartialPlace.template.dto';

type AddressSearchItem = FindAddressResponseDto['items'][0];
export default Vue.extend({
  props: {
    value: {
      type: Object as () => PlaceTemplateDto,
      required: true,
    },
    defaultObject: {
      type: Object as () => Place | PartialPlaceTemplateDto,
      required: false,
    },
    label: {
      type: String,
      default: 'Adresse',
    },
    color: {
      type: String,
      default: 'info',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      required: false,
    },
    allowManualInput: {
      type: Boolean,
      default: true,
    },
    throttled: {
      type: Boolean,
      default: true,
    },
    throttleDelay: {
      type: Number,
      default: 1000,
    },
    rules: {
      type: Array as () => Array<(v: any) => boolean | string>,
      default: () => ([]),
    },
    requireStreet: {
      type: Boolean,
      default: true,
    },
    useGoogle: {
      type: Boolean,
      default: true,
    },
    useOsm: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    addressSearchInput: '',
    selectedAddressSearchItem: null as AddressSearchItem | null,
    addressSearchItems: [] as AddressSearchItem[],
    addressSearchLoading: false,
    throttleCounter: 0,
    useManualInput: false,

    street: '',
    houseNumber: '',
    postal: '',
    city: '',
    state: null as StateCodes | null,
    country: null as CountryCodes | null,
    lat: undefined as number | undefined,
    lng: undefined as number | undefined,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    StateCodesVue: () => StateCodesVue,
    CountryCodesVue: () => CountryCodesVue,
    hasValidSearchInput(): boolean {
      return !!this.addressSearchInput && (!this.selectedAddressSearchItem || this.selectedAddressSearchItem.text !== this.addressSearchInput);
    },
    defaultItemText(): string {
      if (this.street) {
        if (this.houseNumber) {
          return `${this.street} ${this.houseNumber}, ${this.postal} ${this.city}`.trim();
        }
        return `${this.street}, ${this.postal} ${this.city}`.trim();
      }
      return `${this.postal} ${this.city}`.trim();
    },
  },
  methods: {
    emitUpdate() {
      const place: PlaceTemplateDto = {
        street: this.street,
        houseNumber: this.houseNumber,
        postal: this.postal,
        city: this.city,
        state: this.state as StateCodes,
        country: this.country as CountryCodes,
      };
      if (typeof this.lat !== 'undefined' && typeof this.lng !== 'undefined') {
        place.location = {
          type: 'Point',
          coordinates: [this.lng, this.lat],
        };
      }
      this.$emit(`input`, place);
    },
    handleAddressSearchInput() {
      if (this.selectedAddressSearchItem) {
        const place = this.selectedAddressSearchItem.value;
        this.street = place.street;
        this.houseNumber = place.houseNumber as string;
        this.postal = place.postal;
        this.city = place.city;
        this.state = place.state as StateCodes;
        this.country = place.country;
        this.lng = place.location.coordinates[0];
        this.lat = place.location.coordinates[1];
      } else {
        this.street = '';
        this.houseNumber = '';
        this.postal = '';
        this.city = '';
        this.state = null;
        this.country = null;
        this.lat = undefined;
        this.lng = undefined;
      }
      this.emitUpdate();
    },
    handleManualInput() {
      const value = {
        street: this.street,
        houseNumber: this.houseNumber,
        postal: this.postal,
        city: this.city,
        state: this.state,
        country: this.country,
        location: undefined,
      };
      const item: AddressSearchItem = {text: this.defaultItemText, value: value as any, source: 'unknown'};
      this.addressSearchItems = [item];
      this.selectedAddressSearchItem = item;
      this.lat = undefined;
      this.lng = undefined;
      this.emitUpdate();
    },
    loadDefault() {
      if (this.defaultObject) {
        this.street = this.defaultObject.street as unknown as string;
        this.houseNumber = this.defaultObject.houseNumber as unknown as string;
        this.postal = this.defaultObject.postal as unknown as string;
        this.city = this.defaultObject.city as unknown as string;
        this.state = this.defaultObject.state as unknown as StateCodes;
        this.country = this.defaultObject.country as unknown as CountryCodes;
        const item: AddressSearchItem = { text: this.defaultItemText, value: this.defaultObject as any, source: 'unknown' };
        this.addressSearchItems = [item];
        this.selectedAddressSearchItem = item;
        this.lat = this.defaultObject.location?.coordinates[1];
        this.lng = this.defaultObject.location?.coordinates[0];
        this.emitUpdate();
      }
    },
    async handleAddressSearch() {
      if (!this.hasValidSearchInput) {
        return;
      }
      this.addressSearchLoading = true;
      if (this.throttled) {
        this.throttleCounter++;
        await sleep(this.throttleDelay);
        this.throttleCounter--;
        if (this.throttleCounter > 0) {
          return;
        }
      }
      try {
        if (this.hasValidSearchInput) {
          const resp = await GeocoderAPI.findAddress({
            $search: this.addressSearchInput,
            useGoogle: this.useGoogle,
            useOsm: this.useOsm,
            requireStreet: this.requireStreet,
          });
          this.addressSearchItems = resp.items;
        }
      } finally {
        this.addressSearchLoading = false;
      }
    },
  },
  watch: {
    useManualInput(v: boolean) {
      if (!v) {
        this.street = '';
        this.houseNumber = '';
        this.postal = '';
        this.city = '';
        this.state = null;
        this.country = null;
        this.lat = undefined;
        this.lng = undefined;
        this.selectedAddressSearchItem = null;
        this.addressSearchItems = [];
        this.emitUpdate();
      }
    },
    defaultObject: {
      deep: true,
      immediate: true,
      handler() {
        this.loadDefault();
      },
    },
  },
});
</script>
