import {AvataaarOptions} from '@/interfaces/avataaarOptions.interface';
import {
    avataaarAccessoriesType,
    avataaarClothesColors,
    avataaarClothesGraphicsType,
    avataaarClothesType,
    avataaarEyebrowType,
    avataaarEyesType,
    avataaarFacialHairColor,
    avataaarFacialHairType,
    avataaarMouthType,
    avataaarSkinColor,
    avataaarTopColor,
    avataaarTopType,
} from '@/constants/avataaar.constant';

export class Avataaar {
    constructor(input?: string | AvataaarOptions) {
        if (input) {
            if (typeof input === 'string') {
                const rex = /[0-9A-Fa-f]{6}/g;
                if (!rex.test(input)) {
                    return;
                }
                const hexComponents: string[] = [];
                while (input.length > 0) {
                    hexComponents.push(input.substring(0, 2));
                    input = input.substring(2);
                }
                for (let i = 0; i < hexComponents.length; i++) {
                    const num = parseInt(hexComponents[i], 16) - 1;
                    switch (i) {
                        case 0:
                            this.skinColor = avataaarSkinColor.at(num)?.value as any;
                            break;
                        case 1:
                            this.topType = avataaarTopType.at(num)?.value as any;
                            break;
                        case 2:
                            this.topColor = avataaarTopColor.at(num)?.value as any;
                            break;
                        case 3:
                            this.eyebrowType = avataaarEyebrowType.at(num)?.value as any;
                            break;
                        case 4:
                            this.eyesType = avataaarEyesType.at(num)?.value as any;
                            break;
                        case 5:
                            this.accessoriesType = avataaarAccessoriesType.at(num)?.value as any;
                            break;
                        case 6:
                            this.mouthType = avataaarMouthType.at(num)?.value as any;
                            break;
                        case 7:
                            this.facialHairType = avataaarFacialHairType.at(num)?.value as any;
                            break;
                        case 8:
                            this.facialHairColor = avataaarFacialHairColor.at(num)?.value as any;
                            break;
                        case 9:
                            this.clothesType = avataaarClothesType.at(num)?.value as any;
                            break;
                        case 10:
                            this.clothesGraphicsType = avataaarClothesGraphicsType.at(num)?.value as any;
                            break;
                        case 11:
                            this.clothesColor = avataaarClothesColors.at(num)?.value as any;
                            break;
                        default:
                            throw new Error('Invalid hex string');
                    }
                }
            } else {
                this.skinColor = input.skinColor || 'brown';
                this.clothesType = input.clothesType || 'hoodie';
                this.clothesColor = input.clothesColor || 'blue01';
                this.clothesGraphicsType = input.clothesGraphicsType || 'none';
                this.eyebrowType = input.eyebrowType || 'default';
                this.eyesType = input.eyesType || 'default';
                this.mouthType = input.mouthType || 'smile';
                this.facialHairType = input.facialHairType || 'none';
                this.facialHairColor = input.facialHairColor || 'auburn';
                this.accessoriesType = input.accessoriesType || 'none';
                this.topType = input.topType || 'shortHairTheCaesar';
                this.topColor = input.topColor || 'auburn';
            }
        }
    }

    private skinColor?: 'tanned' | 'yellow' | 'pale' | 'light' | 'brown' | 'darkBrown' | 'dark';

    private clothesType?: 'blazerShirt' | 'blazerSweater' | 'collarSweater' | 'graphicShirt' | 'hoodie' | 'overall' | 'shirtCrewNeck' | 'shirtScoopNeck'
        | 'shirtVNeck';
    private clothesColor?: 'black' | 'blue01' | 'blue02' | 'blue03' | 'gray01' | 'gray02' | 'heather' | 'pastelBlue' | 'pastelGreen' | 'pastelOrange'
        | 'pastelRed' | 'pastelYellow' | 'pink' | 'red' | 'white';
    private clothesGraphicsType?: 'none' | 'bat' | 'cumbia' | 'diamond' | 'pizza' | 'resist' | 'selena' | 'bear' | 'skullOutline' | 'skull';

    private eyebrowType?: 'angry' | 'angryNatural' | 'default' | 'defaultNatural' | 'flatNatural' | 'frownNatural' | 'raisedExcited' | 'raisedExcitedNatural'
        | 'sadConcerned' | 'sadConcernedNatural' | 'unibrowNatural' | 'updown' | 'updownNatural';
    private eyesType?: 'close' | 'cry' | 'default' | 'dizzy' | 'eyeroll' | 'happy' | 'hearts' | 'side' | 'squint' | 'surprised' | 'wink' | 'winkWacky';
    private mouthType?: 'concerned' | 'default' | 'disbelief' | 'eating' | 'grimace' | 'sad' | 'screamOpen' | 'serious' | 'smile' | 'tongue' | 'twinkle' | 'vomit';

    private facialHairType?: 'none' | 'beardMedium' | 'beardLight' | 'beardMajestic' | 'moustacheFancy' | 'moustacheMagnum';
    private facialHairColor?: 'auburn' | 'black' | 'blonde' | 'blondeGolden' | 'brown' | 'brownDark' | 'platinum' | 'red';

    private accessoriesType?: 'none' | 'eyepatch' | 'kurt' | 'prescription01' | 'prescription02' | 'round';
    private topType?: 'none' | 'hat' | 'hijab' | 'turban' | 'winterHat1' | 'winterHat2' | 'winterHat3' | 'winterHat4' |
        'longHairBigHair' | 'longHairBob' | 'longHairBun' | 'longHairCurly' | 'longHairCurvy' | 'longHairDreads' | 'longHairFro' | 'longHairFroBand'
        | 'longHairNotTooLong' | 'longHairShavedSides' | 'longHairMiaWallace' | 'longHairStraight' | 'longHairStraight2' | 'longHairStraightStrand'
        | 'shortHairDreads01' | 'shortHairDreads02' | 'shortHairFrizzle' | 'shortHairShaggyMullet' | 'shortHairShortCurly' | 'shortHairShortFlat'
        | 'shortHairShortRound' | 'shortHairShortWaved' | 'shortHairSides' | 'shortHairTheCaesar' | 'shortHairTheCaesarSidePart';
    private topColor?:
        'auburn' | 'black' | 'blonde' | 'blondeGolden' | 'brown' | 'brownDark' | 'platinum' | 'red' |
        'blue01' | 'blue02' | 'blue03' | 'gray01' | 'gray02' | 'heather' | 'pastelBlue' | 'pastelGreen'
        | 'pastelOrange' | 'pastelRed' | 'pastelYellow' | 'pink' | 'white';

    public get options(): AvataaarOptions {
        return {
            skinColor: this.skinColor || 'brown',
            clothesType: this.clothesType || 'hoodie',
            clothesColor: this.clothesColor || 'blue01',
            clothesGraphicsType: this.clothesGraphicsType || 'none',
            eyebrowType: this.eyebrowType || 'default',
            eyesType: this.eyesType || 'default',
            mouthType: this.mouthType || 'smile',
            facialHairType: this.facialHairType || 'none',
            facialHairColor: this.facialHairColor || 'auburn',
            accessoriesType: this.accessoriesType || 'none',
            topType: this.topType || 'shortHairTheCaesar',
            topColor: this.topColor || 'auburn',
            backgroundColor: undefined,
            backgroundType: 'transparent',
        };
    }

    public toHex(): string {
        let str = '';
        if (this.skinColor) {
            const index = avataaarSkinColor.findIndex(color => color.value === this.skinColor) + 1;
            if (index < 16) {
                str += '0';
            }
            str += index.toString(16);
        } else {
            str += '00';
        }
        if (this.topType) {
            const index = avataaarTopType.findIndex(type => type.value === this.topType) + 1;
            if (index < 16) {
                str += '0';
            }
            str += index.toString(16);
        } else {
            str += '00';
        }
        if (this.topColor) {
            const index = avataaarTopColor.findIndex(color => color.value === this.topColor) + 1;
            if (index < 16) {
                str += '0';
            }
            str += index.toString(16);
        } else {
            str += '00';
        }
        if (this.eyebrowType) {
            const index = avataaarEyebrowType.findIndex(type => type.value === this.eyebrowType) + 1;
            if (index < 16) {
                str += '0';
            }
            str += index.toString(16);
        } else {
            str += '00';
        }
        if (this.eyesType) {
            const index = avataaarEyesType.findIndex(type => type.value === this.eyesType) + 1;
            if (index < 16) {
                str += '0';
            }
            str += index.toString(16);
        } else {
            str += '00';
        }
        if (this.accessoriesType) {
            const index = avataaarAccessoriesType.findIndex(type => type.value === this.accessoriesType) + 1;
            if (index < 16) {
                str += '0';
            }
            str += index.toString(16);
        } else {
            str += '00';
        }
        if (this.mouthType) {
            const index = avataaarMouthType.findIndex(type => type.value === this.mouthType) + 1;
            if (index < 16) {
                str += '0';
            }
            str += index.toString(16);
        } else {
            str += '00';
        }
        if (this.facialHairType) {
            const index = avataaarFacialHairType.findIndex(type => type.value === this.facialHairType) + 1;
            if (index < 16) {
                str += '0';
            }
            str += index.toString(16);
        } else {
            str += '00';
        }
        if (this.facialHairColor) {
            const index = avataaarFacialHairColor.findIndex(color => color.value === this.facialHairColor) + 1;
            if (index < 16) {
                str += '0';
            }
            str += index.toString(16);
        } else {
            str += '00';
        }
        if (this.clothesType) {
            const index = avataaarClothesType.findIndex(type => type.value === this.clothesType) + 1;
            if (index < 16) {
                str += '0';
            }
            str += index.toString(16);
        } else {
            str += '00';
        }
        if (this.clothesGraphicsType) {
            const index = avataaarClothesGraphicsType.findIndex(type => type.value === this.clothesGraphicsType) + 1;
            if (index < 16) {
                str += '0';
            }
            str += index.toString(16);
        } else {
            str += '00';
        }
        if (this.clothesColor) {
            const index = avataaarClothesColors.findIndex(color => color.value === this.clothesColor) + 1;
            if (index < 16) {
                str += '0';
            }
            str += index.toString(16);
        } else {
            str += '00';
        }
        return str;
    }
}
