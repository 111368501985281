<template>
  <v-dialog :value="value" :persistent="loading" @input="reset" max-width="600"
            :fullscreen="$vuetify.breakpoint.smAndDown">
    <v-card>
      <v-card-title>
        <div class="error--text">
          Stapel löschen
        </div>
        <v-spacer/>
        <v-btn icon :disabled="loading" @click="reset">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          Du bist im Begriff, diesen Stapel zu löschen.
          Dieser Vorgang kann <strong>nicht</strong> rückgängig gemacht werden.
        </p>
        <p>
          Vanilla bietet dir alternativ die Möglichkeit, diesen Stapel zu archivieren.
          Der Stapel ist dann standardmäßig ausgeblendet, bleibt aber allen berechtigten Nutzern
          weiterhin zugänglich.
        </p>
        <p>
          Bist du sicher, dass du diesen Stapel löschen möchtest?
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="reset" text :disabled="loading">
          Abbrechen
        </v-btn>
        <v-spacer/>
        <v-btn color="error" :loading="loading" @click="removeStack">
          Stapel löschen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {Types} from 'mongoose';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {StackBus} from '@/busses/StackBus';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    stackId: {
      type: Types.ObjectId,
      required: false,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async removeStack() {
      if (!this.stackId) {
        this.$$showSnackbar('Die Stapelreferenz konnte nicht ermittelt werden', 'error');
      }
      this.loading = true;
      try {
        await StackManagerApi.remove(this.stackId);
        this.$$showSnackbar('Der Stapel wurde erfolgreich gelöscht', 'success');
        StackBus.$emit('deleted:stack', this.stackId);
        this.reset();
      } catch (e) {
        this.$$showSnackbar('Beim Löschen des Stapels ist ein Fehler aufgetreten', 'error');
      } finally {
        this.loading = false;
      }
    },
    reset() {
      this.$emit('input', false);
    },
  },
});
</script>
