export enum SubmissionStates {
    ACTIVE,
    PENDING,
    CANCELLED,
    COMPLETED,
}

export const SubmissionStatesVue = [{
    text: 'Aktive Anmeldung',
    value: SubmissionStates.ACTIVE,
    icon: 'mdi-file-document-arrow-right',
    color: '',
}, {
    text: 'Warteliste',
    value: SubmissionStates.PENDING,
    icon: 'mdi-file-clock',
    color: 'info',
}, {
    text: 'Abgebrochen',
    value: SubmissionStates.CANCELLED,
    icon: 'mdi-file-cancel',
    color: 'error',
},
// , {
//     text: 'Abgeschlossen',
//     value: SubmissionStates.COMPLETED,
//     icon: 'mdi-file-check',
//     color: 'success',
// }
]
