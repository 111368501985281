<template>
  <v-dialog
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :value="value"
      max-width="750px"
      persistent
      transition="dialog-transition"
      @input="reset"
  >
    <v-card>
      <v-card-title>
        <div class="warning--text">Lehrkräfte vormerken</div>
        <v-spacer/>
        <v-btn icon @click="reset" :disabled="loading || removeLoading">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-2">
        <v-list v-can:read-employees="{ keys: ['firstName' ,'lastName'] }">
          <div v-for="(item, i) in plannedEmployees" :key="i">
            <v-list-item >
              <v-list-item-icon>
                <v-icon>mdi-human-male-board</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.firstName ? item.firstName : ''}} {{item.lastName ? item.lastName : ''}}
                  <span v-if="!item.firstName && !item.lastName">Unbekannt</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  Bereits vorgemerkte Lehrkraft
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-can:read-employees="{ id: item._id, keys: ['plannedInstitutions'] }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="removePlanning(item._id)" :loading="removeLoading">
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Zuweisung aufheben</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </div>
          <div v-if=" plannedEmployees.length === 0">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-human-male-board</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Es wurden noch keine Lehrkräfte zugeteilt
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
        <v-row>
          <v-col>
            <employee-autocomplete :prefetch="employeePrefetch" :prefetch-filter="employeePrefetchFilter" :filter="{ isActive: true, isTeacher: true }" label="Lehrkraft auswählen" v-model="addEmployee" prepend-icon="mdi-human-male-board" color="warning" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="reset">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="warning" @click="addPlanning" :disabled="!addEmployee || loading || removeLoading">Lehrkraft vormerken</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { Employee } from '@/interfaces/employee.interface';
import { Types } from 'mongoose';
import { EmployeeHandlerAPI } from '@/classes/api/employee-handler.api.class';
import { EventBus } from '@/busses/EventBus';
import EmployeeAutocomplete from '@/components/common/search-fields/employeeAutocomplete.vue';
import { FindEmployeeFilter } from '@/classes/dto/employee-handler/request/filters/FindEmployee.filter';
import { CrudEntityTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import { CrudActionTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import { InstitutionerApi } from '@/classes/api/institutioner.api.class';


export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    institutionId: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
    plannedEmployees: {
      type: Array as () => Array<Pick<Employee, '_id' | 'firstName' | 'lastName'>>,
      required: true,
    },
  },
  components: {
    EmployeeAutocomplete,
  },
  data: () => ({
    loading: false,
    addEmployee: null as null | Types.ObjectId,
    removeLoading: false,
    employeePrefetch: false,
    employeePrefetchFilter: {} as FindEmployeeFilter,
  }),
  methods: {
    async getEmployeePrefetchFilter() {
      try {
        const canReadInstitutionAddress = this.$$crudAccessManager.canAccessEntity(CrudEntityTypes.INSTITUTION, CrudActionTypes.READ, this.institutionId, 'address');
        if (canReadInstitutionAddress) {
          const institution = await InstitutionerApi.findById(this.institutionId, {fields: ['address']});
          if (institution.address.location.coordinates.length === 2 && institution.address.location.coordinates[0] !== 0 && institution.address.location.coordinates[1] !== 0) {
            this.employeePrefetchFilter = {
              isTeacher: true,
              near: {
                lat: institution.address.location.coordinates[1],
                lon: institution.address.location.coordinates[0],
                maxDistance: 20000,
              },
            } as FindEmployeeFilter;
            this.employeePrefetch = true;
          }
        }

      } catch (e) {
        this.$$showSnackbar('Beim Laden der Daten ist ein Fehler aufgetreten', 'error');
      }
    },
    async addPlanning() {
      try {
        if (this.addEmployee) {
          this.loading = true;
          await EmployeeHandlerAPI.addAssignment(this.addEmployee, {
            type: 'plan',
            institutionId: this.institutionId,
          });
          this.$$showSnackbar(`Die Vormerkung der Lehrkraft war erfolgreich.`, 'success');
          this.$emit('update:institution');
          EventBus.$emit('update:institution');
        }
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler bei der Vormerkung der Lehrkraft aufgetreten. Bitte erneut versuchen.', 'error');
      } finally {
        this.loading = false;
        this.reset();
      }
    },
    async removePlanning(id: Types.ObjectId) {
      try {
        this.removeLoading = false;
        await EmployeeHandlerAPI.removeAssignment(id, {
          type: 'plan',
          institutionId: this.institutionId,
        });
        this.$$showSnackbar('Die Vormerkung wurde erfolgreich aufgehoben.', 'success');
        this.$emit('update:institution');
        EventBus.$emit('update:institution');
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler beim Aufheben der Vormerkung ausgetreten. Bitte erneut versuchen.', 'error');
      } finally {
        this.removeLoading = false;
      }
    },
    reset() {
      this.addEmployee = null;
      this.$emit('input', false);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.getEmployeePrefetchFilter();
        }
      },
    },
  },
});
</script>
