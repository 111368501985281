<template>
  <div v-if="entity && entity.birthday">
    <v-divider/>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-cake</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{moment(entity.birthday).format('DD. MMMM YYYY')}}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{moment().diff(entity.birthday, 'years')}} Jahre alt
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import moment from 'moment';

interface CanHaveBirthday {
  birthday?: Date;
}

export default Vue.extend({
  props: {
    entity: {
      type: Object as () => CanHaveBirthday,
      required: true,
    },
  },
  computed: {
    moment: () => moment,
  },
})
</script>
