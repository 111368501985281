<template>
  <v-alert v-if="notification.showInApp" :key="notification.id" class="elevation-3 mt-4 mx-2 notification" dark
           transition="slide-x-transition">
    <template v-slot:prepend>
      <div class="mr-4">
        <v-icon>mdi-alert</v-icon>
      </div>
    </template>
    <template v-slot:default>
      <div class="body-2">
        Erinnerung: Überfällige Aufgabe!
      </div>
      <v-card class="mt-2" light>
        <v-list-item>
          <v-list-item-avatar>
            <v-simple-checkbox :value="toDoValue" color="error" disabled/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="body-2">
              <overflow-text :text="notification.messagePayload.name"/>
            </v-list-item-title>
            <v-list-item-subtitle v-if="notification.messagePayload.dueDate" class="error--text">
              Fällig seit {{ moment(notification.messagePayload.dueDate).fromNow(true) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-btn :href="link" class="mt-2" small @click="hide" target="_blank" text>
        <v-icon class="mr-2" small>mdi-page-next-outline</v-icon>
        Profil öffnen
      </v-btn>
      <to-do-notification-acquisition-lists-link-button :notification="notification" v-if="notification"/>
    </template>
    <template v-slot:close>
      <v-btn class="ml-2" icon small @click="hide">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import {NotifierApi} from '@/classes/api/notifier.api';
import {
  OverdueToDoNotificationDocument,
} from '@/documentTypes/notifier/discriminators/OverdueToDoNotification.document';
import OverflowText from '@/components/common/overflow-text.vue';
import moment from 'moment';
import ToDoNotificationAcquisitionListsLinkButton
  from "@/components/app/appBar/notifications/appNotifications/components/todoHelpers/toDoNotificationAcquisitionListsLinkButton.vue";

moment.locale('de');

export default Vue.extend({
  components: {ToDoNotificationAcquisitionListsLinkButton, OverflowText},
  props: {
    notification: {
      type: Object as () => NotificationLike<OverdueToDoNotificationDocument>,
      required: true,
    },
  },
  computed: {
    moment: () => moment,
    link(): string {
      return this.$router.resolve(`/${this.notification.messagePayload.entityType}/${this.notification.messagePayload.entityId}`).href;
    },
  },
  data: () => ({
    toDoValue: false,
  }),
  methods: {
    async hide() {
      const id = this.notification._id;
      await NotifierApi.markRead({
        notificationIds: [id],
      });
      this.$store.commit('hideNotification', id);
    },
  },
});
</script>

<style scoped>
.notification {
  background-color: rgba(0, 0, 10, 0.5) !important;
  backdrop-filter: blur(15px);
}
</style>
