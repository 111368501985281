<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
            v-model="mail1"
            :rules="[
              RuleFactory.isValidMail(),
            ]"
            color="warning"
            dense
            hint="optional"
            label="E-Mail-Adresse" outlined
            prepend-icon="mdi-email"
            rounded
            @input="$emit('input', computedContactInformation)"
        ></v-text-field>
        <v-text-field v-model="mail1Note" color="warning" dense
                      label="Anmerkung (optional)"
                      prepend-icon="mdi-note-outline"
                      @input="$emit('input', computedContactInformation)"/>
        <v-text-field
            v-model="mail2"
            :rules="[RuleFactory.isValidMail()]"
            class="mt-4"
            color="warning"
            dense
            hint="optional"
            label="Zweite E-Mail-Adresse"
            outlined prepend-icon="mdi-email"
            rounded
            @input="$emit('input', computedContactInformation)"
        ></v-text-field>
        <v-text-field v-model="mail2Note" color="warning" dense
                      label="Anmerkung (optional)"
                      prepend-icon="mdi-note-outline"
                      @input="$emit('input', computedContactInformation)"/>
      </v-col>
      <v-col>
        <v-text-field
            v-model="phone1"
            v-mask="InputMasks.phoneNumber(phone1)" type="tel"
            :rules="[RuleFactory.isValidPhoneNumber()]"
            color="warning"
            dense
            hint="optional"
            label="Festnetz-Nummer"
            outlined
            prepend-icon="mdi-phone" rounded
            @input="$emit('input', computedContactInformation)"
        ></v-text-field>
        <!-- Do not remove this div or v-mask will apply itself onto the text field!! -->
        <div>
          <v-text-field v-model="phone1Note" color="warning" dense
                        label="Anmerkung (optional)"
                        prepend-icon="mdi-note-outline"
                        @input="$emit('input', computedContactInformation)"/>
        </div>
        <v-text-field
            v-model="phone2"
            v-mask="InputMasks.phoneNumber(phone2)"
            :rules="[RuleFactory.isValidPhoneNumber()]"
            class="mt-4"
            color="warning"
            dense
            hint="optional"
            label="Zweite Festnetz-Nummer"
            outlined prepend-icon="mdi-phone"
            rounded
            @input="$emit('input', computedContactInformation)"
        ></v-text-field>
        <v-text-field v-model="phone2Note" color="warning"
                      dense
                      label="Anmerkung (optional)"
                      prepend-icon="mdi-note-outline"
                      @input="$emit('input', computedContactInformation)"/>
      </v-col>
      <v-col>
        <v-text-field
            v-model="mobile1"
            v-mask="InputMasks.phoneNumber(mobile1)"
            :rules="[RuleFactory.isValidPhoneNumber()]"
            color="warning"
            dense
            hint="optional"
            label="Mobilnummer"
            outlined
            prepend-icon="mdi-cellphone"
            rounded
            @input="$emit('input', computedContactInformation)"
        ></v-text-field>
        <!-- Do not remove this div or v-mask will apply itself onto the text field!! -->
        <div>
          <v-text-field v-model="mobile1Note" color="warning"
                        dense
                        label="Anmerkung (optional)"
                        prepend-icon="mdi-note-outline"
                        @input="$emit('input', computedContactInformation)"/>
        </div>
        <v-text-field
            v-model="mobile2"
            v-mask="InputMasks.phoneNumber(mobile2)"
            :rules="[RuleFactory.isValidPhoneNumber()]"
            class="mt-4"
            color="warning"
            dense
            hint="optional"
            label="Zweite Mobilnummer"
            outlined prepend-icon="mdi-cellphone"
            rounded
            @input="$emit('input', computedContactInformation)"
        ></v-text-field>
        <v-text-field v-model="mobile2Note" color="warning"
                      dense
                      label="Anmerkung (optional)"
                      prepend-icon="mdi-note-outline"
                      @input="$emit('input', computedContactInformation)"/>
      </v-col>

    </v-row>
    <v-row v-if="isBusinessContact">
      <v-col>
        <v-text-field
            v-model="fax1"
            v-mask="InputMasks.phoneNumber(fax1)"
            :rules="[RuleFactory.isValidPhoneNumber()]"
            color="warning"
            dense
            hint="optional"
            label="Telefax"
            outlined
            prepend-icon="mdi-fax" rounded
            @input="$emit('input', computedContactInformation)"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            v-model="fax2"
            v-mask="InputMasks.phoneNumber(fax2)"
            :rules="[RuleFactory.isValidPhoneNumber()]"
            color="warning"
            dense
            hint="optional"
            label="Zweites Telefax"
            outlined
            prepend-icon="mdi-fax" rounded
            @input="$emit('input', computedContactInformation)"
        ></v-text-field>
      </v-col>
      <v-col md6 xl3 xs12>
        <v-text-field
            v-model="website"
            color="warning"
            dense
            hint="optional (Format: http://muster.de oder https://muster.de"
            label="Website"
            outlined
            prepend-icon="mdi-web" rounded
            @input="$emit('input', computedContactInformation)"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {ContactInformation} from '@/interfaces/contactInformation.interface';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {compareForUpdate, UpdateObject} from '@/helpers/compareForUpdate';
import {InputMasks} from '@/helpers/vueTheMask';

export default Vue.extend({
  props: {
    isBusinessContact: {
      type: Boolean,
      default: false,
    },
    contactInformation: {
      type: Object as () => ContactInformation,
      required: true,
    },
  },
  data: () => ({
    fax1: undefined as string | undefined,
    fax2: undefined as string | undefined,
    mail1: undefined as string | undefined,
    mail2: undefined as string | undefined,
    phone1: undefined as string | undefined,
    phone2: undefined as string | undefined,
    mobile1: undefined as string | undefined,
    mobile2: undefined as string | undefined,
    website: undefined as string | undefined,
    mail1Note: undefined as string | undefined,
    mail2Note: undefined as string | undefined,
    phone1Note: undefined as string | undefined,
    phone2Note: undefined as string | undefined,
    mobile1Note: undefined as string | undefined,
    mobile2Note: undefined as string | undefined,
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    InputMasks: () => InputMasks,
    computedContactInformation(): UpdateObject<ContactInformation | undefined> {
      const updateObj = {} as UpdateObject<ContactInformation>;
      updateObj.fax1 = compareForUpdate(this.fax1 || undefined, this.contactInformation.fax1);
      updateObj.fax2 = compareForUpdate(this.fax2 || undefined, this.contactInformation.fax2);
      updateObj.mail1 = compareForUpdate(this.mail1 || undefined, this.contactInformation.mail1);
      updateObj.mail2 = compareForUpdate(this.mail2 || undefined, this.contactInformation.mail2);
      updateObj.phone1 = compareForUpdate(this.phone1 || undefined, this.contactInformation.phone1);
      updateObj.phone2 = compareForUpdate(this.phone2 || undefined, this.contactInformation.phone2);
      updateObj.mobile1 = compareForUpdate(this.mobile1 || undefined, this.contactInformation.mobile1);
      updateObj.mobile2 = compareForUpdate(this.mobile2 || undefined, this.contactInformation.mobile2);
      updateObj.website = compareForUpdate(this.website || undefined, this.contactInformation.website);
      updateObj.notes = {
        mail1: compareForUpdate(this.mail1Note, this.contactInformation.notes?.mail1),
        mail2: compareForUpdate(this.mail2Note, this.contactInformation.notes?.mail2),
        mobile1: compareForUpdate(this.mobile1Note, this.contactInformation.notes?.mobile1),
        mobile2: compareForUpdate(this.mobile2Note, this.contactInformation.notes?.mobile2),
        phone1: compareForUpdate(this.phone1Note, this.contactInformation.notes?.phone1),
        phone2: compareForUpdate(this.phone2Note, this.contactInformation.notes?.phone2),
      } as any;
      // Check if notes has at least one prop which is not undefined
      const hasNotes = Object.keys(updateObj.notes as object).some((key) => updateObj.notes && (updateObj.notes as Record<string, any>)[key] !== undefined);
      if (!hasNotes) {
        updateObj.notes = undefined;
      }
      // Check if updateObj has at least one prop which is not undefined
      const hasProps = Object.keys(updateObj as object).some((key) => updateObj && (updateObj as Record<string, any>)[key] !== undefined);
      if (!hasProps) {
        return undefined;
      }
      return updateObj;
    },
  },
  methods: {
    copy() {
      this.fax1 = this.contactInformation.fax1;
      this.fax2 = this.contactInformation.fax2;
      this.mail1 = this.contactInformation.mail1;
      this.mail2 = this.contactInformation.mail2;
      this.phone1 = this.contactInformation.phone1;
      this.phone2 = this.contactInformation.phone2;
      this.mobile1 = this.contactInformation.mobile1;
      this.mobile2 = this.contactInformation.mobile2;
      this.website = this.contactInformation.website;
      this.mail1Note = this.contactInformation.notes?.mail1;
      this.mail2Note = this.contactInformation.notes?.mail2;
      this.mobile1Note = this.contactInformation.notes?.mobile1;
      this.mobile2Note = this.contactInformation.notes?.mobile2;
      this.phone1Note = this.contactInformation.notes?.phone1;
      this.phone2Note = this.contactInformation.notes?.phone2;
      this.$emit('input', this.computedContactInformation);
    },
  },
  watch: {
    contactInformation: {
      immediate: true,
      deep: true,
      handler() {
        if (this.contactInformation) {
          this.copy();
        }
      },
    },
  },
});
</script>
