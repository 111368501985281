import Vue from 'vue';
import {MessengerEventPayloads, MessengerEvents} from '@/enums/notifier/serviceEvents/MessengerEvents';

interface MessengerEventBus {
    $emit(event: MessengerEvents.NEW_MESSAGE, payload: MessengerEventPayloads[MessengerEvents.NEW_MESSAGE]): void;
    $emit(event: MessengerEvents.UPDATE_MESSAGE, payload: MessengerEventPayloads[MessengerEvents.UPDATE_MESSAGE]): void;

    $on(event: MessengerEvents.NEW_MESSAGE, callback: (payload: MessengerEventPayloads[MessengerEvents.NEW_MESSAGE]) => void): void;
    $on(event: MessengerEvents.UPDATE_MESSAGE, callback: (payload: MessengerEventPayloads[MessengerEvents.UPDATE_MESSAGE]) => void): void;
}

export const MessengerEventBus: MessengerEventBus = new Vue();
