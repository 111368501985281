import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {EventInfoFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/EventInfoFields';

export const eventInfoFieldsDictionary: FieldDictionary<EventInfoFields> = [{
    text: 'Beginn',
    value: EventInfoFields.START_TIME,
}, {
    text: 'Dauer',
    value: EventInfoFields.DURATION_IN_MINUTES,
}, {
    text: 'Art der Veranstaltung',
    value: EventInfoFields.TOPIC,
}, {
    text: 'Veranstaltende',
    value: EventInfoFields.ORGANIZER_LIST,
}, {
    text: 'Teilnehmende',
    value: EventInfoFields.PARTICIPANT_LIST,
}, {
    text: 'Veranstaltungsort',
    value: EventInfoFields.VENUE,
}, {
    text: 'Infos über Absage?',
    value: EventInfoFields.IS_CANCELLED,
}, {
    text: 'Ist öffentlich für alle möglichen Teilnehmenden?',
    value: EventInfoFields.IS_PUBLIC,
}];
