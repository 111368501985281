import {VNode} from 'vue';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import {Types} from 'mongoose';
import store from '@/store';
import {DirectiveBinding} from 'vue/types/options';
import {CustomAccessKeys} from '@/classes/clientOnly/permissionTreeResources/enums/CustomAccessKeys';
import {SingleAccessEntityTypes} from '@/classes/clientOnly/permissionTreeResources/classes/CrudAccessEntity';

type CheckAbilityPayload =
    { action: CrudActionTypes; entity: SingleAccessEntityTypes; id?: Types.ObjectId; keys?: string[] }
    | { action: 'custom'; entity: CustomAccessKeys };

export abstract class CrudManagerDirective {
    public static handle(el: HTMLElement | null, binding: DirectiveBinding, vNode: VNode) {
        let hasAccess = false;

        if (binding && binding.arg) {
            try {
                if (binding.arg.includes('readAndUpdate')) {
                    const splitArguments = binding.arg.split('-');
                    if (splitArguments.length === 2) {
                        const entityType = splitArguments[1] as unknown as CrudEntityTypes;
                        const options = binding.value || {};
                        const canRead = this.checkAbility({ ...options, action: CrudActionTypes.READ, entity: entityType });
                        const canUpdate = this.checkAbility({ ...options, action: CrudActionTypes.UPDATE, entity: entityType });
                        hasAccess = canRead && canUpdate;
                    }
                } else {
                    const args = this.argHelper(binding.arg);
                    const options = binding.value || {};
                    hasAccess = this.checkAbility({...args, ...options});
                }
            } catch (e) {
                hasAccess = false;
            }
        }

        if (!hasAccess && el) {
            if (vNode.elm && vNode.elm.parentElement) {
                vNode.elm.parentElement.removeChild(vNode.elm);
            }
        }
    }

    public static checkAbility(data: CheckAbilityPayload): boolean {
        const instance = store.state.crudAccessManager;
        if (data.action === 'custom') {
            return !!instance.getCustom(data.entity);
        }
        if (data.action === CrudActionTypes.CREATE || data.action === CrudActionTypes.DELETE) {
            return instance.getAccess(data.entity, data.action);
        } else {
            if (!data.id) {
                if (data.keys) {
                    switch (data.action) {
                        case CrudActionTypes.READ:
                            return instance.canReadAllProvided(data.entity, ...data.keys as string[]);
                        case CrudActionTypes.UPDATE:
                            return instance.canUpdateAllProvided(data.entity, ...data.keys as string[]);
                        default:
                            throw new Error(`Action ${data.action} is not supported in this context`);
                    }
                }
                return instance.canAccessType(data.entity, data.action);
            }
            const entity = instance.entity(data.entity, data.id);
            switch (data.action) {
                case CrudActionTypes.READ:
                    if (!data.keys) {
                        return entity.canRead();
                    } else {
                        return entity.canReadAll(...data.keys);
                    }
                case CrudActionTypes.UPDATE:
                    if (!data.keys) {
                        return entity.canUpdate();
                    } else {
                        return entity.canUpdateAll(...data.keys);
                    }
                default:
                    return false;
            }
        }
    }

    private static argHelper(arg: string) {
        const arr = arg.split('-');
        if (Array.isArray(arr) && arr.length === 2) {
            const crudAction = this.getCrudActionByString(arr[0]);
            const crudEntity = arr[1] as unknown as CrudEntityTypes;
            return {action: crudAction, entity: crudEntity};
        } else {
            throw new Error(`The args ${arg} received by directive do not match`);
        }

    }

    private static getCrudActionByString(str: string) {
        switch (str) {
            case CrudActionTypes.READ:
                return CrudActionTypes.READ;
            case CrudActionTypes.CREATE:
                return CrudActionTypes.CREATE;
            case CrudActionTypes.UPDATE:
                return CrudActionTypes.UPDATE;
            case CrudActionTypes.DELETE:
                return CrudActionTypes.DELETE;
            case 'custom':
                return 'custom';
            default:
                throw new Error(`The received string ${str} does not match any CrudActionType`);
        }
    }
}
