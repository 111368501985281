<template>
  <v-list-item :class="conversationUnreadItemColor">
    <v-list-item-content>
      <v-list-item-title :class="conversationUnreadTextColor">
        {{ conversation.name }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="messagePreview">
        {{ messagePreview }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-else>
        Neue Konversation
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script lang="ts">
import Vue from 'vue';
import {GetConversationsResponseDto} from '@/classes/dto/messenger/response/messages/GetConversations.response.dto';
import {MessengerTypesEnum} from '@/enums/messenger/MessengerTypes.enum';

export default Vue.extend({
  props: {
    conversation: {
      type: Object as () => GetConversationsResponseDto['conversations'][0],
      required: true,
    },
  },
  computed: {
    messagePreview(): string {
      return this.conversation.lastMessagePreview?.replace(/<[^>]*>?/gm, ' ')
          .replace(/([/<>])/g, ' ');
    },
    color(): string {
      switch (this.conversation.lastMessageType) {
        case MessengerTypesEnum.MAIL:
          return 'outlook';
        case MessengerTypesEnum.WHATS_APP:
          return 'whatsApp';
        default:
          return 'info';
      }
    },
    conversationUnreadTextColor(): string | undefined {
      if (this.conversation.seen) {
        return undefined;
      }
      return this.color + '--text';
    },
    conversationUnreadItemColor(): string | undefined {
      if (this.conversation.seen) {
        return undefined;
      }
      return `${this.color} lighten-4`;
    },
  },
});
</script>
