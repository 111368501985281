import { VueEnumObject } from '@/interfaces/VueEnumObject.interface';

export enum StackItemStates {
  PENDING,
  DONE,
  IN_PROGRESS,
  WAITING_FOR_REPLY,
  MUTED,
}

export const StackItemStatesVue: Array<VueEnumObject<StackItemStates>> = [{
  text: 'Ausstehend',
  value: StackItemStates.PENDING,
  color: 'grey',
  icon: 'mdi-crop-free',
}, {
  text: 'Erledigt',
  value: StackItemStates.DONE,
  color: 'success',
  icon: 'mdi-check',
}, {
  text: 'In Bearbeitung',
  value: StackItemStates.IN_PROGRESS,
  color: 'warning',
  icon: 'mdi-briefcase-clock',
}, {
  text: 'Warte auf Rückmeldung',
  value: StackItemStates.WAITING_FOR_REPLY,
  color: 'blue-grey',
  icon: 'mdi-reply',
}, {
  text: 'Zur Ablage',
  value: StackItemStates.MUTED,
  color: 'grey',
  icon: 'mdi-archive',
}];
