<template>
  <div v-if="entity && entity.address">
    <v-divider/>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-map-marker</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{entity.address.street}} {{entity.address.houseNumber}}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{entity.address.postal}} {{entity.address.city}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Place} from '@/interfaces/place.interface';

interface CanHaveAddress {
  address?: Place;
}

export default Vue.extend({
  props: {
    entity: {
      type: Object as () => CanHaveAddress,
      required: true,
    },
  },
})
</script>
