export enum SalaryBasis {
  PER_MONTH, PER_HOUR, PER_COURSE, PER_STUDENT,
}

export const SalaryBasisVue = [
  {
      text: 'Monatlicher Fixbetrag',
      value: SalaryBasis.PER_MONTH,
  },
  {
      text: 'Stundenlohn',
      value: SalaryBasis.PER_HOUR,
  },
  {
      text: 'Je Kurs',
      value: SalaryBasis.PER_COURSE,
  },
  {
      text: 'Je Schüler je Kurs',
      value: SalaryBasis.PER_STUDENT,
  },
];
