import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {EventTopicFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/EventTopicFields';

export const eventTopicFieldsDictionary: FieldDictionary<EventTopicFields> = [{
    text: 'Name der Veranstaltungsart',
    value: EventTopicFields.NAME,
}, {
    text: 'Art der Teilnehmenden',
    value: EventTopicFields.PARTICIPANT_TYPES,
}];
