<template>
  <v-badge style="width: 100%" :value="!notification.readAt" color="red" dot offset-x="25" offset-y="20">
    <v-list-item :class="{ 'red lighten-5': !notification.readAt }">
      <v-list-item-avatar>
        <v-icon>mdi-alert</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle>
          Erinnerung: Überfällige Aufgabe!
        </v-list-item-subtitle>
        <v-list-item-title>
          {{ notification.messagePayload.name }}
        </v-list-item-title>
        <v-list-item-subtitle class="error--text" v-if="notification.messagePayload.dueDate">
          {{ moment(notification.messagePayload.dueDate).fromNow() }} fällig
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="!notification.dismissedAt">
          {{ moment(notification.createdAt).fromNow() }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          <v-icon small>mdi-delete</v-icon>
          {{ moment(notification.dismissedAt).fromNow() }} gelöscht
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn icon v-on="on" :href="link" target="_blank">
              <v-icon>mdi-page-next-outline</v-icon>
            </v-btn>
          </template>
          <span>Ansehen</span>
        </v-tooltip>
      </v-list-item-action>
      <v-list-item-action>
        <to-do-notification-acquisition-lists-link-button show-tooltip :notification="notification"
                                                          v-if="notification"/>
      </v-list-item-action>
      <v-list-item-action class="ml-0" v-if="!notification.dismissedAt">
        <v-btn icon @click="$emit('dismiss', notification._id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action class="ml-0" v-else>
        <v-btn icon @click="$emit('revoke', notification._id)">
          <v-icon>mdi-delete-off</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-badge>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import moment from 'moment';
import {
  OverdueToDoNotificationDocument,
} from '@/documentTypes/notifier/discriminators/OverdueToDoNotification.document';
import ToDoNotificationAcquisitionListsLinkButton
  from "@/components/app/appBar/notifications/appNotifications/components/todoHelpers/toDoNotificationAcquisitionListsLinkButton.vue";

moment.locale('de');

export default Vue.extend({
  components: {ToDoNotificationAcquisitionListsLinkButton},
  props: {
    notification: {
      type: Object as () => NotificationLike<OverdueToDoNotificationDocument>,
      required: true,
    },
  },
  computed: {
    moment: () => moment,
    link(): string {
      return this.$router.resolve(`/${this.notification.messagePayload.entityType}/${this.notification.messagePayload.entityId}`).href;
    },
  },
});
</script>
