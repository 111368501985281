<template>
  <v-menu v-model="visible" :close-on-content-click="false" max-width="250" min-width="250" nudge-bottom="4"
          nudge-right="200" offset-y
          transition="slide-y-transition" z-index="0">
    <template v-slot:activator="{on}">
      <v-badge :color="$store.state.socketConnected ? 'success': 'error'" bottom left dot offset-x="5" offset-y="5">
        <v-btn icon small v-on="on">
          <profile-avatar :user="$store.state.user" bg-color="primary lighten-1" fallback-bg-color="primary darken-1"
                          fallback-icon-color="primary lighten-4"/>
        </v-btn>
      </v-badge>
    </template>
    <v-list class="pb-0" dense>
      <v-list-item v-if="$store.state.user && $store.state.user._id"
                   @click="$router.push(`/employees/${$store.state.user._id}`)">
        <v-list-item-icon>
          <v-icon dense>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Mein Profil</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$emit('show:createAvataaarDialog')">
        <v-list-item-icon>
          <v-icon dense>mdi-account-edit</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Avatar bearbeiten</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-can:readAndUpdate-messengerAccounts v-if="$store.state.user?.isEmployee"
                   @click="showMessengerMailSettingsDialog = true;">
        <v-list-item-icon>
          <v-icon dense>mdi-email-sync</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Kommunikationsaccounts
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-skeleton-loader v-if="loading" class="mb-n2 mt-n4" type="list-item-avatar-two-line"/>
      <v-list-item v-else v-can:read-dhlStandingData>
        <v-list-item-icon>
          <v-icon dense>mdi-email-fast-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ toCurrencyString(balance) }}</v-list-item-title>
          <v-list-item-subtitle>Portokasse Guthaben</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon dense>mdi-wrench</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ apiPath }}</v-list-item-title>
          <v-list-item-subtitle>Verbundener Server</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="$store.state.originUserId" @click="loginAsOrigin">
        <v-list-item-icon>
          <v-icon color="warning" dense>mdi-account-switch</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Zurück zu meinem Account
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon color="error" dense>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- DIALOGS -->
    <synchronize-messenger-accounts-dialog v-can:readAndUpdate-messengerAccounts
                                           v-model="showMessengerMailSettingsDialog"/>
  </v-menu>
</template>
<script lang="ts">
import Vue from 'vue';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import {LoginApi} from '@/classes/api/login.api.class';
import {toCurrencyString} from '@/helpers/toCurrencyString.helper';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import {DhlPostApi} from '@/classes/api/dhl-post.api.class';
import SynchronizeMessengerAccountsDialog from "@/components/messenger/synchronizeMessengerAccountsDialog.vue";

export default Vue.extend({
  data: () => ({
    loading: false,
    balance: 0,
    visible: false,
    showMessengerMailSettingsDialog: false,
  }),
  components: {SynchronizeMessengerAccountsDialog, ProfileAvatar},
  computed: {
    toCurrencyString: () => toCurrencyString,
    apiPath: () => process.env.VUE_APP_API_PATH,
  },
  methods: {
    async logout() {
      try {
        await LoginApi.logout();
        this.$$showSnackbar('Du wurdest erfolgreich ausgeloggt', 'success');
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler aufgetreten', 'error');
      }
    },
    async getWalletBalance() {
      if (this.$$crudAccessManager.canReadAllProvided(CrudEntityTypes.DHL_STANDING_DATA, CrudActionTypes.READ)) {
        try {
          this.loading = true;
          this.balance = await DhlPostApi.getBalance();
        } catch (e) {
          this.$$showSnackbar('Es ist ein Fehler beim Abrufen des aktuellen Guthabens der Portokasse aufgetreten', 'error');
        } finally {
          this.loading = false;
        }
      }
    },
    async loginAsOrigin() {
      try {
        await LoginApi.loginAsOrigin();
      } catch (e) {
        this.$$showSnackbar('Fehler bei Rückkehr zum Ursprungsaccount', 'error', e);
      }
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.getWalletBalance();
        }
      },
      immediate: true,
    },
  },
});
</script>
