<template>
  <v-navigation-drawer
      :value="value"
      absolute
      clipped
      right
      style="top: 62px; max-height: calc(100% - 62px); z-index: 99999"
      width="400px"
      @input="(v) => $emit('input', v)"
  >
    <template v-slot:prepend>
      <v-card color="transparent" flat>
        <v-card-title>
          {{ targets.length }} Ergebnisse
          <v-spacer/>
          <v-tooltip bottom>
            <template v-slot:activator="{on}">
              <v-btn icon @click="$emit('input', false)" v-on="on">
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </template>
            Ergebnisse ausblenden
          </v-tooltip>
        </v-card-title>
      </v-card>
    </template>
    <v-list three-line>
      <div v-for="item in targets" :key="item._id">
        <v-list-item v-if="isEmployee(item)">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.firstName ? item.firstName : '' }} {{ item.lastName ? item.lastName : '' }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="item.distance && item.distance >= 0" class="pr-1">
                              {{ (item.distance / 1000).toLocaleString('de-DE', {maximumFractionDigits: 2}) }}km
              </span>
              <span v-else class="pr-1">
                Entfernung konnte nicht ermittelt werden
              </span>
              <span v-if="item.duration" class="pr-1">
              {{ getDurationReadable(item.duration) }}
            </span>
              <span v-else class="pr-1">
              Dauer konnte nicht ermittelt werden
            </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.travelMode">
              <v-icon small>{{ GoogleTravelModesRecord[item.travelMode]?.icon }}</v-icon>
              {{ GoogleTravelModesRecord[item.travelMode]?.readable }}
            </v-list-item-subtitle>

          </v-list-item-content>
          <v-list-item-action>
            <v-menu offset-y>
              <template v-slot:activator="{on}">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list class="py-0" dense>
                <v-list-item @click="activeEmployeeId = item._id; showEmployeeCardDialog = true;">
                  <v-list-item-icon>
                    <v-icon>mdi-eye</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Lehrkraft ansehen
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :href="$router.resolve(`/teachers/${item._id}`).href" target="_blank">
                  <v-list-item-icon>
                    <v-icon>mdi-page-next-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Lehrkraft in neuem Tab ansehen
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-else-if="isInstitution(item)">
          <v-list-item-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                <v-icon v-on="on">
                  {{
                    item.assignedTeachers && item.assignedTeachers.length > 0 ? 'mdi-account-arrow-right-outline' : ''
                  }}
                </v-icon>
              </template>
              <span>Diesem Standort ist bereits eine Lehrkraft zugeteilt</span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="item.distance && item.distance >= 0" class="pr-1">
                              {{ (item.distance / 1000).toLocaleString('de-DE', {maximumFractionDigits: 2}) }}km
              </span>
              <span v-else class="pr-1">
                Entfernung konnte nicht ermittelt werden
              </span>
              <span v-if="item.duration" class="pr-1">
              {{ getDurationReadable(item.duration) }}
            </span>
              <span v-else class="pr-1">
              Dauer konnte nicht ermittelt werden
            </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.travelMode">
              <v-icon small>{{ GoogleTravelModesRecord[item.travelMode]?.icon }}</v-icon>
              {{ GoogleTravelModesRecord[item.travelMode]?.readable }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-menu offset-y>
              <template v-slot:activator="{on}">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list class="py-0" dense>
                <v-list-item @click="activeInstitutionId = item._id; showInstitutionCardDialog = true">
                  <v-list-item-icon>
                    <v-icon>mdi-eye</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Standortprofil ansehen
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :href="$router.resolve(`/institutions/${item._id}`).href" target="_blank">
                  <v-list-item-icon>
                    <v-icon>mdi-page-next-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Standortprofil in neuem Tab ansehen
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-list>
    <!-- DIALOGS -->
    <institution-card-dialog v-if="activeInstitutionId" v-model="showInstitutionCardDialog"
                             :institution-id="activeInstitutionId"/>
    <employee-card-dialog v-if="activeEmployeeId" v-model="showEmployeeCardDialog" :employee-id="activeEmployeeId"/>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';
import {LeanDocument, Types} from 'mongoose';
import {Institution} from '@/interfaces/institution.interface';
import {GetDirectionResponseDto} from '@/classes/dto/geocoder/response/GetDirections.response.dto';
import {Employee} from '@/interfaces/employee.interface';
import {GoogleTravelModesEnum} from '@/classes/api/google/GoogleTravelModes.enum';
import InstitutionCardDialog from '@/components/common/profile/institutionCardDialog.vue';
import EmployeeCardDialog from '@/components/common/profile/employeeCardDialog.vue';

export default Vue.extend({
  components: {EmployeeCardDialog, InstitutionCardDialog},
  props: {
    value: {
      type: Boolean,
    },
    targets: {
      type: Array as () => Array<LeanDocument<Institution | Employee> & GetDirectionResponseDto>,
    },
  },
  data: () => ({
    activeEmployeeId: null as null | Types.ObjectId,
    activeInstitutionId: null as null | Types.ObjectId,
    showInstitutionCardDialog: false,
    showEmployeeCardDialog: false,
  }),
  computed: {
    GoogleTravelModesRecord: () => GoogleTravelModesEnum.Record,
  },
  methods: {
    isEmployee(item: LeanDocument<Institution | Employee> & GetDirectionResponseDto): item is LeanDocument<Employee> & GetDirectionResponseDto {
      return item.hasOwnProperty('firstName') || item.hasOwnProperty('lastName');
    },
    isInstitution(item: LeanDocument<Institution | Employee> & GetDirectionResponseDto): item is LeanDocument<Institution> & GetDirectionResponseDto {
      return item.hasOwnProperty('name');
    },
    getDurationReadable(seconds: number) {
      const hours = Math.floor(seconds / 3600);
      let remaining = seconds % 3600;
      const minutes = Math.floor(remaining / 60);
      remaining = remaining % 3600;
      if (hours > 0) {
        if (minutes > 0) {
          return `${hours} ${hours === 1 ? 'Stunde' : 'Stunden'} und ${minutes} ${minutes === 1 ? 'Minute' : 'Minuten'}`;
        } else {
          return `${hours} ${hours === 1 ? 'Stunde' : 'Stunden'}`;
        }
      } else {
        if (minutes > 0) {
          return `${minutes} ${minutes === 1 ? 'Minute' : 'Minuten'}`;
        } else {
          return 'Weniger als eine Minute';
        }
      }
    },
  },
});
</script>
