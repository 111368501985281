import {AcquisitionClosedReasonEnum} from '@/enums/institutioner/acquisition/AcquisitionClosedReason.enum';
import colors from 'vuetify/lib/util/colors';
import {mapRecordToItems} from '@/helpers/mapRecordToItems.helper';

export enum AcquisitionListStatusEnum {
    OPEN, COMMITTED, CLOSED, IN_PROGRESS,
}

export namespace AcquisitionListStatusEnum {
    export function getChartDisplay(state: AcquisitionListStatusEnum, reason?: AcquisitionClosedReasonEnum): {
        text: string;
        color: string
    } {
        return {
            text: getChartLabel(state, reason),
            color: getChartColor(state, reason),
        }
    }

    type Data = {
        text: string;
        color: string;
        icon: string;
    }

    export const Record: Record<AcquisitionListStatusEnum, Data> = {
        [AcquisitionListStatusEnum.OPEN]: {
            text: 'Offen',
            color: 'black',
            icon: 'mdi-account-clock',
        },
        [AcquisitionListStatusEnum.IN_PROGRESS]: {
            text: 'In Arbeit',
            color: 'info',
            icon: 'mdi-briefcase',
        },
        [AcquisitionListStatusEnum.COMMITTED]: {
            text: 'Zusage',
            color: 'success',
            icon: 'mdi-check',
        },
        [AcquisitionListStatusEnum.CLOSED]: {
            text: 'Absage',
            color: 'error',
            icon: 'mdi-cancel',
        },
    };

    export const Items = mapRecordToItems(Record);
}

function getChartLabel(status: AcquisitionListStatusEnum, reason?: AcquisitionClosedReasonEnum): string {
    const statusLabel = AcquisitionListStatusEnum.Record[status]?.text ?? '-';
    if (reason) {
        const reasonLabel = AcquisitionClosedReasonEnum.Record[reason].text ?? '';
        return `${statusLabel} - ${reasonLabel}`;
    }
    return statusLabel;
}

function getChartColor(status: AcquisitionListStatusEnum, reason?: AcquisitionClosedReasonEnum): string {
    switch (status) {
        case AcquisitionListStatusEnum.OPEN:
            return colors.blueGrey.darken4;
        case AcquisitionListStatusEnum.IN_PROGRESS:
            return colors.blue.base;
        case AcquisitionListStatusEnum.COMMITTED:
            return colors.green.base;
        case AcquisitionListStatusEnum.CLOSED:
            if (reason) {
                switch (reason) {
                    case AcquisitionClosedReasonEnum.NON_COMMERCIAL:
                        return colors.red.base;
                    case AcquisitionClosedReasonEnum.COMPETITOR:
                        return colors.red.accent1;
                    case AcquisitionClosedReasonEnum.COOPERATION_CANCELLED:
                        return colors.red.accent2;
                    case AcquisitionClosedReasonEnum.NO_ROOM:
                        return colors.red.lighten3;
                    case AcquisitionClosedReasonEnum.OTHER:
                        return colors.red.darken3;
                    default:
                        return colors.red.accent1;
                }
            } else {
                return colors.red.base;
            }
        default:
            return '#8c634c';
    }
}
