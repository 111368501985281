<template>
  <div v-if="entity && entity.subjects">
    <v-divider/>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-guitar-acoustic</v-icon>
      </v-list-item-icon>
      <v-list-item-content v-if="entity.subjects.length > 0">
        <v-list-item-title>
          <v-chip small v-for="(subject, i) in entity.subjects" :key="i" class="mr-1" color="grey lighten-3">
            {{subject.name ? subject.name : 'Unbekanntes Unterrichtsfach'}}
          </v-chip>
        </v-list-item-title>
        <v-list-item-subtitle>{{entity.subjects.length > 1 ? 'Unterrichtsfächer' : 'Unterrichtsfach'}}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content v-else>
        <v-list-item-title class="grey--text">
          Keine Unterrichtsfächer hinterlegt
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Subject} from '@/interfaces/subject.interface';

interface CanHaveSubjects {
  subjects?: Subject[];
}

export default Vue.extend({
  props: {
    entity: {
      type: Object as () => CanHaveSubjects,
      required: true,
    },
  },
})
</script>
