<template>
  <v-list-item :color="stack.color">
    <v-list-item-avatar>
      <profile-avatar :bg-color="stack.color" :fallback-bg-color="stack.color"
                      :icon="item.type === 'Institution' ? 'mdi-domain' : undefined"
                      :user="item.meta"
                      size="28"/>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <overflow-text :text="name"/>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-icon>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-icon size="20" class="mt-3" v-on="on">{{stateIcon}}</v-icon>
        </template>
        {{stateText}}
      </v-tooltip>
    </v-list-item-icon>
    <v-list-item-action v-can:update-stacks="{ id: stack._id, keys: ['items'] }">
      <v-menu offset-x>
        <template v-slot:activator="{on}">
          <v-btn small icon v-on.stop="on" :loading="loading">
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense class="py-0" @click.stop>
          <v-list-item @click.stop="setStateAs(StackItemStates.DONE)" v-if="item.state !== StackItemStates.DONE">
            <v-list-item-icon>
              <v-icon color="success">
                mdi-check
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Element als erledigt markieren
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="setStateAs(StackItemStates.IN_PROGRESS)" v-if="item.state !== StackItemStates.IN_PROGRESS">
            <v-list-item-icon>
              <v-icon color="warning">
                mdi-briefcase-clock
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Element als "In Bearbeitung" markieren
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="deleteItem">
            <v-list-item-icon>
              <v-icon color="error">
                mdi-delete
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Element aus Stapel löschen
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
</template>
<script lang="ts">
import Vue from 'vue';
import {Institution} from '@/interfaces/institution.interface';
import {User} from '@/interfaces/user.interface';
import {Stack, StackItem} from '@/interfaces/stack.interface';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {StackBus} from '@/busses/StackBus';
import {StackItemStates, StackItemStatesVue} from '@/enums/StackItemStates.enum';
import OverflowText from '@/components/common/overflow-text.vue';

export default Vue.extend({
  components: {OverflowText, ProfileAvatar},
  props: {
    item: {
      type: Object as () => StackItem<any>,
    },
    stack: {
      type: Object as () => Stack,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    StackItemStates: () => StackItemStates,
    name(): string {
      if (this.item.type === 'Institution') {
        const doc = this.item.meta as Institution;
        return doc.name;
      } else {
        const doc = this.item.meta as User;
        return `${doc.firstName} ${doc.lastName}`;
      }
    },
    stateText(): string {
      return StackItemStatesVue.find((el) => el.value === this.item?.state)?.text || '';
    },
    stateIcon(): string {
      return StackItemStatesVue.find((el) => el.value === this.item?.state)?.icon || '';
    },
  },
  methods: {
    async setStateAs(state: StackItemStates) {
      this.loading = true;
      try {
        await StackManagerApi.updateItem(this.stack._id, this.item?._id, { state });
        StackBus.$emit('update:itemState', { itemId: this.item?._id, state });
        this.$$showSnackbar(`Der Status wurde erfolgreich aktualisiert`, 'success');
      } catch (e) {
        this.$$showSnackbar(`Statusupdate fehlgeschlagen`, 'error');
      } finally {
        this.loading = false;
      }
    },
    async deleteItem() {
      this.loading = true;
      try {
        await StackManagerApi.pullItem(this.stack._id, this.item?._id);
        StackBus.$emit('reload:selectedStack');
        this.$$showSnackbar(`Das Element wurde erfolgreich aus dem Stapel gelöscht`, 'success');
      } catch (e) {
        this.$$showSnackbar(`Fehler beim Löschen des Elements`, 'error');
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
