<template>
  <v-row no-gutters>
    <v-col cols="12" md="6" v-if="contactInformation.mail1 || contactInformation.mail2">
      <v-list-item dense>
        <v-list-item-icon>
          <v-icon small>mdi-mail</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-if="contactInformation.mail1">
            <a class="info--text" :href="`mailto:${contactInformation.mail1}`">{{contactInformation.mail1}}</a>
          </v-list-item-title>
          <v-list-item-title v-if="contactInformation.mail2" >
            <a class="info--text" :href="`mailto:${contactInformation.mail2}`">{{contactInformation.mail2}}</a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="12" md="6" v-if="contactInformation.phone1 || contactInformation.phone2">
      <v-list-item dense>
        <v-list-item-icon>
          <v-icon small>mdi-phone</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-if="contactInformation.phone1">
            <a class="info--text" :href="`tel:${contactInformation.phone1}`">{{ contactInformation.phone1 }}</a>
          </v-list-item-title>
          <v-list-item-title v-if="contactInformation.phone2" >
            <a class="info--text" :href="`tel:${contactInformation.phone2}`">{{ contactInformation.phone2 }}</a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="12" md="6" v-if="contactInformation.mobile1 || contactInformation.mobile2">
      <v-list-item dense>
        <v-list-item-icon>
          <v-icon small>mdi-cellphone</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-if="contactInformation.mobile1">
            <a class="info--text" :href="`tel:${contactInformation.mobile1}`">{{ contactInformation.mobile1 }}</a>
          </v-list-item-title>
          <v-list-item-title v-if="contactInformation.mobile2" >
            <a class="info--text" :href="`tel:${contactInformation.mobile2}`">{{ contactInformation.mobile2 }}</a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import {ContactInformation} from '@/interfaces/contactInformation.interface';

export default Vue.extend({
  props: {
    contactInformation: {
      type: Object as () => ContactInformation,
      required: true,
    },
  },
});
</script>
