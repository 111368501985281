<template>
  <v-badge style="width: 100%" :value="!notification.readAt" color="red" dot offset-x="25" offset-y="20">
    <v-list-item :class="{ 'red lighten-5': !notification.readAt }">
      <v-list-item-avatar>
        <profile-avatar :user="{profileImg: notification.messagePayload.fromProfileImg}"/>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle>
          Eingehender Anruf
        </v-list-item-subtitle>
        <v-list-item-title>
          {{ notification.messagePayload.fromName || 'Unbekannter Anrufer' }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="!notification.dismissedAt">
          {{ moment(notification.createdAt).fromNow() }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          <v-icon small>mdi-delete</v-icon>
          {{ moment(notification.dismissedAt).fromNow() }} gelöscht
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="link">
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn :href="link" icon target="_blank" v-on="on">
              <v-icon>mdi-page-next-outline</v-icon>
            </v-btn>
          </template>
          <span>Zum Profil</span>
        </v-tooltip>
      </v-list-item-action>
      <v-list-item-action v-if="!notification.dismissedAt">
        <v-btn icon @click="$emit('dismiss', notification._id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action v-else>
        <v-btn icon @click="$emit('revoke', notification._id)">
          <v-icon>mdi-delete-off</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-badge>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import moment from 'moment';
import {CallNotificationDocument} from '@/documentTypes/notifier/discriminators/CallNotification.document';

moment.locale('de');

export default Vue.extend({
  props: {
    notification: {
      type: Object as () => NotificationLike<CallNotificationDocument>,
      required: true,
    },
  },
  components: {ProfileAvatar},
  computed: {
    moment: () => moment,
    link(): string | undefined {
      const entityType = this.notification.messagePayload.entityType;
      const entityId = this.notification.messagePayload.entityId;
      if (entityType && entityId) {
        return this.$router.resolve(`/${entityType}/${entityId}`).href;
      }
      return undefined;
    },
  },
});
</script>
