<template>
  <div>
    <v-row v-if="includeSubheader">
      <v-subheader>{{ subheaderText }}</v-subheader>
    </v-row>

    <v-row class="mt-0" dense>
      <v-col cols="12" md="4">
        <v-text-field
            v-model="mail1"
            :color="color"
            :dense="dense"
            :disabled="disabled"
            :filled="filled"
            :outlined="outlined"
            :readonly="readonly"
            :rounded="rounded"
            :rules="[
              RuleFactory.isValidMail(),
              requireMail? RuleFactory.required() : true,
            ]"
            :solo="solo"
            label="E-Mail-Adresse"
            prepend-icon="mdi-email"
            validate-on-blur
            @input="$emit('input', computedContactInformation)"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="phone1" v-mask="InputMasks.phoneNumber(phone1)" :color="color" :dense="dense" :disabled="disabled"
                      :filled="filled" :outlined="outlined" :readonly="readonly" :rounded="rounded"
                      :rules="[RuleFactory.isValidPhoneNumber()]" :solo="solo" label="Festnetz-Nummer"
                      prepend-icon="mdi-phone" validate-on-blur
                      @input="$emit('input', computedContactInformation)"/>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="mobile1" v-mask="InputMasks.phoneNumber(mobile1)" :color="color" :dense="dense" :disabled="disabled"
                      :filled="filled" :outlined="outlined" :readonly="readonly" :rounded="rounded"
                      :rules="[RuleFactory.isValidPhoneNumber(), requireMobile ? RuleFactory.required() : true,]"
                      :solo="solo" label="Mobilnummer" prepend-icon="mdi-cellphone" validate-on-blur
                      @input="$emit('input', computedContactInformation)"/>
      </v-col>
    </v-row>
    <v-row class="mt-0" dense>
      <v-col cols="12" md="4">
        <v-text-field v-if="!hideNotes" v-model="mail1Note" :color="color" dense label="Anmerkung (optional)"
                      prepend-icon="mdi-note-outline" @input="$emit('input', computedContactInformation)"/>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-if="!hideNotes" v-model="phone1Note" :color="color" dense label="Anmerkung (optional)"
                      prepend-icon="mdi-note-outline" @input="$emit('input', computedContactInformation)"/>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-if="!hideNotes" v-model="mobile1Note" dense label="Anmerkung (optional)"
                      prepend-icon="mdi-note-outline" @input="$emit('input', computedContactInformation)"/>
      </v-col>
    </v-row>
    <v-row v-if="showSecondLine" class="mt-0" dense>
      <v-col cols="12" md="4">
        <v-text-field v-if="showSecondLine" v-model="mail2" :color="color" :dense="dense" :disabled="disabled"
                      :filled="filled" :outlined="outlined" :readonly="readonly" :rounded="rounded"
                      :rules="[RuleFactory.isValidMail()]" :solo="solo" class="mt-4"
                      label="Zweite E-Mail-Adresse" prepend-icon="mdi-email" validate-on-blur
                      @input="$emit('input', computedContactInformation)"/>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-if="showSecondLine" v-model="phone2" v-mask="InputMasks.phoneNumber(phone2)" :color="color" :dense="dense"
                      :disabled="disabled" :filled="filled" :outlined="outlined" :readonly="readonly"
                      :rounded="rounded" :rules="[RuleFactory.isValidPhoneNumber()]" :solo="solo" class="mt-4"
                      label="Zweite Festnetz-Nummer" prepend-icon="mdi-phone" validate-on-blur
                      @input="$emit('input', computedContactInformation)"/>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-if="showSecondLine" v-model="mobile2" v-mask="InputMasks.phoneNumber(mobile2)" :color="color" :dense="dense"
                      :disabled="disabled" :filled="filled" :outlined="outlined" :readonly="readonly"
                      :rounded="rounded" :rules="[RuleFactory.isValidPhoneNumber()]" :solo="solo" class="mt-4"
                      label="Zweite Mobilnummer" prepend-icon="mdi-cellphone" validate-on-blur
                      @input="$emit('input', computedContactInformation)"/>
      </v-col>
    </v-row>
    <v-row v-if="showSecondLine" class="mt-0" dense>
      <v-col cols="12" md="4">
        <v-text-field v-if="showSecondLine && !hideNotes" v-model="mail2Note" :color="color" dense
                      label="Anmerkung (optional)" prepend-icon="mdi-note-outline"
                      @input="$emit('input', computedContactInformation)"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-if="showSecondLine && !hideNotes" v-model="phone2Note"
                      :color="color"
                      dense label="Anmerkung (optional)"
                      prepend-icon="mdi-note-outline"
                      @input="$emit('input', computedContactInformation)"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-if="showSecondLine && !hideNotes" v-model="mobile2Note"
                      :color="color"
                      dense label="Anmerkung (optional)"
                      prepend-icon="mdi-note-outline"
                      @input="$emit('input', computedContactInformation)"
        />
      </v-col>
    </v-row>

    <v-row v-if="isBusinessContact" class="mt-0" dense>
      <v-col cols="12" md="4">
        <v-text-field v-model="fax1" v-mask="InputMasks.phoneNumber(fax1)" :color="color" :dense="dense" :disabled="disabled"
                      :filled="filled" :outlined="outlined" :readonly="readonly" :rounded="rounded"
                      :rules="[RuleFactory.isValidPhoneNumber()]" :solo="solo" label="Telefax"
                      prepend-icon="mdi-fax" validate-on-blur
                      @input="$emit('input', computedContactInformation)"/>
      </v-col>
      <v-col v-if="showSecondLine" cols="12" md="4">
        <v-text-field v-model="fax2" v-mask="InputMasks.phoneNumber(fax2)" :color="color" :dense="dense" :disabled="disabled"
                      :filled="filled" :outlined="outlined" :readonly="readonly" :rounded="rounded"
                      :rules="[RuleFactory.isValidPhoneNumber()]" :solo="solo" label="Zweites Telefax"
                      prepend-icon="mdi-fax" validate-on-blur
                      @input="$emit('input', computedContactInformation)"/>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="website" :color="color" :dense="dense" :disabled="disabled" :filled="filled"
                      :outlined="outlined" :readonly="readonly" :rounded="rounded" :solo="solo"
                      hint="(Format: http://muster.de oder https://muster.de" label="Website"
                      prepend-icon="mdi-web" @input="$emit('input', computedContactInformation)"/>
      </v-col>
    </v-row>
    <v-row v-if="!showSecondLine" class="mt-0" dense justify="center">
      <v-col cols="12">
        <v-btn :disabled="disabled" block small text @click="showSecondLine = true">Weitere Kontaktfelder
          einblenden
        </v-btn>
      </v-col>
    </v-row>
  </div>


</template>

<script lang="ts">
import Vue from 'vue';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {ContactInformationTemplateDto} from '@/classes/dto/_common/templates/ContactInformation.template.dto';
import {InputMasks} from '@/helpers/vueTheMask';

export default Vue.extend({
  props: {
    hideNotes: {
      type: Boolean,
      default: false,
    },
    isBusinessContact: {
      type: Boolean,
      default: false,
    },
    includeSubheader: {
      type: Boolean,
      default: true,
    },
    subheaderText: {
      type: String,
      default: 'Kontaktinformationen',
    },

    color: {
      type: String,
      default: 'info',
    },
    defaultObject: {
      type: Object as () => ContactInformationTemplateDto,
      required: false,
    },
    requireMail: {
      type: Boolean,
      default: false,
    },
    requireMobile: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fax1: '',
    fax2: '',
    mail1: '',
    mail2: '',
    phone1: '',
    phone2: '',
    mobile1: '',
    mobile2: '',
    website: '',
    mail1Note: '',
    mail2Note: '',
    phone1Note: '',
    phone2Note: '',
    mobile1Note: '',
    mobile2Note: '',
    showSecondLine: false,
  }),
  computed: {
    InputMasks: () => InputMasks,
    RuleFactory: () => RuleFactory,
    computedContactInformation(): ContactInformationTemplateDto {
      return {
        fax1: this.fax1 || undefined,
        fax2: this.fax2 || undefined,
        mail1: this.mail1 || undefined,
        mail2: this.mail2 || undefined,
        phone1: this.phone1 || undefined,
        phone2: this.phone2 || undefined,
        mobile1: this.mobile1 || undefined,
        mobile2: this.mobile2 || undefined,
        website: this.website || undefined,
        notes: {
          mail1: this.mail1Note || undefined,
          mail2: this.mail2Note || undefined,
          mobile1: this.mobile1Note || undefined,
          mobile2: this.mobile2Note || undefined,
          phone1: this.phone1Note || undefined,
          phone2: this.phone2Note || undefined,
        },
      }
    },
  },
  methods: {
    loadDefault() {
      if (this.defaultObject) {
        this.fax1 = this.defaultObject.fax1 || '';
        this.fax2 = this.defaultObject.fax2 || '';
        this.mail1 = this.defaultObject.mail1 || '';
        this.mail2 = this.defaultObject.mail2 || '';
        this.phone1 = this.defaultObject.phone1 || '';
        this.phone2 = this.defaultObject.phone2 || '';
        this.mobile1 = this.defaultObject.mobile1 || '';
        this.mobile2 = this.defaultObject.mobile2 || '';
        this.website = this.defaultObject.website || '';
        this.mail1Note = this.defaultObject.notes?.mail1 || '';
        this.mail2Note = this.defaultObject.notes?.mail2 || '';
        this.phone1Note = this.defaultObject.notes?.phone1 || '';
        this.phone2Note = this.defaultObject.notes?.phone2 || '';
        this.mobile1Note = this.defaultObject.notes?.mobile1 || '';
        this.mobile2Note = this.defaultObject.notes?.mobile2 || '';
        this.$emit('input', this.computedContactInformation);
      }
    },
  },
  watch: {
    defaultObject: {
      immediate: true,
      deep: true,
      handler() {
        if (this.defaultObject) {
          this.loadDefault();
        }
      },
    },
  },
});
</script>
