import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {StoredDocumentFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/StoredDocumentFields';

export const storedDocumentFieldsDictionary: FieldDictionary<StoredDocumentFields> = [
    {
        text: 'Datei',
        value: StoredDocumentFields.STORED_FILE,
        readOnly: true,
    },
    {
        text: 'Bereitstellende:r',
        value: StoredDocumentFields.PROVIDER,
    },
    {
        text: 'Typ der Bereitstellende:n',
        value: StoredDocumentFields.PROVIDER_TYPE,
    },
    {
        text: 'Art des Dokuments',
        value: StoredDocumentFields.DOCUMENT_TYPE,
    },
    {
        text: 'Erzeugungsdatum',
        value: StoredDocumentFields.CREATED_AT,
        readOnly: true,
    },
    {
        text: 'Aktualisierungsdatum',
        value: StoredDocumentFields.UPDATED_AT,
        readOnly: true,
    },
];
