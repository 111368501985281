export enum NotificationTypes {
  COMMON = 'common',
  CALL = 'call',
  MENTION = 'mention',
  OVERDUE_TO_DO = 'overdueToDo',
  TO_DO_ASSIGNED = 'toDoAssigned',
  TO_DO_COMPLETED = 'toDoCompleted',
  ENTITY_ASSIGNED_TO_SUPERVISOR = 'employeeAssignedToSupervisor',
  SUPERVISED_ENTITY_DEACTIVATED = 'supervisedEntityDeactivated',
  WHATSAPP_MESSAGE_TEMPLATE_STATUS_UPDATE = 'whatsappMessageTemplateStatusUpdate',
  COURSE_CANCELLATION_FAILED = 'courseCancellationFailed',
  HANDOUT_EVENT_FEEDBACK = 'handoutEventFeedback',
}
