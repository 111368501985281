<template>
  <div>
    <div v-if="entity.responsibles && entity.responsibles.length > 0">
      <template v-for="(item, i) in filteredEntities">
        <v-divider :key="`d-${i}`"/>
        <v-list-item
            :key="i"
            v-can:read-responsibles="{ id: item._id, keys: ['lastName', 'gender'] }"
            two-line
        >
          <v-list-item-avatar>
            <profile-avatar :user="item"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ typeof item.gender === 'number' ? toSalutation(item.gender) : '' }}
              <span v-can:read-responsibles="{ id: item._id, keys: ['firstName'] }">{{ item.firstName }}</span>
              {{ item.lastName }}
            </v-list-item-title>
            <v-list-item-subtitle>
            <span v-can:read-responsibles="{ id: item._id, keys: ['jobType'] }">
              {{ getJobTypeString(item)}}
            </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-layout row wrap>
              <v-flex>
                <v-tooltip v-can:update-responsibles="{ id: item._id }" bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        @click="activeResponsible = item; showEditResponsibleDialog = true"
                        v-on="on"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  Bearbeiten
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                        :id="'responsibleMenu' + i"
                        icon
                        @click="activeResponsible = item; showResponsibleDialog = true;"
                        v-on="on"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  Ansehen
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-list-item-action>
        </v-list-item>
      </template>
    </div>
    <div v-else>
      <v-divider/>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-account-tie</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Es wurden noch keine Ansprechpartner angelegt.
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  icon
                  @click="showCreateResponsibleDialog = true"
                  v-on="on"
                  color="info"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            Ansprechpartner anlegen
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </div>
    <!-- DIALOGS -->
    <create-responsible-dialog @create:responsible="$emit('create:responsible')" v-model="showCreateResponsibleDialog" :institution-id="entity._id"
                               :responsible="activeResponsible"/>
    <edit-responsible-dialog v-if="activeResponsible" v-model="showEditResponsibleDialog" :responsible-id="activeResponsible._id"/>
    <responsible-card-dialog v-if="activeResponsible" v-model="showResponsibleDialog" :institution-id="entity._id"
                             :responsible="activeResponsible" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Responsible } from '@/interfaces/responsible.interface';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import { toSalutation } from '@/helpers/toSalutation.helper';
import CreateResponsibleDialog from '@/components/responsibles/createResponsibleDialog.vue';
import EditResponsibleDialog from '@/components/responsibles/editResponsibleDialog.vue';
import ResponsibleCardDialog from '@/components/responsibles/responsibleCardDialog.vue';
import { Institution } from '@/interfaces/institution.interface';
import { isObjectId } from '@/helpers/isObjectId.helper';
import { isJobType } from '@/interfaces/jobType.interface';
import { GenderTypes } from '@/enums/GenderTypes.enum';

export default Vue.extend({
  data: () => ({
    activeResponsible: null as null | Responsible,
    showEditResponsibleDialog: false,
    showResponsibleDialog: false,
    showCreateResponsibleDialog: false,
  }),
  components: {
    ProfileAvatar,
    CreateResponsibleDialog,
    EditResponsibleDialog,
    ResponsibleCardDialog,
  },
  props: {
    entity: {
      type: Object as () => Institution,
      required: true,
    },
  },
  computed: {
    filteredEntities(): Responsible[] {
      if (Array.isArray(this.entity.responsibles)) {
        return this.entity.responsibles.filter((el) => !isObjectId(el) && el) as Responsible[];
      } else {
        return [];
      }
    },
    toSalutation: () => toSalutation,
  },
  methods: {
    getJobTypeString(responsible: Responsible) {
      if (responsible.jobType && isJobType(responsible.jobType)) {
        switch (responsible.gender) {
          case GenderTypes.FEMALE:
            return responsible.jobType.female;
          case GenderTypes.MALE:
            return responsible.jobType.male;
          case GenderTypes.TRANS:
            return responsible.jobType.neutral;
          default:
            return responsible.jobType.neutral;
        }
      } else {
        return 'Jobposition unbekannt';
      }
    },
  },
});
</script>

