<template>
  <v-badge style="width: 100%" :value="!notification.readAt" color="red" dot offset-x="25" offset-y="20">
    <v-list-item :class="{ 'red lighten-5': !notification.readAt }">
      <v-list-item-icon>
        <v-icon :color="formattedTemplateStatus.color">
          {{ formattedTemplateStatus.icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-subtitle>
          Aktualisierung der Nachrichtenvorlage
        </v-list-item-subtitle>
        <v-list-item-title>
          {{ notification.messagePayload.name }} {{ formattedTemplateStatus.text }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="!notification.dismissedAt">
          {{ moment(notification.createdAt).fromNow() }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="link">
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn :href="link" icon target="_blank" v-on="on">
              <v-icon>mdi-page-next-outline</v-icon>
            </v-btn>
          </template>
          <span>Zur Vorlagenübersicht</span>
        </v-tooltip>
      </v-list-item-action>
      <v-list-item-action v-if="!notification.dismissedAt">
        <v-btn icon @click="$emit('dismiss', notification._id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action v-else>
        <v-btn icon @click="$emit('revoke', notification._id)">
          <v-icon>mdi-delete-off</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-badge>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import moment from 'moment';
import {
  WhatsAppMessageTemplateStatusUpdateNotificationDocument,
} from "@/documentTypes/notifier/discriminators/supervisor/WhatsAppMessageTemplateStatusUpdateNotification.document";

moment.locale('de');

export default Vue.extend({
  props: {
    notification: {
      type: Object as () => NotificationLike<WhatsAppMessageTemplateStatusUpdateNotificationDocument>,
      required: true,
    },
  },
  computed: {
    moment: () => moment,
    link(): string | undefined {
      const configId = this.notification.messagePayload.configurationId;
      if (configId) {
        return this.$router.resolve(`/templates/whatsapp/${configId}`).href;
      }
      return undefined;
    },
    formattedTemplateStatus() {
      switch (this.notification.messagePayload.status) {
        case 'REJECTED':
          return {color: 'error', text: 'abgelehnt', icon: 'mdi-close'};
        case 'APPROVED':
          return {color: 'success', text: 'genehmigt', icon: 'mdi-check'};
        case 'PENDING':
          return {color: 'warning', text: 'ausstehend', icon: 'mdi-clock'};
        default:
          return {color: 'grey', text: 'unbekannt', icon: 'mdi-help'};
      }
    },
  },
});
</script>
