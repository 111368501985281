import {Types} from 'mongoose';
import {API} from './api.class';
import CrudAccess from '@/classes/clientOnly/permissionTreeResources/interfaces/CrudAccess';
import {GrantAccessRequestDto} from '@/classes/dto/permissioner/GrantAccess.request.dto';
import {UpdateCrudRequestDto} from '@/classes/dto/permissioner/UpdateCrud.request.dto';
import {CreatePermissionHookRequestDto} from '@/classes/dto/permissioner/CreatePermissionHook.request.dto';
import {PermissionHook} from '@/interfaces/permissionHook.interface';
import {FindPermissionHookRequestDto} from '@/classes/dto/permissioner/FindPermissionHook.request.dto';
import {FindByIdOptionsRequestDto} from '@/classes/dto/_common/request/FindByIdOptions.request.dto';
import {UpdatePermissionHookRequestDto} from '@/classes/dto/permissioner/UpdatePermissionHook.request.dto';
import {SetHookStateRequestDto} from "@/classes/dto/permissioner/SetHookState.request.dto";
import {DeletePermissionHookRequestDto} from "@/classes/dto/permissioner/DeletePermissionHook.request.dto";
import {CrudEntityTypes} from "@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes";
import {UpdateGuiAccessRequestDto} from '@/classes/dto/permissioner/UpdateGuiAccess.request.dto';
import {NavigationDrawerAccess} from '@/enums/_common/NavigationDrawerAccess.enum';

export interface InitializeHooksState {
    isInitializing: boolean;

    entityType?: CrudEntityTypes;
    hookIndex: number;
    hookCount: number;

    entityIndex: number;
    entityCount: number;

    hasError?: boolean;
}

/**
 * This class is used for communicating with Permissioner microservice
 * @class PermissionerApi
 * @author mklaczinski
 */
export abstract class PermissionerApi extends API {
    static service = 'permissioner';

    static gui = {
        async getOwnGuiAccess(): Promise<NavigationDrawerAccess[]> {
            return PermissionerApi.get('gui');
        },
        async updateGuiAccess(type: 'user' | 'role', id: Types.ObjectId, data: UpdateGuiAccessRequestDto): Promise<Types.ObjectId> {
            return PermissionerApi.put(`gui/access/${type}/${id}`, data);
        },
    }

    static permissionHooks = {
        async initOne(id: Types.ObjectId, data: { _pass: string }): Promise<void> {
            return PermissionerApi.post(`permission-hook/init/${id}`, data);
        },
        async init(data: { _pass: string }): Promise<void> {
            return PermissionerApi.post('permission-hook/init', data);
        },
        async getInitState(): Promise<InitializeHooksState> {
            return PermissionerApi.get('permission-hook/init');
        },
        async create(data: CreatePermissionHookRequestDto & { _pass: string }): Promise<PermissionHook> {
            return PermissionerApi.post('permission-hook', data);
        },
        async find(data: FindPermissionHookRequestDto): Promise<{ permissionHooks: PermissionHook[]; maxPage: number }> {
            return PermissionerApi.get('permission-hook', data);
        },
        async findById(id: Types.ObjectId, options?: FindByIdOptionsRequestDto): Promise<PermissionHook> {
            return PermissionerApi.get(`permission-hook/${id}`, options);
        },
        async update(id: Types.ObjectId, update: UpdatePermissionHookRequestDto): Promise<void> {
            return PermissionerApi.put(`permission-hook/${id}`, update);
        },
        async remove(id: Types.ObjectId, payload: DeletePermissionHookRequestDto & { _pass: string }): Promise<void> {
            return PermissionerApi.delete(`permission-hook/${id}`, payload);
        },
    };

    static async getOwnCrud(): Promise<CrudAccess> {
        return this.get('crud');
    }

    static async getCrudById(type: 'user' | 'role', id: Types.ObjectId): Promise<CrudAccess> {
        return this.get(`roles-and-users/${type}/${id}`);
    }

    static async grantAccess(type: 'user' | 'role', id: Types.ObjectId, data: GrantAccessRequestDto & { _pass: string }) {
        return this.put(`grant/${type}/${id}`, data);
    }

    static async setCrud(type: 'user' | 'role', id: Types.ObjectId, data: UpdateCrudRequestDto & { _pass: string }): Promise<void> {
        return this.put(`update/${type}/${id}`, data);
    }

    static async setHookState(userId: Types.ObjectId, data: SetHookStateRequestDto): Promise<void> {
        return this.put(`set-hook-state/user/${userId}`, data);
    }

}
