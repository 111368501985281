export enum Weekdays {
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY,
}

export const WeekdaysVue = [{
    text: 'Montag',
    value: Weekdays.MONDAY,
}, {
    text: 'Dienstag',
    value: Weekdays.TUESDAY,
}, {
    text: 'Mittwoch',
    value: Weekdays.WEDNESDAY,
}, {
    text: 'Donnerstag',
    value: Weekdays.THURSDAY,
}, {
    text: 'Freitag',
    value: Weekdays.FRIDAY,
}, {
    text: 'Samstag',
    value: Weekdays.SATURDAY,
}, {
    text: 'Sonntag',
    value: Weekdays.SUNDAY,
}];

export function getWeekdayString(weekday: Weekdays) {
    const found = WeekdaysVue.find((el) => el.value === weekday);
    return found ? found.text : '';
}

export function getWeekdayAdverbString(weekday: Weekdays, capitalize?: boolean) {
    let str = getWeekdayString(weekday);
    if (str) {
        str += 's';
        if (capitalize) {
            return str;
        } else {
            return str.toLowerCase();
        }
    }
    return '';
}
