<template>
  <v-container class="grey lighten-2 fill-height" fluid grid-list-xs>
    <v-layout align-center justify-center row wrap>
      <v-flex md4 sm8 xl3 xs12>
        <v-card>
          <v-card-text>
            <div class="display-2 font-weight-bold text-center">
              404
            </div>
            <br/>
            Die gesuchte Seite konnte im System nicht gefunden werden.
            Vergewissere dich, dass deine Eingabe korrekt war.
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="info" text @click="$router.go(-1)">
              <v-icon>mdi-chevron-left</v-icon>
              Zurück zur vorigen Seite
            </v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend();
</script>
