<template>
  <v-dialog
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :value="value"
      max-width="1000px"
      @input="reset"
  >
    <v-card>
      <v-row justify="space-between" no-gutters style="max-height: 100%; overflow: hidden">
        <v-col cols="12" md="4">
          <v-card-title>
            <div class="info--text">
              Avatar bearbeiten
            </div>
            <v-spacer></v-spacer>
            <v-btn class="hidden-md-and-up" icon @click="reset">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-row align="center" justify="center" style="height: calc(100% - 68px)">
            <v-col cols="12">
              <v-card-text class="text-center">
                <v-avatar color="blue-grey lighten-4" size="224" style="box-shadow: #aaa 0 -5px 5px inset">
                  <avataaars ref="avatar" :avatarOptions="avatarOptions"/>
                </v-avatar>
              </v-card-text>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical/>
        <v-col cols="12" md="8">
          <v-card :color="$vuetify.breakpoint.mdAndUp ? '#fafdff' : ''" flat tile>
            <v-card-title class="hidden-sm-and-down">
              <v-spacer/>
              <v-btn icon @click="reset">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-stepper v-model="step" class="transparent" flat>
                <v-stepper-header class="elevation-0">
                  <v-stepper-step color="info" step="1">Allgemein</v-stepper-step>
                  <v-stepper-step color="info" step="2">Frisur</v-stepper-step>
                  <v-stepper-step color="info" step="3">Gesicht</v-stepper-step>
                  <v-stepper-step color="info" step="4">Outfit</v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <create-avataaar-common-step :skin-color.sync="avatarOptions.skinColor" @next="step++"/>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <create-avataaar-hair-step :top-color.sync="avatarOptions.topColor"
                                               :top-type.sync="avatarOptions.topType" @next="step++" @prev="step--"/>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <create-avataaar-face-step :accessories-type.sync="avatarOptions.accessoriesType"
                                               :eyebrow-type.sync="avatarOptions.eyebrowType"
                                               :eyes-type.sync="avatarOptions.eyesType"
                                               :facial-hair-color.sync="avatarOptions.facialHairColor"
                                               :facial-hair-type.sync="avatarOptions.facialHairType"
                                               :mouth-type.sync="avatarOptions.mouthType"
                                               @next="step++" @prev="step--"/>
                  </v-stepper-content>
                  <v-stepper-content step="4">
                    <create-avataaar-outfit-step :clothes-color.sync="avatarOptions.clothesColor"
                                                 :clothes-graphics-type.sync="avatarOptions.clothesGraphicsType"
                                                 :clothes-type.sync="avatarOptions.clothesType" @prev="step--"
                                                 @save="save"/>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Avataaars from 'vue-avataaars';
import {AvataaarOptions} from '@/interfaces/avataaarOptions.interface';
import {EmployeeHandlerAPI} from '@/classes/api/employee-handler.api.class';
import store from '@/store';
import CreateAvataaarCommonStep from '@/components/common/profile/createAvataaarDialog/createAvataaarCommonStep.vue';
import CreateAvataaarHairStep from '@/components/common/profile/createAvataaarDialog/createAvataaarHairStep.vue';
import CreateAvataaarFaceStep from '@/components/common/profile/createAvataaarDialog/createAvataaarFaceStep.vue';
import CreateAvataaarOutfitStep from '@/components/common/profile/createAvataaarDialog/createAvataaarOutfitStep.vue';
import {Avataaar} from '@/classes/clientOnly/Avataaar';

export default Vue.extend({
  props: {
    value: Boolean,
    type: String as () => 'employee' | 'parent' | 'student',
  },
  components: {
    CreateAvataaarOutfitStep,
    CreateAvataaarFaceStep,
    CreateAvataaarHairStep,
    CreateAvataaarCommonStep,
    Avataaars,
  },
  data: () => ({
    loading: false,
    step: 1,
    avatarSeed: '',
    avatarOptions: {
      backgroundType: 'transparent',
      backgroundColor: 'black',
      clothesType: 'hoodie',
      clothesColor: 'blue01',
      clothesGraphicsType: 'none',
      eyebrowType: 'default',
      mouthType: 'smile',
      facialHairType: 'none',
      facialHairColor: 'auburn',
      topType: 'shortHairTheCaesar',
      topColor: 'auburn',
      accessoriesType: 'none',
      eyesType: 'default',
      skinColor: 'brown',
    } as AvataaarOptions,
  }),
  methods: {
    initialize() {
      if (this.$store.state.user) {
        const avataaar = new Avataaar(this.$store.state.user.profileImg);
        this.avatarOptions = avataaar.options;
      }
    },
    reset() {
      this.step = 1;
      this.$emit('input', false);
    },
    async save() {
      this.loading = true;
      const avataaar = new Avataaar(this.avatarOptions);
      try {
        switch (this.type) {
          case 'employee':
            if (store.state.user) {
              await EmployeeHandlerAPI.setProfileImg({
                id: store.state.user._id,
                profileImg: avataaar.toHex(),
              });
              store.commit('user', {...store.state.user, profileImg: avataaar.toHex()});
              this.reset();
            }
            break;
          case 'parent':
            // ParentHandlerApi
            break;
          case 'student':
            // StudentHandlerApi
            break;
        }
      } catch (error) {
        this.loading = false;
        return;
      }
      this.loading = false;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          this.initialize();
        }
      },
    },
  },
});
</script>
