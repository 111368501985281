<template>
  <div ref="root" class="root">
    <editor-content v-if="editor" :editor="editor" :style="wrapperStyles" class="content" @blur="handleBlur"
                    @focus="handleFocus"/>
    <bubble-menu v-if="editor" :editor="editor">
      <v-toolbar dense>
        <v-toolbar-items>
          <v-btn :class="{'v-btn--active': editor.isActive('bold')}" class="mx-1" icon
                 @click="editor.chain().focus().toggleBold().run()">
            <v-icon>mdi-format-bold</v-icon>
          </v-btn>
          <v-btn :class="{'v-btn--active': editor.isActive('italic')}" class="mx-1" icon
                 @click="editor.chain().focus().toggleItalic().run()">
            <v-icon>mdi-format-italic</v-icon>
          </v-btn>
          <v-btn v-if="context !== 'whatsApp'" :class="{'v-btn--active': editor.isActive('underline')}" class="mx-1"
                 icon @click="editor.chain().focus().toggleUnderline().run()">
            <v-icon>mdi-format-underline</v-icon>
          </v-btn>
          <v-btn :class="{'v-btn--active': editor.isActive('strike')}" class="mx-1" icon
                 @click="editor.chain().focus().toggleStrike().run()">
            <v-icon>mdi-format-strikethrough</v-icon>
          </v-btn>
          <v-btn :class="{'v-btn--active': editor.isActive('bulletList')}" class="mx-1" icon
                 @click="editor.chain().focus().toggleBulletList().run()">
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
          <v-btn :class="{'v-btn--active': editor.isActive('orderedList')}" class="mx-1" icon
                 @click="editor.chain().focus().toggleOrderedList().run()">
            <v-icon>mdi-format-list-numbered</v-icon>
          </v-btn>
          <v-menu v-if="context !== 'whatsApp'" :close-on-content-click="false" min-width="350" nudge-bottom="3"
                  nudge-left="80"
                  offset-y>
            <template v-slot:activator="{on}">
              <v-btn :class="{'v-btn--active': editor.isActive('orderedList')}" class="mx-1" icon
                     v-on="on">
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="10">
                    <v-text-field v-model="computedLinkValue" color="info" dense hide-details label="Link" outlined
                                  rounded/>
                  </v-col>
                  <v-col cols="2">
                    <v-btn :disabled="!linkValue" color="info" icon
                           @click="editor.commands.toggleLink({href: linkValue, target: '_blank'})">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-menu>

        </v-toolbar-items>
      </v-toolbar>
    </bubble-menu>
    <v-textarea :color="color" :dense="dense" :filled="filled" :label="label" :loading="loading" :outlined="outlined"
                :rounded="rounded" :rules="rules" :value="value" @input="$emit('input', $event)">
    </v-textarea>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import {BubbleMenu, Editor, EditorContent} from '@tiptap/vue-2';
import {StarterKit} from '@tiptap/starter-kit';
import {Underline} from '@tiptap/extension-underline';
import {TextAlign} from '@tiptap/extension-text-align';
import {Rule} from '@/helpers/ruleFactory.helper';
import {Link} from '@tiptap/extension-link';

export default Vue.extend({
  components: {
    EditorContent,
    BubbleMenu,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Text',
    },
    context: {
      type: String as () => 'complete' | 'whatsApp',
      default: 'complete',
    },
    color: {
      type: String,
      default: 'primary',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array as () => Rule[],
    },
  },
  data: () => ({
    editor: undefined as undefined | Editor,

    isFocused: false,
    hasContent: false,

    linkValue: '',
  }),
  computed: {
    computedLinkValue: {
      get() {
        if (typeof this.linkValue === 'string' && !this.linkValue.startsWith('http') && !this.linkValue.startsWith('mailto') && !this.linkValue.startsWith('tel')) {
          return `https://${this.linkValue}`;
        }
        return this.linkValue;
      },
      set(v: string) {
        this.linkValue = v;
      },
    },
    wrapperStyles(): Record<string, any> {
      if (this.rounded) {
        if (this.outlined) {
          if (this.dense) {
            return {paddingLeft: '24px', paddingTop: '13px', paddingBottom: '40px', paddingRight: '24px'};
          } else {
            return {paddingLeft: '24px', paddingTop: '17px', paddingBottom: '40px', paddingRight: '24px'};
          }
        }
        if (this.dense) {
          return {paddingLeft: '24px', paddingTop: '7px', paddingBottom: '35px', paddingRight: '24px'};
        } else {
          return {paddingLeft: '24px', paddingTop: '19px', paddingBottom: '40px', paddingRight: '24px'};
        }
      }
      if (this.outlined) {
        if (this.dense) {
          return {paddingLeft: '12px', paddingTop: '13px', paddingBottom: '35px', paddingRight: '12px'};
        } else {
          return {paddingLeft: '12px', paddingTop: '17px', paddingBottom: '40px', paddingRight: '12px'};
        }
      }
      if (this.filled) {
        return {paddingLeft: '12px', paddingTop: '31px', paddingBottom: '35px', paddingRight: '12px'};
      }
      if (this.dense) {
        return {paddingTop: '7px', paddingBottom: '18px'};
      } else {
        return {paddingTop: '19px', paddingBottom: '22px'};
      }
    },
  },
  methods: {
    shouldShow(props: { editor: Editor }) {
      return props.editor.isFocused;
    },
    handleContentful() {
      (this.$refs.root as HTMLElement).querySelector('label')?.classList.add('v-label--active');
      (this.$refs.root as HTMLElement).querySelector('v-input')?.classList.add('v-label--is-focused');
    },
    handleContentless() {
      if (this.isFocused) {
        setTimeout(() => {
          this.handleFocus();
        }, 10);
      } else {
        (this.$refs.root as HTMLElement).querySelector('label')?.classList.remove('v-label--active');
        (this.$refs.root as HTMLElement).querySelector('.v-input')?.classList.remove('v-label--is-focused');
      }
    },
    handleFocus() {
      (this.$refs.root as HTMLElement).querySelector('label')?.classList.add('v-label--active', `${this.color}--text`);
      (this.$refs.root as HTMLElement).querySelector('.v-input')?.classList.add('v-label--is-focused', `${this.color}--text`);
    },
    handleBlur() {
      (this.$refs.root as HTMLElement).querySelector('label')?.classList.remove(`${this.color}--text`);
      (this.$refs.root as HTMLElement).querySelector('.v-input')?.classList.remove(`${this.color}--text`);
      if (!this.hasContent) {
        this.handleContentless();
      }
    },
  },
  watch: {
    isFocused(v) {
      if (v) {
        this.handleFocus();
      } else {
        this.handleBlur();
      }
    },
    hasContent(v) {
      if (v) {
        this.handleContentful();
      } else {
        this.handleContentless();
      }
    },
    value(v) {
      if (this.editor && v !== this.editor.getHTML()) {
        this.editor.commands.setContent(v);
        this.hasContent = !!this.editor.getText().trim();
      }
    },
  },
  mounted() {
    const extensions: any[] = [
      StarterKit.configure({
        heading: false,
      }),
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Link.configure({
        autolink: true,
        protocols: ['http', 'https', 'mailto', 'tel'],
        linkOnPaste: true,
      }),
    ];

    this.editor = new Editor({
      content: this.value,
      extensions,
    });
    this.editor.on('focus', () => {
      this.isFocused = true;
    });
    this.editor.on('blur', () => {
      this.isFocused = false;
    });
    this.editor.on('update', () => {

      const hasTextContent = !!this.editor?.getText().trim();
      this.hasContent = hasTextContent;
      this.$emit('input', hasTextContent ? (this.editor as Editor).getHTML() : '');
    });
    this.$nextTick(() => {
      this.hasContent = !!(this.editor as Editor).getText().trim();
    });
  },
});
</script>

<style>
.root {
  position: relative;

  .v-text-field__slot textarea {
    opacity: 0;
  }

  /*noinspection CssUnusedSymbol*/
  .content .tiptap {
    overflow-y: scroll;
  }

  .content {
    position: absolute;
    top: -5px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    /*background-color: rgba(0, 0, 0, 0.1);*/

    :focus {
      outline: none !important;
      border: none !important;
    }

    /*noinspection CssUnusedSymbol*/

    .tiptap {
      height: 100%;
      /*background-color: rgba(255,0,0,0.1);*/
    }
  }
}
</style>
