<template>
  <div class="pa-5" style="position: relative">
    <v-row v-if="messageLoading">
      <v-skeleton-loader type="list-item-two-line"/>
    </v-row>
    <v-container v-else id="vanilla-messaging-dialog-message-container" fluid>
      <template v-for="(message, i) in conversation.messages">
        <messenger-message-card-wrapper :key="retrieveObjectId(message)" :message.sync="conversation.messages[i]"/>
      </template>
      <div v-if="conversation.messages.length === 0">
        Keine Nachrichten
      </div>
      <span v-intersect="handleIntersection" style="position: relative; top: -20px; z-index: -1"/>
    </v-container>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {GetConversationsResponseDto} from '@/classes/dto/messenger/response/messages/GetConversations.response.dto';
import {MessengerTypesEnum} from '@/enums/messenger/MessengerTypes.enum';
import MessengerMessageCardWrapper
  from '@/components/app/messagingComponents/messageCards/messengerMessageCardWrapper.vue';
import {retrieveObjectId} from '@/helpers/isObjectId.helper';

export default Vue.extend({
  components: {MessengerMessageCardWrapper},
  props: {
    conversation: {
      type: Object as () => GetConversationsResponseDto['conversations'][0],
      required: true,
    },
    messageLoading: {
      type: Boolean,
      default: false,
    },
    isAtBottom: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    MessengerTypesEnum: () => MessengerTypesEnum,
    retrieveObjectId: () => retrieveObjectId,
  },
  methods: {
    handleIntersection(entries: IntersectionObserverEntry[]) {
      this.$emit('update:isAtBottom', entries[0].isIntersecting);
    },
  },
});
</script>
