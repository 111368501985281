<template>
  <v-row justify="center" style="min-height: 64px">
    <search-grid-item :highlight-best-match="highlightBestMatch" :result="result" field="students" icon="mdi-school"
                      title="Schüler:innen" url="students"/>
    <search-grid-item :highlight-best-match="highlightBestMatch" :result="result" field="parents"
                      icon="mdi-account-child" title="Eltern" url="parents"/>
    <search-grid-item :highlight-best-match="highlightBestMatch" :result="result" field="employees"
                      icon="mdi-account-hard-hat" title="Mitarbeitende" url="employees"/>
    <search-grid-item :highlight-best-match="highlightBestMatch" :result="result" field="institutions" icon="mdi-domain"
                      title="Standorte" url="institutions"/>
    <search-grid-item :highlight-best-match="highlightBestMatch" :result="result" field="articles" icon="mdi-warehouse"
                      title="Produkte" url="products"/>
    <search-grid-item :highlight-best-match="highlightBestMatch" :result="result" field="courses"
                      icon="mdi-table-account" title="Kurse" url="courses"/>
  </v-row>
</template>
<script lang="ts">
import Vue from 'vue';
import {FindGlobalResponseDto} from '@/classes/dto/finder/response/FindGlobal.response.dto';
import SearchGridItem from '@/components/app/appBar/searchDialog/searchGridItem.vue';

export default Vue.extend({
  components: {SearchGridItem},
  props: {
    result: {
      type: Object as () => FindGlobalResponseDto,
      required: true,
    },
    highlightBestMatch: {
      type: Boolean,
      required: true,
    },
  },
});
</script>
