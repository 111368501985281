import axios from 'axios';
import store from '@/store';

export default class CookieManager {
    private _version = 1;
    private _cookies = {
        version: this._version,
        functional: true,
        analytics: false,
    } as { version: number; functional: true, analytics: boolean };

    constructor() {
        const rawData = localStorage.getItem('cookieSettings');
        if (rawData) {
            this._cookies = JSON.parse(rawData) as { version: number; functional: true, analytics: boolean };
        } else {
            setTimeout(() => {
                this.showCookieDialog();
            }, 1000);
        }
    }

    public showCookieDialog(): void {
        store.commit('showCookieDialog', true);
    }

    public hideCookieDialog(): void {
        store.commit('showCookieDialog', false);
    }

    public getCookieSettings() {
        return this._cookies;
    }

    public async resetTrackingCookie(): Promise<void> {
        try {
            this._cookies.analytics = false;
            this._saveCookieSettings();
            await axios.delete(`${process.env.VUE_APP_API_PATH}/tracker/cookie`, {
                withCredentials: true,
            });
        } finally {
            this.hideCookieDialog();
        }
    }

    public async setTrackingCookie(): Promise<void> {
        try {
            this._cookies.analytics = true;
            this._saveCookieSettings();
            await axios.post(`${process.env.VUE_APP_API_PATH}/tracker/cookie`, undefined, {
                withCredentials: true,
            });
        } finally {
            this.hideCookieDialog();
        }
    }

    private _saveCookieSettings() {
        localStorage.setItem('cookieSettings', JSON.stringify(this._cookies));
    }
}
