<template>
  <div>
    <v-autocomplete clearable
                    :color="color" :dense="dense" :disabled="disabled" :filled="filled"
                    :items="items" :label="label" :loading="loading" :multiple="multiple" :outlined="outlined" :rounded="rounded"
                    :rules="rules" :single-line="singleLine" :value="value" cache-items prepend-icon="mdi-chair-rolling"
                    @input="handleInput" :append-outer-icon="appendOuterIcon" placeholder="Tippen um zu suchen">
      <template v-slot:prepend-item>
        <v-subheader class="px-4">Vorgeschlagene Ergebnisse:</v-subheader>
        <v-divider/>
      </template>
      <template v-if="canCreate" v-slot:append-outer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="info" icon @click="showCreateJobTypeDialog=true" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          Neue Jobposition anlegen
        </v-tooltip>
      </template>
    </v-autocomplete>

    <!-- DIALOGS -->
    <create-job-type v-model="showCreateJobTypeDialog" @update:jobType="setDefault"/>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { CrudEntityTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import { CrudActionTypes } from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import CreateJobType from '@/components/responsibles/createJobTypeDialog.vue';
import { JobType } from '@/interfaces/jobType.interface';
import { ResponsibleHandlerAPI } from '@/classes/api/responsible-handler.api.class';
import mongoose from "mongoose";

export default Vue.extend({
  components: {CreateJobType},
  props: {
    rules: {
      type: Array as () => Array<(v: any) => boolean | string>,
      default: () => ([]),
    },
    color: {
      type: String,
      default: 'info',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Suche',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    throttled: {
      type: Boolean,
      default: true,
    },
    throttleDelay: {
      type: Number,
      default: 500,
    },
    enableCreate: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object as () => mongoose.Types.ObjectId | null | undefined,
      required: true,
    },
    appendOuterIcon: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    items: [] as Array<{ text: string; value: mongoose.Types.ObjectId }>,
    loading: false,
    throttleCounter: 0,
    showCreateJobTypeDialog: false,
  }),
  computed: {
    canCreate(): boolean {
      if (!this.enableCreate) {
        return false;
      } else {
        return this.$$crudAccessManager.getAccess(CrudEntityTypes.JOB_TYPE, CrudActionTypes.CREATE);
      }
    },
  },
  methods: {
  setDefault(type: JobType) {
    this.items.push({
      text: type.neutral,
      value: type._id,
    });
    this.$emit('input', type._id);
  },
    handleInput(v: any) {
      this.$emit('input', v);
    },

    async getInitialValues() {
      this.loading = true;
      try {
        const resp = await ResponsibleHandlerAPI.jobTypes.find({ skipPagination: true });
        this.items = resp.jobTypes.map((jobType) => ({
          text: jobType.neutral,
          value: jobType._id,
        }));
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getInitialValues();
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.$emit('update:jobType', this.value || undefined);
      },
    },
  },
});
</script>
