import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {CourseFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/CourseFields';

export const courseFieldsDictionary: FieldDictionary<CourseFields> = [
    {
        text: 'Kursnummer',
        value: CourseFields.CUSTOM_ID,
    },
    {
        text: 'Fach',
        value: CourseFields.SUBJECT,
    },
    {
        text: 'Aktuelle Lehrkraft',
        value: CourseFields.TEACHER,
    },
    {
        text: 'Reguläre Lehrkraft',
        value: CourseFields.OWNING_TEACHER,
    },
    {
        text: 'Angefragte Vertretungslehrkräfte',
        value: CourseFields.TEACHERS_FOR_SUBSTITUTION,
    },
    {
        text: 'Schüler u. ggf. abweichendes Fach',
        value: CourseFields.STUDENT_ITEMS,
    },
    {
        text: 'Unterrichtsraum',
        value: CourseFields.ROOM,
    },
    {
        text: 'Standort',
        value: CourseFields.INSTITUTION,
    },
    {
        text: 'Wochentag',
        value: CourseFields.DAY_OF_WEEK,
    },
    {
        text: 'Startdatum',
        value: CourseFields.START_DATE,
    },
    {
        text: 'Enddatum',
        value: CourseFields.END_DATE,
    },
    {
        text: 'Uhrzeit',
        value: CourseFields.TIME_RANGE,
    },
    {
        text: 'Ganztägig?',
        value: CourseFields.IS_ALL_DAY,
    },
    {
        text: 'Wiederholungsintervall',
        value: CourseFields.WEEK_INTERVAL,
    },
    {
        text: 'Ist unrentabel?',
        value: CourseFields.IS_UNPROFITABLE,
    },
    {
        text: 'Rentabilitätsbericht',
        value: CourseFields.PROFIT_REPORT,
    },
    {
        text: 'Ferienregion (Bundesland)',
        value: CourseFields.HOLIDAY_STATE,
    },
    {
        text: 'Ferienland',
        value: CourseFields.HOLIDAY_COUNTRY,
    },
    {
        text: 'Vorgänger',
        value: CourseFields.PREDECESSOR,
    },
    {
        text: 'Nachfolger',
        value: CourseFields.SUCCESSOR,
    },
    {
        text: 'Kursstatus',
        value: CourseFields.COURSE_STATE,
    },
    {
        text: 'Vertretungsanfrage',
        value: CourseFields.SUBSTITUTION_INQUIRY,
    },
];
