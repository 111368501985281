<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row align="center">
        <v-col cols="11">
          <v-card v-if="repliesTo && repliesTo.message" class="mb-4">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Antwort auf:</v-list-item-title>
                <v-list-item-subtitle>{{ repliesTo.message }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon small @click="resetRepliesTo">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card>

          <div v-if="lastUsedMailOption" class="caption">
            Nachricht wird von {{ outMailAddress }} gesendet.
          </div>
          <v-select v-else v-model="outMailAddress" :items="mailOptions" :rules="[RuleFactory.required()]"
                    color="outlook" dense filled label="E-Mail-Adresse"/>
          <v-text-field v-model="subject" :rules="[RuleFactory.required()]" color="outlook" dense filled
                        label="Betreff"/>
          <html-content-input v-model="message" :loading="loading" :rules="[RuleFactory.required()]" color="outlook"
                              dense filled/>
        </v-col>
        <v-col cols="1">
          <v-btn :disabled="!valid" :loading="loading" color="outlook" icon @click="sendMessage">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {MessengerMessageDocument} from '@/documentTypes/messenger/MessengerMessage.document';
import {MessengerTypesEnum} from '@/enums/messenger/MessengerTypes.enum';
import {MessengerBus} from '@/busses/MessengerBus';
import {MessengerMail, MessengerMailDocument} from '@/documentTypes/messenger/messages';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {Form} from '@/interfaces/Form.interface';
import {MessengerApi} from '@/classes/api/messenger.api';
import {GetConversationsResponseDto} from '@/classes/dto/messenger/response/messages/GetConversations.response.dto';
import HtmlContentInput from '@/components/common/inputs/htmlContentInput.vue';
import {LeanDocument} from 'mongoose';
import {MessengerAccountDocument} from '@/documentTypes/messenger/MessengerAccount.document';
import {MessengerMailAccountDocument} from '@/documentTypes/messenger/MessengerMailAccount.document';
import {isPopulated} from '@/helpers/isObjectId.helper';
import {MailApiConfigurationDocument} from '@/documentTypes/api-handler/MailApiConfiguration';

type LocalStorageDraft = {
  message: string;
  subject: string;
  repliesTo?: MessengerMailDocument;
}

export default Vue.extend({
  components: {HtmlContentInput},
  props: {
    conversation: {
      type: Object as () => GetConversationsResponseDto['conversations'][0],
      required: true,
    },
    accounts: {
      type: Array as () => Array<LeanDocument<MessengerAccountDocument>>,
      required: true,
    },
  },
  data: () => ({
    repliesTo: undefined as undefined | MessengerMailDocument,
    valid: false,
    subject: '',
    message: '',
    loading: false,
    mailRowCount: 1,
    whatsAppRowCount: 1,
    customOutMailAddress: '',
  }),
  computed: {
    RuleFactory: () => RuleFactory,
    MessengerTypesEnum: () => MessengerTypesEnum,
    outMailAddress: {
      get(): string {
        return this.lastUsedMailOption || this.customOutMailAddress;
      },
      set(value: string) {
        this.customOutMailAddress = value;
      },
    },
    mailOptions(): string[] {
      const mailAccounts = this.accounts.filter(account => account.type === MessengerTypesEnum.MAIL) as LeanDocument<MessengerMailAccountDocument>[];
      return mailAccounts
          .flatMap(account => account.apiConfigs as LeanDocument<MailApiConfigurationDocument>[])
          .filter(isPopulated)
          .flatMap(apiConfig => [apiConfig.smtpUsername, ...(apiConfig.aliases || [])]);
    },
    lastUsedMailOption(): string | undefined {
      if (!this.conversation.mail) return undefined;
      const messages = (this.conversation.messages as LeanDocument<MessengerMailDocument>[]).filter((m) => isPopulated(m) && m.type === MessengerTypesEnum.MAIL);
      messages.reverse();
      for (const message of messages) {
        if (message.fromMail === this.conversation.mail && this.mailOptions.includes(message.toMail)) {
          return message.toMail;
        } else if (message.toMail === this.conversation.mail && this.mailOptions.includes(message.fromMail)) {
          return message.fromMail;
        }
      }
      return undefined;
    },
  },
  methods: {
    handleResize() {
      const windowHeight = window.innerHeight;
      if (windowHeight > 1200) {
        this.mailRowCount = 7;
      } else if (windowHeight > 1000) {
        this.mailRowCount = 6;
      } else if (windowHeight > 800) {
        this.mailRowCount = 5;
      } else {
        this.mailRowCount = 4;
      }
    },
    async sendMessage() {
      const form = this.$refs.form as Form;
      if (form && form.validate()) {
        this.loading = true;
        try {
          await MessengerApi.messages.mails.sendMail({
            message: this.message,
            subject: this.subject,
            to: this.conversation.profileId,
            toType: this.conversation.profileType ? this.conversation.profileType : undefined,
            fromMail: this.outMailAddress,
            toMail: this.conversation.mail as string,
            repliesTo: this.repliesTo ? (this.repliesTo as MessengerMailDocument)._id : undefined,
          });
          this.$$showSnackbar('E-Mail wurde gesendet', 'success');
          this.$emit('sent');
          form.reset();
          this.resetRepliesTo();
          this.$nextTick(() => {
            this.deleteDraft();
          });
        } catch (e) {
          this.$$showSnackbar('E-Mail konnte nicht gesendet werden', 'error', e);
        } finally {
          this.loading = false;
        }
      }
    },
    resetRepliesTo() {
      MessengerBus.$emit('update:replyToMessage', undefined);
      this.subject = '';
    },
    storeDraft() {
      const draft: LocalStorageDraft = {
        message: this.message,
        subject: this.subject,
        repliesTo: this.repliesTo,
      };
      localStorage.setItem(`draft.${MessengerTypesEnum.MAIL}.${this.conversation.mail}`, JSON.stringify(draft));
    },
    deleteDraft() {
      localStorage.removeItem(`draft.${MessengerTypesEnum.MAIL}.${this.conversation.mail}`);
    },
    restoreDraft() {
      const draft = localStorage.getItem(`draft.${MessengerTypesEnum.MAIL}.${this.conversation.mail}`);
      if (draft) {
        const parsedDraft = JSON.parse(draft) as LocalStorageDraft;
        this.message = parsedDraft.message;
        this.subject = parsedDraft.subject;
        MessengerBus.$emit('update:replyToMessage', parsedDraft.repliesTo);
      } else {
        this.message = '';
        this.subject = '';
        this.repliesTo = undefined;
      }
      const form = this.$refs.form as Form;
      if (form) form.resetValidation();
    },
  },
  watch: {
    message() {
      this.storeDraft();
    },
    subject() {
      this.storeDraft();
    },
    repliesTo() {
      this.storeDraft();
    },
    conversation() {
      const form = this.$refs.form as Form;
      if (form) {
        form.reset();
      }
      this.resetRepliesTo();
      this.restoreDraft();
    },
  },
  mounted() {
    MessengerBus.$on('update:replyToMessage', (message?: MessengerMessageDocument | MessengerMailDocument) => {
      if (message && message.type !== MessengerTypesEnum.MAIL) {
        this.repliesTo = undefined;
        return;
      }
      this.repliesTo = message as MessengerMailDocument;
      if (message) {
        if ((message as MessengerMail).subject) {
          this.subject = 'AW:' + (message as MessengerMail).subject;
        }
      } else {
        this.subject = '';
      }
    });
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.restoreDraft();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
});
</script>
