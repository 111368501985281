<template>
  <v-alert v-if="notification.showInApp" :key="notification.id" class="elevation-3 mt-4 mx-2 notification" dark
           transition="slide-x-transition">
    <template v-if="notification.messagePayload.fromProfileImg" v-slot:prepend>
      <div class="mr-4">
        <profile-avatar :user="{profileImg: notification.messagePayload.fromProfileImg}"/>
      </div>
    </template>
    <template v-else v-slot:prepend>
      <div class="mr-4">
        <v-icon>mdi-phone-incoming</v-icon>
      </div>
    </template>
    <template v-slot:default>
      <div class="body-2">
        {{ notification.messagePayload.fromName || 'Jemand' }} ruft an...
      </div>
      <v-btn v-if="link" :href="link" @click="hide" class="mt-2" small target="_blank" text>
        <v-icon class="mr-2" small>mdi-page-next-outline</v-icon>
        Profil öffnen
      </v-btn>
    </template>
    <template v-slot:close>
      <v-btn class="ml-2" icon small @click="hide">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>
<script lang="ts">
import Vue from 'vue';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import {NotifierApi} from '@/classes/api/notifier.api';
import {CallNotificationDocument} from '@/documentTypes/notifier/discriminators/CallNotification.document';

export default Vue.extend({
  props: {
    notification: {
      type: Object as () => NotificationLike<CallNotificationDocument>,
      required: true,
    },
  },
  components: {ProfileAvatar},
  computed: {
    link(): string | undefined {
      const entityType = this.notification.messagePayload.entityType;
      const entityId = this.notification.messagePayload.entityId;
      if (entityType && entityId) {
        return this.$router.resolve(`/${entityType}/${entityId}`).href;
      }
      return undefined;
    },
  },
  methods: {
    async hide() {
      const id = this.notification._id;
      await NotifierApi.markRead({
        notificationIds: [id],
      });
      this.$store.commit('hideNotification', id);
    },
  },
});
</script>

<style scoped>
.notification {
  background-color: rgba(0, 0, 10, 0.5) !important;
  backdrop-filter: blur(15px);
}
</style>
