import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {ArticleFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/ArticleFields';

export const articleFieldsDictionary: FieldDictionary<ArticleFields> = [
    {
        text: 'Artikelnummer - Öffentlich',
        value: ArticleFields.ARTICLE_NUMBER,
        readOnly: true,
    },
    {
        text: 'Titel',
        value: ArticleFields.TITLE,
        readOnly: true,
    },
    {
        text: 'Beschreibung',
        value: ArticleFields.DESCRIPTION,
        readOnly: true,
    },
    {
        text: 'Einheit',
        value: ArticleFields.UNIT,
        readOnly: true,
    },
    {
        text: 'Preis je Einheit',
        value: ArticleFields.UNIT_PRICE,
        readOnly: true,
    },
    {
        text: 'MwSt',
        value: ArticleFields.VAT_PERCENT,
        readOnly: true,
    },
    {
        text: 'Netto / Brutto',
        value: ArticleFields.IS_GROSS,
        readOnly: true,
    },
    {
        text: 'Artikelnummer - Systemintern',
        value: ArticleFields.ARTICLE_ID,
        readOnly: true,
    },
    {
        text: 'Tags',
        value: ArticleFields.TAGS,
        readOnly: true,
    },
    {
        text: 'Standardprodukt?',
        value: ArticleFields.IS_DEFAULT,
        readOnly: true,
    },
    {
        text: 'Lagerverfolgung?',
        value: ArticleFields.TRACK_STOCK,
    },
    {
        text: 'Paketmaße',
        value: ArticleFields.PACKAGE_DEFAULTS,
    },
    {
        text: 'Lagerbestand',
        value: ArticleFields.STOCK,
        readOnly: true,
    },
    {
        text: 'DHL Standard Produkt',
        value: ArticleFields.DHL_DEFAULT_PRODUCT,
    },
];
