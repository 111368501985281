export enum StudentFields {
    GENDER = 'gender',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    BIRTHDAY = 'birthday',
    ADDRESS = 'address',
    PAYMENT = 'payment',
    HAS_LOGIN = 'hasLogin',
    IS_ACTIVE = 'isActive',
    DEACTIVATED_AT = 'deactivatedAt',
    CONTACT_INFORMATION = 'contactInformation',
    CUSTOM_ID = 'customId',
    CUSTOM_ID_SCHEME = 'customIdScheme',
    ACCOUNTING_REF = 'accountingRef',

    INSTITUTION = 'institution',
    PARENTS = 'parents',
    CLASS = 'class',
    SPECIAL_INFORMATION = 'specialInformation',
    STATE = 'state',
    SUBJECTS = 'subjects',
    IS_CUSTOMER = 'isCustomer',
    PAUSED_UNTIL = 'pausedUntil',
    TAGS = 'tags',
    SUBMISSION = 'submission',
    AVAILABILITIES = 'availabilities',

    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',

    DEACTIVATE_AT = 'deactivateAt',
    ACTIVATE_AT = 'activateAt',

    /** Virtual fields */
    INVENTORY = 'inventory',
}
