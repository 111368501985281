import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {RoomFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/RoomFields';

export const roomFieldsDictionary: FieldDictionary<RoomFields> = [
    {
        text: 'Name',
        value: RoomFields.NAME,
    },
    {
        text: 'Verknüpfter Standort',
        value: RoomFields.INSTITUTION,
        readOnly: true,
    },
    {
        text: 'Raumnummer',
        value: RoomFields.ROOM_NUMBER,
    },
    {
        text: 'Stockwerk',
        value: RoomFields.FLOOR,
    },
    {
        text: 'Kapazität',
        value: RoomFields.CAPACITY,
    },
    {
        text: 'Verfügbarkeit',
        value: RoomFields.AVAILABILITY,
    },
    {
        text: 'Details',
        value: RoomFields.DETAILS,
    },
    {
        text: 'Online',
        value: RoomFields.IS_ONLINE,
    },
    {
        text: 'Zugangsdaten Online',
        value: RoomFields.ONLINE_CREDENTIALS,
    },
];
