<template>
  <v-row>
    <v-col v-if="canUpdate('gender')" md4 xs12>
      <v-select v-model="genderCopy" :color="color" :item-color="color" :items="SalutationTypesVue"
                :rules="[RuleFactory.required('Dieses Feld ist erforderlich.')]" dense outlined
                prepend-icon="mdi-human-greeting-variant" label="Anrede"
                rounded @input="$emit('update:gender', updateGender)"/>
    </v-col>
    <v-col v-if="canUpdate('firstName')" md4 xs12>
      <v-text-field v-model="firstNameCopy" :color="color" label="Vorname"
                    :rules="requireFirstName ? [RuleFactory.required('Dieses Feld ist erforderlich.')] : []"
                    dense outlined prepend-icon="mdi-card-account-details" rounded
                    @input="$emit('update:firstName', updateFirstName)"/>
    </v-col>
    <v-col v-if="canUpdate('lastName')" md4 xs12>
      <v-text-field v-model="lastNameCopy" :color="color" label="Nachname"
                    :rules="[RuleFactory.required('Dieses Feld ist erforderlich.')]" dense outlined
                    prepend-icon="mdi-card-account-details" rounded
                    @input="$emit('update:lastName', updateLastName)"/>
    </v-col>
  </v-row>
</template>

<script lang="ts">

import Vue from 'vue';
import {GenderTypes, SalutationTypesVue} from '@/enums/GenderTypes.enum';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {Types} from 'mongoose';
import {compareForUpdate, UpdateType} from '@/helpers/compareForUpdate';
import {Responsible} from '@/interfaces/responsible.interface';
import {Student} from '@/interfaces/student.interface';
import {Parent} from '@/interfaces/parent.interface';
import {Employee} from '@/interfaces/employee.interface';
import {SingleAccessEntityTypes} from '@/classes/clientOnly/permissionTreeResources/classes/CrudAccessEntity';

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: 'warning',
    },
    gender: {
      type: Object as () => GenderTypes,
    },
    firstName: {
      type: String,
    },
    lastName: {
      type: String,
    },
    entity: {
      type: Object as () => Responsible | Student | Parent | Employee,
      required: true,
    },
    crudEntity: {
      type: Object as () => SingleAccessEntityTypes,
      required: true,
    },
    id: {
      type: Object as () => Types.ObjectId,
      required: true,
    },
    requireFirstName: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    genderCopy: undefined as UpdateType<GenderTypes>,
    firstNameCopy: undefined as UpdateType<string>,
    lastNameCopy: undefined as UpdateType<string>,
  }),
  computed: {
    SalutationTypesVue: () => SalutationTypesVue,
    RuleFactory: () => RuleFactory,
    updateGender(): GenderTypes | null | undefined {
      return compareForUpdate(this.genderCopy, this.entity.gender);
    },
    updateFirstName(): string | null | undefined {
      return compareForUpdate(this.firstNameCopy, this.entity.firstName);
    },
    updateLastName(): string | null | undefined {
      return compareForUpdate(this.lastNameCopy, this.entity.lastName);
    },
  },
  methods: {
    canUpdate(key: string): boolean {
      const entity = this.$$getCrudEntity(this.crudEntity as SingleAccessEntityTypes, this.id);
      return entity.canUpdate(key) && entity.canRead(key);
    },
  },
  watch: {
    entity: {
      deep: true,
      immediate: true,
      handler() {
        this.genderCopy = this.entity.gender;
        this.firstNameCopy = this.entity.firstName;
        this.lastNameCopy = this.entity.lastName;
        this.$emit('update:gender', this.updateGender);
        this.$emit('update:firstName', this.updateFirstName);
        this.$emit('update:lastName', this.updateLastName);
      },
    },
  },
});

</script>
