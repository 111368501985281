<template>
  <v-menu v-model="showMenu" :close-on-content-click="false" min-width="0" nudge-right="24" nudge-top="30" offset-y>
    <template v-slot:activator="{ on }">
      <div>
        <v-text-field
            ref="textField"
            v-model="textFieldValue"
            :clearable="clearable"
            :color="color"
            :dense="dense"
            :disabled="disabled || !isReady"
            :filled="filled"
            :hide-details="hideDetails"
            :hint="calcAge ? `${calcAge} Jahre alt` : hint"
            :label="label"
            :loading="loading || !isReady"
            :outlined="outlined"
            :persistent-hint="birthdayMode || persistentHint"
            :prepend-icon="prependIcon"
            :rounded="rounded"
            :rules="rules"
            :shaped="shaped"
            :solo="solo"
            data-form-type="none"
            @input="parseDate"
            @click:clear="clear"
        >
          <template v-slot:append>
            <v-tooltip :disabled="showMenu" bottom>
              <template v-slot:activator="tooltip">
                <v-icon :disabled="disabled" @click="on.click" v-on="tooltip.on">mdi-calendar</v-icon>
              </template>
              Kalender anzeigen
            </v-tooltip>
          </template>
        </v-text-field>
        <v-input v-if="loading" v-model="loading"
                 :rules="[loadingRule]" readonly
                 style="margin-top: -22px;"/>
      </div>
    </template>
    <v-card>
      <v-date-picker
          ref="picker"
          v-model="tValue"
          :allowed-dates="allowedDates"
          :close-on-content-click="false"
          :color="color"
          :first-day-of-week="1"
          :max="max ? max : birthdayMode ? moment().format() : ''"
          :min="min"
          :reactive="true"
          :show-current="showCurrent"
          class="elevation-0"
          no-title
          @change="emitChange"
      ></v-date-picker>
      <div v-if="excludeHolidays && holidaysState" class="caption pt-0 px-2 pb-2">
        Ferien für {{ StateCodesVue.find((s) => s.value === holidaysState)?.text }} berücksichtigt
      </div>
      <div v-if="excludeHolidays && holidaysInstitution" class="caption pt-0 px-2 pb-2">
        Ferien der Schule berücksichtigt
      </div>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';
import moment from 'moment';
import {RuleFactory} from '@/helpers/ruleFactory.helper';
import {Holiday} from '@/interfaces/holiday.interface';
import {VacationGetterApi} from '@/classes/api/vacation-getter.api.class';
import {StateCodes, StateCodesVue} from '@/enums/StateCodes.enum';
import mongoose from 'mongoose';

moment.locale('de');

export default Vue.extend({
  props: {
    value: {
      type: Date,
    },
    rules: {
      type: Array as () => Array<(v: any) => boolean | string>,
      default: () => ([]),
    },
    birthdayMode: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Datum',
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    max: {
      type: String,
    },
    min: {
      type: String,
    },
    format: {
      type: String,
      default: 'DD. MMMM YYYY',
    },
    excludedDates: {
      type: Array as () => Date[],
      default: () => ([]),
    },
    excludeHolidays: {
      type: Boolean,
      default: true,
    },
    holidaysState: {
      type: String as () => StateCodes,
      required: false,
    },
    holidaysInstitution: {
      type: mongoose.Types.ObjectId,
      required: false,
    },
    showCurrent: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      required: false,
    },
    appendIcon: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    shaped: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tValue: '',
    showMenu: false,
    throttleCounter: 0,
    loading: false,
    acceptedFormats: ['DD.MM.YYYY', 'DD. MMMM YYYY', 'D.MM.YYYY', 'DD.M.YYYY', 'D.M.YYYY'],
    textFieldValue: '',
    isReady: false,
    holidays: [] as Holiday[],
    loadingRule: function (v: boolean) {
      return !v || 'Bitte warte, bis die Ergebnisse geladen sind'
    },
  }),
  computed: {
    moment: () => moment,
    StateCodesVue: () => StateCodesVue,
    RuleFactory: () => RuleFactory,
    fValue: {
      get(): string {
        if (this.value) {
          return moment(this.value).format(this.format);
        } else {
          return '';
        }
      },
      set(val: string) {
        this.tValue = moment(val).format('YYYY-MM-DD');
      },
    },
    calcAge() {
      if (this.birthdayMode && this.tValue) {
        return moment().diff(moment(this.tValue), 'years');
      } else {
        return '';
      }
    },
    formattedExcludedDates(): string[] {
      const formattedDatesSet: Set<string> = new Set(this.excludedDates.map((date) => moment(date).format('YYYY-MM-DD')));
      if (this.excludeHolidays) {
        for (const holiday of this.holidays) {
          const current = moment(holiday.start);
          const end = moment(holiday.end).add(1, 'day');
          while (current.format('YYYY-MM-DD') !== end.format('YYYY-MM-DD')) {
            formattedDatesSet.add(current.format('YYYY-MM-DD'));
            current.add(1, 'day');
          }
        }
      }
      return Array.from(formattedDatesSet);
    },
  },
  methods: {
    allowedDates(val: string): boolean {
      return !this.formattedExcludedDates.includes(val);
    },
    updateTempValue() {
      if (this.value) {
        this.tValue = moment(this.value).format('YYYY-MM-DD');
      } else {
        this.tValue = '';
      }
      this.textFieldValue = this.fValue;
    },
    clear() {
      this.$emit('input', null);
    },
    emitChange() {
      this.$emit('input', moment(this.tValue).toDate());
      this.showMenu = false;
    },
    async loadHolidays() {
      this.isReady = false;
      try {
        this.holidays = [];
        if (this.excludeHolidays) {
          const holidays: Holiday[] = [];
          if (this.holidaysState) {
            const stateHolidays = await VacationGetterApi.find({
              skipPagination: true,
              filter: {
                stateCode: [this.holidaysState],
                range: {
                  start: moment().subtract(6, 'months').toDate(),
                  end: moment().add(6, 'months').toDate(),
                },
              },
            });
            holidays.push(...stateHolidays.holidays);
          }
          if (this.holidaysInstitution) {
            const institutionHolidays = await VacationGetterApi.find({
              skipPagination: true,
              filter: {
                institution: [this.holidaysInstitution],
                considerInstitutionStateAndCountry: true,
                range: {
                  start: moment().subtract(6, 'months').toDate(),
                  end: moment().add(6, 'months').toDate(),
                },
              },
            });
            holidays.push(...institutionHolidays.holidays);
          }
          this.holidays = holidays;
        }
      } finally {
        this.isReady = true;
      }
    },
    parseDate() {
      if (!this.textFieldValue) {
        if (this.throttleCounter === 0) {
          this.$emit('input', null);
        }
        return;
      }
      this.throttleCounter++;
      this.loading = true;
      setTimeout(() => {
        this.throttleCounter--;
        if (this.throttleCounter === 0 && this.textFieldValue) {
          for (const format of this.acceptedFormats) {
            if (moment(this.textFieldValue, format).isValid()) {
              this.$emit('input', moment(this.textFieldValue, format).toDate());
              this.updateTempValue();
              this.loading = false;
              return;
            }
          }
          this.$$showSnackbar({text: 'Der eingegebene Text konnte nicht als Datum interpretiert werden'});
          this.$emit('input', null);
          setTimeout(() => {
            this.$emit('input', this.value);
            this.updateTempValue();
            this.loading = false;
          });
        } else if (!this.textFieldValue) {
          this.$emit('input', null);
          this.loading = false;
        }
      }, 1250);
    },
  },
  watch: {
    value() {
      this.updateTempValue();
    },
    showMenu(v: boolean) {
      if (v && this.birthdayMode) {
        setTimeout(() => {
          const picker = this.$refs.picker as {
            activePicker: string;
          } & HTMLElement;
          picker.activePicker = 'YEAR';
        });
      }
    },
    holidaysState() {
      this.loadHolidays();
    },
    holidaysInstitution() {
      this.loadHolidays();
    },
    excludeHolidays() {
      this.loadHolidays();
    },
    format() {
      this.parseDate();
    },
  },
  mounted() {
    this.updateTempValue();
    this.loadHolidays();
    this.textFieldValue = this.fValue;
  },
});
</script>
