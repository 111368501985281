<template>
  <v-scale-transition class="align-center" group hide-on-leave origin="left" tag="v-row">
    <v-col key="icon" cols="1">
      <v-icon>mdi-note</v-icon>
    </v-col>
    <v-col v-if="!editMode" key="note" cols="10" style="text-align: justify">
      <span v-if="note" v-text="note"/>
      <span v-else class="grey--text">Keine Notiz vorhanden</span>
    </v-col>
    <v-col v-else key="note-edit" cols="10">
      <v-textarea v-model="updatedNote" :disabled="loading" autofocus color="warning" height="100" hide-details
                  label="Notiz" no-resize outlined dense rounded/>
    </v-col>
    <v-col v-if="!editMode" key="actions" v-can:update-stacks="{ id: this.stackId, keys: ['items'] }" cols="1">
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon @click="initializeEditMode" v-on="on">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        Notiz bearbeiten
      </v-tooltip>
    </v-col>
    <v-col v-else key="edit-actions" cols="1">
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn :loading="loading" icon @click="saveChanges" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        Änderungen speichern
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn :disabled="loading" icon @click="stopEditMode" v-on="on">
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
        </template>
        Änderungen verwerfen
      </v-tooltip>
    </v-col>
  </v-scale-transition>
</template>
<script lang="ts">
import Vue from 'vue';
import {Types} from 'mongoose';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';

export default Vue.extend({
  props: {
    note: {type: String, required: true},
    stackId: {type: Object as () => Types.ObjectId, required: true},
    itemId: {type: Object as () => Types.ObjectId, required: true},
  },
  data: () => ({
    editMode: false,
    loading: false,
    updatedNote: '',
  }),
  methods: {
    initializeEditMode() {
      this.updatedNote = this.note;
      this.editMode = true;
    },
    stopEditMode() {
      this.updatedNote = '';
      this.editMode = false;
    },
    async saveChanges() {
      this.loading = true;
      try {
        await StackManagerApi.updateItem(this.stackId, this.itemId, {note: this.updatedNote || null});
        this.$$showSnackbar('Die Änderungen wurden erfolgreich gespeichert', 'success');
        this.$emit('update:note', this.updatedNote);
        this.stopEditMode();
      } catch (e) {
        this.$$showSnackbar('Beim Speichern der Änderungen ist ein Fehler aufgetreten', 'error');
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
