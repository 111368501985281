<template>
  <v-col cols="12" sm="6" md="4" lg="3" v-if="items.length > 0">
    <v-card class="rounded-xl translucent">
      <v-card-title class="pb-2 font-weight-bold text-body-1">
        <v-icon class="mr-2">{{ icon }}</v-icon>
        {{ title }}
        <v-spacer/>
        <v-icon v-if="containsMaxScore && highlightBestMatch" color="info">mdi-star</v-icon>
      </v-card-title>
      <v-list dense class="pt-0">
        <v-list-item v-for="item in items" :key="item.value" :href="$router.resolve(`/${url}/${item.value}`).href">
          <v-list-item-content>
            <v-list-item-title :class="{ 'info--text font-weight-black': isMaxScore(item) && highlightBestMatch }">
              {{ item.text }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.ref">
              {{item.ref}}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon v-if="!item.isActive">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on">mdi-sleep</v-icon>
              </template>
              Deaktiviert
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>
  </v-col>
</template>
<script lang="ts">
import Vue from 'vue';
import {FindGlobalResponseDto} from '@/classes/dto/finder/response/FindGlobal.response.dto';
import * as mongoose from "mongoose";

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    result: {
      type: Object as () => FindGlobalResponseDto,
      required: true,
    },
    highlightBestMatch: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    containsMaxScore() {
      const rootItems = this.result.items as Record<string, { maxScore: number }>;
      return rootItems[this.field]?.maxScore === this.result.maxScore;
    },
    items(): Array<{text: string; value: mongoose.Types.ObjectId; isActive: boolean; ref: string; score: number}> {
      const rootItems = this.result.items as Record<string, { items: any[] }>;
      return rootItems[this.field]?.items || [];
    },
  },
  methods: {
    isMaxScore(item: {score: number}): boolean {
      return item.score === this.result.maxScore;
    },
  },
});
</script>

<style scoped>
.translucent {
  opacity: 0.9;
}
</style>
