import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {InvoiceTemplateFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/InvoiceTemplateFields';

export const invoiceTemplateFieldsDictionary: FieldDictionary<InvoiceTemplateFields> = [
    {
        text: 'Elemente',
        value: InvoiceTemplateFields.ITEMS,
    },
    {
        text: 'Name der Vorlage',
        value: InvoiceTemplateFields.TITLE,
    },
    {
        text: 'Produktbild',
        value: InvoiceTemplateFields.IMG,
    },
    {
        text: 'Art der erbrachten Leistung',
        value: InvoiceTemplateFields.PRODUCT_TYPE,
    },
    {
        text: 'Verknüpfte Fächer',
        value: InvoiceTemplateFields.FOR_SUBJECTS,
    },
    {
        text: 'Für alle Fächer relevant?',
        value: InvoiceTemplateFields.IS_FOR_ALL_SUBJECTS,
    },
    {
        text: 'Verborgen?',
        value: InvoiceTemplateFields.IS_HIDDEN,
    },
];
