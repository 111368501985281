<template>
  <v-alert v-if="notification.showInApp" :key="notification.id" class="elevation-3 mt-4 mx-2 notification" dark
           transition="slide-x-transition">
    <template v-if="notification.messagePayload.fromProfileImg" v-slot:prepend>
      <div class="mr-4">
        <profile-avatar :user="{profileImg: notification.messagePayload.fromProfileImg}"/>
      </div>
    </template>
    <template v-else v-slot:prepend>
      <div class="mr-4">
        <v-icon>mdi-comment</v-icon>
      </div>
    </template>
    <template v-slot:default>
      <div class="body-2">
        {{ notification.messagePayload.fromName || 'Jemand' }} hat dich in einem Kommentar zu
        {{ notification.messagePayload.objectName }} erwähnt:
      </div>
      <v-card v-if="notification.messagePayload.message" class="mt-2" flat light>
        <v-card-text class="pa-2 caption" v-html="notification.messagePayload.message"/>
      </v-card>
      <v-btn :href="link" class="mt-2" small @click="hide" target="_blank" text>
        <v-icon class="mr-2" small>mdi-page-next-outline</v-icon>
        Profil öffnen
      </v-btn>
    </template>
    <template v-slot:close>
      <v-btn class="ml-2" icon small @click="hide">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>
<script lang="ts">
import Vue from 'vue';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import {LogEntryObjectTypes} from '@/enums/LogEntryEnums';
import {NotifierApi} from '@/classes/api/notifier.api';
import {MentionNotificationDocument} from '@/documentTypes/notifier/discriminators/MentionNotification.document';

export default Vue.extend({
  props: {
    notification: {
      type: Object as () => NotificationLike<MentionNotificationDocument>,
      required: true,
    },
  },
  components: {ProfileAvatar},
  computed: {
    link(): string {
      switch (this.notification.messagePayload.objectType) {
        case LogEntryObjectTypes.EMPLOYEE:
          return this.$router.resolve(`/employees/${this.notification.messagePayload.objectId}`).href;
        case LogEntryObjectTypes.INSTITUTION:
          return this.$router.resolve(`/institutions/${this.notification.messagePayload.objectId}`).href;
        case LogEntryObjectTypes.PARENT:
          return this.$router.resolve(`/parents/${this.notification.messagePayload.objectId}`).href;
        case LogEntryObjectTypes.STUDENT:
          return this.$router.resolve(`/students/${this.notification.messagePayload.objectId}`).href;
        default:
          throw new Error('Unknown object type');
      }
    },
  },
  methods: {
    async hide() {
      const id = this.notification._id;
      await NotifierApi.markRead({
        notificationIds: [id],
      });
      this.$store.commit('hideNotification', id);
    },
  },
});
</script>

<style scoped>
.notification {
  background-color: rgba(0, 0, 10, 0.5) !important;
  backdrop-filter: blur(15px);
}
</style>
