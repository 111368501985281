export class DownloadHelper {
    public static triggerBlobDownload(file: Blob, filename: string, showOnly = false, useRawName = false) {
        const virtualLink = document.createElement('a');
        virtualLink.setAttribute('href', window.URL.createObjectURL(file));
        if (showOnly) {
            virtualLink.setAttribute('name', this.getCleanFilename(filename));
            virtualLink.setAttribute('target', '_blank');
        } else {
            virtualLink.setAttribute('download', useRawName ? filename : `${this.getCleanFilename(filename)}.${this.getFileType(file.type)}`);
        }
        virtualLink.click();
        virtualLink.remove();
    }

    private static getCleanFilename(filename: string) {
        return filename.replace(/[/|\\:*?"<>]/g, ' ').trim().replace(/\s/gm, '_');
    }

    private static getFileType(mimeType: string) {
        switch (mimeType) {
            case 'application/msword':
                return 'doc';
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'docx';
            case 'application/pdf':
                return 'pdf';
            case 'image/jpeg':
                return 'jpg';
            case 'image/png':
                return 'png';
            default:
                return '';
        }
    }
}
