export enum HolidayFields {
    TYPE = 'type',
    START = 'start',
    END = 'end',
    NAME = 'name',
    STATE_CODE = 'stateCode',
    COUNTRY_CODE = 'countryCode',
    ADDITIONAL_TEXT = 'additionalText',
    FOR_INSTITUTIONS = 'forInstitutions',
    INSTITUTION = 'institution',
    CREATOR = 'creator',
}
