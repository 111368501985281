<template>
  <v-menu offset-y>
    <template v-slot:activator="{on}">
      <v-btn small icon v-on="on">
        <v-icon small>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list dense class="py-0">
      <v-list-item @click="$emit('update:showClosedStacks', !showClosedStacks)">
        <v-list-item-icon>
          <v-icon>
            {{showClosedStacks ? 'mdi-eye-off' : 'mdi-eye'}}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-if="showClosedStacks">
            Archivierte Stapel ausblenden
          </v-list-item-title>
          <v-list-item-title v-else>
            Archivierte Stapel anzeigen
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    showClosedStacks: {
      type: Boolean,
      required: true,
    },
  },
})
</script>
