<template>
  <autocomplete-preset :api="api" :fields="['meta', 'type']"
                       :populate="{ 'meta': { fields: ['firstName', 'lastName', 'customId'] } }"
                       :resp-mapper="respMapper"
                       :value="value" v-bind="$props"
                       @input="$emit('input', $event)"/>
</template>
<script lang="ts">
import AutocompleteMixin from '@/mixins/autocompletes/Autocomplete.mixin';
import {UserLoginHandlerApi} from '@/classes/api/user-login-handler.api';
import {LoginUser} from '@/interfaces/loginUser.interface';
import {Employee} from '@/interfaces/employee.interface';

export default AutocompleteMixin.extend({
  computed: {
    api: () => UserLoginHandlerApi,
  },
  methods: {
    respMapper(resp: { loginUsers: LoginUser[] }) {
      return resp.loginUsers.map((loginUser) => {
        const profile = loginUser.meta as Employee;
        return {
          text: `${profile?.firstName} ${profile?.lastName}`,
          description: profile?.customId,
          value: loginUser._id,
        };
      });
    },
  },
});
</script>
