
import { API } from '@/classes/api/api.class';
import { Config } from '@/interfaces/config.interface';
import {CreateConfigRequestDto} from '@/classes/dto/config-handler/request/CreateConfig.request.dto';
import {UpdateConfigRequestDto} from '@/classes/dto/config-handler/request/UpdateConfig.request.dto';
/**
 * This class is used for communicating with the config-handler microservice.
 * @class ConfigHandllerAPI
 * @author Joshua Seipel
 */
export class ConfigHandlerAPI extends API {
  static service = 'config-handler';

  static async create(newDocument: CreateConfigRequestDto): Promise<void> {
    return this.post('', newDocument);
  }

  static async getConfig(): Promise<Omit<Config, 'mail.customer.auth.password' & 'mail.customer.global' >> {
    return this.get('');
  }

  static async getDhlApiConfig(): Promise<{useTrackingApi: boolean; useDhlApi: boolean; useRetoureApi: boolean}> {
    return this.get('getDhlApiConfig');
  }

  static async update(query: UpdateConfigRequestDto): Promise<void> {
    return this.put('', query);
  }

}
