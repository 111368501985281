<template>
  <v-card flat color="transparent">
    <v-card-text>
      <v-select :items="clothesTypeItems" :value="clothesType" color="info" dense item-color="info"
                label="Outfit auswählen" outlined rounded @input="(v) => $emit('update:clothesType', v)"/>
      <v-slide-y-transition>
        <v-select v-if="clothesType === 'graphicShirt'" :items="clothesGraphicsTypeItems" :value="clothesGraphicsType"
                  color="info" dense item-color="info" label="Motiv auswählen" outlined rounded
                  @input="(v) => $emit('update:clothesGraphicsType', v)"/>
      </v-slide-y-transition>
      <v-subheader>Farbe des Outfits</v-subheader>
      <color-button :value="clothesColor" color="#FFFFFF" icon-color="black" output-value="white"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <color-button :value="clothesColor" color="#E6E6E6" icon-color="black" output-value="gray01"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <color-button :value="clothesColor" color="#939598" output-value="gray02"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <color-button :value="clothesColor" color="#3C4F5C" output-value="heather"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <color-button :value="clothesColor" color="#2B1B18" output-value="black"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <v-divider/>
      <color-button :value="clothesColor" color="#B1E2FF" output-value="pastelBlue"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <color-button :value="clothesColor" color="#65C9FF" output-value="blue01"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <color-button :value="clothesColor" color="#5199E4" output-value="blue02"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <color-button :value="clothesColor" color="#26557C" output-value="blue03"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <v-divider/>
      <color-button :value="clothesColor" color="#FFFFB1" icon-color="black" output-value="pastelYellow"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <color-button :value="clothesColor" color="#FFDEB5" output-value="pastelOrange"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <color-button :value="clothesColor" color="#FFAFB9" output-value="pastelRed"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <color-button :value="clothesColor" color="#C93305" output-value="red"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <color-button :value="clothesColor" color="#FF488E" output-value="pink"
                    @input="(v) => $emit('update:clothesColor', v)"/>
      <v-divider/>
      <color-button :value="clothesColor" color="#A7FFC4" output-value="pastelGreen"
                    @input="(v) => $emit('update:clothesColor', v)"/>

    </v-card-text>
    <v-card-actions>
      <v-btn color="info" text @click="$emit('prev')">
        <v-icon>mdi-chevron-left</v-icon>
        Zurück
      </v-btn>
      <v-spacer/>
      <v-btn color="info" @click="$emit('save')">
        Speichern
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue';
import {avataaarClothesGraphicsType, avataaarClothesType} from '@/constants/avataaar.constant';
import ColorButton from '@/components/common/profile/createAvataaarDialog/colorButton.vue';

export default Vue.extend({
  components: {ColorButton},
  props: {
    clothesType: {
      type: String,
      default: '',
    },
    clothesColor: {
      type: String,
      default: '',
    },
    clothesGraphicsType: {
      type: String,
      default: '',
    },
  },
  computed: {
    clothesTypeItems: () => avataaarClothesType,
    clothesGraphicsTypeItems: () => avataaarClothesGraphicsType,
  },
});
</script>
