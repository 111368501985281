export class SoundManager {
    private static playing = false;
    private static muted = false;
    private static audio: HTMLAudioElement | undefined;

    public static volume = 0.5;

    public static get isMuted(): boolean {
        return this.muted;
    }

    public static mute() {
        this.muted = true;
        if (this.audio) {
            this.audio.pause();
            this.playing = false;
            this.audio = undefined;
        }
        localStorage.setItem('sounds-muted', 'true');
    }

    public static unmute() {
        this.muted = false;
        localStorage.setItem('sounds-muted', 'false');
        SoundManager.sounds.common();
    }

    private static async playAudio(path: string) {
        if (this.playing || this.muted) {
            return;
        }
        this.playing = true;
        this.audio = new Audio(path);
        this.audio.volume = this.volume;
        this.audio.addEventListener('ended', () => {
            this.playing = false;
            this.audio = undefined;
        });
        await this.audio.play();
    }

    public static get sounds() {
        return {
            call: () => this.playAudio('sounds/notifications/call.mp3'),
            common: () => this.playAudio('sounds/notifications/common.mp3'),
            silent: () => this.playAudio('sounds/notifications/silent.mp3'),
            completed: () => this.playAudio('sounds/notifications/completed.mp3'),
            reminder: () => this.playAudio('sounds/notifications/reminder.mp3'),
        };
    }
}

function readLocalState() {
    const muted = localStorage.getItem('sounds-muted');
    if (muted === 'true') {
        SoundManager.mute();
    }
}

readLocalState();
