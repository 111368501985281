import {Types} from 'mongoose';
import CrudAccess, {EntityAccessRecord, FieldAccessRecord} from '@/classes/clientOnly/permissionTreeResources/interfaces/CrudAccess';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import CrudAccessEntity, {SingleAccessEntityTypes} from '@/classes/clientOnly/permissionTreeResources/classes/CrudAccessEntity';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import {CustomAccessKeys} from '@/classes/clientOnly/permissionTreeResources/enums/CustomAccessKeys';

export default class CrudAccessManager {
    private readonly _data: CrudAccess;

    constructor(data?: CrudAccess) {
        const empty = {
            create: {},
            read: {},
            update: {},
            delete: {},
            custom: {},
        };
        if (data) {
            try {
                this._data = {...empty, ...JSON.parse(JSON.stringify(data))};
            } catch (err) {
                this._data = empty;
            }
        } else {
            this._data = empty;
        }
    }

    /**
     * Adds a CrudAccess object to the permissions of this instance
     * @param crudAccess {CrudAccess} The access object to add to this instance
     */
    add(crudAccess: CrudAccess) {
        for (const actionType in crudAccess) {
            if (crudAccess.hasOwnProperty(actionType)) {
                const existingActionEntry = this._data[actionType as CrudActionTypes];
                const newActionEntry = crudAccess[actionType as CrudActionTypes];
                if (existingActionEntry.fullAccess) {
                    continue;
                } else if (newActionEntry.fullAccess) {
                    existingActionEntry.fullAccess = true;
                    continue;
                }

                if (actionType === CrudActionTypes.CREATE || actionType === CrudActionTypes.DELETE || actionType === 'custom') {
                    for (const entityType in newActionEntry) {
                        if (newActionEntry.hasOwnProperty(entityType)) {
                            const newEntityEntry = newActionEntry[entityType as CrudEntityTypes];
                            existingActionEntry[entityType as CrudEntityTypes] = existingActionEntry[entityType as CrudEntityTypes] || newEntityEntry;
                        }
                    }
                } else if (actionType === CrudActionTypes.READ || actionType === CrudActionTypes.UPDATE) {
                    for (const entityType in newActionEntry) {
                        if (newActionEntry.hasOwnProperty(entityType)) {
                            const newEntityEntry = newActionEntry[entityType as CrudEntityTypes] as unknown as  EntityAccessRecord<any>;
                            if (newEntityEntry) {
                                if (!existingActionEntry[entityType as CrudEntityTypes]) {
                                    existingActionEntry[entityType as CrudEntityTypes] = newEntityEntry;
                                } else {
                                    const existingEntityEntry = existingActionEntry[entityType as CrudEntityTypes] as unknown as  EntityAccessRecord<any>;
                                    for (const fieldName in newEntityEntry as EntityAccessRecord<any>) {
                                        if (newEntityEntry.hasOwnProperty(fieldName)) {
                                            const newFieldAccessEntry = newEntityEntry[fieldName];
                                            existingEntityEntry[fieldName] = existingEntityEntry[fieldName] || newFieldAccessEntry;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
    }

    /**
     * Returns a helper object for a certain entity to prevent repetitive crud access validation.
     * @param type {CrudAccessEntity} The entity's type
     * @param id {Types.ObjectId} The entity's id
     */
    entity(type: SingleAccessEntityTypes, id: Types.ObjectId): CrudAccessEntity {
        return new CrudAccessEntity(type, id, this._data);
    }

    /**
     * Returns if the user can read ALL the given keys of ALL entities of the given entity type he can access.
     * @param type {CrudEntityTypes}
     * @param keys {string[]}
     */
    canReadAllProvided<T extends string = string>(type: CrudEntityTypes, ...keys: T[]) {
        return this._canAccessAllOfType(CrudActionTypes.READ, type, ...keys);
    }

    /**
     * Returns if the user can read ALL the given keys of ALL entities of the given entity type he can access.
     * @param type {CrudEntityTypes}
     * @param keys {string[]}
     */
    canUpdateAllProvided<T extends string = string>(type: CrudEntityTypes, ...keys: T[]) {
        return this._canAccessAllOfType(CrudActionTypes.UPDATE, type, ...keys);
    }

    /**
     * Grants full access for action for selected entityType.
     * @param entityType Type of entity to grant access for
     * @param actionType Type of action to grant access for
     */
    setAccess(entityType: CrudEntityTypes, actionType: CrudActionTypes): void;
    /**
     * Grants restricted read or write access to a certain entity.
     * @param entityType Type of entity to grant access for
     * @param actionType Type of entity to grant access for
     * @param id Entity id
     * @param fields Fields to grant access for - if omitted, full access will be granted
     */
    setAccess(entityType: CrudEntityTypes, actionType: CrudActionTypes.READ | CrudActionTypes.UPDATE, id: Types.ObjectId, fields?: string[]): void;
    setAccess(entityType: CrudEntityTypes, actionType: CrudActionTypes, id?: Types.ObjectId, fields?: string[]): void {
        if (!id && !fields) {
            if ([CrudActionTypes.CREATE, CrudActionTypes.DELETE].includes(actionType)) {
                this._data[actionType][entityType] = true;
                return;
            } else {
                const entityTypeObject = this._data[actionType][entityType] as EntityAccessRecord<any>;
                if (!entityTypeObject) {
                    this._data[actionType][entityType] = {all: {fullAccess: true}};
                } else {
                    entityTypeObject.all = {fullAccess: true};
                }
                return;
            }
        }

        if ((id || fields) && [CrudActionTypes.CREATE, CrudActionTypes.DELETE].includes(actionType)) {
            throw new Error(`Too many parameters for action ${actionType}`);
        }

        const fieldAccessKeys: FieldAccessRecord<any> = {};
        if (!fields) {
            fieldAccessKeys.fullAccess = true;
        } else {
            for (const field of fields) {
                fieldAccessKeys[field] = true;
            }
        }

        if (id) {
            if (!this._data[actionType].hasOwnProperty(entityType)) {
                this._data[actionType][entityType] = {};
            }
            const entityAccessKey = this._data[actionType][entityType] as EntityAccessRecord<any>;
            if (entityAccessKey) {
                entityAccessKey[id.toString()] = fieldAccessKeys;
            }
        } else {
            if (!this._data[actionType][entityType]) {
                this._data[actionType][entityType] = {};
            }
            const scope = this._data[actionType][entityType] as EntityAccessRecord<any>;
            if (scope) {
                scope.all = fieldAccessKeys;
            }
        }
    }

    getAccess(entityType: CrudEntityTypes, actionType: CrudActionTypes.CREATE | CrudActionTypes.DELETE): boolean;
    getAccess<T extends string = string>(entityType: CrudEntityTypes, actionType: CrudActionTypes.READ | CrudActionTypes.UPDATE, entity?: Types.ObjectId):
        Record<T, 0 | 1> & { fullAccess?: 0 | 1 };
    getAccess(entityType: CrudEntityTypes, actionType: CrudActionTypes, entity?: Types.ObjectId): boolean | Record<string, 0 | 1> {
        if (!Object.values(CrudActionTypes).includes(actionType)) {
            throw new Error(`Unknown action type ${actionType}`);
        }
        let fieldAccess = {} as Record<string, boolean>;
        if (entity) {
            if ([CrudActionTypes.CREATE, CrudActionTypes.DELETE].includes(actionType)) {
                throw new Error('Create and Delete operations can not be retrieved for existing instance');
            }
            if (this._data[actionType].fullAccess) {
                return {};
            }
            const entityTypeObject = this._data[actionType][entityType] as EntityAccessRecord<any>;
            if (entityTypeObject) {
                if (entityTypeObject.all && entityTypeObject.all.fullAccess) {
                    return {};
                }
                const entityObject = entityTypeObject[entity.toString()];
                if (entityObject) {
                    if (entityObject.fullAccess) {
                        return {};
                    } else if (entityTypeObject.all) {
                        fieldAccess = {...entityTypeObject.all, ...entityObject};
                    } else {
                        fieldAccess = entityObject;
                    }
                } else if (entityTypeObject.all) {
                    fieldAccess = entityTypeObject.all;
                } else {
                    return {_id: 1};
                }
                const record = {} as Record<string, 0 | 1>;
                for (const key in fieldAccess) {
                    if (fieldAccess[key]) {
                        record[key] = 1;
                    }
                }
                return record;
            }
            return {_id: 1};
        } else {
            if ([CrudActionTypes.CREATE, CrudActionTypes.DELETE].includes(actionType)) {
                if (this._data[actionType].fullAccess) {
                    return true;
                }
                return !!this._data[actionType][entityType];
            } else {
                const entityTypeObject = this._data[actionType][entityType] as EntityAccessRecord<any>;
                if (entityTypeObject && entityTypeObject.all) {
                    if (entityTypeObject.all.fullAccess) {
                        return {};
                    }
                    fieldAccess = entityTypeObject.all;
                } else {
                    fieldAccess = {_id: true};
                }
                const record = {} as Record<string, 0 | 1>;
                for (const key in fieldAccess) {
                    if (fieldAccess[key]) {
                        record[key] = 1;
                    }
                }
                return record;
            }
        }
    }

    /**
     * Checks whether an action can be performed on any key for any entity of the provided type.
     * @param entityType The type of entity
     * @param actionType The action to perform
     */
    canAccessType(entityType: CrudEntityTypes, actionType: CrudActionTypes) {
        if (this._data[actionType].fullAccess) {
            return true;
        } else if (this._data[actionType][entityType]) {
            if ([CrudActionTypes.CREATE, CrudActionTypes.DELETE].includes(actionType)) {
                return !!this._data[actionType][entityType];
            }
            const entityAccessRecord = this._data[actionType][entityType] as EntityAccessRecord<any>;
            for (const entityId in entityAccessRecord) {
                if (entityAccessRecord.hasOwnProperty(entityId)) {
                    const fieldAccessRecord = entityAccessRecord[entityId];
                    if (fieldAccessRecord) {
                        for (const field in fieldAccessRecord) {
                            if (fieldAccessRecord.hasOwnProperty(field)) {
                                if (fieldAccessRecord[field]) {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    /**
     * Checks whether an entity can be accessed. If key is provided, checks whether the certain key can be accessed instead.
     * @param entityType Type of entity
     * @param actionType Action to perform
     * @param id Id of entity
     * @param key (Optional) Key to access
     */
    canAccessEntity<T extends string = string>(
        entityType: CrudEntityTypes,
        actionType: CrudActionTypes.READ | CrudActionTypes.UPDATE,
        id: Types.ObjectId,
        key?: T,
    ): boolean {
        if (this._data[actionType].fullAccess) {
            return true;
        }
        if (this._data[actionType][entityType]) {
            const entityAccessRecord = this._data[actionType][entityType] as EntityAccessRecord<any>;
            if (entityAccessRecord.all) {
                if (entityAccessRecord.all.fullAccess) {
                    return true;
                }
                if (key && entityAccessRecord.all[key]) {
                    return true;
                } else if (!key) {
                    for (const fieldName in entityAccessRecord.all) {
                        if (entityAccessRecord.all.hasOwnProperty(fieldName) && entityAccessRecord.all[fieldName]) {
                            return true;
                        }
                    }
                }
            }
            if (entityAccessRecord[id.toString()]) {
                const fieldAccessRecord = entityAccessRecord[id.toString()] as FieldAccessRecord<any>;
                if (fieldAccessRecord.fullAccess) {
                    return true;
                }
                if (key && fieldAccessRecord[key]) {
                    return true;
                } else if (!key) {
                    for (const fieldName in fieldAccessRecord) {
                        if (fieldAccessRecord.hasOwnProperty(fieldName) && fieldAccessRecord[fieldName]) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    /**
     * Returns the entities which can be accessed. Returns "all" if there is an entry in the all field.
     * @param entityType The entity type to look for
     * @param actionType The action type to look for
     */
    getAccessibleEntities(entityType: CrudEntityTypes, actionType: CrudActionTypes.READ | CrudActionTypes.UPDATE): 'all' | string[] {
        const entityAccessRecord = this._data[actionType][entityType] as EntityAccessRecord<any>;
        if (this._data[actionType].fullAccess) {
            return 'all';
        }
        if (!entityAccessRecord) {
            return [];
        }
        if (entityAccessRecord.all) {
            for (const key in entityAccessRecord.all) {
                if (entityAccessRecord.all[key]) {
                    return 'all';
                }
            }
        }
        const entityIds = [];
        for (const entityId in entityAccessRecord) {
            if (entityId !== 'all' && entityAccessRecord[entityId]) {
                const fieldAccessRecord = entityAccessRecord[entityId] as FieldAccessRecord<any>;
                for (const key in fieldAccessRecord) {
                    if (fieldAccessRecord[key]) {
                        entityIds.push(entityId);
                    }
                }
            }
        }
        return entityIds;
    }

    /**
     * Removes full access for action for given entity type
     * @param entityType The entity type to remove access for
     * @param actionType The action type to remove access for
     */
    removeAccess(entityType: CrudEntityTypes, actionType: CrudActionTypes.CREATE | CrudActionTypes.DELETE): void;
    /**
     * Removes general access for action or a certain entity.
     * @param entityType The entity type to remove access for
     * @param actionType The action type to remove access for
     * @param entity The entity id - if omitted, general access will be removed
     */
    removeAccess(entityType: CrudEntityTypes, actionType: CrudActionTypes.READ | CrudActionTypes.UPDATE, entity?: Types.ObjectId): void;
    removeAccess(entityType: CrudEntityTypes, actionType: CrudActionTypes, entity?: Types.ObjectId): void {
        if (!Object.values(CrudActionTypes).includes(actionType)) {
            throw new Error(`Unknown action type ${actionType}`);
        }
        if (entity) {
            if ([CrudActionTypes.CREATE, CrudActionTypes.DELETE].includes(actionType)) {
                throw new Error('Create and Delete operations have no entities');
            }
            const entityTypeObject = this._data[actionType][entityType] as EntityAccessRecord<any>;
            if (entityTypeObject) {
                delete entityTypeObject[entity.toString()];
            }
        } else {
            if ([CrudActionTypes.CREATE, CrudActionTypes.DELETE].includes(actionType)) {
                delete this._data[actionType][entityType];
            } else {
                const entityTypeObject = this._data[actionType][entityType] as EntityAccessRecord<any>;
                if (entityTypeObject.all) {
                    delete entityTypeObject.all;
                }
            }
        }
    }

    /**
     * Add a custom permission to the user's permissions.
     * @param key The key of the permission to add
     * @param value
     */
    setCustom(key: CustomAccessKeys, value: boolean) {
        this._data.custom[key] = value;
    }

    /**
     * Get the value of a custom permission of the user.
     * @param key The key of the permission to retrieve
     */
    getCustom(key: CustomAccessKeys) {
        return this._data.custom[key];
    }

    /**
     * A human-readable fulltext report of all permissions the user has.
     */
    get report(): string {
        let report = '--- CrudManager Report ---\n';
        if (this._data.create.fullAccess) {
            report += `The user can create ANY kind of entity.\n`;
        } else {
            const record = this._data.create as Partial<Record<CrudEntityTypes, boolean>>;
            for (const key in record) {
                if (record[key as CrudEntityTypes]) {
                    report += `The user can create ${key.toUpperCase()}.\n`;
                }
            }
        }
        report += `\n`;

        if (this._data.read.fullAccess) {
            report += `The user can read ANY kind of entity.\n`;
        } else {
            const entityRecord = this._data.read as Partial<Record<CrudEntityTypes, EntityAccessRecord<any>>>;
            for (const entity in entityRecord) {
                if (entityRecord[entity as CrudEntityTypes]) {
                    const entityAccessKey = entityRecord[entity as CrudEntityTypes] as EntityAccessRecord<any>;
                    if (entityAccessKey.all) {
                        if (entityAccessKey.all.fullAccess) {
                            report += `The user can read ALL FIELDS of ALL ${entity.toUpperCase()}.\n`;
                        } else {
                            report += `The user can read the following fields of ALL ${entity.toUpperCase()}:\n`;
                            for (const field in entityAccessKey.all) {
                                if (entityAccessKey.all[field]) {
                                    report += `\t- ${field}\n`;
                                }
                            }
                        }
                    }
                    if (!!Object.values(entityAccessKey).find((v) => v) && !!Object.keys(entityAccessKey).find((k) => k !== 'all')) {
                        report += `The user can read the following ${entity.toUpperCase()}:\n`;
                        for (const key in entityAccessKey) {
                            if (entityAccessKey[key] && key !== 'all') {
                                if (entityAccessKey[key].fullAccess) {
                                    report += `\t - ALL FIELDS of entity ${key}:\n`;
                                } else {
                                    report += `\t - Following fields of entity ${key}:\n`;
                                    for (const field in entityAccessKey[key]) {
                                        if (entityAccessKey[key][field]) {
                                            report += `\t\t- ${field}\n`;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        report += `\n`;

        if (this._data.update.fullAccess) {
            report += `The user can update ANY kind of entity.\n`;
        } else {
            const entityRecord = this._data.update as Partial<Record<CrudEntityTypes, EntityAccessRecord<any>>>;
            for (const entity in entityRecord) {
                if (entityRecord[entity as CrudEntityTypes]) {
                    const entityAccessKey = entityRecord[entity as CrudEntityTypes] as EntityAccessRecord<any>;
                    if (entityAccessKey.all) {
                        if (entityAccessKey.all.fullAccess) {
                            report += `The user can update ALL FIELDS of ALL ${entity.toUpperCase()}.\n`;
                        } else {
                            report += `The user can update the following fields of ALL ${entity.toUpperCase()}:\n`;
                            for (const field in entityAccessKey.all) {
                                if (entityAccessKey.all[field]) {
                                    report += `\t- ${field}\n`;
                                }
                            }
                        }
                    }
                    if (!!Object.values(entityAccessKey).find((v) => v) && !!Object.keys(entityAccessKey).find((k) => k !== 'all')) {
                        report += `The user can update the following ${entity.toUpperCase()}:\n`;
                        for (const key in entityAccessKey) {
                            if (entityAccessKey[key] && key !== 'all') {
                                if (entityAccessKey[key].fullAccess) {
                                    report += `\t - ALL FIELDS of entity ${key}:\n`;
                                } else {
                                    report += `\t - Following fields of entity ${key}:\n`;
                                    for (const field in entityAccessKey[key]) {
                                        if (entityAccessKey[key][field]) {
                                            report += `\t\t- ${field}\n`;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        report += `\n`;

        if (this._data.delete.fullAccess) {
            report += `The user can delete ANY kind of entity.\n`;
        } else {
            const record = this._data.delete as Partial<Record<CrudEntityTypes, boolean>>;
            for (const key in record) {
                if (record[key as CrudEntityTypes]) {
                    report += `The user can delete ${key.toUpperCase()}.\n`;
                }
            }
        }
        return report;
    }

    toJSON(): CrudAccess {
        return JSON.parse(JSON.stringify(this._data));
    }

    /**
     * Returns whether the user can access ALL the given keys of ALL entities of the given entity type he can access in ANY way.
     * @param action {CrudActionTypes.READ | CrudActionTypes.UPDATE}
     * @param entityType
     * @param keys
     * @private
     */
    private _canAccessAllOfType(action: CrudActionTypes.READ | CrudActionTypes.UPDATE, entityType: CrudEntityTypes, ...keys: string[]) {
        if (keys.length === 0) {
            return false;
        }
        if (this._data[action].fullAccess) {
            return true;
        }
        const entityAccessRecord = this._data[action][entityType];
        if (!entityAccessRecord) {
            return false;
        }
        // If no keys are provided, there is nothing to access
        if (Object.keys(entityAccessRecord).length === 0) {
            return false;
        }
        if (entityAccessRecord.all && entityAccessRecord.all.fullAccess) {
            return true;
        }
        // Apply .all if defined
        if (entityAccessRecord.all) {
            // Check if .all has at least one truthy value to determine if it can be used to check keys
            let hasAtLeastOneTruthyValue = false;
            for (const k in entityAccessRecord.all) {
                if (entityAccessRecord.all.hasOwnProperty(k) && entityAccessRecord.all[k]) {
                    hasAtLeastOneTruthyValue = true;
                    break;
                }
            }
            // Check all keys against .all
            if (hasAtLeastOneTruthyValue) {
                for (const key of keys) {
                    if (!entityAccessRecord.all[key]) {
                        return false;
                    }
                }
                return true;
            }
            // else fallthrough
        }
        // Check every accessible entity
        for (const entityId in entityAccessRecord) {
            if (entityId !== 'all' && entityAccessRecord.hasOwnProperty(entityId) && entityAccessRecord[entityId]) {
                const fieldAccessRecord = entityAccessRecord[entityId];
                if (fieldAccessRecord.fullAccess) {
                    continue;
                }
                for (const key of keys) {
                    // If a key of a single shared entity can not be accessed, deny access
                    if (!fieldAccessRecord[key]) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
}
