<template>
  <v-snackbar v-model="snackbar.show" :timeout="timeout" multi-line transition="slide-y-reverse-transition">
    <div style="min-width: 400px">
      {{ snackbar.text }}
    </div>
    <v-slide-x-transition>
      <v-sheet v-if="showInfo && snackbar.info" class="pa-3 mt-2 rounded-lg grey darken-4" style="max-width: 400px">
        <pre style="white-space: break-spaces">{{ snackbar.info }}</pre>
      </v-sheet>
    </v-slide-x-transition>
    <template v-if="snackbar.actions.length > 0" v-slot:action>
      <v-btn v-for="(action, i) in snackbar.actions" :key="i" :color="action.color || 'info'" text @click="action.fn">
        {{ action.text }}
      </v-btn>
      <v-btn v-if="snackbar.info" :color="snackbar.btnColor" icon @click="showInfo = !showInfo">
        <v-icon v-if="snackbar.btnColor === 'error'">mdi-alert-outline</v-icon>
        <v-icon v-else>mdi-information-outline</v-icon>
      </v-btn>
      <v-btn color="grey" icon @click="hideSnackbar">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <template v-else-if="snackbar.info" v-slot:action>
      <v-btn v-if="snackbar.info" :color="snackbar.btnColor" icon @click="showInfo = !showInfo">
        <v-icon v-if="snackbar.btnColor === 'error'">mdi-alert-outline</v-icon>
        <v-icon v-else>mdi-information-outline</v-icon>
      </v-btn>
      <v-btn color="grey" icon @click="hideSnackbar">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <template v-else v-slot:action>
      <v-btn :color="snackbar.btnColor" text @click="hideSnackbar"> Schließen</v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    timeoutPaused: false,
    showInfo: false,
  }),
  computed: {
    timeout(): number {
      return this.timeoutPaused ? -1 : this.$store.state.snackbar.timeout;
    },
    snackbar() {
      return this.$store.state.snackbar;
    },
  },
  methods: {
    hideSnackbar() {
      this.$store.commit('hideSnackbar')
    },
    pauseTimeoutIfWindowIsHidden() {
      if (document.hidden) {
        this.handleWindowBlur()
      } else {
        this.handleWindowFocus()
      }
    },
    handleWindowBlur() {
      this.timeoutPaused = true;
    },
    handleWindowFocus() {
      if (!this.showInfo) {
        this.timeoutPaused = false;
      }
    },
  },
  watch: {
    'snackbar.show'(v: boolean) {
      if (!v) {
        this.showInfo = false;
      }
    },
    showInfo(v: boolean) {
      if (v) {
        this.timeoutPaused = true;
      } else {
        if (this.$store.state.snackbar.show) {
          this.timeoutPaused = false;
        }
      }
    },
  },
  mounted() {
    document.addEventListener('visibilitychange', this.pauseTimeoutIfWindowIsHidden);
    window.addEventListener('blur', this.handleWindowBlur);
    window.addEventListener('focus', this.handleWindowFocus);
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.pauseTimeoutIfWindowIsHidden);
    window.removeEventListener('blur', this.handleWindowBlur);
    window.removeEventListener('focus', this.handleWindowFocus);
  },
});
</script>
