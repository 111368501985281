<template>
  <v-dialog
      :value="value"
      fullscreen
      max-width="750px"
      persistent
      transition="dialog-transition"
      @input="reset"
  >
    <v-card>
      <v-card-title>
        <div class="info--text">Google Maps Suche</div>
        <v-spacer/>
        <v-btn icon @click="reset">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-navigation-drawer v-model="showFilterOptions" absolute clipped left
                             style="top: 62px; max-height: calc(100% - 62px);" width="400px">
          <v-slide-x-transition group leave-absolute>
            <v-card v-show="showFilterOptions" key="filter" flat>
              <v-card-title>
                Filter
                <v-spacer/>
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn icon @click="showFilterOptions = false" v-on="on">
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                  </template>
                  Filter ausblenden
                </v-tooltip>
              </v-card-title>
              <v-form ref="form" v-model="valid" lazy-validation>
                <div class="overflow-y-auto" style="max-height: calc(100vh - 215px)">
                  <v-card-text>
                    <create-address v-model="address" :allow-manual-input="false" :require-street="false" :use-osm="false"
                                    color="info" label="Adresse eingeben" prepend-icon="mdi-magnify"/>
                  </v-card-text>
                  <v-divider/>
                  <v-card-text>
                    <v-tabs v-model="tab" centered color="info">
                      <v-tab v-can:read-institutions="{ keys: ['name', 'address', 'assignedTeachers'] }">Standorte
                      </v-tab>
                      <v-tab
                          v-can:read-employees="{ keys: ['firstName','lastName','address', 'hasCar', 'assignedInstitutions'] }">
                        Lehrkräfte
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                      <v-tab-item v-can:read-institutions="{ keys: ['name', 'address', 'assignedTeachers'] }">
                        <v-card>
                          <v-card-text>
                            <v-subheader class="pl-0 pb-2">Reiseart wählen</v-subheader>
                            <v-radio-group
                                v-model="travelMode"
                                class="text-center"
                                dense
                                row
                                style="margin-top: -20px;"
                            >
                              <v-radio
                                  :value="googleTravelModes.DRIVING"
                                  color="info"
                              >
                                <template v-slot:label>
                                  <v-icon class="mr-2" color="blue">mdi-car</v-icon>
                                </template>
                              </v-radio>
                              <v-radio
                                  :value="googleTravelModes.TRANSIT"
                                  color="info"
                              >
                                <template v-slot:label>
                                  <v-icon class="mr-2" color="blue">mdi-bus</v-icon>
                                </template>
                              </v-radio>
                              <v-radio
                                  :value="googleTravelModes.BICYCLING"
                                  color="info"
                              >
                                <template v-slot:label>
                                  <v-icon class="mr-2" color="blue">mdi-bicycle</v-icon>
                                </template>
                              </v-radio>
                              <v-radio
                                  :value="googleTravelModes.WALKING"
                                  color="info"
                              >
                                <template v-slot:label>
                                  <v-icon class="mr-2" color="blue">mdi-walk</v-icon>
                                </template>
                              </v-radio>
                            </v-radio-group>
                            <v-slider v-model="radius" :hint="`${radius} km`" color="info" label="Kilometerradius" max="30"
                                      min="5"
                                      persistent-hint step="2.5" thumb-label/>
                          </v-card-text>
                          <institution-gmaps-filter :filter.sync="institutionFilter"/>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item
                          v-can:read-employees="{ keys: ['firstName','lastName','address', 'hasCar', 'assignedInstitutions'] }">
                        <v-card>
                          <v-card-text>
                            <v-subheader class="pl-0 pb-2">Reiseart wählen</v-subheader>
                            <v-checkbox v-model="considerTravelMode" class="text-center" color="info" dense
                                        label="Fahrzeug der Lehrkräfte berücksichtigen" style="margin-top: -20px;"/>
                            <v-radio-group
                                v-if="!considerTravelMode"
                                v-model="travelMode"
                                class="text-center"
                                dense
                                row
                                style="margin-top: -20px;"
                            >
                              <v-radio
                                  :value="googleTravelModes.DRIVING"
                                  color="info"
                              >
                                <template v-slot:label>
                                  <v-icon class="mr-2" color="blue">mdi-car</v-icon>
                                </template>
                              </v-radio>
                              <v-radio
                                  :value="googleTravelModes.TRANSIT"
                                  color="info"
                              >
                                <template v-slot:label>
                                  <v-icon class="mr-2" color="blue">mdi-bus</v-icon>
                                </template>
                              </v-radio>
                              <v-radio
                                  :value="googleTravelModes.BICYCLING"
                                  color="info"
                              >
                                <template v-slot:label>
                                  <v-icon class="mr-2" color="blue">mdi-bicycle</v-icon>
                                </template>
                              </v-radio>
                              <v-radio
                                  :value="googleTravelModes.WALKING"
                                  color="info"
                              >
                                <template v-slot:label>
                                  <v-icon class="mr-2" color="blue">mdi-walk</v-icon>
                                </template>
                              </v-radio>
                            </v-radio-group>
                            <v-slider v-model="radius" :hint="`${radius} km`" color="info" label="Kilometerradius" max="30"
                                      min="5"
                                      persistent-hint step="2.5" thumb-label/>
                          </v-card-text>
                          <v-card-text>
                            <employee-gmaps-filter :filter.sync="teacherFilter"/>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card-text>
                </div>
              </v-form>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="!address.location || !address.location.coordinates.length === 2" :loading="loading"
                       color="info" @click="search">
                  Suchen
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>

            </v-card>

          </v-slide-x-transition>
        </v-navigation-drawer>
        <div>
          <div v-if="!showFilterOptions" key="button" class="ma-5" style="position: absolute; z-index: 3;">
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                <v-btn color="info" fab small @click="showFilterOptions = true" v-on="on">
                  <v-icon small>mdi-filter-menu</v-icon>
                </v-btn>
              </template>
              Filter einblenden
            </v-tooltip>
          </div>
          <gmap v-if="isMapAvailable" :additional-markers="getAdditionalMarkers" :center-element="{address}"
                :radius="radius * 1000" @update:position="handleDragPositionUpdate"/>
          <div v-if="!showResults && targets.length > 0" class="ma-5"
               style="position: absolute; z-index: 999; right: 0; top: 65px;">
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                <v-btn color="success" fab small @click="showResults = true" v-on="on">
                  <v-icon small>mdi-playlist-check</v-icon>
                </v-btn>
              </template>
              Ergebnisse einblenden
            </v-tooltip>
          </div>
        </div>

        <gmaps-search-response-nav v-model="showResults" :targets="targets"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Gmap from '@/components/common/gmap.vue';
import {GoogleTravelModesEnum} from '@/classes/api/google/GoogleTravelModes.enum';
import {InstitutionStatus} from '@/enums/InstitutionStatus.enum';
import {InstitutionerApi} from '@/classes/api/institutioner.api.class';
import {LeanDocument} from 'mongoose';
import {HandoutItemStates} from '@/enums/HandoutItemStates.enum';
import {Place} from '@/interfaces/place.interface';
import CreateAddress from '@/components/common/create/createAddress.vue';
import InstitutionGmapsFilter from '@/components/app/gmapsSearchDialogCompoents/institutionGmapsFilter.vue';
import EmployeeGmapsFilter from '@/components/app/gmapsSearchDialogCompoents/employeeGmapsFilter.vue';
import {FindInstitutionFilter} from '@/classes/dto/institutioner/request/filters/FindInstitution.filter';
import {FindEmployeeFilter} from '@/classes/dto/employee-handler/request/filters/FindEmployee.filter';
import {EmployeeHandlerAPI} from '@/classes/api/employee-handler.api.class';
import {Form} from '@/interfaces/Form.interface';
import {Institution} from '@/interfaces/institution.interface';
import {Employee} from '@/interfaces/employee.interface';
import {GetDirectionResponseDto} from '@/classes/dto/geocoder/response/GetDirections.response.dto';
import GmapsSearchResponseNav from '@/components/app/gmapsSearchDialogCompoents/gmapsSearchResponseNav.vue';
import {InstitutionTypes} from '@/enums/InstitutionTypes.enum';
import {GeocoderAPI} from '@/classes/api/geocoder.api.class';

export default Vue.extend({
  data: () => ({

    address: {} as Place,
    tab: 0,
    radius: 10,
    institutionStatus: [] as InstitutionStatus[],
    hasNoAssignmentForTeaching: false,
    considerTravelMode: true,
    travelMode: null as GoogleTravelModesEnum | null,
    targets: [] as Array<LeanDocument<Institution | Employee> & GetDirectionResponseDto>,
    loadingMapsDistance: false,
    loading: false,
    valid: false,
    showFilterOptions: true,
    showResults: false,
    handoutItemStates: [] as HandoutItemStates[],
    institutionFilter: {
      isActive: true,
      type: [InstitutionTypes.PUBLICSCHOOL],
    } as FindInstitutionFilter,
    teacherFilter: {
      isActive: true,
      isTeacher: true,
    } as FindEmployeeFilter,
  }),
  components: {GmapsSearchResponseNav, Gmap, CreateAddress, InstitutionGmapsFilter, EmployeeGmapsFilter},
  props: {
    value: {
      type: Boolean,
    },
  },
  methods: {
    async handleDragPositionUpdate(coordinates: { lat: number; lng: number }) {
      const resp = await GeocoderAPI.findAddress({
        $search: coordinates.lat + ',' + coordinates.lng,
        requireHouseNumber: false,
        requireStreet: false,
      });
      if (resp.items.length > 0) {
        this.address = resp.items[0].value;
      } else {
        this.address = {
          street: coordinates.lat.toString(),
          postal: coordinates.lng.toString(),
          city: 'ausserhalb',
          country: '' as any,
          state: '' as any,
          location: {
            type: 'Point',
            coordinates: [coordinates.lng, coordinates.lat],
          },
        }
      }
      await this.search();
    },
    async searchInstitutions() {
      try {
        this.loading = true;
        const response = await InstitutionerApi.find({
          filter: {
            ...this.institutionFilter,
            near: {
              lat: this.address.location.coordinates[1],
              lon: this.address.location.coordinates[0],
              maxDistance: this.radius * 1000,
              useExactDistance: true,
              useTravelMode: this.travelMode as GoogleTravelModesEnum,
            },
          },
          fields: ['name', 'address', 'assignedTeachers'],
          populate: {
            assignedTeachers: {
              fields: ['firstName', 'lastName'],
            },
          },
          skipPagination: true,
        });
        this.targets = response.institutions as Array<LeanDocument<Institution> & GetDirectionResponseDto>;
        this.showFilterOptions = false;
        this.showResults = true;
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler beim Suchen der Standorte aufgetreten. Bitte erneut versuchen.', 'error');
      } finally {
        this.loading = false;
      }
    },
    async searchEmployees() {
      try {
        this.loading = true;
        const response = await EmployeeHandlerAPI.find({
          filter: {
            ...this.teacherFilter,
            near: {
              lat: this.address.location.coordinates[1],
              lon: this.address.location.coordinates[0],
              maxDistance: this.radius * 1000,
              useExactDistance: true,
              considerTravelMode: this.considerTravelMode ? this.considerTravelMode : undefined,
              useTravelMode: this.travelMode && !this.considerTravelMode ? this.travelMode : undefined,
            },
          },
          populate: {
            assignedInstitutions: {
              fields: ['name'],
            },
          },
          fields: ['firstName', 'lastName', 'address', 'hasCar', 'assignedInstitutions'],
          skipPagination: true,
        });
        this.targets = response.employees as Array<LeanDocument<Employee> & GetDirectionResponseDto>;
        this.showFilterOptions = false;
        this.showResults = true;
      } catch (e) {
        this.$$showSnackbar('Es ist ein Fehler beim Suchen der Lehrkräfte aufgetreten. Bitte erneut versuchen.', 'error');
      } finally {
        this.loading = false;
      }
    },
    async search() {
      const form = this.$refs.form as Form;
      if (!form.validate()) {
        return;
      }
      this.loading = true;
      switch (this.tab) {
        case 0:
          await this.searchInstitutions();
          break;
        case 1:
          await this.searchEmployees();
          break;
      }
    },
    reset() {
      this.$emit('input', false);
    },
  },
  computed: {
    getAdditionalMarkers(): { institutions: Array<LeanDocument<Institution> & GetDirectionResponseDto>; employees: Array<LeanDocument<Employee> & GetDirectionResponseDto> } {
      if (this.targets.length > 0) {
        switch (this.tab) {
          case 0:
            return {
              institutions: this.targets as Array<LeanDocument<Institution> & GetDirectionResponseDto>,
              employees: [],
            };
          case 1:
            return {
              employees: this.targets as Array<LeanDocument<Employee> & GetDirectionResponseDto>,
              institutions: [],
            };
          default:
            return {institutions: [], employees: []};
        }
      } else {
        return {
          institutions: [] as Array<LeanDocument<Institution> & GetDirectionResponseDto>,
          employees: [] as Array<LeanDocument<Employee> & GetDirectionResponseDto>,
        };
      }
    },
    googleTravelModes: () => GoogleTravelModesEnum,
    isMapAvailable(): boolean {
      return (this.address && this.address.location
          && this.address.location.coordinates.length === 2);
    },
  },
  watch: {
    tab: {
      immediate: true,
      handler(v: number) {
        if (v === 0) {
          this.travelMode = GoogleTravelModesEnum.DRIVING;
        } else if (v === 1) {
          this.travelMode = null;
        }
      },
    },
  },
});
</script>

