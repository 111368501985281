export enum StoredFileFields {
    TYPE = 'type',
    NAME = 'name',
    PATH = 'path',
    FILENAME = 'filename',
    FILETYPE = 'filetype',
    FILE_HASH = 'fileHash',
    TEMPLATE = 'template',
    IS_TEMPLATE = 'isTemplate',
    CREATOR = 'creator',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
    VISIBLE = 'visible',
    IS_PUBLIC = 'isPublic',
}
