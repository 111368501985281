<template>
  <v-alert v-if="notification.showInApp" :key="notification.id" class="elevation-3 mt-4 mx-2 notification" dark
           transition="slide-x-transition">
    <template v-slot:prepend>
      <div class="mr-4">
        <profile-avatar v-if="notification.messagePayload.entityType === 'employees'"
                        :user="{profileImg: notification.messagePayload.profileImg}"/>
        <v-avatar v-else color="grey" size="32">
          <v-icon color="white" small>mdi-domain</v-icon>
        </v-avatar>
      </div>
    </template>
    <template v-slot:default>
      <div class="body-2">
        {{ notification.messagePayload.entityName || 'Jemand' }} wurde dir zugewiesen
      </div>
      <v-btn :href="link" class="mt-2" small target="_blank" text @click="hide">
        <v-icon class="mr-2" small>mdi-page-next-outline</v-icon>
        Profil öffnen
      </v-btn>
    </template>
    <template v-slot:close>
      <v-btn class="ml-2" icon small @click="hide">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>
<script lang="ts">
import Vue from 'vue';
import ProfileAvatar from '@/components/common/profile-avatar.vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import {NotifierApi} from '@/classes/api/notifier.api';
import {
  EntityAssignedToSupervisorNotificationDocument,
} from '@/documentTypes/notifier/discriminators/supervisor/EntityAssignedToSupervisorNotification.document';

export default Vue.extend({
  props: {
    notification: {
      type: Object as () => NotificationLike<EntityAssignedToSupervisorNotificationDocument>,
      required: true,
    },
  },
  components: {ProfileAvatar},
  computed: {
    link(): string {
      return this.$router.resolve(`/${this.notification.messagePayload.entityType}/${this.notification.messagePayload.entityId}`).href;
    },
  },
  methods: {
    async hide() {
      const id = this.notification._id;
      await NotifierApi.markRead({
        notificationIds: [id],
      });
      this.$store.commit('hideNotification', id);
    },
  },
});
</script>

<style scoped>
.notification {
  background-color: rgba(0, 0, 10, 0.5) !important;
  backdrop-filter: blur(15px);
}
</style>
