import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {ResponsibleFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/ResponsibleFields';

export const responsibleFieldsDictionary: FieldDictionary<ResponsibleFields> = [
    {
        text: 'Geschlecht',
        value: ResponsibleFields.GENDER,
    },
    {
        text: 'Vorname',
        value: ResponsibleFields.FIRST_NAME,
    },
    {
        text: 'Nachname',
        value: ResponsibleFields.LAST_NAME,
    },
    {
        text: 'Geburtsdatum',
        value: ResponsibleFields.BIRTHDAY,
    },
    {
        text: 'Anschrift',
        value: ResponsibleFields.ADDRESS,
    },
    {
        text: 'Zahlungsinformationen',
        value: ResponsibleFields.PAYMENT,
    },
    {
        text: 'Kontaktdaten',
        value: ResponsibleFields.CONTACT_INFORMATION,
    },
    {
        text: 'Aktivitätsstatus',
        value: ResponsibleFields.IS_ACTIVE,
    },
    {
        text: 'Zugang zu Vanilla',
        value: ResponsibleFields.HAS_LOGIN,
    },
    {
        text: 'Position',
        value: ResponsibleFields.SCHOOL_TYPE,
    },
    {
        text: 'Jobposition',
        value: ResponsibleFields.JOB_TYPE,
    },
];
