export enum InstitutionTypes {
    PUBLICSCHOOL, INSTITUTION, FLAT, HEADQUARTER, OTHER, ONLINE,
}

export const InstitutionTypesVue = [
    {
        text: 'Öffentliche Schule',
        value: InstitutionTypes.PUBLICSCHOOL,
    },
    {
        text: 'Einrichtung',
        value: InstitutionTypes.INSTITUTION,
    },
    {
        text: 'Privatwohnung',
        value: InstitutionTypes.FLAT,
    },
    {
        text: 'Hauptsitz',
        value: InstitutionTypes.HEADQUARTER,
    },
    {
        text: 'Online-Standort',
        value: InstitutionTypes.ONLINE,
    },
    {
        text: 'Sonstige',
        value: InstitutionTypes.OTHER,
    },
];
