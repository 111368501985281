<template>
  <v-list dense style="max-height: 100%; overflow: hidden; width: 300px">
    <v-card-title class="title px-5 pb-5 pt-3">
      <div class="info--text">
        Deine Ablagestapel
      </div>
      <v-spacer/>
      <stacks-list-menu :show-closed-stacks="showClosedStacks"
                        @update:showClosedStacks="(v) => $emit('update:showClosedStacks', v)"/>
    </v-card-title>
    <v-divider/>
    <div v-if="initializing">
      <v-skeleton-loader type="list-item-avatar-two-line"/>
      <v-skeleton-loader type="list-item-avatar-two-line"/>
      <v-skeleton-loader type="list-item-avatar-two-line"/>
    </div>
    <v-list-item-group v-else v-model="activeStackIndex" :style="{ 'max-height': maxHeightCss }" style="overflow: auto">
      <v-card flat color="grey lighten-4" v-if="activeStacks.length > 0">
        <v-subheader>Stapel des ausgewählten Profils</v-subheader>
        <v-list-item v-for="activeStack in activeStacks" :key="activeStack._id" :color="activeStack.color">
          <v-list-item-avatar>
            <v-tooltip bottom :disabled="!activeStack.closedAt">
              <template v-slot:activator="{on}">
                <v-avatar :color="activeStack.color" size="28" v-on="on">
                  <v-icon color="white" x-small>
                    {{ activeStack.closedAt ? 'mdi-archive' : 'mdi-tray-full' }}
                  </v-icon>
                </v-avatar>
              </template>
              Dieser Stapel wurde archiviert
            </v-tooltip>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title :class="{ 'grey--text': activeStack.closedAt }">
              <overflow-text :text="activeStack.name"/>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="!activeStack.closedAt"
                              v-can:update-stacks="{ id: activeStack._id, keys: ['items'] }">
            <add-element-to-stack-button :stack-id="activeStack._id"/>
          </v-list-item-action>
          <v-list-item-action v-else v-can:update-stacks="{ id: activeStack._id, keys: ['items'] }">
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                <v-icon v-on="on" small>mdi-tray-remove</v-icon>
              </template>
              <div style="max-width: 200px">
                Dieser Stapel ist archiviert und kann deshalb keine neuen Elemente aufnehmen
              </div>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
        <v-divider/>
      </v-card>
      <v-list-item v-for="stack in filteredStacks" :key="stack._id" :color="stack.color">
        <v-list-item-avatar>
          <v-tooltip bottom :disabled="!stack.closedAt">
            <template v-slot:activator="{on}">
              <v-avatar :color="stack.color" size="28" v-on="on">
                <v-icon color="white" x-small>
                  {{ stack.closedAt ? 'mdi-archive' : 'mdi-tray-full' }}
                </v-icon>
              </v-avatar>
            </template>
            Dieser Stapel wurde archiviert
          </v-tooltip>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title :class="{ 'grey--text': stack.closedAt }">
            <overflow-text :text="stack.name"/>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="!stack.closedAt" v-can:update-stacks="{ id: stack._id, keys: ['items'] }">
          <add-element-to-stack-button :stack-id="stack._id"/>
        </v-list-item-action>
        <v-list-item-action v-else v-can:update-stacks="{ id: stack._id, keys: ['items'] }">
          <v-tooltip bottom>
            <template v-slot:activator="{on}">
              <v-icon v-on="on" small>mdi-tray-remove</v-icon>
            </template>
            <div style="max-width: 200px">
              Dieser Stapel ist archiviert und kann deshalb keine neuen Elemente aufnehmen
            </div>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
    <v-divider v-can:create-stacks v-if="stacks.length > 0"/>
    <v-list-item v-can:create-stacks @click="showCreateStackDialog = true">
      <v-list-item-avatar>
        <v-icon>mdi-plus</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          Neuen Stapel anlegen
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <create-stack-dialog v-model="showCreateStackDialog"/>
  </v-list>
</template>
<script lang="ts">
import Vue from 'vue';
import {Stack} from '@/interfaces/stack.interface';
import {StackManagerApi} from '@/classes/api/stack-manager.api.class';
import {StackBus} from '@/busses/StackBus';
import {Types} from 'mongoose';
import OverflowText from '@/components/common/overflow-text.vue';
import CreateStackDialog from '@/components/stack/createStackDialog.vue';
import AddElementToStackButton from '@/components/stack/stackDialog/stacksList/AddElementToStackButton.vue';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';
import StacksListMenu from '@/components/stack/stackDialog/stacksList/StacksListMenu.vue';

export default Vue.extend({
  components: {StacksListMenu, AddElementToStackButton, CreateStackDialog, OverflowText},
  props: {
    stackId: {
      type: Types.ObjectId,
      required: true,
    },
    showClosedStacks: {
      type: Boolean,
      required: true,
    },
    activeStacks: {
      type: Array as () => Stack[],
      default: () => [],
    },
  },
  data: () => ({
    showCreateStackDialog: false,
    initializing: false,
    stacks: [] as Stack[],
  }),
  computed: {
    filteredStacks(): Stack[] {
      const activeStackIds = this.activeStacks.map((s) => s._id.toString());
      return this.stacks.filter((el) => {
        return !activeStackIds.includes(el._id.toString());
      });
    },
    maxHeightCss(): string {
      if (this.$$crudAccessManager.getAccess(CrudEntityTypes.STACK, CrudActionTypes.CREATE)) {
        return 'calc(100% - 114px)';
      } else {
        return 'calc(100% - 57px)';
      }
    },
    activeStackIndex: {
      get(): number {
        return this.stacks.findIndex((s) => s._id === this.stackId);
      },
      set(v: number) {
        if (v >= 0) {
          this.$emit('update:stackId', this.stacks[v]._id);
        } else {
          this.$emit('update:stackId', null);
        }
      },
    },
  },
  methods: {
    async getStacks() {
      if (!this.$$crudAccessManager.canAccessType(CrudEntityTypes.STACK, CrudActionTypes.READ)) {
        return;
      }
      this.initializing = true;
      try {
        const resp = await StackManagerApi.find({
          skipPagination: true,
          filter: this.showClosedStacks ? undefined : {isClosed: false},
          fields: ['name', 'color', 'closedAt'],
          sortBy: 'updatedAt',
          sortDesc: true,
        });
        if (this.activeStacks.length > 0) {
          const activeStackIds = this.activeStacks.map((s) => s._id.toString());
          this.stacks = [...this.activeStacks, ...resp.stacks.filter((el) => !activeStackIds.includes(el._id.toString()))]
        } else {
          this.stacks = resp.stacks;
        }
        if (this.stackId) {
          const found = this.stacks.find((stack) => stack._id === this.stackId);
          if (!found) {
            this.$emit('update:stackId', null);
          }
        }
      } catch (e) {
        this.$$showSnackbar(`Fehler beim Laden der Ablagestapel`);
      } finally {
        this.initializing = false;
      }
    },
  },
  watch: {
    showClosedStacks() {
      this.getStacks();
    },
  },
  mounted() {
    StackBus.$on('reload:stacks', this.getStacks);
    StackBus.$on('deleted:stack', this.getStacks);
    this.getStacks();
  },
});
</script>
