<template>
  <v-badge :value="!notification.readAt" color="red" dot offset-x="25" offset-y="20" style="width: 100%">
    <v-list-item :class="{ 'red lighten-5': !notification.readAt }" three-line>
      <v-list-item-avatar>
        <v-icon color="error">mdi-alert</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle>
          Fehler bei Kursabsage
        </v-list-item-subtitle>
        <v-list-item-title v-if="notification.messagePayload">
          <ul>
            <li v-if="notification.messagePayload.institutionErrorCount">
              Probleme bei Standort
            </li>
            <li v-if="notification.messagePayload.studentErrorCount">
              Probleme bei {{ notification.messagePayload.studentErrorCount }}
              {{ notification.messagePayload.studentErrorCount === 1 ? 'Schüler:in' : 'Schüler:innen' }}
            </li>
            <li v-if="notification.messagePayload.teacherErrorCount">
              Probleme bei Lehrkraft
            </li>
          </ul>
        </v-list-item-title>
        <v-list-item-subtitle v-if="!notification.dismissedAt">
          {{ moment(notification.createdAt).fromNow() }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          <v-icon small>mdi-delete</v-icon>
          {{ moment(notification.dismissedAt).fromNow() }} gelöscht
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="directLink">
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn :href="directLink" icon target="_blank" v-on="on">
              <v-icon>mdi-page-next-outline</v-icon>
            </v-btn>
          </template>
          <span>Zum Profil</span>
        </v-tooltip>
      </v-list-item-action>
      <v-list-item-action v-if="!notification.dismissedAt">
        <v-btn icon @click="$emit('dismiss', notification._id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action v-else>
        <v-btn icon @click="$emit('revoke', notification._id)">
          <v-icon>mdi-delete-off</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-badge>
</template>
<script lang="ts">
import Vue from 'vue';
import {NotificationLike} from '@/types/NotificationLike.type';
import moment from 'moment';
import {
  CourseCancellationFailedNotificationDocument,
} from '@/documentTypes/notifier/discriminators/substitutionManager/CourseCancellationFailedNotification.document';

moment.locale('de');

export default Vue.extend({
  props: {
    notification: {
      type: Object as () => NotificationLike<CourseCancellationFailedNotificationDocument>,
      required: true,
    },
  },
  computed: {
    moment: () => moment,
    directLink(): string {
      return this.$router.resolve(`/substitution-inquiries-table?q=1&filter._id=["${this.notification.messagePayload.substitutionInquiryId}"]`).href;
    },
  },
});
</script>
