import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {BookingFormFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/BookingFormFields';

export const bookingFormFieldsDictionary: FieldDictionary<BookingFormFields> = [
    {
        text: 'Vorlage',
        value: BookingFormFields.TEMPLATE,
    },
    {
        text: 'Art der Kursauswahl',
        value: BookingFormFields.COURSE_TYPE,
    },
    {
        text: 'Wählbare Zeitslots',
        value: BookingFormFields.SELECTABLE_TIMESLOTS,
    },
    {
        text: 'Wählbare Kurse',
        value: BookingFormFields.SELECTABLE_COURSES,
    },
    {
        text: 'Verwendung offener Kurse?',
        value: BookingFormFields.USE_OPEN_COURSES,
    },
    {
        text: 'Verwendung von Kursen im Entwurfstatus?',
        value: BookingFormFields.USE_DRAFT_COURSES,
    },
    {
        text: 'Konfiguration fü gedrucktes Formular',
        value: BookingFormFields.PRINTED_FORM_CONFIG,
    },
    {
        text: 'Online-Formular',
        value: BookingFormFields.ONLINE_FORM_CONFIG,
    },
    {
        text: 'Rechnungsvorlagen',
        value: BookingFormFields.INVOICE_TEMPLATES,
    },
    {
        text: 'Standort',
        value: BookingFormFields.INSTITUTION,
    },
    {
        text: 'Anmeldefrist',
        value: BookingFormFields.DEADLINE,
    },
    {
        text: 'Platzhalterwerte',
        value: BookingFormFields.PLACEHOLDER_VALUES,
    },
    {
        text: 'Kurz-ID',
        value: BookingFormFields.SHORT_ID,
    },
    {
        text: 'Ist Standardformular für Standort?',
        value: BookingFormFields.IS_INSTITUTION_DEFAULT,
    },
    {
        text: 'Gedrucktes Formular',
        value: BookingFormFields.PRINTED_FORM,
    },
    {
        text: 'Verknüpfte Werbeaktion',
        value: BookingFormFields.HANDOUT_EVENT,
    },
    {
        text: 'Zusätzliche Dokumente',
        value: BookingFormFields.ADDITIONAL_DOCUMENTS,
    },
];
