import {Document, Types} from 'mongoose';
import {FinderResponseDTO} from '../dto/finder/response/Finder.response.dto';
import {Room} from '@/interfaces/room.interface';
import {Student} from '@/interfaces/student.interface';
import {API} from '@/classes/api/api.class';
import {FindIndexRequestDto} from '@/classes/dto/finder/request/FindIndex.request.dto';
import {FinderIndices} from '@/enums/FinderIndices';
import {FinderResponseTypes} from '@/enums/FinderResponseTypes';
import {FindVueItemsResponseDto} from '@/classes/dto/finder/response/FindVueItems.response.dto';
import {FindGlobalResponseDto} from '@/classes/dto/finder/response/FindGlobal.response.dto';
import {FindIndicesRequestDto} from '@/classes/dto/finder/request/FindIndices.request.dto';

/**
 * This class is used for communicating with the finder microservice.
 * @class FinderAPI
 * @author Joshua Seipel
 */
export class FinderAPI extends API {
  static service = 'finder';

  static async findGlobal(term: string): Promise<FindGlobalResponseDto> {
    return this.get('global', { term });
  }

  static async findIndex(index: FinderIndices, term: string, minScore?: number): Promise<FindVueItemsResponseDto> {
    const data: FindIndexRequestDto = {
      index,
      term,
      minScore,
    };
    return this.get(`index/${FinderResponseTypes.VUE_ITEMS}`, data);
  }

  static async findIndices(indices: FinderIndices[], term: string): Promise<FindVueItemsResponseDto> {
    const data: FindIndicesRequestDto = {
      indices,
      term,
    };
    return this.get(`indices/${FinderResponseTypes.VUE_ITEMS}`, data);
  }

  static async findRoomsByInstitution(query: string, institutionId: Types.ObjectId): Promise<FinderResponseDTO<Room>> {
    return this.get(`rooms/byInstitution/${institutionId}/${query}`, query);
  }

  static async findStudentsByInstitution(query: string, institutionId: Types.ObjectId): Promise<FinderResponseDTO<Student>> {
    return this.get(`students/byInstitution/${institutionId}/${query}`, query);
  }

  /**
   * @deprecated
   * @param index
   * @param query
   */
  static async findIndexDep<T extends Document>(index: string | string[], query: string): Promise<FinderResponseDTO<T>> {
    if (Array.isArray(index)) {
      index = index.join();
    }
    return this.get(`${index}/${query}`, query);
  }
}
