<template>
  <v-expansion-panel-header>
    {{ title }}
    <div class="text-right mt-n2 mb-n2">
      <v-slide-x-reverse-transition>
        <v-chip small color="success" class="mr-2" v-if="filterCount > 0 && panelValue !== value">
          {{ filterCount }} Filter
        </v-chip>
      </v-slide-x-reverse-transition>
    </div>
  </v-expansion-panel-header>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    panelValue: {
      type: Number as () => number | null,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    filterCount: {
      type: Number,
      required: true,
    },
  },
})
</script>
