export enum BookingFormFields {
    TEMPLATE = 'template',
    COURSE_TYPE = 'courseType',
    SELECTABLE_TIMESLOTS = 'selectableTimeslots',
    SELECTABLE_COURSES = 'selectableCourses',
    USE_OPEN_COURSES = 'useOpenCourses',
    USE_DRAFT_COURSES = 'useDraftCourses',
    PRINTED_FORM_CONFIG = 'printedFormConfig',
    ONLINE_FORM_CONFIG = 'onlineFormConfig',
    INVOICE_TEMPLATES = 'invoiceTemplates',
    INSTITUTION = 'institution',
    DEADLINE = 'deadline',
    PLACEHOLDER_VALUES = 'placeholderValues',
    SHORT_ID = 'shortId',
    IS_INSTITUTION_DEFAULT = 'isInstitutionDefault',
    PRINTED_FORM = 'printedForm',
    HANDOUT_EVENT = 'handoutEvent',
    ADDITIONAL_DOCUMENTS = 'additionalDocuments',
}
