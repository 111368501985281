export enum ParentFields {
    GENDER = 'gender',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    BIRTHDAY = 'birthday',
    ADDRESS = 'address',
    PAYMENT = 'payment',
    HAS_LOGIN = 'hasLogin',
    IS_ACTIVE = 'isActive',
    DEACTIVATED_AT = 'deactivatedAt',
    CONTACT_INFORMATION = 'contactInformation',
    CUSTOM_ID = 'customId',
    CUSTOM_ID_SCHEME = 'customIdScheme',
    ACCOUNTING_REF = 'accountingRef',
    TAGS = 'tags',
    SUBMISSION = 'submission',

    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',

    DEACTIVATE_AT = 'deactivateAt',
    ACTIVATE_AT = 'activateAt',
}
