import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';

export enum LogEntryTypes {
    TERMINATION = 'termination',
    COURSE = 'course',
    COMMON = 'common',
    RETURN_LABEL = 'return_label',

    RETURNING_DONE = 'returning_done',
    RETURNING_DELAYED = 'returning_delayed',

    PHONE = 'phone',

    QUALITY_MANAGEMENT_TEACHER = 'quality_management_teacher',

    MESSENGER_MAIL = 'messenger_mail',
    MESSENGER_WHATS_APP = 'messenger_whats_app',
}

export const LogEntryTypesItems = [
    {
        value: LogEntryTypes.TERMINATION,
        text: 'Kündigungen',
    },
    {
        value: LogEntryTypes.COURSE,
        text: 'Kurse',
    },
    {
        value: LogEntryTypes.COMMON,
        text: 'Allgemein',
    },
    {
        value: LogEntryTypes.RETURN_LABEL,
        text: 'Rücksendeetiketten',
    },
    {
        value: LogEntryTypes.RETURNING_DONE,
        text: 'Rückgaben erledigt',
    },
    {
        value: LogEntryTypes.RETURNING_DELAYED,
        text: 'Rückgaben verzögert',
    },
    {
        value: LogEntryTypes.PHONE,
        text: 'Telefonate',
    },
    {
        value: LogEntryTypes.QUALITY_MANAGEMENT_TEACHER,
        text: 'Qualitätsmanagement Lehrer',
    },
    {
        value: LogEntryTypes.MESSENGER_MAIL,
        text: 'Messenger Mail',
    },
    {
        value: LogEntryTypes.MESSENGER_WHATS_APP,
        text: 'Messenger WhatsApp',
    },
];

export enum LogEntryAuthorTypes {
    EMPLOYEE = 'Employee',
    INSTITUTION = 'Institution',
    SYSTEM = 'System',
    MESSENGER_ACCOUNT = 'MessengerAccount',
}

export enum LogEntryObjectTypes {
    EMPLOYEE = 'Employee',
    INSTITUTION = 'Institution',
    STUDENT = 'Student',
    PARENT = 'Parent',
    RESPONSIBLE = 'Responsible',
}

export namespace LogEntryObjectTypes {
    export function fromCrudEntityTypes(type: CrudEntityTypes.EMPLOYEE | CrudEntityTypes.INSTITUTION | CrudEntityTypes.PARENT | CrudEntityTypes.STUDENT | CrudEntityTypes.RESPONSIBLE): LogEntryObjectTypes {
        switch (type) {
            case CrudEntityTypes.EMPLOYEE:
                return LogEntryObjectTypes.EMPLOYEE;
            case CrudEntityTypes.INSTITUTION:
                return LogEntryObjectTypes.INSTITUTION;
            case CrudEntityTypes.PARENT:
                return LogEntryObjectTypes.PARENT;
            case CrudEntityTypes.STUDENT:
                return LogEntryObjectTypes.STUDENT;
            case CrudEntityTypes.RESPONSIBLE:
                return LogEntryObjectTypes.RESPONSIBLE;
        }
    }
}

