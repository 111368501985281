import {Types} from 'mongoose';
import {CrudEntityTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudEntityTypes';
import CrudAccess, {EntityAccessRecord, FieldAccessRecord} from '@/classes/clientOnly/permissionTreeResources/interfaces/CrudAccess';
import {CrudActionTypes} from '@/classes/clientOnly/permissionTreeResources/enums/CrudActionTypes';

/** Type restriction for entities not meant to be used for READ and UPDATE operations */
export type SingleAccessEntityTypes = Exclude<CrudEntityTypes,
    CrudEntityTypes.HANDOUT_TEMPLATE | CrudEntityTypes.OWN_DOCUMENT | CrudEntityTypes.ANY_DOCUMENT>;

/**
 * Helper for easier and less repetitive crud permission validation.
 */
export default class CrudAccessEntity {
    private _type: SingleAccessEntityTypes;
    private _id: string;
    private _crudAccess: CrudAccess;

    public constructor(type: SingleAccessEntityTypes, id: Types.ObjectId, crudAccess: CrudAccess) {
        this._type = type;
        this._id = id.toString();
        this._crudAccess = crudAccess;
    }

    public get type(): SingleAccessEntityTypes {
        return this._type;
    }

    public get id(): string {
        return this._id;
    }

    public get hasFullReadAccess(): boolean {
        return this._getHasFullAccess(CrudActionTypes.READ);
    }

    public get readFields(): Record<string, 0 | 1> {
        return this._getActionFields(CrudActionTypes.READ);
    }

    public get hasFullUpdateAccess(): boolean {
        return this._getHasFullAccess(CrudActionTypes.UPDATE);
    }

    public get updateFields(): Record<string, 0 | 1> {
        return this._getActionFields(CrudActionTypes.UPDATE);
    }

    /**
     * Checks whether an action can be performed on the keys of a certain entity.
     * If no key is provided, returns true if ANY key can be accessed.
     * @param action The action which shall be performed
     * @param key (Optional) The key to check for
     */
    public canAccess<T extends string = string>(action: CrudActionTypes.READ | CrudActionTypes.UPDATE, key?: T): boolean {
        if (this._crudAccess[action].fullAccess) {
            return true;
        }
        const entityScope = this._getEntityScope(action);
        const entityTypeScope = this._getEntityTypeScope(action);
        if (!entityScope && !entityTypeScope) {
            return false;
        }
        if (entityScope && entityScope.fullAccess || entityTypeScope && entityTypeScope.fullAccess) {
            return true;
        }
        if (entityScope) {
            if (key) {
                return !!entityScope[key];
            } else {
                for (const k in entityScope) {
                    if (entityScope.hasOwnProperty(k) && entityScope[k]) {
                        return true;
                    }
                }
            }
        }
        if (entityTypeScope) {
            if (key) {
                return !!entityTypeScope[key];
            } else {
                for (const k in entityTypeScope) {
                    if (entityTypeScope.hasOwnProperty(k) && entityTypeScope[k]) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * Checks whether an action can be performed on ALL given keys of a certain entity.
     * @param action The action which shall be performed
     * @param keys The keys to check for
     */
    public canAccessAll<T extends string = string>(action: CrudActionTypes.READ | CrudActionTypes.UPDATE, ...keys: T[]): boolean {
        for (const key of keys) {
            if (!this.canAccess(action, key)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Checks whether an action can be performed on at least ONE of the given keys of a certain entity.
     * @param action The action which shall be performed
     * @param keys The keys to check for
     */
    public canAccessOne<T extends string = string>(action: CrudActionTypes.READ | CrudActionTypes.UPDATE, ...keys: T[]): boolean {
        for (const key of keys) {
            if (this.canAccess(action, key)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Checks whether a field of a certain entity can be read.
     * If no key is provided, returns true if ANY key can be updated.
     * @param key
     */
    canRead<T extends string = string>(key?: T): boolean {
        return this.canAccess(CrudActionTypes.READ, key);
    }
    /**
     * Checks whether ALL given keys can be read.
     * @param keys The keys to check for
     */
    canReadAll<T extends string = string>(...keys: T[]): boolean {
        return this.canAccessAll(CrudActionTypes.READ, ...keys);
    }
    /**
     * Checks whether at least ONE of the given keys can be read.
     * @param keys The keys to check for
     */
    canReadOne<T extends string = string>(...keys: T[]): boolean {
        return this.canAccessOne(CrudActionTypes.READ, ...keys);
    }

    /**
     * Checks whether a field of a certain entity can be updated.
     * If no key is provided, returns true if ANY key can be updated.
     * @param key
     */
    canUpdate<T extends string = string>(key?: T): boolean {
        return this.canAccess(CrudActionTypes.UPDATE, key);
    }
    /**
     * Checks whether ALL given keys can be updated.
     * @param keys The keys to check for
     */
    canUpdateAll<T extends string = string>(...keys: T[]): boolean {
        return this.canAccessAll(CrudActionTypes.UPDATE, ...keys);
    }
    /**
     * Checks whether at least ONE of the given keys can be updated.
     * @param keys The keys to check for
     */
    canUpdateOne<T extends string = string>(...keys: T[]): boolean {
        return this.canAccessOne(CrudActionTypes.UPDATE, ...keys);
    }

    private _getEntityScope(action: CrudActionTypes.READ | CrudActionTypes.UPDATE): FieldAccessRecord<any> | undefined {
        const actionScope = this._crudAccess[action];
        if (!actionScope[this._type]) {
            return undefined;
        }
        const entityTypeScope = actionScope[this._type];
        if (!entityTypeScope || !entityTypeScope[this._id]) {
            return undefined;
        }
        return entityTypeScope[this._id];
    }

    private _getEntityTypeScope(action: CrudActionTypes): FieldAccessRecord<any> | undefined {
        const actionScope = this._crudAccess[action];
        if (!actionScope[this._type]) {
            return undefined;
        }
        const entityTypeScope = actionScope[this._type] as EntityAccessRecord<any>;
        return entityTypeScope.all;
    }

    private _getActionFields(action: CrudActionTypes.READ | CrudActionTypes.UPDATE): Record<string, 0 | 1> {
        if (this._crudAccess[action].fullAccess) {
            return {};
        }
        const entityTypeAccess = this._getEntityTypeScope(action);
        if (entityTypeAccess && entityTypeAccess.fullAccess) {
            return {};
        }
        const entityAccess = this._getEntityScope(action);
        if (entityAccess && entityAccess.fullAccess) {
            return {};
        }
        let fieldAccess = {} as Record<string, boolean>;
        if (entityAccess && entityTypeAccess) {
            fieldAccess = { ...entityTypeAccess, ...entityAccess };
        } else if (entityAccess) {
            fieldAccess = entityAccess;
        } else if (entityTypeAccess) {
            fieldAccess = entityTypeAccess;
        } else {
            return {_id: 1};
        }

        const record = {} as Record<string, 0 | 1>;
        for (const key in fieldAccess) {
            if (fieldAccess[key]) {
                record[key] = 1;
            }
        }
        return record;
    }

    private _getHasFullAccess(actionType: CrudActionTypes.READ | CrudActionTypes.UPDATE) {
        if (this._crudAccess[actionType].fullAccess) {
            return true;
        }
        const entityTypeAccess = this._crudAccess[actionType][this.type];
        if (entityTypeAccess) {
            if (entityTypeAccess.all && entityTypeAccess.all.fullAccess) {
                return true;
            }
            const entityAccess = entityTypeAccess[this.id];
            if (entityAccess && entityAccess.fullAccess) {
                return true;
            }
        }
        return false;
    }
}
