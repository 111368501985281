import FieldDictionary from '@/classes/clientOnly/permissionTreeResources/dictionaries/fieldDictionary';
import {ToDoItemFields} from '@/classes/clientOnly/permissionTreeResources/enums/entityFields/ToDoItemFields';

export const toDoItemFieldsDictionary: FieldDictionary<ToDoItemFields> = [
    {
        text: 'Bezugselement',
        value: ToDoItemFields.ENTITY,
        readOnly: true,
    }, {
        text: 'Bezugselementtyp',
        value: ToDoItemFields.ENTITY_TYPE,
        readOnly: true,
    }, {
        text: 'Name',
        value: ToDoItemFields.NAME,
    }, {
        text: 'Beschreibung',
        value: ToDoItemFields.DESCRIPTION,
    }, {
        text: 'Fälligkeitsdatum',
        value: ToDoItemFields.DUE_DATE,
    }, {
        text: 'Verantwortlicher',
        value: ToDoItemFields.RESPONSIBLE,
    }, {
        text: 'Erledigt',
        value: ToDoItemFields.COMPLETED,
    }, {
        text: 'Erledigt am',
        value: ToDoItemFields.COMPLETED_AT,
        readOnly: true,
    }, {
        text: 'Kommentar zur Erledigung',
        value: ToDoItemFields.COMPLETION_COMMENT,
    }, {
        text: 'Daten',
        value: ToDoItemFields.DATA,
    }, {
        text: 'Datentyp',
        value: ToDoItemFields.DATA_TYPE,
        readOnly: true,
    }, {
        text: 'Vorlage',
        value: ToDoItemFields.FROM_TEMPLATE,
        readOnly: true,
    }, {
        text: 'Erstellt am',
        value: ToDoItemFields.CREATED_AT,
        readOnly: true,
    }, {
        text: 'Aktualisiert am',
        value: ToDoItemFields.UPDATED_AT,
        readOnly: true,
    },
];
