<template>
  <v-card flat color="transparent">
    <v-card-text>
      <v-select :items="avataaarTopTypeItems" :value="topType" color="info" dense item-color="info"
                label="Frisur auswählen" outlined rounded @input="(v) => $emit('update:topType', v)"/>
      <v-slide-y-transition>
        <div v-if="!hairlessTypes.includes(topType)">
          <v-subheader>Haarfarbe</v-subheader>
          <color-button :value="topColor" color="#ECDCBF" icon-color="black" output-value="platinum"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#D6B370" output-value="blondeGolden"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#B58143" output-value="blonde"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#A55729" output-value="auburn"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#724133" output-value="brown"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#4A312C" output-value="brownDark"
                        @input="(v) => $emit('update:topColor', v)"/>
          <v-divider/>
          <color-button :value="topColor" color="#FFFFFF" icon-color="black" output-value="white"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#E6E6E6" icon-color="black" output-value="gray01"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#939598" output-value="gray02"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#3C4F5C" output-value="heather"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#2B1B18" output-value="black"
                        @input="(v) => $emit('update:topColor', v)"/>
          <v-divider/>
          <color-button :value="topColor" color="#B1E2FF" output-value="pastelBlue"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#65C9FF" output-value="blue01"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#5199E4" output-value="blue02"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#26557C" output-value="blue03"
                        @input="(v) => $emit('update:topColor', v)"/>
          <v-divider/>
          <color-button :value="topColor" color="#FFFFB1" icon-color="black" output-value="pastelYellow"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#FFDEB5" output-value="pastelOrange"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#FFAFB9" output-value="pastelRed"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#C93305" output-value="red"
                        @input="(v) => $emit('update:topColor', v)"/>
          <color-button :value="topColor" color="#FF488E" output-value="pink"
                        @input="(v) => $emit('update:topColor', v)"/>
          <v-divider/>
          <color-button :value="topColor" color="#A7FFC4" output-value="pastelGreen"
                        @input="(v) => $emit('update:topColor', v)"/>
        </div>
      </v-slide-y-transition>
    </v-card-text>
    <v-card-actions>
      <v-btn color="info" text @click="$emit('prev')">
        <v-icon>mdi-chevron-left</v-icon>
        Zurück
      </v-btn>
      <v-spacer/>
      <v-btn color="info" text @click="$emit('next')">
        Weiter
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue';
import {avataaarTopType} from '@/constants/avataaar.constant';
import ColorButton from '@/components/common/profile/createAvataaarDialog/colorButton.vue';

export default Vue.extend({
  components: {ColorButton},
  data: () => ({
    hairlessTypes: ['none', 'hat', 'hijab', 'turban', 'winterHat1', 'winterHat2', 'winterHat3', 'winterHat4'],
  }),
  props: {
    topType: {
      type: String,
      default: '',
    },
    topColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    avataaarTopTypeItems: () => avataaarTopType,
  },
});
</script>
