<template>
  <v-dialog :value="value" :persistent="loading" @input="reset" max-width="1200"
            :fullscreen="$vuetify.breakpoint.smAndDown">
    <v-card>
      <v-card-title>
        <div class="success--text">
          Vanilla hat mehrere Möglichkeiten zum Öffnen der Liste erkannt!
        </div>
        <v-spacer/>
        <v-btn icon :disabled="loading" @click="reset">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="loading">
        <v-skeleton-loader type="list-item-avatar-two-line@2"/>
      </v-card-text>
      <v-card-text v-else>
        <v-alert type="success">
          Der Standort kann entweder in <strong>einer der unten aufgeführten Akquiselisten</strong> oder direkt über das
          <strong>Standortprofil</strong> geöffnet werden.
        </v-alert>

        <v-subheader>Standortprofil</v-subheader>
        <v-list>
          <v-list-item :href="$router.resolve(`/institutions/${institution._id}`).href" target="_blank">
            <v-list-item-avatar>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ institution.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-subheader>Akquiselisten</v-subheader>
        <v-data-table :headers="headers" :items="acquisitionLists" :loading="loading" :server-items-length="1"
                      class="clickable-data-table"
                      hide-default-footer @click:row="(item) => handleRowClick(item)">
          <template v-slot:item.assignee="{ item }">
            <profile-avatar :user="item.assignee.meta" :size="40" show-tooltip
                            v-if="item.assignee && item.assignee.meta"/>
            <span v-else>Keine Person zugewiesen</span>
          </template>
          <template v-slot:item.forEmployee="{ item }">
            {{ item.forEmployee.firstName }} {{ item.forEmployee.lastName }}
          </template>
          <template v-slot:item.institutions="{ item }">
            {{ item.institutions.length }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="reset" text :disabled="loading">
          Abbrechen
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import {Institution} from "@/interfaces/institution.interface";
import {AcquisitionList} from "@/documentTypes/institutioner/acquisition/AcquisitionList.document";
import {retrieveObjectId} from "@/helpers/isObjectId.helper";
import {DataTableHeader} from "vuetify";
import ProfileAvatar from "@/components/common/profile-avatar.vue";

export default Vue.extend({
  components: {ProfileAvatar},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    acquisitionLists: {
      type: Object as () => AcquisitionList[],
      required: true,
    },
    institution: {
      type: Object as () => Institution,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    headers(): DataTableHeader[] {
      return [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Zuständig',
          value: 'assignee',
        },
        {
          text: 'Für Lehrkraft',
          value: 'forEmployee',
        },
        {
          text: 'Standorte',
          value: 'institutions',
        },
      ]
    },
  },
  methods: {
    handleRowClick(item: AcquisitionList) {
      const id = retrieveObjectId(item);
      const link = this.$router.resolve({
        path: `/acquisitionLists/${id}/${this.institution._id}`,
      }).href;
      window.open(link, '_blank');
      this.reset();
    },
    reset() {
      this.$emit('input', false);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v: boolean) {
        if (v) {
          if (!this.institution && !(this.institution as Institution).name) {
            this.$$showSnackbar('Fehler beim Laden der Daten.', 'error');
            this.reset();
          }
        }
      },
    },
  },
})
</script>
